// /src/components/ClientPreferences.js

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  OutlinedInput,
  Card,
  CardContent,
  Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { doc, updateDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';

// Styled Components (matching your existing patterns)
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground)' : 'var(--light-foreground)',
  color: theme.palette.mode === 'dark' ? 'var(--dark-text)' : 'var(--light-text)',
  boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-md-dark)' : 'var(--shadow-md-light)',
  borderRadius: 'var(--border-radius-base)',
  transition: 'all var(--transition-duration) var(--transition-ease)',
  '&:hover': {
    boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-lg-dark)' : 'var(--shadow-lg-light)',
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '16px',
  borderRadius: 'var(--border-radius-base)',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'var(--dark-foreground-accent)' 
    : 'var(--light-foreground-accent)',
  boxShadow: theme.palette.mode === 'dark'
    ? 'var(--shadow-sm-dark)'
    : 'var(--shadow-sm-light)',
}));

const CleanerBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark'
      ? 'var(--dark-foreground-accent)'
      : 'var(--light-foreground-accent)',
    boxShadow: theme.palette.mode === 'dark'
      ? 'var(--shadow-sm-dark)'
      : 'var(--shadow-sm-light)',
    border: `2px solid ${
      theme.palette.mode === 'dark'
        ? 'var(--dark-focus-ring)'
        : 'var(--light-focus-ring)'
    }`,
    borderRadius: '8px',
    padding: '16px',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  }));

const PreferenceSelect = styled(Select)(({ theme }) => ({
  minWidth: 200,
}));

const ClientPreferences = ({ customer, orgId, recurrenceGroupId }) => {
  const [cleanerHistory, setCleanerHistory] = useState([]);
  const [availableCleaners, setAvailableCleaners] = useState([]); 
  const [preferences, setPreferences] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const timeOptions = [
    'Early Morning',
    'Morning',
    'Afternoon',
    'Evening',
    'Flexible'
  ];

  const dayOptions = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  const cleanerOptions = [
    'Preferred',
    'Flexible'
  ];

  // Add function to fetch existing preferences
  const fetchCustomerPreferences = async () => {
    try {
      // If we have a recurrenceGroupId, fetch from the recurringServices collection
      if (recurrenceGroupId) {
        const recurringServiceRef = doc(db, 'organizations', orgId, 'recurringServices', recurrenceGroupId);
        const recurringServiceDoc = await getDoc(recurringServiceRef);
        
        if (recurringServiceDoc.exists()) {
          const data = recurringServiceDoc.data();
          setPreferences({
            preferredCleaners: data.preferredCleaners || [],
            timePreference: data.timePreference || 'Flexible',
            dayPreference: data.dayPreference || [],
            recurrenceGroupId: recurrenceGroupId
          });
        } else {
          setPreferences({
            preferredCleaners: [],
            timePreference: 'Flexible',
            dayPreference: [],
            recurrenceGroupId: recurrenceGroupId
          });
        }
      } else {
        // Fallback for non-recurring preferences if needed
        const customerRef = doc(db, 'organizations', orgId, 'customers', customer.id);
        const customerDoc = await getDoc(customerRef);
        
        if (customerDoc.exists()) {
          const data = customerDoc.data();
          setPreferences({
            preferredCleaners: data.preferredCleaners || [],
            timePreference: data.timePreference || 'Flexible',
            dayPreference: data.dayPreference || []
          });
        } else {
          setPreferences({
            preferredCleaners: [],
            timePreference: 'Flexible',
            dayPreference: []
          });
        }
      }
    } catch (err) {
      console.error('Error fetching customer preferences:', err);
      setError('Failed to fetch preferences');
    }
  };

  // Function to fetch all active cleaners
  const fetchAvailableCleaners = async () => {
    try {
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const cleanersList = usersSnapshot.docs
        .map(doc => ({ 
          id: doc.id,
          ...doc.data() 
        }))
        .filter(user => 
          user.orgId === orgId && 
          user.role === 'cleaner' &&
          user.isActive !== false
        );

      // Format cleaner names consistent with your pattern
      const formattedCleaners = cleanersList.map(cleaner => ({
        id: cleaner.id,
        name: cleaner.firstName && cleaner.lastName
          ? `${cleaner.firstName} ${cleaner.lastName}`
          : cleaner.email
      }));

      setAvailableCleaners(formattedCleaners);
    } catch (err) {
      console.error('Error fetching cleaners:', err);
      setError('Failed to fetch cleaners list');
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      if (orgId && customer?.id) {
        setLoading(true);
        try {
          await Promise.all([
            fetchCustomerPreferences(),
            fetchCleanerHistory(),
            fetchAvailableCleaners()
          ]);
        } catch (err) {
          console.error('Error fetching data:', err);
          setError('Failed to fetch data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [orgId, customer, recurrenceGroupId]);

  // Fetch cleaner history and details
  const fetchCleanerHistory = async () => {
    try {

      // Just query by customerId
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const jobsQuery = query(
        jobsRef,
        where('customerId', '==', customer.id),
        where('status', '==', 'Completed')
      );
  
      const jobsSnapshot = await getDocs(jobsQuery);
      const jobs = jobsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      // Filter jobs from last 6 months in memory
      const sixMonthsAgo = moment().subtract(6, 'months').toDate();
      const recentJobs = jobs.filter(job => {
        const jobDate = job.appointmentDate?.toDate?.() || job.appointmentDate;
        return jobDate >= sixMonthsAgo;
      });
  
      // Get unique cleaner info
      const cleanerMap = new Map();
      recentJobs.forEach(job => {
        if (job.cleanerId) {
          // If we haven't seen this cleaner or this job is more recent
          const existingJob = cleanerMap.get(job.cleanerId);
          const jobDate = job.appointmentDate?.toDate?.() || job.appointmentDate;
          
          if (!existingJob || jobDate > (existingJob.appointmentDate?.toDate?.() || existingJob.appointmentDate)) {
            cleanerMap.set(job.cleanerId, job);
          }
        }
      });
  
      const cleanerHistory = Array.from(cleanerMap.values()).map(job => ({
        id: job.cleanerId,
        name: job.cleanerName || 'Unknown Cleaner',
        mostRecentVisit: job.appointmentDate?.toDate?.() || job.appointmentDate
      }));
  
      setCleanerHistory(cleanerHistory);
  
    } catch (err) {
      console.error('Error in fetchCleanerHistory:', err);
      setError(`Failed to fetch cleaner history: ${err.message}`);
    }
  };

  // Save preferences to database
  const savePreferences = async (newPreferences) => {
    try {
      if (recurrenceGroupId) {
        const recurringServiceRef = doc(db, 'organizations', orgId, 'recurringServices', recurrenceGroupId);
        await updateDoc(recurringServiceRef, {
          preferredCleaners: newPreferences.preferredCleaners,
          timePreference: newPreferences.timePreference,
          dayPreference: newPreferences.dayPreference,
          updatedAt: new Date()
        });
      } else {
        const customerRef = doc(db, 'organizations', orgId, 'customers', customer.id);
        await updateDoc(customerRef, newPreferences);
      }
    } catch (err) {
      console.error('Error saving preferences:', err);
      setError('Failed to save preferences');
    }
  };

  // Handle preference changes
  const handlePreferenceChange = async (field, value) => {
    const newPreferences = { ...preferences, [field]: value };
    setPreferences(newPreferences);
    await savePreferences(newPreferences);
  };

  // Don't render until we have preferences loaded
  if (loading || !preferences) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <div>
        <StyledCard>
        <CardContent>
            <Typography variant="h6" gutterBottom>Client Preferences</Typography>
            <Grid container spacing={3}>
            {/* Section 1: Cleaner History */}
            <Grid item xs={12} md={4}>
                <StyledBox sx={{ height: '240px' }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Cleaner Assignment History
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        {cleanerHistory.length > 0 ? (
                            <Grid container spacing={2}>
                                {cleanerHistory.map((cleaner) => (
                                    <Grid item xs={12} key={cleaner.id}>
                                        <CleanerBox>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                                {cleaner.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                Last Visit: {moment(cleaner.mostRecentVisit).format('MM/DD/YYYY')}
                                            </Typography>
                                        </CleanerBox>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Typography color="textSecondary">
                                No recent cleaner history
                            </Typography>
                        )}
                    </Box>
                </StyledBox>
            </Grid>

            {/* Section 2: Preferred Cleaners */}
            <Grid item xs={12} md={4}>
                <StyledBox sx={{ height: '240px' }}>
                <Typography variant="subtitle1" gutterBottom>
                    Preferred Cleaners
                </Typography>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Select Preferred Cleaners</InputLabel>
                    <Select
                    multiple
                    value={preferences?.preferredCleaners || []}
                    onChange={(e) => handlePreferenceChange('preferredCleaners', e.target.value)}
                    input={<OutlinedInput label="Select Preferred Cleaners" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                            const cleaner = availableCleaners.find(c => c.id === value);
                            return (
                            <Chip 
                                key={value} 
                                label={cleaner ? cleaner.name : value}
                                onDelete={() => {
                                const newSelected = preferences.preferredCleaners.filter(id => id !== value);
                                handlePreferenceChange('preferredCleaners', newSelected);
                                }}
                            />
                            );
                        })}
                        </Box>
                    )}
                    >
                    {availableCleaners.map((cleaner) => (
                        <MenuItem key={cleaner.id} value={cleaner.id}>
                        {cleaner.name}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </StyledBox>
            </Grid>

            {/* Section 3: Schedule Preferences */}
            <Grid item xs={12} md={4}>
                <StyledBox sx={{ height: '240px' }}>
                <Typography variant="subtitle1" gutterBottom>
                    Schedule Preferences
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
                    <FormControl fullWidth>
                    <InputLabel>Time Preference</InputLabel>
                    <PreferenceSelect
                        value={preferences.timePreference}
                        onChange={(e) => handlePreferenceChange('timePreference', e.target.value)}
                        label="Time Preference"
                    >
                        {timeOptions.map(option => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                    </PreferenceSelect>
                    </FormControl>

                    <FormControl fullWidth>
                    <InputLabel>Day Preference</InputLabel>
                    <PreferenceSelect
                        multiple
                        value={preferences.dayPreference}
                        onChange={(e) => handlePreferenceChange('dayPreference', e.target.value)}
                        input={<OutlinedInput label="Day Preference" />}
                        renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                            <Chip key={value} label={value} />
                            ))}
                        </Box>
                        )}
                    >
                        {dayOptions.map(day => (
                        <MenuItem key={day} value={day}>{day}</MenuItem>
                        ))}
                    </PreferenceSelect>
                    </FormControl>
                </Box>
                </StyledBox>
            </Grid>
            </Grid>
        </CardContent>
        </StyledCard>
    </div>
  );
};

export default ClientPreferences;