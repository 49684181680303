import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import styles from '../styles/ClientRequestModal.module.css';

const ClientRequestModal = ({ 
  open, 
  onClose, 
  requestData, 
  onStatusChange,
  loading,
  orgId
}) => {
  const [adminNotes, setAdminNotes] = useState('');
  const [updateError, setUpdateError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleApprove = async () => {
    if (!requestData || !orgId) {
      setUpdateError('Missing required data');
      return;
    }
  
    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', requestData.jobId);
      
      const jobUpdates = {
        lastUpdated: new Date()
      };
  
      if (requestData.type === 'cancel') {
        jobUpdates.status = 'cancelled';
        jobUpdates.cancellationReason = requestData.clientNotes || '';
        jobUpdates.cancelledAt = new Date();
      } else {
        jobUpdates.appointmentDate = requestData.requestedDate;
        jobUpdates.scheduledEndTime = requestData.requestedEndTime;
        jobUpdates.cleanerId = requestData.newCleanerId;
        jobUpdates.cleanerName = requestData.newCleanerName;
      }
  
      await updateDoc(jobRef, jobUpdates);
      await onStatusChange({
        ...requestData,
        status: 'approved',
        adminNotes
      });
  
      setSuccessMessage(
        requestData.type === 'cancel' 
          ? 'Cancellation request approved successfully' 
          : 'Reschedule request approved successfully'
      );
  
      setTimeout(() => {
        setSuccessMessage('');
        onClose();
      }, 2000);
  
    } catch (error) {
      console.error('Error updating job:', error);
      setUpdateError('Failed to update appointment. Please try again.');
    }
  };

  const handleDeny = async () => {
    if (!requestData || !orgId) {
      setUpdateError('Missing required data');
      return;
    }
  
    try {
      await onStatusChange({
        ...requestData,
        status: 'denied',
        adminNotes
      });
  
      setSuccessMessage(
        requestData.type === 'cancel' 
          ? 'Cancellation request denied successfully' 
          : 'Reschedule request denied successfully'
      );
  
      setTimeout(() => {
        setSuccessMessage('');
        onClose();
      }, 2000);
  
    } catch (error) {
      console.error('Error denying request:', error);
      setUpdateError('Failed to deny request. Please try again.');
    }
  };

  if (!requestData) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 1,
          bgcolor: 'background.paper'
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h6">
          {requestData.type === 'cancel' ? 'Cancellation Request' : 'Client Reschedule Request'}
        </Typography>
        <IconButton
          onClick={onClose}
          className={styles.closeButton}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        {(updateError || successMessage) && (
          <Box sx={{ mb: 2 }}>
            {updateError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {updateError}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
          </Box>
        )}

        <div className={styles.section}>
          <Typography className={styles.sectionTitle}>
            Client Details
          </Typography>
          <Typography>
            <span className={styles.infoLabel}>Client:</span>
            {requestData.customerName}
          </Typography>
          <Typography>
            <span className={styles.infoLabel}>Address:</span>
            {requestData.address}
          </Typography>
        </div>

        <div className={styles.section}>
          <Typography className={styles.sectionTitle}>
            Current Appointment
          </Typography>
          <Typography>
            <span className={styles.infoLabel}>Date:</span>
            {moment(requestData.currentAppointmentDate?.toDate?.() || requestData.currentAppointmentDate).format('MMMM D, YYYY')}
          </Typography>
          <Typography>
            <span className={styles.infoLabel}>Time:</span>
            {moment(requestData.currentAppointmentDate?.toDate?.() || requestData.currentAppointmentDate).format('h:mm A')} - 
            {moment(requestData.currentEndTime?.toDate?.() || requestData.currentEndTime).format('h:mm A')}
          </Typography>
          <Typography>
            <span className={styles.infoLabel}>Cleaner:</span>
            {requestData.currentCleanerName}
          </Typography>
        </div>

        {requestData.type !== 'cancel' && (
          <div className={styles.section}>
            <Typography className={styles.sectionTitle}>
              Requested Changes
            </Typography>
            {requestData.type === 'manual' ? (
              <>
                <Typography>
                  <span className={styles.infoLabel}>Preferred Date:</span>
                  {requestData.preferredDate ? moment(requestData.preferredDate).format('MMMM D, YYYY') : 'Not specified'}
                </Typography>
                <Typography>
                  <span className={styles.infoLabel}>Preferred Time:</span>
                  {requestData.preferredTimeRange || 'Not specified'}
                </Typography>
              </>
            ) : (
              <>
                <Typography>
                  <span className={styles.infoLabel}>New Date:</span>
                  {moment(requestData.requestedDate?.toDate?.() || requestData.requestedDate).format('MMMM D, YYYY')}
                </Typography>
                <Typography>
                  <span className={styles.infoLabel}>New Time:</span>
                  {moment(requestData.requestedDate?.toDate?.() || requestData.requestedDate).format('h:mm A')} - 
                  {moment(requestData.requestedEndTime?.toDate?.() || requestData.requestedEndTime).format('h:mm A')}
                </Typography>
                <Typography>
                  <span className={styles.infoLabel}>New Cleaner:</span>
                  {requestData.newCleanerName || 'No change'}
                </Typography>
              </>
            )}
          </div>
        )}

        <div className={styles.section}>
          <Typography className={styles.sectionTitle}>
            {requestData.type === 'cancel' ? 'Cancellation Reason' : 'Client Notes'}
          </Typography>
          <Typography>
            {requestData.clientNotes || 'No notes provided'}
          </Typography>
        </div>

        <div className={styles.section}>
          <Typography className={styles.sectionTitle}>
            Admin Notes
          </Typography>
          <TextField
            multiline
            rows={3}
            fullWidth
            value={adminNotes}
            onChange={(e) => setAdminNotes(e.target.value)}
            placeholder={`Add any notes about this ${requestData.type === 'cancel' ? 'cancellation' : 'reschedule'} request...`}
            disabled={loading}
            variant="outlined"
          />
        </div>
      </DialogContent>

      <DialogActions sx={{ 
        p: 2, 
        gap: 1,
        borderTop: 1,
        borderColor: 'divider'
      }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          color="inherit"
        >
          CLOSE
        </Button>
        <Button
          onClick={handleDeny}
          variant="outlined"
          color="error"
          disabled={loading}
        >
          DENY {requestData.type === 'cancel' ? 'CANCELLATION' : 'REQUEST'}
        </Button>
        <Button
          variant="contained"
          onClick={handleApprove}
          disabled={loading}
        >
          {loading ? 'PROCESSING...' : `APPROVE ${requestData.type === 'cancel' ? 'CANCELLATION' : 'CHANGES'}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientRequestModal;