import styles from "../../../styles/JobOverview/CalendarCards/TimeOffCard.module.css";

import moment from "moment";

const TimeOffCard = ({ timeOff }) => {
  const formatTime = (date) => date
    ? date.format('hh:mm A')
    : undefined;

  const startDate = formatTime(moment(timeOff.startTime.seconds * 1000))
  const endDate = formatTime(moment(timeOff.endTime.seconds * 1000))

  return (
    <div className={styles.card}>
      <p>
        {startDate} - {endDate}
      </p>
      <p>
        {timeOff.cleaner.firstName} Time Off
      </p>
    </div>
  )
}

export default TimeOffCard;