import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Avatar,
  Tabs,
  Tab,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import { Phone, Email, AccessTime } from '@mui/icons-material';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import { useTimezone } from '../context/TimeZoneContext';
import TimeOffRequestModal from './TimeOffRequestModal';

// Import MetricCard and PERIOD_OPTIONS from CleanersReporting
const PERIOD_OPTIONS = {
  MONTH: 'month',
  QUARTER: 'quarter',
  ALL_TIME: 'all-time'
};

const MetricCard = ({ title, value, percentage, unit = '' }) => (
  <Card>
    <CardContent>
      <Typography variant="subtitle1" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="h5">
        {value === 'N/A' ? 'N/A' : (unit === '$' ? `${unit}${value}` : `${value}${unit}`)}
      </Typography>
      {percentage !== undefined && (
        <Typography 
          sx={{ 
            color: percentage >= 0 ? 'success.main' : 'error.main',
            fontSize: '0.875rem',
            fontWeight: 500
          }}
        >
          ({percentage > 0 ? '+' : ''}{percentage.toFixed(1)}%)
        </Typography>
      )}
    </CardContent>
  </Card>
);

const CleanerProfile = () => {
  const { orgId, cleanerId } = useParams();
  const [cleaner, setCleaner] = useState(null);
  const [timeOffRequests, setTimeOffRequests] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [openTimeOffModal, setOpenTimeOffModal] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(PERIOD_OPTIONS.MONTH);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM'));
  const { timezone, convertFromOrgTz, convertToOrgTz } = useTimezone();

  const getDateRange = () => {
    const now = moment();
    switch (selectedPeriod) {
      case PERIOD_OPTIONS.MONTH:
        return {
          start: moment(selectedDate).startOf('month'),
          end: moment(selectedDate).endOf('month')
        };
      case PERIOD_OPTIONS.QUARTER:
        return {
          start: moment(selectedDate).startOf('quarter'),
          end: moment(selectedDate).endOf('quarter')
        };
      case PERIOD_OPTIONS.ALL_TIME:
        return {
          start: moment(0),
          end: now
        };
      default:
        return {
          start: moment(selectedDate).startOf('month'),
          end: moment(selectedDate).endOf('month')
        };
    }
  };

  const getFilteredJobs = (jobs) => {
    const { start, end } = getDateRange();
    return jobs.filter(job => {
      if (!job.appointmentDate) return false;
      const jobDate = moment(job.appointmentDate);
      return jobDate.isBetween(start, end, 'day', '[]');
    });
  };

  const calculateMetrics = (filteredJobs) => {
    const completedJobs = filteredJobs.filter(job => job.status?.toLowerCase() === 'completed');
    
    let totalRevenue = 0;
    let totalProfit = 0;
    let totalDuration = 0;
    let totalEfficiency = 0;
    let jobsWithEfficiency = 0;
  
    completedJobs.forEach(job => {
      const cost = parseFloat(job.serviceCost || job.price || 0);
      totalRevenue += cost;
      
      if (job.startTime && job.endTime) {
        const startTime = moment(job.startTime.toDate?.() || job.startTime);
        const endTime = moment(job.endTime.toDate?.() || job.endTime);
        const duration = endTime.diff(startTime, 'hours', true);
        totalDuration += duration;
        
        const laborCost = duration * (cleaner?.payRate || 0);
        totalProfit += (cost - laborCost);
  
        if (job.scheduledDuration) {
          const scheduledHours = job.scheduledDuration / (1000 * 60 * 60);
          const actualHours = duration;
          const jobEfficiency = (scheduledHours / actualHours) * 100;
          
          if (!isNaN(jobEfficiency) && isFinite(jobEfficiency)) {
            totalEfficiency += jobEfficiency;
            jobsWithEfficiency++;
          }
        }
      } else {
        totalProfit += cost;
      }
    });
  
    const avgEfficiency = jobsWithEfficiency > 0 ? 
      (totalEfficiency / jobsWithEfficiency) : 0;
  
    const totalProfitPercentage = totalRevenue > 0 ? (totalProfit / totalRevenue) * 100 : 0;
    const avgProfitPerJob = completedJobs.length > 0 ? totalProfit / completedJobs.length : 0;
    const avgRevenuePerJob = completedJobs.length > 0 ? totalRevenue / completedJobs.length : 0;
    const avgProfitPercentage = avgRevenuePerJob > 0 ? (avgProfitPerJob / avgRevenuePerJob) * 100 : 0;
  
    return {
      totalJobs: filteredJobs.length,
      completedJobs: completedJobs.length,
      totalRevenue: totalRevenue.toFixed(2),
      totalProfit: totalProfit.toFixed(2),
      totalProfitPercentage,
      avgProfit: avgProfitPerJob.toFixed(2),
      avgProfitPercentage,
      efficiency: avgEfficiency.toFixed(1),
      avgDuration: completedJobs.length > 0 ? 
        (totalDuration / completedJobs.length).toFixed(2) : '0.00'
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch cleaner data from the cleaners collection
        const cleanerRef = doc(db, 'organizations', orgId, 'cleaners', cleanerId);
        const cleanerSnapshot = await getDoc(cleanerRef);
        
        if (cleanerSnapshot.exists()) {
          const cleanerData = {
            id: cleanerSnapshot.id,
            ...cleanerSnapshot.data()
          };
          setCleaner(cleanerData);
        } else {
          console.error('No cleaner found with this ID');
        }
  
        // Fetch time off requests
        const timeOffRef = collection(db, 'organizations', orgId, 'cleaners', cleanerId, 'timeOffRequests');
        const timeOffSnapshot = await getDocs(timeOffRef);
        const timeOffData = timeOffSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          startDate: doc.data().startDate?.toDate(),
          endDate: doc.data().endDate?.toDate(),
          startTime: doc.data().startTime?.toDate(),
          endTime: doc.data().endTime?.toDate()
        }));
        setTimeOffRequests(timeOffData);
  
        // Fetch jobs
        const jobsRef = collection(db, 'organizations', orgId, 'jobs');
        const jobsQuery = query(jobsRef, where('cleanerId', '==', cleanerId));
        const jobsSnapshot = await getDocs(jobsQuery);
        const jobsData = jobsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          appointmentDate: doc.data().appointmentDate?.toDate(),
          startTime: doc.data().startTime?.toDate(),
          endTime: doc.data().endTime?.toDate()
        }));
        setJobs(jobsData);
  
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    if (orgId && cleanerId) {
      fetchData();
    }
  }, [orgId, cleanerId]);

  const handlePeriodChange = (event, newPeriod) => {
    if (newPeriod !== null) {
      setSelectedPeriod(newPeriod);
      if (newPeriod === PERIOD_OPTIONS.ALL_TIME) {
        setSelectedDate(moment().format('YYYY-MM'));
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!cleaner) {
    return <div>Cleaner not found</div>;
  }

  const filteredJobs = getFilteredJobs(jobs);
  const metrics = calculateMetrics(filteredJobs);

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Section */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Avatar
              sx={{ width: 100, height: 100, fontSize: '2.5rem' }}
            >
              {`${cleaner.firstName?.[0]}${cleaner.lastName?.[0]}`}
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="h4">
              {`${cleaner.firstName} ${cleaner.lastName}`}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Email sx={{ mr: 1 }} />
                <Typography>{cleaner.email}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Phone sx={{ mr: 1 }} />
                <Typography>{cleaner.phoneNumber}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenTimeOffModal(true)}
              startIcon={<AccessTime />}
            >
              Request Time Off
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Period Selector */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <ToggleButtonGroup
              value={selectedPeriod}
              exclusive
              onChange={handlePeriodChange}
              size="small"
            >
              <ToggleButton value={PERIOD_OPTIONS.MONTH}>Monthly</ToggleButton>
              <ToggleButton value={PERIOD_OPTIONS.QUARTER}>Quarterly</ToggleButton>
              <ToggleButton value={PERIOD_OPTIONS.ALL_TIME}>All Time</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          
          {selectedPeriod !== PERIOD_OPTIONS.ALL_TIME && (
            <Grid item>
              <FormControl variant="outlined" size="small">
                <InputLabel id="date-select-label">
                  {selectedPeriod === PERIOD_OPTIONS.MONTH ? 'Month' : 'Quarter'}
                </InputLabel>
                <Select
                  labelId="date-select-label"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  label={selectedPeriod === PERIOD_OPTIONS.MONTH ? 'Month' : 'Quarter'}
                >
                  {[...Array(6)].map((_, i) => {
                    const date = moment().subtract(i, selectedPeriod === PERIOD_OPTIONS.MONTH ? 'months' : 'quarters');
                    const value = date.format('YYYY-MM');
                    const label = selectedPeriod === PERIOD_OPTIONS.MONTH ? 
                      date.format('MMMM YYYY') :
                      `Q${date.quarter()} ${date.year()}`;
                    return (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Paper>

      {/* Metrics Section */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Completed Jobs"
            value={metrics.completedJobs}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Total Revenue"
            value={metrics.totalRevenue}
            unit="$"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Total Profit"
            value={metrics.totalProfit}
            percentage={metrics.totalProfitPercentage}
            unit="$"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Average Profit per Job"
            value={metrics.avgProfit}
            percentage={metrics.avgProfitPercentage}
            unit="$"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Efficiency Rating"
            value={metrics.efficiency}
            unit="%"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MetricCard
            title="Average Duration"
            value={metrics.avgDuration}
            unit=" hrs"
          />
        </Grid>
      </Grid>

      {/* Tabs Section */}
      <Paper>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab label="Schedule" />
          <Tab label="Time Off History" />
          <Tab label="Job History" />
        </Tabs>

        <Box sx={{ p: 3 }}>
          {/* Schedule Tab */}
          {tabValue === 0 && (
            <Grid container spacing={2}>
              {Object.entries(cleaner.workingHours || {}).map(([day, hours]) => (
                <Grid item xs={12} sm={6} md={4} key={day}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                        {day}
                      </Typography>
                      <Typography>
                        {hours.start && hours.end ? `${hours.start} - ${hours.end}` : 'Not Available'}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}

          {/* Time Off History Tab */}
          {tabValue === 1 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date Range</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timeOffRequests.map((request) => (
                    <TableRow key={request.id}>
                      <TableCell>
                        {moment(request.startDate).format('MMM D, YYYY')} - 
                        {moment(request.endDate).format('MMM D, YYYY')}
                      </TableCell>
                      <TableCell>
                        {moment(request.startTime).format('h:mm A')} - 
                        {moment(request.endTime).format('h:mm A')}
                      </TableCell>
                      <TableCell>
                        {Math.round(request.duration / 60 * 10) / 10} hours
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={request.status}
                          color={
                            request.status === 'approved' ? 'success' :
                            request.status === 'pending' ? 'warning' :
                            request.status === 'denied' ? 'error' :
                            'default'
                          }
                          size="small"
                        />
                      </TableCell>
                      <TableCell>{request.notes}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Job History Tab */}
          {tabValue === 2 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Client</TableCell>
                    <TableCell>Start Time</TableCell>
                    <TableCell>End Time</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Revenue</TableCell>
                    <TableCell>Efficiency</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredJobs.map((job) => {
                    const startTime = job.startTime?.toDate();
                    const endTime = job.endTime?.toDate();
                    const duration = startTime && endTime ? 
                      moment(endTime).diff(moment(startTime), 'hours', true) : null;
                    const efficiency = job.efficiencyRating || 
                      (job.scheduledDuration && duration ? 
                        (job.scheduledDuration / (duration * 1000 * 60 * 60)) * 100 : 
                        null);

                    return (
                      <TableRow key={job.id}>
                        <TableCell>
                          {moment(job.appointmentDate?.toDate()).format('MMM D, YYYY')}
                        </TableCell>
                        <TableCell>{job.customerName}</TableCell>
                        <TableCell>
                          {startTime ? moment(startTime).format('h:mm A') : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {endTime ? moment(endTime).format('h:mm A') : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {duration ? `${duration.toFixed(1)} hrs` : 'N/A'}
                        </TableCell>
                        <TableCell>
                          ${parseFloat(job.serviceCost || job.price || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {efficiency ? `${efficiency.toFixed(1)}%` : 'N/A'}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={job.status}
                            color={
                              job.status === 'completed' ? 'success' :
                              job.status === 'pending' ? 'warning' :
                              job.status === 'cancelled' ? 'error' :
                              'default'
                            }
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>

      {/* Time Off Request Modal */}
      <TimeOffRequestModal
        open={openTimeOffModal}
        onClose={() => setOpenTimeOffModal(false)}
        cleanerId={cleanerId}
        orgId={orgId}
        cleaner={cleaner}
        onSuccess={() => {
          // Refresh time off requests after successful submission
          const fetchTimeOffRequests = async () => {
            const timeOffRef = collection(db, 'organizations', orgId, 'cleaners', cleanerId, 'timeOffRequests');
            const snapshot = await getDocs(timeOffRef);
            setTimeOffRequests(snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              startDate: doc.data().startDate?.toDate(),
              endDate: doc.data().endDate?.toDate(),
              startTime: doc.data().startTime?.toDate(),
              endTime: doc.data().endTime?.toDate()
            })));
          };
          fetchTimeOffRequests();
        }}
      />
    </Box>
  );
};

export default CleanerProfile;