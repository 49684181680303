// src/components/GoogleMapsProvider.js
// This component is used for the Autocomplete functionality in the CreateCustomerModal, CreateJobForm, and CustomerManagement components.

import React, { useState } from 'react';
import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_CONFIG } from '../constants/googleMaps';

export const GoogleMapsProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <LoadScript
      googleMapsApiKey={GOOGLE_MAPS_CONFIG.apiKey}  // This will now use REACT_APP_GOOGLE_API_KEY
      libraries={GOOGLE_MAPS_CONFIG.libraries}
      onLoad={() => {
        setIsLoaded(true);
      }}
      onError={(error) => {
        console.error('Error loading Google Maps:', error);
      }}
    >
      {children}
    </LoadScript>
  );
};
