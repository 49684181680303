import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Trash2Icon, PlusIcon } from 'lucide-react';
import styles from '../styles/OrgSettings.module.css';
import { 
    Accordion,
    AccordionDetails,
    AccordionSummary,
    TextField,
    Button,
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
    Switch,
    InputAdornment,
    Alert,
    Select,
    MenuItem,
    Divider,
  } from '@mui/material';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DEFAULT_MESSAGE = `When you cancel an appointment, our team suffers from losing hours, and a loss to their wages. It's important to us that we are a reliable company for them and they have reliable work. They can choose to work anywhere, but they chose us, and so we need to make sure that we hold our end of the bargain by finding clients like you who are reliable for them.

As it stands you currently have {#ofCancelations} with us. Here is our fee schedule for cancelations:

{Fee Schedule}

If you can please consider Rescheduling, or contact the office if you want to work something out.`;

const ClientPortalSettings = ({ orgId, isAdmin }) => {
  const [settings, setSettings] = useState({
    cancellationMessage: DEFAULT_MESSAGE,
    cancellationFeeSchedule: [
      { count: '1-2', fee: 0 },
      { count: '3', fee: 25 },
      { count: '4', fee: 100 },
      { count: '5-6', fee: 'fullService' },
      { count: '7', fee: 'doubleService' }
    ],
    serviceAddOns: [
      { 
        name: '', 
        description: '', 
        price: 0,
        active: true 
      }
    ],
    allowReschedule: true,
    rescheduleLockDays: 2,
    allowCancel: true,
    cancelLockDays: 2,
    modificationLockedMessage: "Please contact our office to modify your appointment.",
    allowServiceCancellation: true,
    serviceCancellationLockedMessage: "Please contact our office to cancel your services."
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [previewMessage, setPreviewMessage] = useState('');
  

  useEffect(() => {
    const fetchSettings = async () => {
      if (!orgId) return;

      try {
        const settingsDoc = await getDoc(doc(db, 'organizations', orgId));
        if (settingsDoc.exists() && settingsDoc.data().clientPortalSettings) {
          setSettings(prevSettings => ({
            ...prevSettings,
            ...settingsDoc.data().clientPortalSettings,
            serviceAddOns: settingsDoc.data().clientPortalSettings.serviceAddOns || []
          }));
        }
      } catch (err) {
        console.error('Error fetching client portal settings:', err);
        setError('Failed to load settings');
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, [orgId]);

  useEffect(() => {
    // Generate fee schedule text
    const feeScheduleText = settings.cancellationFeeSchedule
      .map(item => {
        const feeText = item.fee === 'fullService' ? '{Service Cost}' :
                       item.fee === 'doubleService' ? '{Double Service Cost}' :
                       `$${item.fee}`;
        return `${item.count} Cancellations - ${feeText}`;
      })
      .join('\n');

    // Replace placeholder with current values
    let message = settings.cancellationMessage
      .replace('{Fee Schedule}', feeScheduleText);
    
    setPreviewMessage(message);
  }, [settings]);

  const handleSave = async () => {
    if (!isAdmin) {
      setError('You do not have permission to update settings');
      return;
    }
  
    try {
      setLoading(true);
      
      // Clean up and validate settings before saving
      const cleanSettings = {
        ...settings,
        // Ensure numerical values are actually numbers
        rescheduleLockDays: parseInt(settings.rescheduleLockDays) || 2,
        cancelLockDays: parseInt(settings.cancelLockDays) || 2,
        // Ensure boolean values are actually booleans
        allowReschedule: Boolean(settings.allowReschedule),
        allowCancel: Boolean(settings.allowCancel),
        // Ensure message has a default if empty
        modificationLockedMessage: settings.modificationLockedMessage || 
          "Please contact our office to modify your appointment."
      };
  
      await updateDoc(doc(db, 'organizations', orgId), {
        clientPortalSettings: cleanSettings,
        updatedAt: new Date().toISOString()
      });
  
      setSettings(cleanSettings); // Update local state with cleaned values
      setSuccess('Settings updated successfully');
      setError('');
    } catch (err) {
      console.error('Error updating settings:', err);
      setError('Failed to update settings');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  const addFeeScheduleItem = () => {
    setSettings({
      ...settings,
      cancellationFeeSchedule: [
        ...settings.cancellationFeeSchedule,
        { count: '', fee: 0 }
      ]
    });
  };

  const addServiceAddOn = () => {
    setSettings({
      ...settings,
      serviceAddOns: [
        ...settings.serviceAddOns,
        { name: '', description: '', unit: '', price: 0, active: true }
      ]
    });
  };

  const removeFeeScheduleItem = (index) => {
    const newSchedule = settings.cancellationFeeSchedule.filter((_, i) => i !== index);
    setSettings({
      ...settings,
      cancellationFeeSchedule: newSchedule
    });
  };

  const removeServiceAddOn = (index) => {
    const newAddOns = settings.serviceAddOns.filter((_, i) => i !== index);
    setSettings({
      ...settings,
      serviceAddOns: newAddOns
    });
  };

  const handleFeeScheduleChange = (index, field, value) => {
    const newSchedule = [...settings.cancellationFeeSchedule];
    newSchedule[index] = {
      ...newSchedule[index],
      [field]: value
    };
    setSettings({
      ...settings,
      cancellationFeeSchedule: newSchedule
    });
  };

  const handleServiceAddOnChange = (index, field, value) => {
    const newAddOns = [...settings.serviceAddOns];
    newAddOns[index] = {
      ...newAddOns[index],
      [field]: field === 'price' ? parseFloat(value) || 0 : value
    };
    setSettings({
      ...settings,
      serviceAddOns: newAddOns
    });
  };

  if (!isAdmin) {
    return (
      <div className={styles.errorContainer}>
        You do not have permission to view these settings.
      </div>
    );
  }

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  return (
    <Box className={styles.orgContent}>
      <Box className={styles.orgSection}>
      {/* Appointment Modification Settings */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Appointment Modification Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Box sx={{ mb: 4 }}>
            <Typography variant="body1" sx={{ mb: 3 }}>
                Control whether clients can reschedule or cancel appointments through the portal, and set timing restrictions.
            </Typography>

            {/* Reschedule Settings */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
                Reschedule Settings
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Switch
                    checked={settings.allowReschedule ?? true}
                    onChange={(e) => setSettings({
                    ...settings,
                    allowReschedule: e.target.checked
                    })}
                />
                <Typography sx={{ ml: 1 }}>Allow clients to reschedule appointments</Typography>
                </Box>
                <TextField
                type="number"
                size="small"
                label="Lock rescheduling before appointment (days)"
                value={settings.rescheduleLockDays ?? 2}
                onChange={(e) => setSettings({
                    ...settings,
                    rescheduleLockDays: parseInt(e.target.value) || 0
                })}
                disabled={!settings.allowReschedule}
                InputProps={{
                    endAdornment: <InputAdornment position="end">days</InputAdornment>,
                }}
                sx={{ width: 300 }}
                />
            </Box>

            {/* Cancel Settings */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
                Cancellation Settings
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Switch
                    checked={settings.allowCancel ?? true}
                    onChange={(e) => setSettings({
                    ...settings,
                    allowCancel: e.target.checked
                    })}
                />
                <Typography sx={{ ml: 1 }}>Allow clients to cancel appointments</Typography>
                </Box>
                <TextField
                type="number"
                size="small"
                label="Lock cancellation before appointment (days)"
                value={settings.cancelLockDays ?? 2}
                onChange={(e) => setSettings({
                    ...settings,
                    cancelLockDays: parseInt(e.target.value) || 0
                })}
                disabled={!settings.allowCancel}
                InputProps={{
                    endAdornment: <InputAdornment position="end">days</InputAdornment>,
                }}
                sx={{ width: 300 }}
                />
            </Box>

            {/* Service Cancellation Settings */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
                Service Cancellation Settings
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Switch
                    checked={settings.allowServiceCancellation ?? true}
                    onChange={(e) => setSettings({
                    ...settings,
                    allowServiceCancellation: e.target.checked
                    })}
                />
                <Typography sx={{ ml: 1 }}>Allow clients to cancel all services</Typography>
                </Box>
            </Box>

            {/* Contact Messages */}
            <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
                Contact Messages
                </Typography>
                <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                Message to show clients when they cannot modify appointments
                </Typography>
                <TextField
                multiline
                rows={4}
                fullWidth
                value={settings.modificationLockedMessage ?? "Please contact our office to modify your appointment."}
                onChange={(e) => setSettings({
                    ...settings,
                    modificationLockedMessage: e.target.value
                })}
                sx={{ mb: 3 }}
                />

                <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                Message to show clients when they cannot cancel services
                </Typography>
                <TextField
                multiline
                rows={4}
                fullWidth
                value={settings.serviceCancellationLockedMessage ?? "Please contact our office to cancel your services."}
                onChange={(e) => setSettings({
                    ...settings,
                    serviceCancellationLockedMessage: e.target.value
                })}
                />
            </Box>
            </Box>
        </AccordionDetails>
        </Accordion>
        {/* Cancellation Settings */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Cancellation Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Enter the message to show clients when they attempt to cancel.
              You can use the following placeholders:
            </Typography>
            
            <List>
              {[
                "{#ofCancelations} - Shows the client's current number of cancellations",
                "{Fee Schedule} - Displays the fee schedule based on your settings below",
                "{Service Cost} - Will be replaced with the actual service cost",
                "{Double Service Cost} - Will be replaced with double the service cost"
              ].map((text, i) => (
                <ListItem key={i}>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
  
            <TextField
              multiline
              rows={10}
              fullWidth
              value={settings.cancellationMessage}
              onChange={(e) => setSettings({
                ...settings,
                cancellationMessage: e.target.value
              })}
              sx={{ mb: 4 }}
            />
  
            <Box className={styles.previewSection}>
              <Typography variant="h6" sx={{ mb: 1 }}>Message Preview:</Typography>
              <Box className={styles.previewBox}>
                {previewMessage.split('\n').map((line, i) => (
                  <Typography key={i} paragraph>{line}</Typography>
                ))}
              </Box>
            </Box>
  
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Cancellation Fee Schedule</Typography>
              <Button
                variant="outlined"
                startIcon={<PlusIcon size={16} />}
                onClick={addFeeScheduleItem}
              >
                Add Fee Tier
              </Button>
            </Box>
  
            {settings.cancellationFeeSchedule.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
                <TextField
                  size="small"
                  placeholder="e.g., 1-2 or 3"
                  value={item.count}
                  onChange={(e) => handleFeeScheduleChange(index, 'count', e.target.value)}
                />
                <TextField
                  size="small"
                  placeholder="Amount, fullService, or doubleService"
                  value={item.fee}
                  onChange={(e) => handleFeeScheduleChange(index, 'fee', e.target.value)}
                />
                <Typography>cancellations</Typography>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => removeFeeScheduleItem(index)}
                  sx={{ minWidth: 'auto' }}
                >
                  <Trash2Icon size={18} />
                </Button>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
        {/* Service Add-Ons */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Service Add-Ons</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography>
                Configure additional services that clients can add to their appointments.
              </Typography>
              <Button
                variant="outlined"
                startIcon={<PlusIcon size={16} />}
                onClick={addServiceAddOn}
              >
                Add Service
              </Button>
            </Box>
  
            {settings.serviceAddOns.map((addon, index) => (
              <Box key={index} sx={{ mb: 3 }}>
                {/* Top row with name, unit, price, active status, and delete button */}
                <Box sx={{ display: 'flex', gap: 2, mb: 1, alignItems: 'center' }}>
                  <TextField
                    size="small"
                    placeholder="Service Name"
                    value={addon.name}
                    onChange={(e) => handleServiceAddOnChange(index, 'name', e.target.value)}
                  />
                  <Select
                    size="small"
                    value={addon.unit}
                    onChange={(e) => handleServiceAddOnChange(index, 'unit', e.target.value)}
                  >
                    <MenuItem value="each">Each</MenuItem>
                    <MenuItem value="minute">Minute</MenuItem>
                    <MenuItem value="hour">Hour</MenuItem>
                    <MenuItem value="day">Day</MenuItem>
                    <MenuItem value="week">Week</MenuItem>
                    <MenuItem value="visit">Visit</MenuItem>
                    <MenuItem value="unit">Unit</MenuItem>
                    <MenuItem value="room">Room</MenuItem>
                    <MenuItem value="area">Area</MenuItem>
                    <MenuItem value="sqft">Sqft</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                    <MenuItem value="window">Window</MenuItem>
                    <MenuItem value="mile">Mile</MenuItem>
                  </Select>
                  <TextField
                    size="small"
                    type="number"
                    placeholder="Price"
                    value={addon.price}
                    onChange={(e) => handleServiceAddOnChange(index, 'price', e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Switch
                      checked={addon.active}
                      onChange={(e) => handleServiceAddOnChange(index, 'active', e.target.checked)}
                    />
                    <Typography>Active</Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => removeServiceAddOn(index)}
                    sx={{ minWidth: 'auto' }}
                  >
                    <Trash2Icon size={18} />
                  </Button>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={2}
                  placeholder="Description"
                  value={addon.description}
                  onChange={(e) => handleServiceAddOnChange(index, 'description', e.target.value)}
                />
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
  
        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
  
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientPortalSettings;