// /src/components/StatusSettings.js

import React, { useState } from 'react';
import {
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tab,
  Tabs,
  Paper,
  Alert
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { ChromePicker } from 'react-color';
import { DEFAULT_JOB_STATUSES, DEFAULT_RECURRING_STATUSES } from '../constants/statuses';
import styles from '../styles/OrgSettings.module.css';

const StatusSettings = ({ 
  customJobStatuses = [], 
  customRecurringStatuses = [],
  onStatusUpdate,
  onSave,
  loading 
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingStatus, setEditingStatus] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [newStatus, setNewStatus] = useState({
    name: '',
    color: '#000000',
    description: ''
  });

  const isDuplicateStatusName = (name, type, currentStatusId = null) => {
    const defaultStatuses = type === 'job' ? DEFAULT_JOB_STATUSES : DEFAULT_RECURRING_STATUSES;
    const customStatuses = type === 'job' ? customJobStatuses : customRecurringStatuses;
    
    const normalizedName = name.trim().toLowerCase();
    
    // Check against default statuses
    if (defaultStatuses.some(status => status.name.toLowerCase() === normalizedName)) {
      return true;
    }
    
    // Check against custom statuses (excluding the current status being edited)
    return customStatuses.some(status => 
      status.name.toLowerCase() === normalizedName && status.id !== currentStatusId
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleOpenDialog = (status = null) => {
    if (status) {
      setEditingStatus(status);
      setNewStatus({ ...status });
    } else {
      setEditingStatus(null);
      setNewStatus({ name: '', color: '#000000', description: '' });
    }
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setShowColorPicker(false);
    setEditingStatus(null);
  };

  const handleSaveStatus = () => {
    if (!newStatus.name.trim()) {
      setError('Status name is required');
      return;
    }

    // Make sure we're not duplicating a default status
    const normalizedName = newStatus.name.trim();
    const defaultStatus = DEFAULT_JOB_STATUSES.find(
      status => status.name.toLowerCase() === normalizedName.toLowerCase()
    );

    if (defaultStatus) {
      setError('Cannot create custom status with same name as default status');
      return;
    }

    // Add new status with automation info
    const statusWithMeta = {
      ...newStatus,
      id: editingStatus?.id || Date.now().toString(),
      automations: [],
      isCustom: true
    };

    if (activeTab === 0) {
      const updatedStatuses = editingStatus
        ? customJobStatuses.map(s => s.id === editingStatus.id ? statusWithMeta : s)
        : [...customJobStatuses, statusWithMeta];
      onStatusUpdate('jobStatuses', updatedStatuses);
    } else {
      // Handle recurring status
      const updatedStatuses = editingStatus
        ? customRecurringStatuses.map(s => s.id === editingStatus.id ? newStatus : s)
        : [...customRecurringStatuses, { ...newStatus, id: Date.now().toString() }];
      onStatusUpdate('recurringStatuses', updatedStatuses);
    }
    
    handleCloseDialog();
  };

  const handleDeleteStatus = (statusId, type) => {
    if (type === 'job') {
      const updatedStatuses = customJobStatuses.filter(s => s.id !== statusId);
      onStatusUpdate('jobStatuses', updatedStatuses);
    } else {
      const updatedStatuses = customRecurringStatuses.filter(s => s.id !== statusId);
      onStatusUpdate('recurringStatuses', updatedStatuses);
    }
  };

  const renderStatusList = (defaultStatuses, customStatuses, type) => (
    <List>
      {/* Default Statuses */}
      <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2, mb: 1, ml: 2 }}>
        Default Statuses (Non-editable)
      </Typography>
      {defaultStatuses.map((status) => (
        <ListItem 
          key={status.name}
          sx={{
            borderLeft: `4px solid ${status.color}`,
            bgcolor: 'background.paper',
            mb: 1,
            borderRadius: 1
          }}
        >
          <ListItemText 
            primary={status.name}
            secondary={status.description}
          />
        </ListItem>
      ))}

      {/* Custom Statuses */}
      {customStatuses.length > 0 && (
        <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 3, mb: 1, ml: 2 }}>
          Custom Statuses
        </Typography>
      )}
      {customStatuses.map((status) => (
        <ListItem 
          key={status.id}
          sx={{
            borderLeft: `4px solid ${status.color}`,
            bgcolor: 'background.paper',
            mb: 1,
            borderRadius: 1
          }}
        >
          <ListItemText 
            primary={status.name}
            secondary={status.description}
          />
          <ListItemSecondaryAction>
            <IconButton 
              edge="end" 
              aria-label="edit"
              onClick={() => handleOpenDialog(status)}
              sx={{ mr: 1 }}
            >
              <EditIcon />
            </IconButton>
            <IconButton 
              edge="end" 
              aria-label="delete"
              onClick={() => handleDeleteStatus(status.id, type)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );

  return (
    <div className={styles.orgContent}>
      <div className={styles.orgSection}>
        <Typography variant="h6" className={styles.sectionTitle}>
          Status Settings
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Job Statuses" />
            <Tab label="Recurring Service Statuses" />
          </Tabs>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
          >
            Add New Status
          </Button>
        </Box>

        {/* Status Lists */}
        {activeTab === 0 && renderStatusList(DEFAULT_JOB_STATUSES, customJobStatuses, 'job')}
        {activeTab === 1 && renderStatusList(DEFAULT_RECURRING_STATUSES, customRecurringStatuses, 'recurring')}

        {/* Add/Edit Status Dialog */}
        <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
            <DialogTitle>
            {editingStatus ? 'Edit Status' : 'Add New Status'}
            </DialogTitle>
            <DialogContent>
            {error && (
                <Alert severity="error" sx={{ mb: 2, mt: 1 }}>
                {error}
                </Alert>
            )}
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Status Name"
                value={newStatus.name}
                onChange={(e) => setNewStatus({ ...newStatus, name: e.target.value })}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Description"
                value={newStatus.description}
                onChange={(e) => setNewStatus({ ...newStatus, description: e.target.value })}
                multiline
                rows={2}
                sx={{ mb: 2 }}
              />
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Status Color
                </Typography>
                <Box
                  onClick={() => setShowColorPicker(!showColorPicker)}
                  sx={{
                    width: 100,
                    height: 30,
                    backgroundColor: newStatus.color,
                    cursor: 'pointer',
                    border: '1px solid #ccc',
                    borderRadius: 1
                  }}
                />
                {showColorPicker && (
                  <Box sx={{ position: 'absolute', zIndex: 2, mt: 1 }}>
                    <ChromePicker
                      color={newStatus.color}
                      onChange={(color) => setNewStatus({ ...newStatus, color: color.hex })}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button 
              onClick={handleSaveStatus}
              variant="contained"
              disabled={!newStatus.name.trim()}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Save Button */}
        <Box sx={{ mt: 4 }}>
          <button
            onClick={onSave}
            disabled={loading}
            className={`${styles.orgButton} ${styles.orgButtonPrimary} ${loading ? styles.orgButtonDisabled : ''}`}
          >
            {loading ? 'Saving...' : 'Save Status Settings'}
          </button>
        </Box>
      </div>
    </div>
  );
};

export default StatusSettings;