import React, { useState, useEffect } from 'react';
import styles from '../styles/SelectCleanerModal.module.css';

const SelectCleanersModal = ({ open, handleClose, cleaners = [], selectedCleaners = [], setSelectedCleaners }) => {
  // Local state to track selections
  const [localSelected, setLocalSelected] = useState([]);

  // Initialize local state when modal opens or selectedCleaners changes
  useEffect(() => {
    if (open) {
      setLocalSelected(selectedCleaners);
    }
  }, [open, selectedCleaners]);

  // Helper function to get cleaner display name
  const getCleanerDisplayName = (cleaner) => {
    if (cleaner.firstName && cleaner.lastName) {
      return `${cleaner.firstName} ${cleaner.lastName}`;
    }
    if (cleaner.name) {
      return cleaner.name;
    }
    if (cleaner.displayName) {
      return cleaner.displayName;
    }
    return cleaner.email || 'Unnamed Cleaner';
  };

  if (!open) return null;

  const handleCleanerToggle = (cleaner) => {
    // Check if cleaner is already selected
    const isCurrentlySelected = localSelected.some(c => c.id === cleaner.id);
    let updatedSelection;
    
    if (isCurrentlySelected) {
      // Remove from selection
      updatedSelection = localSelected.filter(c => c.id !== cleaner.id);
    } else {
      // Add to selection while preserving existing selections
      updatedSelection = [...localSelected, {
        id: cleaner.id,
        firstName: cleaner.firstName,
        lastName: cleaner.lastName,
        name: cleaner.name || `${cleaner.firstName} ${cleaner.lastName}`,
        email: cleaner.email,
        displayName: cleaner.displayName
      }];
    }
    
    setLocalSelected(updatedSelection);
  };

  const handleSaveAndClose = () => {
    setSelectedCleaners(localSelected);
    handleClose();
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Select Cleaners</h2>
          <button 
            onClick={handleClose}
            className={styles.closeButton}
          >
            ×
          </button>
        </div>

        <div className={styles.modalBody}>
          {cleaners.map(cleaner => {
            const isSelected = localSelected.some(c => c.id === cleaner.id);
            const displayName = getCleanerDisplayName(cleaner);
            
            return (
              <div key={cleaner.id} className={styles.cleanerOption}>
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => handleCleanerToggle(cleaner)}
                    className={styles.checkbox}
                  />
                  <span>{displayName}</span>
                </label>
              </div>
            );
          })}
        </div>

        <div className={styles.modalFooter}>
          <button 
            onClick={handleSaveAndClose}
            className={styles.button}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectCleanersModal;