// src/components/CompanyInfo.js

import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../firebase';
import { 
  TextField, 
  Grid, 
  Button, 
  Typography, 
  Box,
  IconButton,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

// Styled component for the upload input
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CompanyInfo = ({ orgId, orgName, isAdmin }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    hoursStart: '09:00',
    hoursEnd: '17:00',
    logoUrl: '',
  });

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      if (orgId) {
        try {
          setLoading(true);
          const orgDoc = await getDoc(doc(db, 'organizations', orgId));
          
          if (orgDoc.exists() && orgDoc.data().companyInfo) {
            setCompanyInfo(orgDoc.data().companyInfo);
          }
        } catch (err) {
          console.error('Error fetching company information:', err);
          setError('Failed to load company information');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCompanyInfo();
  }, [orgId]);

  const handleCompanyInfoChange = (field) => (event) => {
    setCompanyInfo({
      ...companyInfo,
      [field]: event.target.value
    });
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file');
      return;
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError('File size should not exceed 5MB');
      return;
    }

    try {
      setUploadLoading(true);
      setError('');

      // Delete existing logo if there is one
      if (companyInfo.logoUrl) {
        const oldLogoRef = ref(storage, `organizations/${orgId}/logo`);
        try {
          await deleteObject(oldLogoRef);
        } catch (error) {
          console.error('Error deleting old logo:', error);
        }
      }

      // Upload new logo
      const logoRef = ref(storage, `organizations/${orgId}/logo`);
      await uploadBytes(logoRef, file);
      const downloadUrl = await getDownloadURL(logoRef);

      // Update company info with new logo URL
      setCompanyInfo(prev => ({
        ...prev,
        logoUrl: downloadUrl
      }));

      setSuccess('Logo uploaded successfully');
    } catch (err) {
      console.error('Error uploading logo:', err);
      setError('Failed to upload logo');
    } finally {
      setUploadLoading(false);
    }
  };

  const handleDeleteLogo = async () => {
    if (!companyInfo.logoUrl) return;

    try {
      setUploadLoading(true);
      const logoRef = ref(storage, `organizations/${orgId}/logo`);
      await deleteObject(logoRef);

      setCompanyInfo(prev => ({
        ...prev,
        logoUrl: ''
      }));

      setSuccess('Logo deleted successfully');
    } catch (err) {
      console.error('Error deleting logo:', err);
      setError('Failed to delete logo');
    } finally {
      setUploadLoading(false);
    }
  };

  const updateCompanyInfo = async () => {
    if (!isAdmin || !orgId) {
      setError('You do not have permission to update company information');
      return;
    }

    try {
      setLoading(true);
      const orgRef = doc(db, 'organizations', orgId);
      
      await updateDoc(orgRef, {
        companyInfo: companyInfo
      });

      setSuccess('Company information updated successfully');
      setError('');
    } catch (err) {
      console.error('Error updating company information:', err);
      setError('Failed to update company information');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Company Information
          </Typography>
        </Grid>
        
        {/* Logo Upload Section */}
        <Grid item xs={12}>
          <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="subtitle1" gutterBottom>
              Company Logo
            </Typography>
            
            {companyInfo.logoUrl && (
              <Box sx={{ position: 'relative', mb: 2 }}>
                <img 
                  src={companyInfo.logoUrl} 
                  alt="Company Logo" 
                  style={{ 
                    maxWidth: '200px', 
                    maxHeight: '200px',
                    objectFit: 'contain'
                  }} 
                />
                <IconButton
                  onClick={handleDeleteLogo}
                  sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    backgroundColor: 'background.paper',
                    '&:hover': { backgroundColor: 'action.hover' }
                  }}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}

            <Button
              component="label"
              variant="contained"
              startIcon={uploadLoading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
              disabled={uploadLoading}
            >
              {uploadLoading ? 'Uploading...' : 'Upload Logo'}
              <VisuallyHiddenInput type="file" onChange={handleLogoUpload} accept="image/*" />
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Company Name"
            value={orgName}
            disabled
            helperText="Company name can be changed in Organization Settings tab"
          />
        </Grid>

        {/* Existing form fields */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="First Name"
            value={companyInfo.firstName}
            onChange={handleCompanyInfoChange('firstName')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Last Name"
            value={companyInfo.lastName}
            onChange={handleCompanyInfoChange('lastName')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Company Email"
            value={companyInfo.email}
            onChange={handleCompanyInfoChange('email')}
            type="email"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Company Phone Number"
            value={companyInfo.phoneNumber}
            onChange={handleCompanyInfoChange('phoneNumber')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Street Address"
            value={companyInfo.address}
            onChange={handleCompanyInfoChange('address')}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="City"
            value={companyInfo.city}
            onChange={handleCompanyInfoChange('city')}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="State"
            value={companyInfo.state}
            onChange={handleCompanyInfoChange('state')}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="ZIP Code"
            value={companyInfo.zipCode}
            onChange={handleCompanyInfoChange('zipCode')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Hours of Operation Start"
            type="time"
            value={companyInfo.hoursStart}
            onChange={handleCompanyInfoChange('hoursStart')}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Hours of Operation End"
            type="time"
            value={companyInfo.hoursEnd}
            onChange={handleCompanyInfoChange('hoursEnd')}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          {success && (
            <Typography color="success.main" sx={{ mt: 2 }}>
              {success}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={updateCompanyInfo}
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? 'Saving...' : 'Save Company Information'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanyInfo;