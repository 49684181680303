// src/components/Layout.js

import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import AvailabilityModal from './AvailabilityModal';

const Layout = ({ isDarkMode, toggleDarkMode }) => {
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);

  const handleAvailabilitySelect = (availabilityData) => {
    setSelectedAvailability(availabilityData);
    setIsAvailabilityModalOpen(true);
  };

  return (
    <div className="app-layout" data-mui-color-scheme={isDarkMode ? 'dark' : 'light'}>
      <Sidebar isDarkMode={isDarkMode} />
      <div className={`main-content ${isDarkMode ? 'dark-mode' : ''}`}>
        <Header 
          isDarkMode={isDarkMode} 
          toggleDarkMode={toggleDarkMode}
          onAvailabilitySelect={handleAvailabilitySelect}
        />
        <div className="content-padding">
          <Outlet context={{ 
            selectedAvailability,
            isAvailabilityModalOpen,
            setIsAvailabilityModalOpen,
            setSelectedAvailability
          }} />
        </div>
      </div>

      {/* Move AvailabilityModal to Layout level */}
      {isAvailabilityModalOpen && (
        <AvailabilityModal
          open={isAvailabilityModalOpen}
          onClose={() => {
            setIsAvailabilityModalOpen(false);
            setSelectedAvailability(null);
          }}
          availabilityData={selectedAvailability}
          onStatusChange={(data) => {
            // Handle status change here
            console.log('Status change:', data);
            setIsAvailabilityModalOpen(false);
            setSelectedAvailability(null);
          }}
          loading={false}
        />
      )}
    </div>
  );
};

export default Layout;
