// src/components/EmailPreviewModal.js

import React, { useState, useEffect } from 'react';
import { useTimezone } from '../context/TimeZoneContext';
import ReactQuill from 'react-quill';
import styles from '../styles/EmailPreviewModal.module.css';


const EmailPreviewModal = ({
  open,
  onClose,
  jobData,
  onSend,
  templates = []
}) => {
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [emailContent, setEmailContent] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const { formatInOrgTz, moment, timezone } = useTimezone();

    useEffect(() => {
      if (selectedTemplate) {
        const template = templates.find(t => t.documentId === selectedTemplate);
        if (template) {
          const processed = processTemplate(template, jobData);
          setEmailSubject(processed.subject);
          setEmailContent(processed.content);
        }
      }
    }, [selectedTemplate, jobData, templates]);

    const processTemplate = (template, data) => {
      const processVariables = (content) => {
        let processed = content;
        
        const cleanersArray = Array.isArray(data.cleanerDetails) ? data.cleanerDetails : [];

        const allCleanerNames = cleanersArray.map((c) => c.name).filter(Boolean);
        const allCleanerEmails = cleanersArray.map((c) => c.email).filter(Boolean);

    
        // Create logo HTML with fixed dimensions
        const logoHtml = data.organizationLogo 
          ? `<img 
               src="${data.organizationLogo}" 
               alt="${data.organizationName || 'Company'} Logo" 
               width="150" 
               height="150" 
               class="preserved-image"
               data-preserved="true"
               style="width: 150px !important; 
                      height: 150px !important; 
                      object-fit: contain; 
                      display: block; 
                      margin: 5px 0;
                      -ms-interpolation-mode: bicubic;
                      max-width: 150px;
                      min-width: 150px;"
             />`
          : '';
      
        // Handle 1, 2, and 3 hour ranges
        const getApproxTimeRange = (date, hours = 1, includeMinutes = false) => {
          if (!date) return '';
          
          const startMoment = moment(date).tz(timezone);
          const endMoment = startMoment.clone().add(hours, 'hours');
          
          const formatTime = (momentObj) => {
            if (includeMinutes) {
              return momentObj.format('h:mma');
            }
            return momentObj.format('ha');
          };
          
          return `${formatTime(startMoment)} - ${formatTime(endMoment)}`;
        };
      
        // Customer Variables
        processed = processed.replace(/{{customer\.name}}/g, data.customerName || '');
        processed = processed.replace(/{{customer\.firstName}}/g, data.customerName?.split(' ')[0] || '');
        processed = processed.replace(/{{customer\.lastName}}/g, data.customerName?.split(' ')[1] || '');
        processed = processed.replace(/{{customer\.email}}/g, data.customerEmail || '');
        processed = processed.replace(/{{customer\.phone}}/g, data.customerPhone || '');
        processed = processed.replace(/{{customer\.address}}/g, data.address || '');
        processed = processed.replace(/{{customer\.billingAddress}}/g, data.billingAddress || '');
        processed = processed.replace(/{{customer\.notes}}/g, data.customerNotes || '');
      
        // Appointment Variables - Timing
        processed = processed.replace(/{{appointment\.appointmentDate}}/g, formatInOrgTz(data.appointmentDate, 'dddd, MMMM D, YYYY'));
        processed = processed.replace(/{{appointment\.scheduledStartTime}}/g, formatInOrgTz(data.appointmentDate, 'h:mm A'));
        processed = processed.replace(/{{appointment\.scheduledEndTime}}/g, formatInOrgTz(data.scheduledEndTime, 'h:mm A'));
        processed = processed.replace(/{{appointment\.duration}}/g, data.duration || '');
      
        // Appointment Variables - Approximate Times
        processed = processed.replace(/{{appointment\.start_time_approx_one}}/g, getApproxTimeRange(data.appointmentDate, 1, false));
        processed = processed.replace(/{{appointment\.start_time_approx_two}}/g, getApproxTimeRange(data.appointmentDate, 2, false));
        processed = processed.replace(/{{appointment\.start_time_approx_three}}/g, getApproxTimeRange(data.appointmentDate, 3, false));
        processed = processed.replace(/{{appointment\.start_time_approx_one_minutes}}/g, getApproxTimeRange(data.appointmentDate, 1, true));
        processed = processed.replace(/{{appointment\.start_time_approx_two_minutes}}/g, getApproxTimeRange(data.appointmentDate, 2, true));
        processed = processed.replace(/{{appointment\.start_time_approx_three_minutes}}/g, getApproxTimeRange(data.appointmentDate, 3, true));
      
        // Appointment Variables - Service Details
        processed = processed.replace(/{{appointment\.serviceCost}}/g, `$${data.serviceCost?.toFixed(2) || '0.00'}`);
        processed = processed.replace(/{{appointment\.address}}/g, data.address || '');
        processed = processed.replace(/{{appointment\.serviceType}}/g, data.serviceType || '');
        processed = processed.replace(/{{appointment\.status}}/g, data.status || '');
        processed = processed.replace(/{{appointment\.totalSize}}/g, `${data.totalSize || 0} sq ft`);
        
        // Appointment Variables - Notes
        processed = processed.replace(/{{appointment\.notes}}/g, data.notes || '');
        processed = processed.replace(/{{appointment\.importantNotes}}/g, data.importantNotes || '');
      
        // Organization Variables
        processed = processed.replace(/{{organization\.name}}/g, data.organizationName || '');
        processed = processed.replace(/{{organization\.logo}}/g, logoHtml);
        processed = processed.replace(/{{organization\.email}}/g, data.organizationEmail || '');
        processed = processed.replace(/{{organization\.phone}}/g, data.organizationPhone || '');

        // Cleaner Variables
        processed = processed.replace(/{{cleaner\.name}}/g, data.cleanerName || '');
        processed = processed.replace(/{{cleaner\.email}}/g, data.cleanerEmail || '');
        processed = processed.replace(/{{cleaners\.names\.comma}}/g, allCleanerNames.join(', '));
        processed = processed.replace(/{{cleaners\.names\.and}}/g, allCleanerNames.join(' and '));
        processed = processed.replace(/{{cleaners\.htmlList}}/g, generateCleanerListHTML(cleanersArray));
      
        // Recurring Service Variables
        processed = processed.replace(/{{recurring\.status}}/g, data.recurringStatus || '');
        processed = processed.replace(/{{recurring\.recurrenceRule}}/g, data.recurrenceRule || '');
    
        processed = `${processed}`;
        return processed;
      };
    
      return {
        subject: processVariables(template.subject),
        content: processVariables(template.content)
      };
    };

    const generateCleanerListHTML = (cleaners) => {
      if (!cleaners?.length) return '';
      let listHtml = '<ul>';
      cleaners.forEach((c) => {
        const displayName = c.name || 'Unnamed Cleaner';
        const displayEmail = c.email ? ` (${c.email})` : '';
        listHtml += `<li>${displayName}${displayEmail}</li>`;
      });
      listHtml += '</ul>';
      return listHtml;
    };

    const handleTemplateSelect = (e) => {
      const selectedTemplateId = e.target.value;
      const template = templates.find(t => t.documentId === selectedTemplateId);
      
      if (template) {
        setSelectedTemplate(template.documentId);
        const processed = processTemplate(template, jobData);
        setEmailSubject(processed.subject);
        setEmailContent(processed.content);
      }
    };

if (!open) return null;

return (
  <div className={styles.emailModalOverlay}>
    <div className={styles.emailModalContent}>
      <h2 className={styles.emailModalTitle}>Generate Email from Template</h2>
      
      <form className={styles.emailForm}>
        <div className={styles.formGroup}>
          <label htmlFor="template">Select Template</label>
          <select
            id="template"
            className={styles.templateSelect}
            value={selectedTemplate || ''}
            onChange={handleTemplateSelect}
          >
            <option value="">Choose a template...</option>
            {templates.map((template) => (
              <option key={template.documentId} value={template.documentId}>
                {template.name}
              </option>
            ))}
          </select>
        </div>

        {selectedTemplate && (
          <>
            <div className={styles.formGroup}>
              <label htmlFor="subject">Subject</label>
              <input
                id="subject"
                type="text"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                className={styles.subjectInput}
                placeholder="Email subject..."
              />
            </div>

            <div className={styles.formGroup}>
              <label>Content</label>
              <div className={styles.editorContainer}>
              <ReactQuill
                value={emailContent}
                onChange={setEmailContent}
              />
              </div>
            </div>
          </>
        )}

        <div className={styles.buttonGroup}>
          <button
            type="button"
            onClick={onClose}
            className={`${styles.button} ${styles.buttonSecondary}`}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {
              if (!selectedTemplate) return;
              onSend({
                documentId: selectedTemplate,
                subject: emailSubject,
                content: emailContent
              });
            }}
            className={`${styles.button} ${styles.buttonPrimary}`}
            disabled={!selectedTemplate}
          >
            Send Email
          </button>
        </div>
      </form>
    </div>
  </div>
);
};

export default EmailPreviewModal;