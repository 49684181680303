import styles from "../../../styles/JobOverview/CalendarCards/AvailableSlotCard.module.css";

const AvailableSlotCard = ({ data }) => {
  const formatTime = (date) => date
    ? date.format('hh:mm A')
    : undefined;

  const getAvailabilityStatusDetails = (status) => {
    switch (status) {
      case 'time_off_approved':
        return {
          color: '#4A148C',
          label: 'Time Off',
          bgColor: 'rgba(74, 20, 140, 0.1)',
          borderColor: 'rgba(74, 20, 140, 1)',
          hoverColor: 'rgba(74, 20, 140, 0.2)'
        };
      case 'time_off_requested':
        return {
          color: '#9C27B0',
          label: 'Time Off Requested',
          bgColor: 'rgba(156, 39, 176, 0.1)',
          borderColor: 'rgba(156, 39, 176, 1)',
          hoverColor: 'rgba(156, 39, 176, 0.2)'
        };
      case 'offer_pending':
        return {
          color: '#FF9800',
          label: 'Offer Pending',
          bgColor: 'rgba(255, 152, 0, 0.1)',
          borderColor: 'rgba(255, 152, 0, 1)',
          hoverColor: 'rgba(255, 152, 0, 0.2)'
        };
      case 'client_requested':
        return {
          color: '#2196F3',
          label: 'Client Requested',
          bgColor: 'rgba(33, 150, 243, 0.1)',
          borderColor: 'rgba(33, 150, 243, 1)',
          hoverColor: 'rgba(33, 150, 243, 0.2)'
        };
      default:
        return {
          color: '#4CAF50',
          label: 'Available',
          bgColor: 'rgba(76, 175, 80, 0.1)',
          borderColor: 'rgba(76, 175, 80, 1)',
          hoverColor: 'rgba(76, 175, 80, 0.2)'
        };
    }
  };

  const statusDetails = getAvailabilityStatusDetails(data.status);

  const startDate = formatTime(data.start)
  const endDate = formatTime(data.end)

  return (
    <div
      className={styles.card}
      style={{
        backgroundColor: statusDetails.bgColor,
        border: `1px dashed ${statusDetails.borderColor}`
      }}
    >
      <p>
        {startDate} - {endDate}
      </p>
      <p>
        {data.cleaner.firstName} is {statusDetails.label}
      </p>
    </div>
  )
}

export default AvailableSlotCard