// /src/components/OrgSettings.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, collection, getDocs, writeBatch, setDoc, arrayUnion } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import UserModal from './UserModal';
import styles from '../styles/OrgSettings.module.css';
import { Tab, Tabs, Box } from '@mui/material';
import { KeyIcon, Edit2Icon, Trash2Icon } from 'lucide-react';
import { useTimezone } from '../context/TimeZoneContext';
import moment from 'moment-timezone';
import { FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { organizationApi } from '../utils/api';
import ScheduleSettings from './ScheduleSettings';
import CompanyInfo from './CompanyInfo';
import ClientPortalSettings from './ClientPortalSettings';

// Custom TabPanel component
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const OrganizationSettings = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { user } = useAuth();
  const [orgName, setOrgName] = useState('');
  const [orgId, setOrgId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [editUserData, setEditUserData] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const { timezone, updateOrgTimezone } = useTimezone();

  const getAuthToken = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken(true);
      return token;
    } catch (err) {
      console.error('Error getting auth token:', err);
      throw err;
    }
  };

  useEffect(() => {
    const fetchOrgData = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();
  
          if (userData?.orgId) {
            setOrgId(userData.orgId);
            const orgDoc = await getDoc(doc(db, 'organizations', userData.orgId));
  
            if (orgDoc.exists()) {
              const orgData = orgDoc.data();
              setOrgName(orgData.name);
              setIsAdmin(orgData.adminIds.includes(user.uid));
              setSelectedTimezone(orgData.timezone || 'America/New_York');
  
              // Fetch all users in the organization
              const userPromises = orgData.userIds.map(async (userId) => {
                const userRef = doc(db, 'users', userId);
                const userSnap = await getDoc(userRef);
                
                if (userSnap.exists()) {
                  const userData = userSnap.data();
                  
                  // Check if user is also in cleaners collection
                  const cleanerRef = doc(db, 'organizations', userData.orgId, 'cleaners', userId);
                  const cleanerSnap = await getDoc(cleanerRef);
                  
                  return {
                    uid: userId,
                    email: userData.email,
                    role: userData.role || (orgData.adminIds.includes(userId) ? 'admin' : 'member'),
                    payType: userData.payType,
                    payRate: userData.payRate,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    phoneNumber: userData.phoneNumber,
                    address: userData.address,
                    manHourCost: userData.manHourCost,
                    isInCleanersCollection: cleanerSnap.exists()
                  };
                }
                return null;
              });
  
              const userResults = await Promise.all(userPromises);
              setUsers(userResults.filter((user) => user !== null));
            }
          }
        } catch (err) {
          console.error('Error fetching organization data:', err);
          setError('Failed to load organization data');
        } finally {
          setLoading(false);
        }
      }
    };
  
    fetchOrgData();
  }, [user]);
  


  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // Schedule Settings
  const [scheduleSettings, setScheduleSettings] = useState({
    minimumGapNotification: 180,
    driveTimeGap: 30,
    timeBlocks: [],
    workingHours: {
      start: '08:00',
      end: '17:00'
    },
    workingDays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    jobStatuses: [],
    recurringStatuses: [],
    emailTemplates: []
  });

    // Schedule settings handlers
    const handleScheduleSettingsChange = (newSettings) => {
      setScheduleSettings(prevSettings => ({
        ...prevSettings,
        ...newSettings
      }));
    };
  
    const updateScheduleSettings = async () => {
      if (!user || !isAdmin || !orgId) {
        setError('You do not have permission to update schedule settings');
        return;
      }
    
      try {
        setLoading(true);
        const token = await getAuthToken();
    
        // Create batch for atomic updates
        const batch = writeBatch(db);
    
        // Update main schedule settings (without email templates)
        const { emailTemplates, ...otherSettings } = scheduleSettings;
        const orgRef = doc(db, 'organizations', orgId);
        batch.update(orgRef, {
          scheduleSettings: {
            ...otherSettings,
            updatedAt: new Date().toISOString()
          }
        });
    
        // Handle email templates separately in their own collection
        const emailTemplatesRef = collection(db, 'organizations', orgId, 'emailTemplates');
        
        // First, delete existing templates
        const existingTemplates = await getDocs(emailTemplatesRef);
        existingTemplates.forEach(doc => {
          batch.delete(doc.ref);
        });
    
        // Then add new templates
        if (emailTemplates?.length > 0) {
          emailTemplates.forEach(template => {
            const templateRef = doc(emailTemplatesRef);
            batch.set(templateRef, {
              ...template,
              updatedAt: new Date().toISOString()
            });
          });
        }
    
        await batch.commit();
    
        setSuccess('Settings updated successfully');
        setError('');
      } catch (err) {
        console.error('Error updating settings:', err);
        setError('Failed to update settings');
        setSuccess('');
      } finally {
        setLoading(false);
      }
    };
  
    // Fetch schedule settings on component mount
    useEffect(() => {
      const fetchScheduleSettings = async () => {
        if (orgId) {
          try {
            // Fetch main settings
            const orgDoc = await getDoc(doc(db, 'organizations', orgId));
            let settingsData = {};
            
            if (orgDoc.exists() && orgDoc.data().scheduleSettings) {
              settingsData = {
                ...orgDoc.data().scheduleSettings,
                timeBlocks: orgDoc.data().scheduleSettings.timeBlocks || []
              };
            }
    
            // Fetch email templates
            const emailTemplatesSnapshot = await getDocs(
              collection(db, 'organizations', orgId, 'emailTemplates')
            );
            
            const emailTemplates = [];
            emailTemplatesSnapshot.forEach(doc => {
              emailTemplates.push({ id: doc.id, ...doc.data() });
            });
    
            // Combine settings with email templates
            setScheduleSettings({
              ...settingsData,
              emailTemplates
            });
    
          } catch (err) {
            console.error('Error fetching settings:', err);
          }
        }
      };
    
      fetchScheduleSettings();
    }, [orgId]);

  
    const addUser = async (userData) => {
      if (!user || !isAdmin || !userData.email || !orgId) {
        setError('Invalid request or insufficient permissions');
        return;
      }
    
      try {
        setLoading(true);
        const token = await getAuthToken();
    
        // Ensure all required fields are present
        const enrichedUserData = {
          ...userData,
          orgId,
          role: userData.role || 'member',
          availability: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            ...userData.availability
          },
          workingHours: {
            monday: { start: '', end: '' },
            tuesday: { start: '', end: '' },
            wednesday: { start: '', end: '' },
            thursday: { start: '', end: '' },
            friday: { start: '', end: '' },
            saturday: { start: '', end: '' },
            sunday: { start: '', end: '' },
            ...userData.workingHours
          },
          isActive: true
        };
    
        // Create a batch for atomic operations
        const batch = writeBatch(db);
    
        // First create the user in your backend (which presumably
        // creates /users/{uid} at the top level):
        const response = await organizationApi.createUser(token, enrichedUserData);
    
        if (response.data.success) {
          const userId = response.data.uid;
          const newUser = {
            uid: userId,
            ...enrichedUserData
          };
    
          // Add doc to organization's users subcollection
          const userOrgRef = doc(db, 'organizations', orgId, 'users', userId);
          batch.set(userOrgRef, {
            ...enrichedUserData,
            userId,
            createdAt: new Date(),
            updatedAt: new Date()
          });
    
          // If cleaner, also add doc to the cleaners subcollection
          if (enrichedUserData.role === 'cleaner') {
            const cleanerRef = doc(db, 'organizations', orgId, 'cleaners', userId);
            batch.set(cleanerRef, {
              ...enrichedUserData,
              userId,
              createdAt: new Date(),
              updatedAt: new Date(),
              completedJobs: 0,
              totalJobs: 0,
              rating: 0,
              maxJobsPerDay: 4,
              status: 'active',
              skills: []
            });
          }
    
          // **Update the top-level organizations doc** to include the new UID
          const orgRef = doc(db, 'organizations', orgId);
          batch.update(orgRef, {
            userIds: arrayUnion(userId) // <<==== THIS IS CRUCIAL
          });
    
          // Commit all the changes in one go
          await batch.commit();
    
          // Update local state
          setUsers([...users, newUser]);
          setIsModalOpen(false);
          setSuccess('User created and invitation email sent successfully');
          setError('');
        }
      } catch (err) {
        console.error('Error adding user:', err);
        setError(err.response?.data?.error || 'Failed to add user');
        setSuccess('');
      } finally {
        setLoading(false);
      }
    };
    

  const updateUser = async (userData) => {
    if (!user || !isAdmin || !userData.uid || !orgId) {
      setError('Invalid request or insufficient permissions');
      return;
    }

    try {
      setLoading(true);
      const token = await getAuthToken();

      const response = await organizationApi.updateUser(token, {
        ...userData,
        orgId,
      });

      if (response.data.success) {
        setUsers(users.map((u) => (u.uid === userData.uid ? { ...u, ...userData } : u)));
        setIsModalOpen(false);
        setSuccess('User updated successfully');
        setError('');
        setEditUserData(null);
      }
    } catch (err) {
      console.error('Error updating user:', err);
      setError(err.response?.data?.error || 'Failed to update user');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (uid) => {
    if (!user || !isAdmin || !orgId) {
      setError('You do not have permission to delete users');
      return;
    }

    try {
      setLoading(true);
      const token = await getAuthToken();

      const response = await organizationApi.deleteUser(token, {
        uid,
        orgId,
      });

      if (response.data.success) {
        setUsers(users.filter((u) => u.uid !== uid));
        setSuccess('User removed successfully');
        setError('');
      }
    } catch (err) {
      console.error('Error deleting user:', err);
      setError(err.response?.data?.error || 'Failed to delete user');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (uid, email) => {
    if (!user || !isAdmin) {
      setError('You do not have permission to reset passwords');
      return;
    }

    try {
      setLoading(true);
      const token = await getAuthToken();

      const response = await organizationApi.resetPassword(token, {
        uid,
        orgId,
        email,
      });

      if (response.data.success) {
        setSuccess('Password reset email sent successfully');
        setError('');
      }
    } catch (err) {
      console.error('Error resetting password:', err);
      setError(err.response?.data?.error || 'Failed to send password reset email');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  const updateOrgName = async () => {
    if (!user || !isAdmin || !orgId) {
      setError('You do not have permission to update the organization name');
      return;
    }

    try {
      setLoading(true);
      const token = await getAuthToken();

      const response = await organizationApi.updateOrgName(token, {
        orgId,
        name: orgName,
      });

      if (response.data.success) {
        setSuccess('Organization name updated successfully');
        setError('');
      }
    } catch (err) {
      console.error('Error updating organization name:', err);
      setError(err.response?.data?.error || 'Failed to update organization name');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div className={styles.errorContainer}>
        You do not have admin access to view organization settings.
      </div>
    );
  }

  const handleTimezoneChange = async (event) => {
    const newTimezone = event.target.value;
    if (!user || !isAdmin || !orgId) {
      setError('You do not have permission to update the timezone');
      return;
    }

    try {
      setLoading(true);
      const token = await getAuthToken();

      const response = await organizationApi.updateOrgSettings(token, {
        orgId,
        timezone: newTimezone,
      });

      if (response.data.success) {
        setSelectedTimezone(newTimezone);
        await updateOrgTimezone(newTimezone); // Update the context
        setSuccess('Organization timezone updated successfully');
        setError('');
      }
    } catch (err) {
      console.error('Error updating timezone:', err);
      setError(err.response?.data?.error || 'Failed to update timezone');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.orgContainer}>
      <h2 className={styles.orgTitle}>Organization Settings</h2>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label="Organization Settings" />
          <Tab label="Company Info" />
          <Tab label="Schedule Settings" />
          <Tab label="Client Portal Settings" />
        </Tabs>
      </Box>

      {/* Organization Settings Tab */}
      <TabPanel value={currentTab} index={0}>
        <div className={styles.orgContent}>
          {/* Organization Name Section */}
          <div className={styles.orgSection}>
            <label htmlFor="orgName" className={styles.label}>
              Organization Name
            </label>
            <div className={styles.orgInputGroup}>
              <input
                type="text"
                id="orgName"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
                className={styles.input}
                placeholder="Enter organization name"
              />
              <button
                onClick={updateOrgName}
                disabled={loading}
                className={`${styles.orgButton} ${styles.orgButtonPrimary}`}
              >
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>

          {/* Organization Timezone Section */}
          <div className={styles.orgSection}>
            <Typography variant="h6" gutterBottom>
              Organization Timezone
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Timezone</InputLabel>
              <Select
                value={selectedTimezone}
                onChange={handleTimezoneChange}
                label="Timezone"
              >
                {moment.tz.names().map((tz) => (
                  <MenuItem key={tz} value={tz}>
                    {tz.replace(/_/g, ' ')} ({moment.tz(tz).format('z')})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
              Current time in organization timezone: {moment().tz(selectedTimezone).format('LLLL')}
            </Typography>
          </div>

          {/* Users Section */}
          <div className={styles.orgSection}>
            <div className={styles.orgSectionHeader}>
              <h3 className={styles.orgSectionTitle}>Users</h3>
              <button
                onClick={() => setIsModalOpen(true)}
                className={`${styles.orgButton} ${styles.orgButtonSecondary}`}
              >
                Add New User
              </button>
            </div>

            <div className={styles.usersList}>
            {users
              .filter(user => user.role !== 'customer')
              .map((user) => (
                <div key={user.uid} className={styles.userCard}>
                  <div className={styles.userInfo}>
                    <div className={styles.userName}>
                      {user.firstName && user.lastName 
                        ? `${user.firstName} ${user.lastName}`
                        : user.email}
                    </div>
                    <div className={styles.userEmail}>{user.firstName ? user.email : null}</div>
                    <div className={styles.userRole}>{user.role}</div>
                    {user.payType && (
                      <div className={styles.userDetails}>
                        <span>Pay Type: {user.payType}</span>
                        <span>Pay Rate: ${user.payRate}</span>
                      </div>
                    )}
                  </div>
                  <div className={styles.userActions}>
                    <button
                      onClick={() => resetPassword(user.uid, user.email)}
                      className={`${styles.orgIconButton} ${styles.orgIconButtonPrimary}`}
                      title="Reset Password"
                    >
                      <KeyIcon size={18} />
                    </button>
                    <button
                      onClick={() => {
                        setEditUserData(user);
                        setIsModalOpen(true);
                      }}
                      className={`${styles.orgIconButton} ${styles.orgIconButtonPrimary}`}
                      title="Edit User"
                    >
                      <Edit2Icon size={18} />
                    </button>
                    <button
                      onClick={() => deleteUser(user.uid)}
                      className={`${styles.orgIconButton} ${styles.orgIconButtonDanger}`}
                      title="Remove User"
                    >
                      <Trash2Icon size={18} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </TabPanel>

      {/* Company Info Tab */}
      <TabPanel value={currentTab} index={1}>
        <CompanyInfo 
            orgId={orgId}
            orgName={orgName}
            isAdmin={isAdmin}
          />
      </TabPanel>

      {/* Schedule Settings Tab */}
      <TabPanel value={currentTab} index={2}>
        <ScheduleSettings 
          scheduleSettings={scheduleSettings}
          onScheduleSettingsChange={handleScheduleSettingsChange}
          onSave={updateScheduleSettings}
          loading={loading}
        />
      </TabPanel>

      {/* Client Portal Settings */}
      <TabPanel value={currentTab} index={3}>
        <ClientPortalSettings 
          orgId={orgId}
          isAdmin={isAdmin}
        />
      </TabPanel>

      {/* Messages */}
      {error && <div className={styles.errorMessage}>{error}</div>}
      {success && <div className={styles.successMessage}>{success}</div>}

      {/* User Modal */}
      {isModalOpen && (
        <UserModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setEditUserData(null);
          }}
          onSubmit={editUserData ? updateUser : addUser}
          loading={loading}
          initialData={editUserData}
        />
      )}
    </div>
  );
};

export default OrganizationSettings;

