import styles from "../../../styles/JobOverview/ActionCenter/ActionCenterCard.module.css"

import React from "react";
import { Button } from "@mui/material";

const TimeOffCard = React.memo(({ data }) => {

  const formatTimestampToDayTime = (timestamp) => {
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const date = new Date(timestamp * 1000);

    const day = days[date.getDay()];
    const dateOfMonth = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()];

    const hours24 = date.getHours();
    const hours12 = hours24 % 12 || 12;
    const amPm = hours24 >= 12 ? 'PM' : 'AM';
    const hours = String(hours12).padStart(2, '0');

    const minutes = String(date.getMinutes()).padStart(2, '0');

    return [`${month} ${dateOfMonth}, ${day}`, `${hours}:${minutes} ${amPm}`];
  };

  const startTime = formatTimestampToDayTime(data.startTime.seconds);
  const endTime = formatTimestampToDayTime(data.endTime.seconds);

  return (
    <div className={styles.card}>
      <p className={styles.heading}>Time off request</p>
      <p className={styles.subHeading}> {data.cleaner.firstName} {data.cleaner.lastName} </p>

      <p> {startTime[0]} {startTime[0] !== endTime[0] && (<span>- {endTime[0]}</span>)} </p>
      <p> {startTime[1]} - {endTime[1]} </p>

      <div className={styles.actionsContainer}>
        <Button className={styles.action}>
          Take action
        </Button>
      </div>
    </div>
  )
})

export default TimeOffCard;