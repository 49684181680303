// src/components/TimeOffRequestsDashboard.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Card, CardContent, Typography, Button, Tabs, Tab } from '@mui/material';
import { Plus, Clock, AlertCircle } from 'lucide-react';
import MobileHeader from './MobileHeader';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';

const TimeOffRequestsDashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const [requests, setRequests] = useState({
    pending: [],
    approved: [],
    denied: [],
    past: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        // Create the subcollection reference - it will exist automatically when we add documents
        const timeOffRef = collection(db, 'organizations', user.orgId, 'cleaners', user.uid, 'timeOffRequests');
        const timeOffQuery = query(timeOffRef, orderBy('date', 'desc'));
        const querySnapshot = await getDocs(timeOffQuery);
        
        // If there are no documents yet, that's fine - we'll just show empty state
        if (querySnapshot.empty) {
          setRequests({
            pending: [],
            approved: [],
            denied: [],
            past: []
          });
          setLoading(false);
          return;
        }

        const now = moment();
        const categorized = {
          pending: [],
          approved: [],
          denied: [],
          past: []
        };

        querySnapshot.forEach((doc) => {
          const request = {
            id: doc.id,
            ...doc.data(),
            date: doc.data().date.toDate()
          };

          const requestDate = moment(request.date);
          
          if (requestDate.isBefore(now, 'day')) {
            categorized.past.push(request);
          } else if (request.status === 'pending') {
            categorized.pending.push(request);
          } else if (request.status === 'approved') {
            categorized.approved.push(request);
          } else if (request.status === 'denied') {
            categorized.denied.push(request);
          }
        });

        // Sort each category by date
        Object.keys(categorized).forEach(key => {
          categorized[key].sort((a, b) => new Date(b.date) - new Date(a.date));
        });

        setRequests(categorized);
      } catch (err) {
        console.error('Error fetching time off requests:', err);
        setError('Failed to load requests');
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [user.orgId, user.uid]);

  const handleNewRequest = () => {
    navigate(`/cleaner-timeoff/${user.uid}/new`);
  };

  const formatDate = (date) => {
    return moment(date).format('ddd, MMMM D, YYYY');
  };

  const renderRequestCard = (request) => (
    <Card key={request.id} className="mb-4 shadow-sm">
      <CardContent>
        <div className="flex items-center justify-between mb-2">
          <div>
            <Typography variant="subtitle1" className="font-medium">
              {formatDate(request.date)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {moment(request.startTime.toDate()).format('h:mm A')} - {moment(request.endTime.toDate()).format('h:mm A')}
            </Typography>
          </div>
          <Typography 
            variant="body2"
            className={`px-2 py-1 rounded-full ${
              request.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
              request.status === 'approved' ? 'bg-green-100 text-green-800' :
              'bg-red-100 text-red-800'
            }`}
          >
            {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
          </Typography>
        </div>
        
        {request.affectedJobs?.length > 0 && (
          <div className="mt-2">
            <Typography variant="body2" color="textSecondary" className="mb-1">
              Affected Jobs:
            </Typography>
            {request.affectedJobs.map((job, index) => (
              <Typography key={index} variant="body2" color="textSecondary">
                {job.customerName} ({moment(job.startTime).format('h:mm A')} - {moment(job.endTime).format('h:mm A')})
              </Typography>
            ))}
          </div>
        )}
        
        <Typography variant="body2" color="textSecondary" className="mt-2">
          Requested on: {moment(request.createdAt.toDate()).format('MMM D, YYYY')}
        </Typography>
      </CardContent>
    </Card>
  );

  if (loading) {
    return (
      <div className="flex flex-col h-full bg-gray-50">
        <MobileHeader title="Time Off Requests" />
        <div className="p-4 text-center">
          <Typography>Loading requests...</Typography>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col h-full bg-gray-50">
        <MobileHeader title="Time Off Requests" />
        <div className="p-4">
          <Card className="bg-red-50">
            <CardContent className="flex items-center gap-2">
              <AlertCircle className="w-5 h-5 text-red-500" />
              <Typography color="error">{error}</Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full bg-gray-50">
      <MobileHeader title="Time Off Requests" />
      
      <div className="p-4">
        <Button
          variant="contained"
          fullWidth
          onClick={handleNewRequest}
          className="mb-4 bg-blue-500 hover:bg-blue-600"
          startIcon={<Plus className="w-4 h-4" />}
        >
          New Request
        </Button>

        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          variant="fullWidth"
          className="mb-4"
        >
          <Tab label={`Pending (${requests.pending.length})`} />
          <Tab label={`Approved (${requests.approved.length})`} />
          <Tab label={`Past (${requests.past.length})`} />
        </Tabs>

        <div className="space-y-4">
          {activeTab === 0 && requests.pending.map(renderRequestCard)}
          {activeTab === 1 && requests.approved.map(renderRequestCard)}
          {activeTab === 2 && requests.past.map(renderRequestCard)}
          
          {((activeTab === 0 && requests.pending.length === 0) ||
            (activeTab === 1 && requests.approved.length === 0) ||
            (activeTab === 2 && requests.past.length === 0)) && (
            <Card className="bg-gray-50">
              <CardContent>
                <div className="text-center py-8">
                  <Clock className="w-12 h-12 mx-auto mb-4 text-gray-400" />
                  <Typography variant="h6" color="textSecondary" className="mb-2">
                    No {activeTab === 0 ? 'pending' : activeTab === 1 ? 'approved' : 'past'} requests
                  </Typography>
                  {activeTab === 0 && (
                    <Typography color="textSecondary">
                      Click the "New Request" button above to request time off
                    </Typography>
                  )}
                </div>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeOffRequestsDashboard;