// src/components/ExpenseHistory.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Card, 
  CardContent, 
  Typography,
  Tabs,
  Tab,
  CircularProgress
} from '@mui/material';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../context/AuthContext';
import moment from 'moment-timezone';
import styles from '../styles/ExpenseHistory.module.css';

const ExpenseHistory = () => {
  const [activeTab, setActiveTab] = useState('pending');
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { cleanerId } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    const fetchExpenses = async () => {
      if (!user?.orgId || !cleanerId) return;
      
      setLoading(true);
      try {
        const expensesRef = collection(db, 'organizations', user.orgId, 'cleaners', cleanerId, 'expenses');
        const expensesQuery = query(expensesRef, orderBy('date', 'desc'));
        const querySnapshot = await getDocs(expensesQuery);

        const expensesList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: doc.data().date.toDate()
        }));

        setExpenses(expensesList);
      } catch (err) {
        console.error('Error fetching expenses:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchExpenses();
  }, [user?.orgId, cleanerId]);

  const filteredExpenses = expenses.filter(expense => expense.status === activeTab);

  const renderExpense = (expense) => (
    <Card key={expense.id} className={styles.expenseCard}>
      <CardContent>
        <Typography variant="subtitle1">
          {expense.type} Expense
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {moment(expense.date).format('MMM D, YYYY')}
        </Typography>
        <Typography>
          Status: {expense.status.charAt(0).toUpperCase() + expense.status.slice(1)}
        </Typography>
        {expense.type === 'Gas' ? (
          <Typography>
            {expense.miles} miles - {expense.clientName}
          </Typography>
        ) : (
          <Typography>
            ${expense.cost} - {expense.products}
          </Typography>
        )}
      </CardContent>
    </Card>
  );

  return (
    <div className={styles.container}>
      <Card className={styles.tabCard}>
        <Tabs 
          value={activeTab}
          onChange={(e, value) => setActiveTab(value)}
          variant="fullWidth"
          className={styles.tabs}
        >
          <Tab value="pending" label="Pending" />
          <Tab value="processed" label="Approved" />
          <Tab value="rejected" label="Rejected" />
        </Tabs>
      </Card>

      <div className={styles.expensesList}>
        {loading ? (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        ) : filteredExpenses.length > 0 ? (
          filteredExpenses.map(renderExpense)
        ) : (
          <Card className={styles.emptyCard}>
            <CardContent>
              <Typography align="center" color="textSecondary">
                No {activeTab} expenses found
              </Typography>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};

export default ExpenseHistory;