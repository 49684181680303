// /src/components/LeadAnalysis.js

import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { collection, doc, getDocs, getDoc, query, where } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from '../firebase';
import AddRoomsModal from './AddRoomsModal';
import moment from 'moment';
import { analysisApi } from '../utils/api';

const LeadAnalysis = ({ onAnalyze }) => {
    const [user, setUser] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const [error, setError] = useState(null);
    const [currentDate] = useState(moment());
    const [existingClients, setExistingClients] = useState([]);
    const [availableCleaners, setAvailableCleaners] = useState([]);
    const [similarServices, setSimilarServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [availableRooms, setAvailableRooms] = useState([]);
    const [isRoomsModalOpen, setIsRoomsModalOpen] = useState(false);
    const [analysisResults, setAnalysisResults] = useState(null);
    const [cleaners, setCleaners] = useState({});
    const [availabilityStatuses, setAvailabilityStatuses] = useState({});
    const [timeOffRequests, setTimeOffRequests] = useState({});
    const [scheduleSettings, setScheduleSettings] = useState({
      minimumGapNotification: 180,
      driveTimeGap: 30
    });

    const FREQUENCIES = [
      { value: 'weekly', label: 'Weekly' },
      { value: 'biweekly', label: 'Bi-weekly' },
      { value: 'monthly', label: 'Monthly' },
      { value: 'once', label: 'One-time' }
    ];

    const [analysis, setAnalysis] = useState(null);
    const [leadData, setLeadData] = useState({
      name: '',
      address: '',
      squareFootage: '',
      frequency: '',
      rooms: []
    });

  // Authentication effect
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchUserOrgId(currentUser.uid);
      } else {
        setLoading(false);
        setError('Please log in to access this feature');
      }
    });

    return () => unsubscribe();
  }, []);

  // Data fetching functions
  const fetchUserOrgId = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }
      const userOrgId = userDoc.data().orgId;
      setOrgId(userOrgId);
      fetchAllData(userOrgId);
    } catch (err) {
      console.error("Error fetching user's organization ID:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchAllData = async (organizationId) => {
    setLoading(true);
    setError(null);
    try {
      // Fetch room types
      const roomTypesRef = collection(db, 'organizations', organizationId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomTypesList = roomTypesSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.name.localeCompare(b.name));
      setAvailableRooms(roomTypesList);

      // Fetch existing clients
      const customersRef = collection(db, 'organizations', organizationId, 'customers');
      const customersQuery = query(customersRef, where('isActive', '==', true));
      const customersSnapshot = await getDocs(customersQuery);
      const clients = customersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        location: doc.data().location || {
          address: doc.data().address,
          coordinates: doc.data().coordinates
        }
      }));
      setExistingClients(clients);

      // Fetch cleaners
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const cleanersList = usersSnapshot.docs
        .map(doc => ({ 
          id: doc.id,
          ...doc.data() 
        }))
        .filter(user => 
          user.orgId === organizationId && 
          user.role === 'cleaner' &&
          user.isActive !== false
        );
      setAvailableCleaners(cleanersList);

      // Fetch similar services
      const servicesRef = collection(db, 'organizations', organizationId, 'recurringServices');
      const servicesQuery = query(servicesRef, where('isActive', '==', true));
      const servicesSnapshot = await getDocs(servicesQuery);
      const services = servicesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        pricing: {
          baseRate: doc.data().baseRate,
          squareFootageRate: doc.data().squareFootageRate,
          additionalServices: doc.data().additionalServices || []
        }
      }));
      setSimilarServices(services);

    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const weekDays = [];
  const startOfWeek = moment(currentDate).startOf('week');
  for (let i = 0; i < 7; i++) {
    weekDays.push(moment(startOfWeek).add(i, 'days'));
  }

  const handleInputChange = (e) => {
    setLeadData({
      ...leadData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddRooms = (selectedRooms) => {
    setLeadData(prev => ({
      ...prev,
      rooms: selectedRooms
    }));
  };

  const analyzeLead = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Prepare the data for Claude
      const cleanerData = Object.values(cleaners).map(cleaner => ({
        id: cleaner.id,
        name: cleaner.name || `${cleaner.firstName} ${cleaner.lastName}`,
        skills: cleaner.skills || [],
        availability: availabilityStatuses[cleaner.id] || {},
        timeOff: timeOffRequests[cleaner.id] || {},
        workingHours: cleaner.workingHours || {}
      }));
  
      // Log the data being sent
      console.log('Sending analysis request with:', {
        leadData,
        cleanerDataCount: cleanerData.length,
        scheduleSettings
      });
      
  
      // Get the user's token
      const token = await user.getIdToken();
  
      // Use the API service
      const response = await analysisApi.analyzeLead(token, {
        leadData: { ...leadData, orgId }, // Include orgId
        cleanerData,
        scheduleSettings
      });
  
      // Check response structure
      console.log('Received response:', response);
  
      if (response.data?.success) {
        setAnalysis(response.data.data);
      } else {
        throw new Error(response.data?.message || 'Unexpected response format');
      }
    } catch (error) {
      console.error('Analysis error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
  
      // Set more specific error messages
      if (error.response?.status === 401) {
        setError('Authentication error. Please try logging in again.');
      } else if (error.response?.status === 400) {
        setError('Invalid request: ' + (error.response.data?.error || error.message));
      } else {
        setError('Failed to analyze lead: ' + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const calculateCost = (inputTokens, outputTokens) => {
    const INPUT_COST_PER_1K = 0.003;  // $0.003 per 1K input tokens
    const OUTPUT_COST_PER_1K = 0.015; // $0.015 per 1K output tokens
  
    const inputCost = (inputTokens / 1000) * INPUT_COST_PER_1K;
    const outputCost = (outputTokens / 1000) * OUTPUT_COST_PER_1K;
  
    return {
      inputCost,
      outputCost,
      totalCost: inputCost + outputCost
    };
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!user || !orgId) {
    return (
      <Box p={3}>
        <Alert severity="warning">Please log in to access this feature.</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', p: 3 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          New Lead Analysis
        </Typography>
        <Grid container spacing={3}>
          {/* Client Info */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Client Name"
              name="name"
              value={leadData.name}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={leadData.address}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>

          {/* Property Details */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Square Footage"
              name="squareFootage"
              type="number"
              value={leadData.squareFootage}
              onChange={handleInputChange}
              margin="normal"
              InputProps={{
                endAdornment: <Typography color="textSecondary">sq ft</Typography>
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Service Frequency</InputLabel>
              <Select
                value={leadData.frequency}
                name="frequency"
                onChange={handleInputChange}
                label="Service Frequency"
              >
                {FREQUENCIES.map(freq => (
                  <MenuItem key={freq.value} value={freq.value}>
                    {freq.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Rooms Section */}
          <Grid item xs={12}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mt: 2 
            }}>
              <Typography variant="h6">
                Rooms to Clean {leadData.rooms.length > 0 && `(${leadData.rooms.length} total)`}
              </Typography>
              <Button 
                variant="outlined" 
                onClick={() => setIsRoomsModalOpen(true)}
              >
                {leadData.rooms.length > 0 ? 'Edit Rooms' : 'Add Rooms'}
              </Button>
            </Box>
            
            {leadData.rooms.length > 0 ? (
              <Box sx={{ mt: 2 }}>
                {leadData.rooms.map((room, index) => (
                  <Paper 
                    key={index}
                    variant="outlined"
                    sx={{ p: 2, mb: 1, bgcolor: 'background.default' }}
                  >
                    <Typography variant="subtitle1">
                      {room.name} ({room.quantity})
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      {room.tasks?.map((task, taskIndex) => (
                        <Typography 
                          key={taskIndex} 
                          variant="body2" 
                          color="text.secondary"
                          sx={{ fontSize: '0.875rem' }}
                        >
                          • {task.description}
                        </Typography>
                      ))}
                    </Box>
                  </Paper>
                ))}
              </Box>
            ) : (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ mt: 2, fontStyle: 'italic' }}
              >
                No rooms added yet. Click 'Add Rooms' to select rooms and tasks.
              </Typography>
            )}
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={analyzeLead}
            disabled={loading || !leadData.name || !leadData.address || leadData.rooms.length === 0}
            sx={{ minWidth: 200 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Analyze Lead'}
          </Button>
        </Box>
      </Paper>

      {analysis && (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
            Analysis Results
            </Typography>
            <Grid container spacing={3}>
            <Grid item xs={12}>
                <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Recommended Cleaners & Scheduling</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Card>
                    <CardContent>
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                        {analysis.content[0].text}
                        </Typography>
                    </CardContent>
                    </Card>
                </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Summary Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Card>
                    <CardContent>
                        <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            Lead Information
                        </Typography>
                        <Typography variant="body2">
                            Client: {leadData.name}
                        </Typography>
                        <Typography variant="body2">
                            Address: {leadData.address}
                        </Typography>
                        <Typography variant="body2">
                            Square Footage: {leadData.squareFootage} sq ft
                        </Typography>
                        <Typography variant="body2">
                            Frequency: {leadData.frequency}
                        </Typography>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            Requested Rooms
                        </Typography>
                        {leadData.rooms.map((room, index) => (
                            <Box key={index} sx={{ mb: 1 }}>
                            <Typography variant="body2" fontWeight="bold">
                                {room.name} (Quantity: {room.quantity})
                            </Typography>
                            <Box sx={{ pl: 2 }}>
                                {room.tasks?.map((task, taskIndex) => (
                                <Typography key={taskIndex} variant="body2" sx={{ fontSize: '0.875rem' }}>
                                    • {task.description}
                                </Typography>
                                ))}
                            </Box>
                            </Box>
                        ))}
                        </Box>
                    </CardContent>
                    </Card>
                </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant="subtitle2" color="text.secondary">
                    Analysis generated at: {new Date().toLocaleString()}
                    </Typography>
                </CardContent>
                    </Card>
                </Grid>
            </Grid>
            </Box>
      )}


      <AddRoomsModal
        open={isRoomsModalOpen}
        handleClose={() => setIsRoomsModalOpen(false)}
        availableRooms={availableRooms}
        existingRooms={leadData.rooms}
        handleAddRooms={handleAddRooms}
        orgId={orgId}
        fetchAvailableRooms={() => fetchAllData(orgId)}
      />
    </Box>
  );
};

export default LeadAnalysis;