import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Checkbox,
  TextField,
  FormControlLabel,
  Divider,
  IconButton,
  Paper,
  Grid
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';

const ServiceAddOnsModal = ({ 
  open, 
  onClose, 
  serviceAddOns = [], 
  appointmentDate,
  onSubmit,
  currentServiceCost,
  loading 
}) => {
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [otherSelected, setOtherSelected] = useState(false);
  const [otherDescription, setOtherDescription] = useState('');
  const [notes, setNotes] = useState('');

  // Reset state when modal opens
  useEffect(() => {
    if (open) {
      setSelectedAddOns([]);
      setOtherSelected(false);
      setOtherDescription('');
      setNotes('');
    }
  }, [open]);

  const handleQuantityChange = (addOn, delta) => {
    setSelectedAddOns(current => {
      const existing = current.find(item => item.name === addOn.name);
      
      if (!existing) {
        // If adding for the first time and trying to decrease, don't add
        if (delta < 0) return current;
        // Add new item with quantity 1
        return [...current, { ...addOn, quantity: 1 }];
      }

      const newQuantity = (existing.quantity || 1) + delta;
      
      if (newQuantity <= 0) {
        // Remove item if quantity would be 0 or less
        return current.filter(item => item.name !== addOn.name);
      }

      // Update quantity
      return current.map(item => 
        item.name === addOn.name 
          ? { ...item, quantity: newQuantity }
          : item
      );
    });
  };

  const calculateTotal = () => {
    const baseCost = Number(currentServiceCost) || 0;
    const addOnsTotal = selectedAddOns.reduce((sum, addOn) => {
      const price = Number(addOn.price) || 0;
      const quantity = Number(addOn.quantity) || 1;
      return sum + (price * quantity);
    }, 0);
    return baseCost + addOnsTotal;
  };

  const handleSubmit = () => {
    if (otherSelected && !otherDescription.trim()) return;
    onSubmit({
      selectedAddOns,
      otherDescription: otherSelected ? otherDescription : '',
      notes,
      totalCost: calculateTotal()
    });
  };

  const getItemQuantity = (addOnName) => {
    const item = selectedAddOns.find(item => item.name === addOnName);
    return item?.quantity || 0;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h5" component="div">
          Additional Services
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          for {appointmentDate}
        </Typography>
      </DialogTitle>
      
      <DialogContent>
        <Grid container spacing={3}>
          {/* Available Services */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ mt: 1 }}>
              Available Services
            </Typography>
            {serviceAddOns.map((addOn) => (
              <Paper 
                key={addOn.name} 
                elevation={1} 
                sx={{ 
                  p: 2, 
                  mb: 2,
                  bgcolor: getItemQuantity(addOn.name) > 0 ? 'action.selected' : 'background.paper'
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" component="div">
                      {addOn.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      ${addOn.price.toFixed(2)} per {addOn.unit}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      {addOn.description}
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'flex-end',
                      gap: 1 
                    }}>
                      <IconButton 
                        onClick={() => handleQuantityChange(addOn, -1)}
                        disabled={getItemQuantity(addOn.name) === 0}
                        size="small"
                      >
                        <RemoveIcon />
                      </IconButton>
                      
                      <Typography sx={{ minWidth: '40px', textAlign: 'center' }}>
                        {getItemQuantity(addOn.name)}
                      </Typography>
                      
                      <IconButton 
                        onClick={() => handleQuantityChange(addOn, 1)}
                        size="small"
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                    
                    {getItemQuantity(addOn.name) > 0 && (
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          textAlign: 'right',
                          mt: 1,
                          fontWeight: 500
                        }}
                      >
                        Subtotal: ${(addOn.price * getItemQuantity(addOn.name)).toFixed(2)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Grid>

          {/* Other Services Request */}
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Paper elevation={1} sx={{ p: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={otherSelected}
                    onChange={(e) => setOtherSelected(e.target.checked)}
                  />
                }
                label="Need something else?"
              />
              {otherSelected && (
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  placeholder="Please describe what you need..."
                  value={otherDescription}
                  onChange={(e) => setOtherDescription(e.target.value)}
                  sx={{ mt: 2 }}
                />
              )}
            </Paper>
          </Grid>

          {/* Additional Notes */}
          <Grid item xs={12}>
            <Paper elevation={1} sx={{ p: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Additional Notes
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                placeholder="Any special instructions or notes..."
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Paper>
          </Grid>

          {/* Cost Summary */}
          <Grid item xs={12}>
            <Paper 
              elevation={2} 
              sx={{ 
                p: 2,
                bgcolor: 'primary.light',
                color: 'primary.contrastText'
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Current Service Cost: ${Number(currentServiceCost).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Add-ons Total: ${(calculateTotal() - Number(currentServiceCost)).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    New Total: ${calculateTotal().toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={loading || (otherSelected && !otherDescription.trim())}
        >
          {loading ? 'Submitting...' : 'Submit Request'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceAddOnsModal;