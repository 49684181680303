// src/components/RescheduleModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  styled,
  Tabs,
  Tab,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Alert
} from '@mui/material';
import moment from 'moment';
import { useSchedule } from '../context/ScheduleContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const StyledCard = styled(Card)(({ theme, selected }) => ({
  cursor: 'pointer',
  border: selected ? `2px solid ${theme.palette.primary.main}` : '1px solid',
  borderColor: selected ? theme.palette.primary.main : theme.palette.divider,
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  }
}));

const steps = ['Select Time', 'Add Notes'];

const RescheduleModal = ({ 
  open, 
  onClose, 
  onReschedule, 
  loading,
  currentAppointment,
  orgId,
  jobs,
  mode = 'reschedule',
  clientPortalSettings
  }) => {
    const { calculateAvailableTimeBlocks } = useSchedule();
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [availableBlocks, setAvailableBlocks] = useState([]);
    const [loadingBlocks, setLoadingBlocks] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [notes, setNotes] = useState('');
    const [preferredDate, setPreferredDate] = useState('');
    const [preferredTime, setPreferredTime] = useState('');
    const [showManualRequest, setShowManualRequest] = useState(false);
    const [manualRequestMode, setManualRequestMode] = useState(false);
    const [preferredTimeRange, setPreferredTimeRange] = useState('');
    const [cancelCount, setCancelCount] = useState(0);

    const MIN_REASON_LENGTH = 30;
    
    // Get the duration of the current appointment
    const appointmentDuration = currentAppointment ? 
      moment.duration(
        moment(currentAppointment.scheduledEndTime?.toDate())
        .diff(moment(currentAppointment.appointmentDate?.toDate()))
      ).asMinutes() : 0;
  
      useEffect(() => {
        const loadAvailableBlocks = async () => {
          if (!open || !currentAppointment || !orgId) return;
          
          setLoadingBlocks(true);
          try {
            const blocks = await calculateAvailableTimeBlocks(
              orgId,
              currentAppointment,
              jobs,
              activeTab === 1
            );
            
            // Filter blocks that have duration >= appointment duration
            const filteredBlocks = blocks.filter(block => {
              const blockDuration = moment.duration(
                moment(block.end).diff(moment(block.start))
              ).asMinutes();
              
              return blockDuration >= appointmentDuration;
            });
            
            setAvailableBlocks(filteredBlocks);
            
            // Show manual request option if no suitable blocks found
            const noBlocks = filteredBlocks.length === 0;
            setShowManualRequest(noBlocks);
            setManualRequestMode(noBlocks); // Also set manualRequestMode
          } catch (error) {
            console.error('Error loading available blocks:', error);
            setShowManualRequest(true);
            setManualRequestMode(true); // Also set manualRequestMode
          } finally {
            setLoadingBlocks(false);
          }
        };
      
        loadAvailableBlocks();
      }, [open, currentAppointment, orgId, jobs, activeTab, appointmentDuration]);


      // Add this useEffect to fetch cancel count
      useEffect(() => {
        const fetchCancelCount = async () => {
          if (!currentAppointment?.customerId || mode !== 'cancel') return;
          
          try {
            const jobsQuery = query(
              collection(db, 'organizations', orgId, 'jobs'),
              where('customerId', '==', currentAppointment.customerId),
              where('status', '==', 'Cancelled')
            );
            
            const querySnapshot = await getDocs(jobsQuery);
            setCancelCount(querySnapshot.size);
          } catch (error) {
            console.error('Error fetching cancellation count:', error);
          }
        };

        fetchCancelCount();
      }, [currentAppointment?.customerId, orgId, mode]);
  
    // Group time blocks by date
    const groupedTimeBlocks = availableBlocks.reduce((acc, block) => {
      const date = moment(block.start).format('YYYY-MM-DD');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(block);
      return acc;
    }, {});

    const handleReschedule = () => {
      if (mode === 'cancel') {
        const requestData = {
          type: 'cancel',
          notes: notes,
          preferredDate: null,
          preferredTimeRange: null,
          requestedDate: null,
          requestedEndTime: null,
          newCleanerId: null,
          newCleanerName: ''
        };
        onReschedule(requestData);
        return;
      }
  
      // For manual mode, check preferred date and time
      if (showManualRequest) {
        if (!preferredDate || !preferredTime) {
          return;
        }
    
        const requestData = {
          type: 'manual',
          preferredDate: moment(preferredDate).toDate(),
          preferredTimeRange: preferredTime,
          notes: notes,
          requestedDate: null,
          requestedEndTime: null,
          newCleanerId: null,
          newCleanerName: ''
        };
    
        onReschedule(requestData);
        return;
      }
    
      // For normal reschedule mode, check selected slot
      if (!selectedSlot) {
        return;
      }
    
      const requestData = {
        type: 'reschedule',
        requestedDate: selectedSlot.start,
        requestedEndTime: selectedSlot.end,
        newCleanerId: selectedSlot.cleanerId,
        newCleanerName: selectedSlot.cleanerName,
        notes: notes
      };
    
      onReschedule(requestData);
    };
  
    // Modify the stepper steps based on mode
    const steps = mode === 'cancel' 
      ? ['Add Reason']  
      : ['Select Time', 'Add Notes'];

    const handleNext = () => {
      setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevStep) => prevStep - 1);
    };

    const handleClose = () => {
      setActiveStep(0);
      setSelectedSlot(null);
      setNotes('');
      setPreferredDate('');
      setPreferredTime('');
      onClose();
    };

    const renderTimeSelection = () => (
      <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs 
            value={activeTab} 
            onChange={(e, newValue) => {
              setActiveTab(newValue);
              setSelectedSlot(null);
            }}
            variant="fullWidth"
          >
            <Tab label="Current Cleaner" />
            <Tab label="All Cleaners" />
          </Tabs>
        </Box>

        {showManualRequest ? (
          <Box sx={{ mt: 2 }}>
            <Alert severity="info" sx={{ mb: 3 }}>
              No available time slots match your appointment duration. You can still submit a request with your preferred date and time, and we'll work to accommodate your schedule.
            </Alert>
            
            <TextField
              fullWidth
              label="Preferred Date"
              type="date"
              value={preferredDate}
              onChange={(e) => setPreferredDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 2 }}
            />
            
            <TextField
              fullWidth
              label="Preferred Time"
              type="time"
              value={preferredTime}
              onChange={(e) => setPreferredTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 2 }}
            />
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            {loadingBlocks ? (
              <Typography align="center">Loading available time slots...</Typography>
            ) : Object.entries(groupedTimeBlocks)
              .sort(([dateA], [dateB]) => moment(dateA).diff(moment(dateB)))
              .map(([date, blocks]) => (
                <Box key={date} sx={{ mb: 3 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 1 }}>
                    {moment(date).format('dddd, MMMM D, YYYY')}
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    {blocks
                      .sort((a, b) => moment(a.start).diff(moment(b.start)))
                      .map((block) => (
                        <StyledCard
                          key={`${block.cleanerId}-${moment(block.start).format('HH:mm')}`}
                          selected={selectedSlot?.start === block.start}
                          onClick={() => setSelectedSlot(block)}
                        >
                          <CardContent>
                            <Typography variant="subtitle1">
                              {moment(block.start).format('h:mm A')} - {moment(block.end).format('h:mm A')}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Cleaner: {block.cleanerName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Available Duration: {moment.duration(moment(block.end).diff(moment(block.start))).asHours().toFixed(1)} hours
                            </Typography>
                          </CardContent>
                        </StyledCard>
                    ))}
                  </Box>
                </Box>
            ))}
          </Box>
        )}
      </>
    );

    const renderNotesStep = () => (
      <Box sx={{ mt: 2 }}>
        {selectedSlot ? (
          <>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Selected Time:
            </Typography>
            <StyledCard>
              <CardContent>
                <Typography variant="subtitle1">
                  {moment(selectedSlot.start).format('dddd, MMMM D, YYYY')}
                </Typography>
                <Typography variant="body1">
                  {moment(selectedSlot.start).format('h:mm A')} - {moment(selectedSlot.end).format('h:mm A')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Cleaner: {selectedSlot.cleanerName}
                </Typography>
              </CardContent>
            </StyledCard>
          </>
        ) : (
          <>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Requested Time:
            </Typography>
            <Typography variant="body1">
              Date: {moment(preferredDate).format('dddd, MMMM D, YYYY')}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Time: {preferredTime}
            </Typography>
          </>
        )}

        <TextField
          multiline
          rows={4}
          fullWidth
          label="Additional Notes"
          placeholder="Add any special instructions or notes for this reschedule request..."
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          sx={{ mt: 3 }}
        />
      </Box>
    );
  
    return (
      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6" component="div" sx={{ mb: 2 }}>
            {mode === 'cancel' ? 'Cancel Appointment' : 'Reschedule Appointment'}
          </Typography>
          {mode !== 'cancel' && (
            <Stepper activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
        </DialogTitle>
          
        <DialogContent>
          {mode === 'cancel' ? (
            <Box sx={{ mt: 2 }}>
              {clientPortalSettings?.cancellationMessage && (
                <Alert severity="warning" sx={{ mb: 3 }}>
                  <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {clientPortalSettings.cancellationMessage
                      .replace('{#ofCancelations}', cancelCount.toString())
                      .replace('{Fee Schedule}', 
                        clientPortalSettings.cancellationFeeSchedule
                          .map(tier => {
                            const feeText = tier.fee === 'fullService' ? 
                              `$${typeof currentAppointment?.serviceCost === 'number' 
                                ? currentAppointment.serviceCost.toFixed(2) 
                                : '0.00'}` :
                              tier.fee === 'doubleService' ? 
                              `$${typeof currentAppointment?.serviceCost === 'number' 
                                ? (currentAppointment.serviceCost * 2).toFixed(2) 
                                : '0.00'}` :
                              `$${tier.fee}`;
                            return `${tier.count} Cancellations - ${feeText}`;
                          })
                          .join('\n')
                      )
                      .replace('{Service Cost}', 
                        `$${typeof currentAppointment?.serviceCost === 'number' 
                          ? currentAppointment.serviceCost.toFixed(2) 
                          : '0.00'}`
                      )
                      .replace('{Double Service Cost}', 
                        `$${typeof currentAppointment?.serviceCost === 'number' 
                          ? (currentAppointment.serviceCost * 2).toFixed(2) 
                          : '0.00'}`
                      )
                    }
                  </Typography>
                </Alert>
              )}
              <Typography sx={{ mb: 2 }}>
                Please provide a reason for cancelling this appointment:
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                label="Cancellation Reason"
                placeholder="Enter your reason for cancellation (minimum 30 characters)..."
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                disabled={loading}
                error={notes.length > 0 && notes.length < MIN_REASON_LENGTH}
                helperText={`${notes.length} / ${MIN_REASON_LENGTH} characters minimum${
                  notes.length > 0 && notes.length < MIN_REASON_LENGTH 
                    ? ' - Please provide more detail' 
                    : ''
                }`}
              />
            </Box>
          ) : (
            activeStep === 0 ? renderTimeSelection() : renderNotesStep()
          )}
        </DialogContent>
  
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={handleClose}
            disabled={loading}
          >
            {mode === 'cancel' ? 'Back' : (activeStep === 0 ? 'Cancel' : 'Back')}
          </Button>
          <Button
            variant="contained"
            color={mode === 'cancel' ? 'error' : 'primary'}
            onClick={mode === 'cancel' ? handleReschedule : (activeStep === 0 ? handleNext : handleReschedule)}
            disabled={loading || 
              (mode === 'cancel' ? !notes.trim() :
                (activeStep === 0 && !showManualRequest && !selectedSlot) || 
                (showManualRequest && (!preferredDate || !preferredTime)))}
          >
            {loading ? 'Processing...' : 
             mode === 'cancel' ? 'Submit Cancellation' :
             (activeStep === 0 ? 'Next' : 'Submit Request')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default RescheduleModal;