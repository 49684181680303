import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { useJobContext } from '../context/JobContext';
import { useAuth } from '../context/AuthContext';
import MetricComparison from './MetricComparison';
import MobileHeader from './MobileHeader';
import styles from '../styles/CleanerDashboard.module.css';

const CleanerDashboard = () => {
  const { cleanerId } = useParams();
  const { user } = useAuth();
  const { getCleanerMetrics } = useJobContext();
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchMetrics = async () => {
      if (!user?.orgId || !cleanerId) {
        setLoading(false);
        setError('Missing organization or cleaner ID');
        return;
      }

      try {
        const cleanerMetrics = await getCleanerMetrics(user.orgId, cleanerId);
        setMetrics(cleanerMetrics);
      } catch (error) {
        setError('Failed to load metrics');
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [user?.orgId, cleanerId, getCleanerMetrics]);

  if (!isMobile) {
    return <Navigate to="/" />;
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className={styles.container}>
      <MobileHeader title="Dashboard" showBack={false} />
      <div className={styles.content}>
      <MetricComparison metrics={metrics} />
      </div>
    </div>
  );
};

export default CleanerDashboard;