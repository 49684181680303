import React from 'react';
import { 
  Star, 
  CheckCircle, 
  Users, 
  TrendingUp, 
  Clock, 
  AlertCircle, 
  XCircle, 
  Calendar,
  TrendingDown
} from 'lucide-react';
import styles from '../styles/MetricComparison.module.css';

const MetricCard = ({ title, metric, icon: Icon, format, isAbsence }) => {
  // If metric is a direct number (for backwards compatibility)
  const value = typeof metric === 'number' ? metric : (metric?.allTime ?? 0);
  const trend = typeof metric === 'number' ? undefined : (metric?.trend ?? 0);
  const showTrend = trend !== undefined && trend !== null;  // Add null check
  const isPositiveTrend = trend > 0;
  
  const formatValue = (val, fmt) => {
    // Ensure val is a number
    const numVal = Number(val);
    if (isNaN(numVal)) return '0';

    switch (fmt) {
      case 'percent':
        return `${numVal.toFixed(1)}%`;
      case 'rating':
        return `${numVal.toFixed(1)} ★`;
      case 'hours':
        return `${numVal}h`;
      default:
        return numVal.toString();
    }
  };

  const trendValue = isPositiveTrend ? `+${formatValue(trend, format)}` : formatValue(trend, format);
  const colorClass = isAbsence ? 
    (isPositiveTrend ? 'trend-negative' : 'trend-positive') :
    (isPositiveTrend ? 'trend-positive' : 'trend-negative');

  return (
    <div className={styles.metricCard}>
      <div className={styles.metricHeader}>
        {title}
      </div>
      <div className={styles.metricValue}>
        {formatValue(value, format)}
      </div>
      {showTrend && (
        <div className={styles.trendContainer}>
          <span className={`${styles.trendValue} ${styles[colorClass]}`}>
            {formatValue(trendValue, format)}
            {trend !== 0 && (
              isPositiveTrend ? 
                <TrendingUp className={styles.trendIcon} /> : 
                <TrendingDown className={styles.trendIcon} />
            )}
          </span>
        </div>
      )}
      <div className={styles.metricPeriod}>
        {isAbsence ? 'Last 12 months' : 'Compared to last month'}
      </div>
    </div>
  );
};

const MetricComparison = ({ metrics }) => {
  if (!metrics) return null;

  const metricConfigs = [
    {
        key: 'totalClients',
        title: 'Total Clients',
        format: 'number',
        icon: Users
      },
    {
        key: 'clientRating',
        title: 'Client Rating',
        format: 'rating',
        icon: Star
    },
    {
      key: 'totalCompletedJobs',
      title: 'Total Completed Jobs',
      format: 'number',
      icon: CheckCircle
    },
    {
      key: 'avgEfficiency',
      title: 'Efficiency',
      format: 'percent',
      icon: TrendingUp
    },
    {
      key: 'onTimeRate',
      title: 'On-Time Rate',
      format: 'percent',
      icon: Clock
    },
    {
      key: 'absences',
      title: 'Absences (12mo)',
      format: 'number',
      icon: XCircle,
      isAbsence: true
    },
    {
      key: 'lateCount',
      title: 'Late Arrivals',
      format: 'number',
      icon: AlertCircle
    },
    {
      key: 'projectedHours',
      title: "This Week's Hours",
      format: 'hours',
      icon: Calendar
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.metricsGrid}>
        {metricConfigs.map(({ key, title, icon, format, isAbsence }) => (
          <MetricCard
            key={key}
            title={title}
            metric={metrics[key]}
            icon={icon}
            format={format}
            isAbsence={isAbsence}
          />
        ))}
      </div>
      <div className={styles.timeManagementCard}>
        <div className={styles.cardHeader}>
          <h2 className={styles.cardTitle}>Time Management</h2>
        </div>
        <div className={styles.cardContent}>
          <div className={styles.timeManagement}>
            <p className={styles.timeLabel}>Average Job Duration</p>
            <p className={styles.timeValue}>{metrics.avgJobDuration} hrs</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetricComparison;