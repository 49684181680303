// src/components/MobileNavigation.js
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaTasks, FaUser, FaMoon, FaSun, FaSignOutAlt, FaArrowLeft } from 'react-icons/fa';
import { Calendar, Receipt } from 'lucide-react';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import styles from '../styles/MobileNavigation.module.css';
import CleanerProfileSelfManage from './CleanerProfileSelfManage';

const MobileNavigation = ({ isDarkMode, toggleDarkMode }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const isStandalonePage = location.pathname.includes('/org/') || 
                          location.pathname.includes('/cleaner-dashboard/');

  const handleSignOut = () => {
    localStorage.removeItem('authToken');
    sessionStorage.removeItem('authToken');
    navigate('/login');
    setMenuOpen(false);
  };

  const handleProfileClick = () => {
    setMenuOpen(false);
    setProfileOpen(true);
  };

  if (isStandalonePage && location.pathname.includes('/job/')) {
    return (
      <nav className={`${styles.mobileNav} ${styles.standaloneNav}`}>
        <button
          className={styles.navItem}
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft className={styles.icon} />
          <span className={styles.label}>Back</span>
        </button>
        <div className={styles.spacer} />
        <button
          className={styles.navItem}
          onClick={() => setMenuOpen(true)}
        >
          <FaUser className={styles.icon} />
          <span className={styles.label}>Menu</span>
        </button>

        <Dialog
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          className={styles.menuDialog}
        >
          <DialogTitle>Menu</DialogTitle>
          <DialogContent>
            <List>
              <ListItem button onClick={toggleDarkMode}>
                <ListItemIcon>
                  {isDarkMode ? <FaSun /> : <FaMoon />}
                </ListItemIcon>
                <ListItemText primary={`${isDarkMode ? 'Light' : 'Dark'} Mode`} />
              </ListItem>
              <ListItem button onClick={handleProfileClick}>
                  <ListItemIcon>
                    <FaUser />
                  </ListItemIcon>
                  <ListItemText primary="Update Profile" />
                </ListItem>
              <ListItem button onClick={handleSignOut}>
                <ListItemIcon>
                  <FaSignOutAlt />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>
      </nav>
    );
  }

  if (user?.role === 'cleaner') {
    return (
      <nav className={styles.mobileNav}>
        <Link
          to={`/cleaner-jobs/${user.uid}`}
          className={`${styles.navItem} ${location.pathname.includes('/cleaner-jobs') ? styles.active : ''}`}
        >
          <FaTasks className={styles.icon} />
          <span className={styles.label}>My Jobs</span>
        </Link>

        <Link
          to={`/cleaner-timeoff/${user.uid}`}
          className={`${styles.navItem} ${location.pathname.includes('/cleaner-timeoff') ? styles.active : ''}`}
        >
          <Calendar className={styles.icon} />
          <span className={styles.label}>Time Off</span>
        </Link>

        <Link
          to={`/cleaner-expenses/${user.uid}`}
          className={`${styles.navItem} ${location.pathname.includes('/cleaner-expenses') ? styles.active : ''}`}
        >
          <Receipt className={styles.icon} />
          <span className={styles.label}>Expenses</span>
        </Link>

        <button
          className={styles.navItem}
          onClick={() => setMenuOpen(true)}
        >
          <FaUser className={styles.icon} />
          <span className={styles.label}>Menu</span>
        </button>

        <Dialog
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          className={styles.menuDialog}
        >
            <DialogTitle>Menu</DialogTitle>
            <DialogContent>
              <List>
                <ListItem button onClick={handleProfileClick}>
                  <ListItemIcon>
                    <FaUser />
                  </ListItemIcon>
                  <ListItemText primary="Update Profile" />
                </ListItem>
                <ListItem button onClick={toggleDarkMode}>
                  <ListItemIcon>
                    {isDarkMode ? <FaSun /> : <FaMoon />}
                  </ListItemIcon>
                  <ListItemText primary={`${isDarkMode ? 'Light' : 'Dark'} Mode`} />
                </ListItem>
                <ListItem button onClick={handleSignOut}>
                  <ListItemIcon>
                    <FaSignOutAlt />
                  </ListItemIcon>
                  <ListItemText primary="Sign Out" />
                </ListItem>
              </List>
            </DialogContent>
          </Dialog>

          <CleanerProfileSelfManage 
            open={profileOpen} 
            onClose={() => setProfileOpen(false)} 
          />
        </nav>
    );
  }

  return null;
};

export default MobileNavigation;