// src/components/AvailabilityModal.js

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  Divider
} from '@mui/material';
import moment from 'moment';

// 1) Import your CreateJobModal
import CreateJobModal from './CreateJobModal';

const AvailabilityModal = ({ 
  open, 
  onClose, 
  availabilityData,
  onStatusChange,
  loading
}) => {

  // This state holds the user’s selected status from the radio buttons
  const [selectedStatus, setSelectedStatus] = useState('');

  // 2) State to manage opening/closing the CreateJobModal from within AvailabilityModal
  const [openCreateJobModal, setOpenCreateJobModal] = useState(false);

  const isTimeOffRequest = availabilityData?.status?.includes('time_off');
  const isApproved = availabilityData?.status === 'time_off_approved';
  const canRevoke = availabilityData?.timeOffData?.canRevoke;

  // Determine which radio options to show based on time-off or normal statuses
  const statusOptions = isTimeOffRequest ? (
    isApproved ? [
      { value: 'revoked', label: 'Revoke Time Off', color: '#F44336' }
    ] : [
      { value: 'approved', label: 'Approve Request', color: '#4CAF50' },
      { value: 'denied', label: 'Decline Request', color: '#F44336' }
    ]
  ) : [
    { value: 'offer_pending', label: 'Offer Pending', color: '#FF9800' },
    { value: 'time_off_requested', label: 'Time Off Requested', color: '#9C27B0' },
    { value: 'client_requested', label: 'Client Requested', color: '#2196F3' }
  ];

  const handleSubmit = () => {
    onStatusChange({
      ...availabilityData,
      status: selectedStatus,
      isTimeOffRequest: isTimeOffRequest
    });
  };

  // 3) If the AvailabilityModal is closed, don’t render anything
  if (!open) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {availabilityData?.status === 'time_off_approved' ? 'Approved Time Off' :
         availabilityData?.status === 'time_off_requested' ? 'Time Off Request' :
         'Update Availability Status'}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Cleaner: {availabilityData?.cleaner?.firstName} {availabilityData?.cleaner?.lastName}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Date: {availabilityData?.date?.format('dddd, MMMM D, YYYY')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Time: {availabilityData?.start?.format('h:mm A')} - {availabilityData?.end?.format('h:mm A')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Duration: {Math.round((availabilityData?.duration || 0) / 60 * 10) / 10} hours
          </Typography>
        </Box>

        {/* Show existing jobs for that day */}
        {availabilityData?.existingJobs?.length > 0 && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" color="error" gutterBottom>
              Scheduled Jobs on This Day:
            </Typography>
            <Box sx={{ ml: 2 }}>
              {availabilityData.existingJobs.map((job, index) => (
                <Box 
                  key={index} 
                  sx={{ 
                    mb: 2, 
                    p: 1.5, 
                    bgcolor: 'error.main', 
                    color: 'error.contrastText', 
                    borderRadius: 1 
                  }}
                >
                  <Typography variant="body2" fontWeight="bold">
                    {job.customerName}
                  </Typography>
                  <Typography variant="caption" display="block">
                    {moment(job.startTime.toDate()).format('h:mm A')} - {moment(job.endTime.toDate()).format('h:mm A')}
                  </Typography>
                  {job.address && (
                    <Typography variant="caption" display="block" sx={{ mt: 0.5 }}>
                      {job.address}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          </>
        )}

        <Divider sx={{ my: 2 }} />

        {/* If time-off is already approved, just show Revoke button. Otherwise show radio group. */}
        {availabilityData?.status === 'time_off_approved' ? (
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={() => onStatusChange({ ...availabilityData, status: 'revoked' })}
            disabled={loading}
          >
            Revoke Time Off
          </Button>
        ) : (
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {availabilityData?.status === 'time_off_requested' ? 'Response' : 'Select Status'}
            </FormLabel>
            <RadioGroup
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              {statusOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>{option.label}</Typography>
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          backgroundColor: option.color,
                          ml: 1
                        }}
                      />
                    </Box>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>

        {/* If not time_off_approved, show the status Update button */}
        {availabilityData?.status !== 'time_off_approved' && (
          <Button 
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={!selectedStatus || loading}
          >
            {loading ? 'Updating...' : 
             availabilityData?.status === 'time_off_requested' ? 'Submit Response' : 
             'Update Status'}
          </Button>
        )}

        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpenCreateJobModal(true)}
        >
          Create New Job
        </Button>
      </DialogActions>


      {openCreateJobModal && (
        <CreateJobModal
          open={openCreateJobModal}
          handleClose={() => setOpenCreateJobModal(false)}
        />
      )}
    </Dialog>
  );
};

export default AvailabilityModal;
