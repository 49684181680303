import styles from "../../../styles/JobOverview/ActionCenter/ActionCenter.module.css"

import { useEffect, useState } from "react";
import { FaCalendarTimes } from "react-icons/fa";

import AvailableSlotCard from "./AvailableSlotCard";
import TimeOffCard from "./TimeOffCard";
import useJobOverview from "../../../hooks/useJobOverview";

const ActionCenter = ({ timeOffRequests }) => {
  const { weekAvailabilityGaps } = useJobOverview();

  const [showTimeOffRequest, setShowTimeOffRequest] = useState(false);
  const [sortedAvailabilityStatuses, setSortedAvailabilityStatuses] = useState([]);

  const pendingTimeOffRequests = timeOffRequests
    .filter((timeOffRequest) => timeOffRequest.status === "pending");

  useEffect(() => {
    const fullAvailabilityStatuses = [];

    weekAvailabilityGaps.forEach(dayAvailabilityGaps => {
      dayAvailabilityGaps.forEach(data => {
        if (data.status === null) {
          fullAvailabilityStatuses.push(data)
        }
      })
    });

    fullAvailabilityStatuses.sort((a, b) => a.start.unix() - b.start.unix());

    setSortedAvailabilityStatuses(fullAvailabilityStatuses);
  }, [weekAvailabilityGaps])

  return (
    <div className={styles.actionCenter}>
      <div className={styles.actionCenterHeading}>
        Action Center

        <div
          className={`
            ${styles.headingActionButton}
            ${showTimeOffRequest
              ? styles.headingActionButtonSelected
              : ''
            }`
          }
          onClick={() => setShowTimeOffRequest(!showTimeOffRequest)}
        >
          <FaCalendarTimes style={{fontSize: "14px"}}/>

          <p className={styles.headingActionButtonLabel}>
            Time Off Requests
          </p>
        </div>
      </div>

      {
        showTimeOffRequest ? (
          <div className={styles.actionCenterList}>
            {pendingTimeOffRequests.map((data, index) => <TimeOffCard key={index} data={data} />)}
          </div>
        ) :
          <div className={styles.actionCenterList}>
            { sortedAvailabilityStatuses.map((data, index) => <AvailableSlotCard key={`available-${index}`} data={data} />) }
          </div>
      }
    </div>
  )
}

export default ActionCenter;