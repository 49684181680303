import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const AddCleanerModal = ({ open, handleClose, handleAddCleaner }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = async () => {
    const newCleaner = {
      firstName,
      lastName,
      email,
      phone,
      role: 'cleaner',
      isActive: true,
      createdAt: new Date(),
      updatedAt: new Date()
    };

    try {
      await handleAddCleaner(newCleaner);
      handleClose();
      // Reset form fields
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
    } catch (error) {
      console.error('Error adding cleaner:', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Cleaner</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="First Name"
          type="text"
          fullWidth
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <TextField
          margin="dense"
          label="Last Name"
          type="text"
          fullWidth
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <TextField
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          margin="dense"
          label="Phone Number"
          type="tel"
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          disabled={!firstName || !lastName || !email}
        >
          Add Cleaner
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCleanerModal;