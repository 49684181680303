// src/constants/statuses.js

import { JOB_STATUSES } from '../context/JobContext';

export const DEFAULT_JOB_STATUSES = Object.entries(JOB_STATUSES).map(([key, value]) => ({
  name: value,
  label: value,
  color: getDefaultStatusColor(value),
  isDefault: true,
  description: getDefaultStatusDescription(value)
}));

function getDefaultStatusDescription(status) {
  switch (status) {
    case JOB_STATUSES.PENDING:
      return 'Job has been created but work has not yet started';
    case JOB_STATUSES.IN_PROGRESS:
      return 'Work is currently being performed on this job';
    case JOB_STATUSES.COMPLETED:
      return 'All work has been finished and verified';
    case JOB_STATUSES.CANCELLED:
      return 'Job has been cancelled and will not be completed';
    default:
      return `Status: ${status}`;
  }
}

function getDefaultStatusColor(status) {
  switch (status) {
    case JOB_STATUSES.PENDING:
      return '#f59e0b';
    case JOB_STATUSES.IN_PROGRESS:
      return '#3b82f6';
    case JOB_STATUSES.COMPLETED:
      return '#10b981';
    case JOB_STATUSES.CANCELLED:
      return '#ef4444';
    default:
      return '#666666';
  }
}
  
  export const DEFAULT_RECURRING_STATUSES = [
    { name: 'Active', color: '#32CD32', description: 'Service is currently active' },
    { name: 'Paused', color: '#FFA500', description: 'Service is temporarily paused' },
    { name: 'Cancelled', color: '#FF0000', description: 'Service has been cancelled' }
  ];