import React, { createContext, useState, useMemo, useEffect } from 'react';
import moment from 'moment';

const JobOverviewContext = createContext();

export function JobOverviewProvider({
  children,
  jobs = [],
  timeOffRequests = [],
  cleaners = [],
  findAvailabilityGaps = (day, dayJobs) => {}
}) {
  const week = Array.from({ length: 6 }, (_, i) => i);
  const today = new Date().getDate();

  const [relativeWeek, setRelativeWeek] = useState(0);
  const [weekJobs, setWeekJobs] = useState([]);
  const [weekAvailabilityGaps, setWeekAvailabilityGaps] = useState([]);

  const currentDates = useMemo(() => {
    const today = new Date();
    const dayOfWeek = today.getUTCDay() || 7;
    const dayLimit = 6;
    const monday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

    monday.setUTCDate(today.getUTCDate() - (dayOfWeek - 2) + relativeWeek * 7);
      
    if (dayOfWeek === 7) {
      monday.setUTCDate(monday.getUTCDate() + 1);
    }
  
    return Array.from({ length: dayLimit }, (_, i) => {
      return new Date(Date.UTC(monday.getUTCFullYear(), monday.getUTCMonth(), monday.getUTCDate() + i));
    });
  }, [relativeWeek]);

  useEffect(() => {
    const getWeekSchedule = () => {
      const weekStart = moment(currentDates[0]);
      const weekEnd = moment(currentDates[currentDates.length - 1]);

      setWeekJobs(
        jobs.filter(job => {
          if (!job.appointmentDate || !moment.isMoment(job.appointmentDate)) {
            console.warn("Invalid or missing appointmentDate (not a Moment object):", job);
            return false;
          }
  
          const appointmentDate = job.appointmentDate;
          return appointmentDate.isSameOrAfter(weekStart) && appointmentDate.isSameOrBefore(weekEnd);
        })
      );
    };
  
    getWeekSchedule();
  }, [jobs, currentDates]);

  useEffect(() => {
    if (cleaners.length > 0) {
      const availabilityGaps = currentDates.map(date => {
        const dayJobs = weekJobs.filter(job => {
          if (!job.appointmentDate || !moment.isMoment(job.appointmentDate)) {
            console.warn("Invalid or missing appointmentDate (not a Moment object):", job);
            return false;
          }
  
          const todayMoment = moment(date);
          return job.appointmentDate.isSame(todayMoment, 'day');
        })

        return findAvailabilityGaps(moment(date), dayJobs)
      })

      setWeekAvailabilityGaps(availabilityGaps)
    }
  }, [
    cleaners,
    currentDates,
    findAvailabilityGaps,
    today,
    weekJobs
  ]);

  return (
    <JobOverviewContext.Provider
      value={{
        week,
        today,
        cleaners,
        currentDates,
        relativeWeek,
        setRelativeWeek,
        weekJobs,
        weekAvailabilityGaps,
      }}
    >
      {children}
    </JobOverviewContext.Provider>
  );
}

export default JobOverviewContext;