import styles from "../../../styles/JobOverview/ActionCenter/AvailableSlotCard.module.css"

import { useState } from "react";

const AvailableSlotCard = ({ data }) => {
  const [hovered, setHovered] = useState(false);

  const formatTimestampToDayTime = (timestamp) => {
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const date = new Date(timestamp * 1000);

    const day = days[date.getDay()];
    const dateOfMonth = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()];
    
    console.log(date.getFullYear())

    const hours24 = date.getHours();
    const hours12 = hours24 % 12 || 12;
    const amPm = hours24 >= 12 ? 'PM' : 'AM';
    const hours = String(hours12).padStart(2, '0');

    const minutes = String(date.getMinutes()).padStart(2, '0');

    return [`${month} ${dateOfMonth}, ${day}`, `${hours}:${minutes} ${amPm}`];
  };

  const getAvailabilityStatusDetails = (status) => {
    switch (status) {
      case 'offer_pending':
        return {
          color: '#FF9800',
          label: 'Offer Pending',
          bgColor: 'rgba(255, 152, 0, 0.1)',
          borderColor: 'rgba(255, 152, 0, 1)',
          hoverColor: 'rgba(255, 152, 0, 0.2)'
        };
      case 'client_requested':
        return {
          color: '#2196F3',
          label: 'Client Requested',
          bgColor: 'rgba(33, 150, 243, 0.1)',
          borderColor: 'rgba(33, 150, 243, 1)',
          hoverColor: 'rgba(33, 150, 243, 0.2)'
        };
      default:
        return {
          color: '#4CAF50',
          label: 'Available',
          bgColor: 'rgba(76, 175, 80, 0.1)',
          borderColor: 'rgba(76, 175, 80, 1)',
          hoverColor: 'rgba(76, 175, 80, 0.2)'
        };
    }
  };

  const startTime = formatTimestampToDayTime(data.start.unix());
  const endTime = formatTimestampToDayTime(data.end.unix());
  const statusDetails = getAvailabilityStatusDetails(data.status);

  return (
    <div
      className={styles.card}
      style={{
        backgroundColor: hovered ? statusDetails.hoverColor : "#1D2024",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <p className={styles.subHeading}> {startTime[0]} {startTime[0] !== endTime[0] && (<span>- {endTime[0]}</span>)} </p>
      <p className={styles.subHeading}> {startTime[1]} - {endTime[1]} </p>
      <p className={styles.subHeading}> {data.cleaner.firstName} {data.cleaner.lastName} </p>
      <p
        className={styles.label}
        style={{
          backgroundColor: statusDetails.bgColor
        }}
      >
        Available gap
      </p>
    </div>
  )
}

export default AvailableSlotCard