// src/components/EditRecurringService.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Alert,
  Checkbox,
  InputAdornment,
} from '@mui/material';
import { collection, doc, updateDoc, getDocs, query, where, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import RecurrenceSelect from './RecurrenceSelect';
import { rrulestr } from 'rrule';
import AddRoomsModal from './AddRoomsModal';
import styles from '../styles/EditRecurringService.module.css';
import { Autocomplete } from '@react-google-maps/api';
import { useTimezone } from '../context/TimeZoneContext';
import SelectCleanersModal from './SelectCleanersModal';

const libraries = ['places'];

const EditRecurringService = ({ customer, jobs, orgId, fetchJobs, recurrenceGroupId, service }) => {

  const { timezone, convertFromOrgTz, moment } = useTimezone();

  const [openSelectCleanersModal, setOpenSelectCleanersModal] = useState(false);
  const [selectedCleaners, setSelectedCleaners] = useState(() => {
    if (Array.isArray(jobs) && jobs.length > 0) {
      const firstJob = jobs[0];
  
      if (firstJob.cleanerDetails && Array.isArray(firstJob.cleanerDetails)) {
        // Already have cleaner objects in the job itself
        return firstJob.cleanerDetails;
      }
  
      if (firstJob.cleaners && Array.isArray(firstJob.cleaners)) {
        // firstJob.cleaners might be an array of cleaner IDs, map them to cleaner objects
        return firstJob.cleaners
          .map(cleanerId => {
            const found = cleaners.find(c => c.id === cleanerId);
            return found ? found : null;
          })
          .filter(Boolean);
      }
  
      // If there's a single cleanerId field
      if (firstJob.cleanerId) {
        const found = cleaners.find(c => c.id === firstJob.cleanerId);
        return found ? [found] : [];
      }
    }
    return [];
  });

  const getInitialServiceCost = () => {
    try {
      const cost = service?.serviceCost 
        || service?.price 
        || (Array.isArray(jobs) && jobs.length > 0 ? (jobs[0].serviceCost || jobs[0].price) : 0);
      if (typeof cost === 'number') return cost;
      if (typeof cost === 'string') {
        const cleanedCost = cost.replace(/[$,]/g, '');
        const numericCost = parseFloat(cleanedCost);
        return isNaN(numericCost) ? 0 : numericCost;
      }
      return 0;
    } catch (error) {
      console.error('Error parsing service cost:', error);
      return 0;
    }
  };

  // State initializations with proper default values
  const [isBillingSameAsService, setIsBillingSameAsService] = useState(
    Boolean(customer?.billingAddress === customer?.serviceAddress)
  );

  const [customerData, setCustomerData] = useState({
    phone: customer?.phone || '',
    email: customer?.email || '',
    serviceAddress: customer?.serviceAddress || '',
    billingAddress: customer?.billingAddress || customer?.serviceAddress || '',
  });

  const [serviceSearchBox, setServiceSearchBox] = useState(null);
  const [billingSearchBox, setBillingSearchBox] = useState(null);

  const [serviceData, setServiceData] = useState({
    serviceType: Array.isArray(jobs) && jobs.length > 0
      ? (jobs[0].serviceType || 'recurring').toLowerCase()
      : 'recurring',
    price: getInitialServiceCost(),
  });

  const [scheduleData, setScheduleData] = useState(() => {
    let startTime = '';
    let endTime = '';
  
    if (Array.isArray(jobs) && jobs.length > 0) {
      const firstJob = jobs[0];
      
      const appointmentDate = firstJob.appointmentDate?.toDate?.();
      const scheduledEndTime = firstJob.scheduledEndTime?.toDate?.();
  
      if (appointmentDate) {
        // Convert UTC to local time and subtract an hour
        startTime = moment.utc(appointmentDate).subtract(1, 'hour').local().format('HH:mm');
  
        if (scheduledEndTime) {
          endTime = moment.utc(scheduledEndTime).subtract(1, 'hour').local().format('HH:mm');
        } else {
          endTime = moment.utc(appointmentDate).subtract(1, 'hour').local().add(2, 'hours').format('HH:mm');
        }
      }
    } else if (service) {
      const serviceStartDate = service.appointmentDate?.toDate?.();
      const serviceEndDate = service.scheduledEndTime?.toDate?.();
  
      if (serviceStartDate) {
        startTime = moment.utc(serviceStartDate).subtract(1, 'hour').local().format('HH:mm');
  
        if (serviceEndDate) {
          endTime = moment.utc(serviceEndDate).subtract(1, 'hour').local().format('HH:mm');
        } else {
          endTime = moment.utc(serviceStartDate).subtract(1, 'hour').local().add(2, 'hours').format('HH:mm');
        }
      }
    }
  
    return {
      startTime,
      endTime,
      recurrenceRule: Array.isArray(jobs) && jobs.length > 0
        ? (jobs[0].recurrenceRule || '')
        : (service?.recurrenceRule || '')
    };
  });

  const [effectiveDate, setEffectiveDate] = useState('');

  
  const [availableDates, setAvailableDates] = useState([]);

  const [dateSelectionType, setDateSelectionType] = useState('existing'); // 'existing' or 'new'
  const [newStartDate, setNewStartDate] = useState('');

  const [cleanerId, setCleanerId] = useState(
    Array.isArray(jobs) && jobs.length > 0 ? (jobs[0].cleanerId || '') : ''
  );
  
  const [rooms, setRooms] = useState(
    Array.isArray(jobs) && jobs.length > 0 ? (jobs[0].rooms || []) : []
  );
  
  const [notes, setNotes] = useState(
    Array.isArray(jobs) && jobs.length > 0 ? (jobs[0].notes || '') : ''
  );
  
  const [importantNotes, setImportantNotes] = useState(
    Array.isArray(jobs) && jobs.length > 0 ? (jobs[0].importantNotes || '') : ''
  );
  
  const [totalSize, setTotalSize] = useState(
    Array.isArray(jobs) && jobs.length > 0 
      ? (jobs[0].totalSize || (service?.totalSize || 0)) 
      : (service?.totalSize || 0)
  );
  
  const [cleaners, setCleaners] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  // Add jobType state
  const [jobType, setJobType] = useState(
    Array.isArray(jobs) && jobs.length > 0 
      ? (jobs[0].jobType || 'residential') 
      : (service?.jobType || 'residential')
  );

  // Google Places setup
  const onServicePlacesLoad = (searchBoxInstance) => {
    setServiceSearchBox(searchBoxInstance);
  };

  const onServicePlaceChanged = () => {
    if (serviceSearchBox) {
      const place = serviceSearchBox.getPlace();
      if (place?.formatted_address) {
        setCustomerData((prev) => ({
          ...prev,
          serviceAddress: place.formatted_address,
          billingAddress: isBillingSameAsService ? place.formatted_address : prev.billingAddress,
        }));
      }
    }
  };

  const onBillingPlacesLoad = (searchBoxInstance) => {
    setBillingSearchBox(searchBoxInstance);
  };

  const onBillingPlaceChanged = () => {
    if (billingSearchBox) {
      const place = billingSearchBox.getPlace();
      if (place?.formatted_address) {
        setCustomerData((prev) => ({
          ...prev,
          billingAddress: place.formatted_address,
        }));
      }
    }
  };

// Fetch available dates
useEffect(() => {
  if (Array.isArray(jobs) && jobs.length > 0) {
    const dates = jobs
      .filter(job => !job.status || job.status.toLowerCase() === 'pending')
      .map(job => {
        const date = moment(job.appointmentDate.toDate());
        return {
          date: date.toDate(),
          formatted: date.format('YYYY-MM-DD')
        };
      })
      .sort((a, b) => moment(a.date).diff(moment(b.date)));
    
    setAvailableDates(dates);
    
    // Set effectiveDate to first available date if not already set to a valid date
    if (dates.length > 0 && (!effectiveDate || !dates.some(d => d.formatted === effectiveDate))) {
      setEffectiveDate(dates[0].formatted);
    } else if (dates.length === 0) {
      setEffectiveDate('');
    }
  } else {
    setAvailableDates([]);
    setEffectiveDate('');
  }
}, [jobs, effectiveDate]);


  // Fetch cleaners and available rooms
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch cleaners
        const usersRef = collection(db, 'organizations', orgId, 'users');
        const q = query(usersRef, where('orgId', '==', orgId), where('role', '==', 'cleaner'));
        const querySnapshot = await getDocs(q);
        const cleanersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCleaners(cleanersList);

        // Fetch available rooms
        const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
        const roomTypesSnapshot = await getDocs(roomTypesRef);
        const roomTypesList = roomTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAvailableRooms(roomTypesList);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (orgId) {
      fetchData();
    }
  }, [orgId]);

  // Populate scheduleData with start and end times
  useEffect(() => {
    if (jobs && jobs.length > 0 && jobs[0]) {
      const appointmentDate = jobs[0].appointmentDate?.toDate?.();
      const scheduledEndTime = jobs[0].scheduledEndTime?.toDate?.();
      
      if (appointmentDate) {
        setScheduleData(prev => ({
          ...prev,
          startTime: moment(appointmentDate).format('HH:mm'),
          endTime: scheduledEndTime ? moment(scheduledEndTime).format('HH:mm') : 
                  moment(appointmentDate).add(2, 'hours').format('HH:mm'),
        }));
      }
    }
  }, [jobs]);

  // Initialize cleanerId after cleaners are fetched
  useEffect(() => {
    if (cleaners.length > 0 && !cleanerId && Array.isArray(jobs) && jobs.length > 0 && jobs[0]?.cleanerId) {
      setCleanerId(jobs[0].cleanerId);
    }
  }, [cleaners, cleanerId, jobs]);

  const handleAddRooms = (roomsToAdd) => {
    setRooms(prevRooms => {
      const updatedRooms = [...prevRooms];
      roomsToAdd.forEach(newRoom => {
        const formattedTasks = (newRoom.tasks || []).map(task => ({
          description: typeof task === 'string' ? task : task.description || '',
          completed: false,
        }));

        const roomToAdd = {
          ...newRoom,
          tasks: formattedTasks,
          quantity: newRoom.quantity || 1,
        };

        const existingIndex = updatedRooms.findIndex(room => room.name === newRoom.name);
        if (existingIndex !== -1) {
          updatedRooms[existingIndex] = {
            ...updatedRooms[existingIndex],
            ...roomToAdd,
            tasks: roomToAdd.tasks.length > 0 ? roomToAdd.tasks : updatedRooms[existingIndex].tasks,
            quantity: roomToAdd.quantity || updatedRooms[existingIndex].quantity || 1,
          };
        } else {
          updatedRooms.push(roomToAdd);
        }
      });

      return updatedRooms;
    });
  };

  const handleRemoveRoom = (index) => {
    setRooms(prevRooms => prevRooms.filter((_, i) => i !== index));
  };

  const createJobData = (appointmentDate, scheduledEndTime, isRecurring = false, recurrenceRule = null) => {
    // Ensure we have valid Date objects
    const validAppointmentDate = appointmentDate instanceof Date ? appointmentDate : new Date(appointmentDate);
    const validScheduledEndTime = scheduledEndTime instanceof Date ? scheduledEndTime : new Date(scheduledEndTime);
    
    // Get primary cleaner with fallback values
    const primaryCleaner = selectedCleaners[0] || {};
    const cleanerEmail = primaryCleaner.email || '';
    const cleanerName = primaryCleaner.firstName && primaryCleaner.lastName 
      ? `${primaryCleaner.firstName} ${primaryCleaner.lastName}`
      : primaryCleaner.email || '';
    
    const jobData = {
      // Customer Info
      customerId: customer.id,
      customerName: customer.name,
      serviceAddress: customerData.serviceAddress,
      billingAddress: customerData.billingAddress || customerData.serviceAddress,
      address: customerData.serviceAddress,
      formattedAddress: customerData.serviceAddress,

      // Location data (if available from original job)
      ...(service?.latitude && { latitude: service.latitude }),
      ...(service?.longitude && { longitude: service.longitude }),

      // Rooms & Notes
      rooms: rooms.map(room => ({
        ...room,
        tasks: (room.tasks || []).map(task => ({
          description: typeof task === 'string' ? task : task.description,
          completed: false
        }))
      })),
      notes: notes || '',
      importantNotes: importantNotes || '',

      // Cleaners - ensure all cleaner fields have fallback values
      cleaners: selectedCleaners.map(c => ({
        id: c.id || '',
        name: (c.firstName && c.lastName) ? `${c.firstName} ${c.lastName}` : (c.email || ''),
        email: c.email || '',
      })),
      cleanerDetails: selectedCleaners.map(c => ({
        id: c.id || '',
        name: (c.firstName && c.lastName) ? `${c.firstName} ${c.lastName}` : (c.email || ''),
        email: c.email || '',
      })),
      
      // Legacy cleaner fields with fallbacks
      cleanerId: primaryCleaner.id || '',
      cleanerEmail: cleanerEmail,
      cleanerName: cleanerName,

      // Service Info
      serviceType: serviceData.serviceType || 'recurring',
      recurring: isRecurring,
      recurrenceRule: isRecurring ? recurrenceRule : null,
      recurrenceGroupId,
      serviceCost: parseFloat(serviceData.price) || 0,
      price: parseFloat(serviceData.price) || 0,
      totalSize: parseFloat(totalSize) || 0,
      jobType: jobType || 'residential',

      // Timestamps
      appointmentDate: validAppointmentDate,
      scheduledEndTime: validScheduledEndTime,
      scheduledDuration: validScheduledEndTime.getTime() - validAppointmentDate.getTime(),
      createdAt: service?.createdAt || new Date(),
      updatedAt: new Date(),
      lastUpdated: new Date(),

      // Status
      status: 'Pending',
      recurringStatus: isRecurring ? 'Active' : null,
      ...(isRecurring && { recurringStartDate: service?.recurringStartDate || appointmentDate }),

      // Organization & Timezone
      organizationId: orgId,
      timezone,
    };

    return jobData;
  };
  
  // Modify generateRecurringJobs to accept a start date
  const generateRecurringJobs = (startDate) => {
    if (!scheduleData.recurrenceRule) return [];
  
    try {
      const jobsData = [];
      // Convert the start date to UTC
      const dtstart = convertFromOrgTz(startDate).startOf('day').toDate();
  
      // Format date string for RRULE
      const dtstartStr = moment(dtstart).format('YYYYMMDDTHHmmss') + 'Z';
      
      const rruleString = scheduleData.recurrenceRule.startsWith('RRULE:') 
        ? `DTSTART:${dtstartStr}\n${scheduleData.recurrenceRule}`
        : `DTSTART:${dtstartStr}\nRRULE:${scheduleData.recurrenceRule}`;
  
      const rule = rrulestr(rruleString);
      
      const endDate = moment(startDate).add(6, 'months').toDate();
      const dates = rule.between(dtstart, endDate, true);
      
      if (!dates.length || moment(dates[0]).format('YYYY-MM-DD') !== moment(startDate).format('YYYY-MM-DD')) {
        const startDateTime = convertFromOrgTz(moment(startDate)
          .set({
            hour: parseInt(scheduleData.startTime.split(':')[0]),
            minute: parseInt(scheduleData.startTime.split(':')[1]),
            second: 0,
            millisecond: 0
          })).toDate();
        
        dates.unshift(startDateTime);
      }
  
      dates.forEach(date => {
        const [startHours, startMinutes] = scheduleData.startTime.split(':').map(Number);
        const [endHours, endMinutes] = scheduleData.endTime.split(':').map(Number);
        
        // Convert times to UTC
        const startTime = convertFromOrgTz(moment(date).set({ 
          hour: startHours, 
          minute: startMinutes,
          second: 0,
          millisecond: 0 
        }));
        
        const endTime = convertFromOrgTz(moment(date).set({ 
          hour: endHours, 
          minute: endMinutes,
          second: 0,
          millisecond: 0 
        }));
  
        jobsData.push(
          createJobData(
            startTime.toDate(),
            endTime.toDate(),
            true,
            scheduleData.recurrenceRule
          )
        );
      });
  
      return jobsData.sort((a, b) => moment(a.appointmentDate).diff(moment(b.appointmentDate)));
    } catch (error) {
      console.error('Error in generateRecurringJobs:', error);
      throw new Error(`Invalid recurrence pattern: ${error.message}`);
    }
  };

const handleSaveChanges = async () => {
  try {
    // Validation checks (keep existing)
    if (!customerData.serviceAddress) {
      throw new Error('Service address is required');
    }

    if (!scheduleData.startTime || !scheduleData.endTime) {
      throw new Error('Start and end times are required');
    }

    if (dateSelectionType === 'existing' && !effectiveDate) {
      throw new Error('Please select an effective date for the changes');
    }

    if (dateSelectionType === 'new' && !newStartDate) {
      throw new Error('Please select a new start date for the service');
    }

    const actualEffectiveDate = dateSelectionType === 'new' 
    ? convertFromOrgTz(newStartDate).format('YYYY-MM-DD')
    : effectiveDate;

    // Update customer data (keep existing)
    const customerRef = doc(db, 'organizations', orgId, 'customers', customer.id);
    await updateDoc(customerRef, {
      phone: customerData.phone || '',
      email: customerData.email || '',
      serviceAddress: customerData.serviceAddress,
      billingAddress: customerData.billingAddress || customerData.serviceAddress,
      updatedAt: new Date(),
    });

    // Start batch write
    const batch = writeBatch(db);
    const jobsRef = collection(db, 'organizations', orgId, 'jobs');
    const recurringServiceRef = doc(db, 'organizations', orgId, 'recurringServices', recurrenceGroupId);

    // Query existing jobs
    const q = query(jobsRef, where('recurrenceGroupId', '==', recurrenceGroupId));
    const querySnapshot = await getDocs(q);

    // Handle pattern changes or new start date
    const originalServiceType = Array.isArray(jobs) && jobs.length > 0 
    ? (jobs[0].serviceType || 'recurring') 
    : 'recurring';
    
    const originalRecurrenceRule = Array.isArray(jobs) && jobs.length > 0 
      ? (jobs[0].recurrenceRule || '') 
      : '';
    
    if (
      serviceData.serviceType !== originalServiceType ||
      scheduleData.recurrenceRule !== originalRecurrenceRule ||
      dateSelectionType === 'new'
    ) {

      const confirmMessage = dateSelectionType === 'new'
        ? 'This will cancel all future appointments and create new ones with the selected pattern.'
        : 'You have changed the service type or recurrence pattern. This will regenerate future appointments.';

      if (!window.confirm(confirmMessage)) return;

      // Delete future jobs
      querySnapshot.docs.forEach(docSnap => {
        const jobData = docSnap.data();
        const jobDate = jobData.appointmentDate?.toDate?.() || new Date(jobData.appointmentDate);

        if (dateSelectionType === 'new') {
          // Delete all future jobs when starting new pattern
          if (jobDate > new Date()) {
            batch.delete(docSnap.ref);
          }
        } else {
          // Delete jobs from effective date
          if (jobDate >= new Date(actualEffectiveDate)) {
            batch.delete(docSnap.ref);
          }
        }
      });

      if (serviceData.serviceType === 'recurring') {
        // Generate new jobs
        const newJobs = generateRecurringJobs(actualEffectiveDate);

        // Update recurring service first
        const recurringServiceData = createJobData(null, null, true, scheduleData.recurrenceRule);
        batch.update(recurringServiceRef, recurringServiceData);

        // Create new job instances
        newJobs.forEach(date => {
          const [startHours, startMinutes] = scheduleData.startTime.split(':').map(Number);
          const [endHours, endMinutes] = scheduleData.endTime.split(':').map(Number);
          
          const startTime = moment(date).set({
            hour: startHours,
            minute: startMinutes,
            second: 0,
            millisecond: 0
          });

          const endTime = moment(date).set({
            hour: endHours,
            minute: endMinutes,
            second: 0,
            millisecond: 0
          });

          const jobRef = doc(jobsRef);
          const jobData = createJobData(startTime.toDate(), endTime.toDate(), true, scheduleData.recurrenceRule);
          batch.set(jobRef, jobData);
        });
      }
    } else {
      // Handle updates without pattern changes
      querySnapshot.docs.forEach(docSnap => {
        const jobData = docSnap.data();
        const jobDate = jobData.appointmentDate?.toDate?.() || new Date(jobData.appointmentDate);

        if (jobDate >= new Date(actualEffectiveDate) && 
            (!jobData.status || jobData.status.toLowerCase() === 'pending')) {
          
          const [startHours, startMinutes] = scheduleData.startTime.split(':').map(Number);
          const [endHours, endMinutes] = scheduleData.endTime.split(':').map(Number);
          
          // Convert times to UTC
          const startDate = convertFromOrgTz(moment(jobDate).set({
            hour: startHours,
            minute: startMinutes,
            second: 0,
            millisecond: 0
          }));

          const endDate = convertFromOrgTz(moment(jobDate).set({
            hour: endHours,
            minute: endMinutes,
            second: 0,
            millisecond: 0
          }));

          const updatedJobData = createJobData(
            startDate.toDate(), 
            endDate.toDate(), 
            true, 
            scheduleData.recurrenceRule
          );
          
          batch.update(docSnap.ref, updatedJobData);
        }
      });

      // Update recurring service record
      const recurringServiceData = createJobData(null, null, true, scheduleData.recurrenceRule);
      batch.update(recurringServiceRef, recurringServiceData);
    }

    // Commit all changes
    await batch.commit();
    
    await fetchJobs();
    setSuccess('Service updated successfully!');
    
    if (dateSelectionType === 'new') {
      setDateSelectionType('existing');
      setNewStartDate('');
    }

  } catch (error) {
    console.error('Detailed error in handleSaveChanges:', error);
    setError(`Failed to update service: ${error.message}`);
  }
};

  return (
    <Box className={styles.container}>
        {error && (
          <Alert severity="error" onClose={() => setError('')} sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
      {success && (
        <Alert severity="success" onClose={() => setSuccess('')} sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}

      {/* Customer Information Section */}
      <Box className={styles.section}>
        <Typography variant="h6">Customer Information</Typography>
        <TextField
          label="Phone Number"
          value={customerData.phone}
          onChange={(e) => setCustomerData({ ...customerData, phone: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          value={customerData.email}
          onChange={(e) => setCustomerData({ ...customerData, email: e.target.value })}
          fullWidth
          margin="normal"
        />
        
        {/* Service Address with Autocomplete */}
        <Autocomplete
          onLoad={onServicePlacesLoad}
          onPlaceChanged={onServicePlaceChanged}
        >
          <TextField
            label="Service Address"
            value={customerData.serviceAddress}
            onChange={(e) => {
              const newAddress = e.target.value;
              setCustomerData((prev) => ({
                ...prev,
                serviceAddress: newAddress,
                billingAddress: isBillingSameAsService ? newAddress : prev.billingAddress,
              }));
            }}
            fullWidth
            margin="normal"
            required
          />
        </Autocomplete>

        {/* Checkbox for Billing Address */}
        <FormControlLabel
          control={
            <Checkbox
              checked={isBillingSameAsService}
              onChange={(e) => {
                setIsBillingSameAsService(e.target.checked);
                if (e.target.checked) {
                  setCustomerData((prev) => ({
                    ...prev,
                    billingAddress: prev.serviceAddress,
                  }));
                }
              }}
            />
          }
          label="Billing address is the same as service address"
        />

        {/* Billing Address with Autocomplete */}
        {!isBillingSameAsService && (
          <Autocomplete
            onLoad={onBillingPlacesLoad}
            onPlaceChanged={onBillingPlaceChanged}
          >
            <TextField
              label="Billing Address"
              value={customerData.billingAddress}
              onChange={(e) =>
                setCustomerData((prev) => ({
                  ...prev,
                  billingAddress: e.target.value,
                }))
              }
              fullWidth
              margin="normal"
              required
            />
          </Autocomplete>
        )}
      </Box>

      {/* Add Job Type Selection */}
      <Box className={styles.section}>
        <Typography variant="h6">Job Type</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Job Type</InputLabel>
          <Select
            value={jobType}
            onChange={(e) => setJobType(e.target.value)}
            label="Job Type"
          >
            <MenuItem value="residential">Residential</MenuItem>
            <MenuItem value="commercial">Commercial</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Service Information Section */}
      <Box className={styles.section}>
        <Typography variant="h6">Service Information</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Service Type</InputLabel>
          <Select
            value={serviceData.serviceType}
            onChange={(e) => setServiceData({
              ...serviceData,
              serviceType: e.target.value.toLowerCase()
            })}
            label="Service Type"
          >
            <MenuItem value="one-time">One-Time</MenuItem>
            <MenuItem value="recurring">Recurring</MenuItem>
          </Select>
        </FormControl>

        {serviceData.serviceType === 'recurring' && (
          <>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>Recurrence Pattern</Typography>
            <RecurrenceSelect
              value={scheduleData.recurrenceRule}
              onChange={(value) => setScheduleData({ ...scheduleData, recurrenceRule: value })}
            />
          </>
        )}

          <TextField
            label="Service Cost"
            type="number"
            value={serviceData.price}
            onChange={(e) => {
              const value = e.target.value;
              if (value === '' || /^\d*\.?\d*$/.test(value)) {
                setServiceData({
                  ...serviceData,
                  price: value
                });
              }
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: { min: 0, step: '0.01' },
            }}
            fullWidth
            margin="normal"
          />
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Schedule Information Section */}

      {/* Pre-Populate Dates Scheduled to make changes effective from */}
      <Box className={styles.section}>
      <Typography variant="h6">Update Settings</Typography>
      
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          How would you like to apply these changes?
        </Typography>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={dateSelectionType === 'existing'}
                onChange={(e) => setDateSelectionType('existing')}
                value="existing"
              />
            }
            label="Apply to existing appointments"
          />
          <FormControlLabel
            control={
              <Radio
                checked={dateSelectionType === 'new'}
                onChange={(e) => setDateSelectionType('new')}
                value="new"
              />
            }
            label="Start a new pattern"
          />
        </div>
      </FormControl>

      {dateSelectionType === 'existing' ? (
        <FormControl fullWidth margin="normal">
          <InputLabel>Changes Effective From</InputLabel>
          <Select
            value={effectiveDate || ''}
            onChange={(e) => setEffectiveDate(e.target.value)}
            label="Changes Effective From"
          >
            <MenuItem value="">
              <em>Select a date</em>
            </MenuItem>
            {availableDates.map(({ formatted, date }) => (
              <MenuItem key={formatted} value={formatted}>
                {moment(date).format('MMM D, YYYY')} - {moment(date).format('dddd')}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
            Changes will apply to this and all future appointments
          </Typography>
        </FormControl>
      ) : (
        <FormControl fullWidth margin="normal">
          <TextField
            label="New Start Date"
            type="date"
            value={newStartDate}
            onChange={(e) => setNewStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: moment().format('YYYY-MM-DD'), // Can't select past dates
            }}
          />
          <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
            This will cancel all existing future appointments and create new ones starting from this date
          </Typography>
        </FormControl>
      )}
      
      {/* Add a warning message if changing recurrence pattern */}
      {serviceData.serviceType === 'recurring' 
        && (Array.isArray(jobs) && jobs.length > 0) 
        && scheduleData.recurrenceRule !== (jobs[0].recurrenceRule || '')
        && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            You are changing the recurrence pattern...
          </Alert>
        )}
    </Box>
      
      {/* Start Time and End Times */}
      <Box className={styles.section}>
        <Typography variant="h6">Schedule Information</Typography>
        <TextField
            label="Start Time"
            type="time"
            value={scheduleData.startTime}
            onChange={(e) => setScheduleData({ ...scheduleData, startTime: e.target.value })}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              step: 300, // 5 min intervals
            }}
            required
          />

          <TextField
            label="End Time"
            type="time"
            value={scheduleData.endTime}
            onChange={(e) => setScheduleData({ ...scheduleData, endTime: e.target.value })}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              step: 300, // 5 min intervals
            }}
            required
          />
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Cleaner Assignment Section */}
      <Box className={styles.section}>
        <Typography variant="h6">Cleaner Assignment</Typography>

      {/* Display selected cleaners */}
      <Box sx={{ mb: 2 }}>
      {selectedCleaners.map((cleaner) => (
        <div key={cleaner.id} className={styles.cleanerItem}>
          <span>
            {cleaner.name
              ? cleaner.name
              : 'No name available'}
          </span>
          <button
            type="button"
            onClick={() =>
              setSelectedCleaners((prev) => prev.filter((c) => c.id !== cleaner.id))
            }
            className={styles.removeButton}
          >
            Remove
          </button>
        </div>
      ))}
      </Box>

        {/* Button or link that triggers your SelectCleanersModal */}
        <Button
          variant="outlined"
          onClick={() => setOpenSelectCleanersModal(true)}
          className={styles.button}
        >
          {selectedCleaners.length > 0 ? 'Modify Cleaners' : 'Select Cleaners'}
        </Button>

        {/* Add the SelectCleanersModal */}
        <SelectCleanersModal
          open={openSelectCleanersModal}
          handleClose={() => setOpenSelectCleanersModal(false)}
          cleaners={cleaners}
          selectedCleaners={selectedCleaners}
          setSelectedCleaners={setSelectedCleaners}
        />
      </Box>

      <Divider sx={{ my: 2 }} />

      <TextField
        label="Total Size (SqFt)"
        type="number"
        value={totalSize}
        onChange={(e) => setTotalSize(Math.max(0, parseFloat(e.target.value) || 0))}
        InputProps={{
          inputProps: { min: 0, step: 1 }
        }}
        fullWidth
        margin="normal"
      />

      {/* Rooms Section */}
      <Box className={styles.section}>
        <Typography variant="h6">Rooms</Typography>
        {rooms.length > 0 ? (
          rooms.map((room, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Typography variant="body1">{room.name}</Typography>
              <Button size="small" onClick={() => handleRemoveRoom(index)} sx={{ ml: 2 }}>
                Remove
              </Button>
            </Box>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">No rooms added.</Typography>
        )}
        <Button variant="outlined" onClick={() => setOpenAddRoomsModal(true)} sx={{ mt: 2 }}>
          Add Rooms
        </Button>
      </Box>

      {/* Add Rooms Modal */}
      <AddRoomsModal
        open={openAddRoomsModal}
        handleClose={() => setOpenAddRoomsModal(false)}
        availableRooms={availableRooms}
        handleAddRooms={handleAddRooms}
        existingRooms={rooms}
        orgId={orgId}
      />

      <Divider sx={{ my: 2 }} />

      {/* Notes Section */}
      <Box className={styles.section}>
        <Typography variant="h6">Notes</Typography>
        <TextField
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={3}
        />
        <TextField
          label="Important Notes"
          value={importantNotes}
          onChange={(e) => setImportantNotes(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={3}
        />
      </Box>

      {/* Save Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveChanges}
        sx={{ mt: 2 }}
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default EditRecurringService;

