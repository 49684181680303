// src/components/EmailTemplates.js

import React, { useState } from 'react';
import {
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import EmailTemplateModal from './EmailTemplateModal';
import styles from '../styles/OrgSettings.module.css';

export const TRIGGER_TYPES = {
  STATUS_CHANGE: 'status_change',
  APPOINTMENT_REMINDER: 'appointment_reminder',
  MANUAL: 'manual'
};

const EmailTemplates = ({
  templates = [],
  jobStatuses = [],
  recurringStatuses = [],
  onTemplateCreate,
  onTemplateUpdate,
  onTemplateDelete,
  onSave,
  loading
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);

  const handleOpenModal = (template = null) => {
    setEditingTemplate(template);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingTemplate(null);
  };

  const handleSaveTemplate = (templateData) => {
    if (editingTemplate) {
      onTemplateUpdate(templateData);
    } else {
      onTemplateCreate({
        ...templateData,
        id: Date.now().toString()
      });
    }
    handleCloseModal();
  };

  return (
    <div className={styles.orgContent}>
      <div className={styles.orgSection}>
        <Typography variant="h6" className={styles.sectionTitle}>
          Email Templates
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenModal()}
          >
            Create New Template
          </Button>
        </Box>

        {/* Template List */}
        <List>
          {templates.map((template) => (
            <ListItem 
              key={template.id}
              sx={{
                bgcolor: 'background.paper',
                mb: 1,
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <ListItemText 
                primary={template.name}
                secondary={
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Subject: {template.subject}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Trigger: {template.trigger.type === TRIGGER_TYPES.MANUAL ? 'Manual Send' :
                              template.trigger.type === TRIGGER_TYPES.STATUS_CHANGE ? `On Status: ${template.trigger.status}` :
                              `${template.trigger.daysBeforeAppointment} days before appointment`}
                    </Typography>
                  </Box>
                }
              />
              <ListItemSecondaryAction>
                <IconButton 
                  edge="end" 
                  aria-label="edit"
                  onClick={() => handleOpenModal(template)}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton 
                  edge="end" 
                  aria-label="delete"
                  onClick={() => onTemplateDelete(template.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        {templates.length === 0 && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2, textAlign: 'center' }}>
            No email templates created yet. Click the button above to create your first template.
          </Typography>
        )}

        {/* Email Template Modal */}
        <EmailTemplateModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleSaveTemplate}
          initialData={editingTemplate}
          jobStatuses={jobStatuses}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default EmailTemplates;