// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Badge, IconButton, Menu, MenuItem, Typography, Tab, Tabs, Box,
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Avatar,
  Stack
} from '@mui/material';
import { Bell, MapPin, SprayCan } from 'lucide-react';
import { collection, query, getDocs, onSnapshot, where, updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useModal } from '../context/ModalContext';
import { useAuth } from '../context/AuthContext';
import AdminCancellationModal from './AdminCancellationModal';
import moment from 'moment';

const Header = ({ isDarkMode, toggleDarkMode, onProfileClick }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [timeOffRequests, setTimeOffRequests] = useState([]);
  const [clientRequests, setClientRequests] = useState([]);
  const { openAvailabilityModal, openClientRequestModal } = useModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [expenses, setExpenses] = useState([]);
  const [expenseModalOpen, setExpenseModalOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [cancellationRequests, setCancellationRequests] = useState([]);
  const [showAdminCancelModal, setShowAdminCancelModal] = useState(false);
  const [selectedCancellation, setSelectedCancellation] = useState(null);

  useEffect(() => {
    if (!user?.orgId) return;
  
    // Add listener for cancellation requests
    const cancellationsRef = collection(db, 'organizations', user.orgId, 'cancellations');
    const cancellationsQuery = query(
      cancellationsRef,
      where('status', '==', 'pending')
    );
  
    const unsubscribeCancellations = onSnapshot(cancellationsQuery, async (snapshot) => {
      const requests = [];
      
      for (const docSnapshot of snapshot.docs) {
        const cancellationData = docSnapshot.data();
        
        // Get customer details
        const customerRef = doc(db, 'organizations', user.orgId, 'customers', cancellationData.customerId);
        const customerDoc = await getDoc(customerRef);
        
        // Get recurring service details
        const servicesRef = collection(db, 'organizations', user.orgId, 'recurringServices');
        const servicesQuery = query(
          servicesRef,
          where('customerId', '==', cancellationData.customerId),
          where('recurringStatus', '==', 'Active')
        );
        const servicesSnapshot = await getDocs(servicesQuery);
        const activeService = servicesSnapshot.docs[0]?.data();
  
        if (customerDoc.exists() && activeService) {
          requests.push({
            id: docSnapshot.id,
            customerName: `${customerDoc.data().firstName} ${customerDoc.data().lastName}`,
            serviceCost: activeService.price,
            frequency: activeService.recurrenceRule,
            recurringServiceId: servicesSnapshot.docs[0].id,
            ...cancellationData
          });
        }
      }
  
      setCancellationRequests(requests.sort((a, b) => 
        moment(b.createdAt.toDate()).diff(moment(a.createdAt.toDate()))
      ));
    });
  
    return () => {
      unsubscribeCancellations();
    };
  }, [user?.orgId]);

  useEffect(() => {
    if (!user?.orgId) return;

    // Listen for time off requests
    const cleanersRef = collection(db, 'organizations', user.orgId, 'cleaners');
    const unsubscribeTimeOff = onSnapshot(cleanersRef, async (cleanersSnapshot) => {
      const allRequests = [];

      for (const cleanerDoc of cleanersSnapshot.docs) {
        const cleanerData = cleanerDoc.data();
        const timeOffRef = collection(db, 'organizations', user.orgId, 'cleaners', cleanerDoc.id, 'timeOffRequests');
        const timeOffSnapshot = await getDocs(timeOffRef);
        
        timeOffSnapshot.forEach(doc => {
          if (doc.data().status === 'pending') {
            allRequests.push({
              id: doc.id,
              cleanerId: cleanerDoc.id,
              cleanerName: `${cleanerData.firstName} ${cleanerData.lastName}`,
              ...doc.data()
            });
          }
        });
      }

      setTimeOffRequests(allRequests.sort((a, b) => 
        moment(b.createdAt.toDate()).diff(moment(a.createdAt.toDate()))
      ));
    });

    // Listen for client requests
    const clientRequestsRef = collection(db, 'organizations', user.orgId, 'clientRequests');
    const clientRequestsQuery = query(
      clientRequestsRef,
      where('status', '==', 'pending')
    );

    const unsubscribeClientRequests = onSnapshot(clientRequestsQuery, (snapshot) => {
      const requests = [];
      snapshot.forEach(doc => {
        requests.push({
          id: doc.id,
          ...doc.data()
        });
      });

      setClientRequests(requests.sort((a, b) => 
        moment(b.createdAt.toDate()).diff(moment(a.createdAt.toDate()))
      ));
    });

    // Add expenses listener
    const unsubscribeExpenses = onSnapshot(cleanersRef, async (cleanersSnapshot) => {
      const allExpenses = [];

      for (const cleanerDoc of cleanersSnapshot.docs) {
        const cleanerData = cleanerDoc.data();
        const expensesRef = collection(
          db, 
          'organizations', 
          user.orgId, 
          'cleaners', 
          cleanerDoc.id, 
          'expenses'
        );
        const expensesQuery = query(expensesRef, where('status', '==', 'pending'));
        const expensesSnapshot = await getDocs(expensesQuery);
        
        expensesSnapshot.forEach(doc => {
          allExpenses.push({
            id: doc.id,
            cleanerId: cleanerDoc.id,
            cleanerName: `${cleanerData.firstName} ${cleanerData.lastName}`,
            path: doc.ref.path,
            ...doc.data()
          });
        });
      }

      setExpenses(allExpenses.sort((a, b) => 
        moment(b.createdAt.toDate()).diff(moment(a.createdAt.toDate()))
      ));
    });

    return () => {
      unsubscribeTimeOff();
      unsubscribeClientRequests();
      unsubscribeExpenses();
    };
  }, [user?.orgId]);

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleTimeOffClick = (request) => {
    const start = moment(request.startTime.toDate());
    const end = moment(request.endTime.toDate());
    const duration = end.diff(start, 'minutes');

    const availabilityData = {
      cleaner: {
        id: request.cleanerId,
        firstName: request.cleanerName.split(' ')[0],
        lastName: request.cleanerName.split(' ')[1]
      },
      start,
      end,
      duration,
      date: moment(request.date.toDate()),
      status: 'time_off_requested',
      requestId: request.id,
      orgId: user.orgId,
      cleanerId: request.cleanerId,
      affectedJobs: request.affectedJobs || []
    };

    const fetchExistingJobs = async () => {
      const jobsRef = collection(db, 'organizations', user.orgId, 'jobs');
      const jobQuery = query(
        jobsRef,
        where('cleanerId', '==', request.cleanerId)
      );

      try {
        const querySnapshot = await getDocs(jobQuery);
        const existingJobs = [];
        const selectedDate = moment(request.date.toDate()).startOf('day');
        
        querySnapshot.forEach((doc) => {
          const jobData = doc.data();
          const jobDate = moment(jobData.appointmentDate.toDate()).startOf('day');
          
          if (jobDate.isSame(selectedDate)) {
            existingJobs.push({
              id: doc.id,
              ...jobData,
              customerName: jobData.customerName,
              startTime: jobData.appointmentDate,
              endTime: jobData.scheduledEndTime,
              address: jobData.address
            });
          }
        });

        existingJobs.sort((a, b) => 
          moment(a.startTime.toDate()).diff(moment(b.startTime.toDate()))
        );

        availabilityData.existingJobs = existingJobs;
        openAvailabilityModal(availabilityData);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchExistingJobs();
    handleNotificationClose();
  };

  const handleClientRequestClick = (request) => {
    const requestData = {
      ...request,
      orgId: user.orgId,
      currentStartTime: request.currentAppointmentDate?.toDate(),
      currentEndTime: request.currentEndTime?.toDate(),
      // Handle both manual and normal requests
      newStartTime: request.type === 'manual' 
        ? null 
        : request.requestedDate?.toDate(),
      newEndTime: request.type === 'manual' 
        ? null 
        : request.requestedEndTime?.toDate(),
      isManualRequest: request.type === 'manual'
    };
  
    openClientRequestModal(requestData);
    handleNotificationClose();
  };

  const handleSignOut = () => {
    localStorage.removeItem('authToken');
    sessionStorage.removeItem('authToken');
    navigate('/login');
  };

  const totalNotifications = timeOffRequests.length + clientRequests.length + expenses.length + cancellationRequests.length;

  const formatDate = (date) => {
    if (!date) return 'Not specified';
    try {
      if (date?.toDate) {
        return moment(date.toDate()).format('MMM D, YYYY');
      }
      if (date instanceof Date) {
        return moment(date).format('MMM D, YYYY');
      }
      return moment(date).format('MMM D, YYYY');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const renderClientRequest = (request) => {
    if (request.type === 'manual') {
      return (
        <MenuItem 
          key={request.id}
          onClick={() => handleClientRequestClick(request)}
          sx={{ flexDirection: 'column', alignItems: 'flex-start', py: 2 }}
        >
          <Typography variant="subtitle2">
            {request.customerName} requested manual reschedule
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Current: {formatDate(request.currentAppointmentDate)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Preferred: {request.preferredDate ? formatDate(request.preferredDate) : 'Not specified'}
            {request.preferredTimeRange ? ` (${request.preferredTimeRange})` : ''}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Requested: {request.createdAt ? moment(request.createdAt.toDate()).fromNow() : 'Recently'}
          </Typography>
        </MenuItem>
      );
    }

    if (request.type === 'cancel') {
      return (
        <MenuItem 
          key={request.id}
          onClick={() => handleClientRequestClick(request)}
          sx={{ flexDirection: 'column', alignItems: 'flex-start', py: 2 }}
        >
          <Typography variant="subtitle2">
            {request.customerName} requested cancellation
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Appointment: {formatDate(request.currentAppointmentDate)}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ 
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
            Reason: {request.clientNotes}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Requested: {request.createdAt ? moment(request.createdAt.toDate()).fromNow() : 'Recently'}
          </Typography>
        </MenuItem>
      );
    }

    if (request.type === 'custom_addon') {
      return (
        <MenuItem 
          key={request.id}
          onClick={() => handleClientRequestClick(request)}
          sx={{ flexDirection: 'column', alignItems: 'flex-start', py: 2 }}
        >
          <Typography variant="subtitle2">
            {request.customerName} requested custom service
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Appointment: {formatDate(request.appointmentDate)}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ 
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
            Request: {request.customRequest}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Requested: {request.createdAt ? moment(request.createdAt.toDate()).fromNow() : 'Recently'}
          </Typography>
        </MenuItem>
      );
    }
  
    return (
      <MenuItem 
        key={request.id}
        onClick={() => handleClientRequestClick(request)}
        sx={{ flexDirection: 'column', alignItems: 'flex-start', py: 2 }}
      >
        <Typography variant="subtitle2">
          {request.customerName} requested reschedule
        </Typography>
        <Typography variant="body2" color="text.secondary">
          From: {formatDate(request.currentAppointmentDate)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          To: {formatDate(request.requestedDate)}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Requested: {request.createdAt ? moment(request.createdAt.toDate()).fromNow() : 'Recently'}
        </Typography>
      </MenuItem>
    );
  };

    // Add expense handlers
    const handleExpenseClick = (expense) => {
      setSelectedExpense(expense);
      setExpenseModalOpen(true);
      handleNotificationClose();
    };
  
    const handleExpenseAction = async (action) => {
      try {
        const expenseRef = doc(db, selectedExpense.path);
        await updateDoc(expenseRef, {
          status: action,
          updatedAt: new Date(),
          updatedBy: user.uid
        });
        setExpenseModalOpen(false);
        setSelectedExpense(null);
      } catch (err) {
        console.error('Error updating expense:', err);
      }
    };
  
    // Add expense notification renderer
    const renderExpenseNotification = (expense) => (
      <MenuItem 
        key={expense.id}
        onClick={() => handleExpenseClick(expense)}
        sx={{ flexDirection: 'column', alignItems: 'flex-start', py: 2 }}
      >
        <Typography variant="subtitle2">
          {expense.cleanerName} submitted {expense.type} expense
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {expense.type === 'Gas' ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <MapPin size={16} />
              {`${expense.miles} miles`}
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <SprayCan size={16} />
              {`$${expense.cost.toFixed(2)} for supplies`}
            </Box>
          )}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Submitted: {moment(expense.createdAt.toDate()).fromNow()}
        </Typography>
      </MenuItem>
    );

    const handleCancellationClick = (cancellation) => {
      setSelectedCancellation(cancellation);
      setShowAdminCancelModal(true);
      handleNotificationClose();
    };
    
    // Add to render client requests section in the menu
    const renderCancellationRequest = (request) => (
      <MenuItem 
        key={request.id}
        onClick={() => handleCancellationClick(request)}
        sx={{ flexDirection: 'column', alignItems: 'flex-start', py: 2 }}
      >
        <Typography variant="subtitle2">
          Service Cancellation Request
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Customer: {request.customerName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Reason: {request.reason}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Requested: {moment(request.createdAt.toDate()).fromNow()}
        </Typography>
      </MenuItem>
    );

    return (
      <>
        <header className={`header ${isDarkMode ? 'dark-mode' : ''}`}>
          <h1 className="title">Gem City Sales Tools</h1>
          <nav className="nav-right">
            <IconButton onClick={handleNotificationClick}>
              <Badge badgeContent={totalNotifications} color="error">
                <Bell />
              </Badge>
            </IconButton>
            
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleNotificationClose}
              PaperProps={{
                style: {
                  maxHeight: 400,
                  width: '300px',
                },
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={selectedTab}
                  onChange={(e, newValue) => setSelectedTab(newValue)}
                  variant="fullWidth"
                >
                  <Tab label={`Client (${clientRequests.length})`} />
                  <Tab label={`Cleaner (${timeOffRequests.length + expenses.length})`} />
                </Tabs>
              </Box>
    
              <Box sx={{ mt: 1 }}>
                {selectedTab === 0 ? (
                  clientRequests.length > 0 ? (
                    clientRequests.map((request) => renderClientRequest(request))
                  ) : (
                    <MenuItem disabled>
                      No pending client requests
                    </MenuItem>
                  )
                ) : (
                  <>
                    {timeOffRequests.map((request) => (
                      <MenuItem 
                        key={request.id}
                        onClick={() => handleTimeOffClick(request)}
                        sx={{ flexDirection: 'column', alignItems: 'flex-start', py: 2 }}
                      >
                        <Typography variant="subtitle2">
                          {request.cleanerName} requested time off
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {moment(request.date.toDate()).format('MMM D, YYYY')}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {moment(request.startTime.toDate()).format('h:mm A')} - 
                          {moment(request.endTime.toDate()).format('h:mm A')}
                        </Typography>
                      </MenuItem>
                    ))}
                    {expenses.map(renderExpenseNotification)}
                    {timeOffRequests.length === 0 && expenses.length === 0 && (
                      <MenuItem disabled>
                        No pending cleaner requests
                      </MenuItem>
                    )}
                  </>
                )}
              </Box>
            </Menu>
    
            <button className="btn btn-primary" onClick={onProfileClick}>
              Profile
            </button>
            <button className="btn btn-secondary" onClick={handleSignOut}>
              Sign Out
            </button>
            <label className="switch">
              <input 
                type="checkbox" 
                checked={isDarkMode} 
                onChange={toggleDarkMode} 
              />
              <span className="slider round">
                <span className="icon">{isDarkMode ? '🌙' : '☀️'}</span>
              </span>
            </label>
          </nav>
        </header>
    
        {/* Expense approval modal */}
        <Dialog 
          open={expenseModalOpen} 
          onClose={() => setExpenseModalOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          {selectedExpense && (
            <>
              <DialogTitle>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar>
                    {selectedExpense.cleanerName.split(' ').map(n => n[0]).join('')}
                  </Avatar>
                  <Typography variant="h6">
                    {selectedExpense.type} Expense Request
                  </Typography>
                </Stack>
              </DialogTitle>
              
              <DialogContent>
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <Typography variant="subtitle2">
                    Submitted by {selectedExpense.cleanerName}
                  </Typography>
                  {selectedExpense.type === 'Gas' ? (
                    <>
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <MapPin size={20} />
                        <Typography>{selectedExpense.miles} miles</Typography>
                      </Box>
                      <Typography variant="body2">
                        Client: {selectedExpense.clientName}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <SprayCan size={20} />
                        <Typography>${selectedExpense.cost.toFixed(2)}</Typography>
                      </Box>
                      <Typography variant="body2">
                        Products: {selectedExpense.products}
                      </Typography>
                    </>
                  )}
                  {selectedExpense.photoUrl && (
                    <Box>
                      <img 
                        src={selectedExpense.photoUrl} 
                        alt="Expense documentation"
                        style={{
                          width: '100%',
                          maxHeight: '200px',
                          objectFit: 'contain',
                          borderRadius: '4px'
                        }}
                      />
                    </Box>
                  )}
                </Stack>
              </DialogContent>

              {cancellationRequests.map(renderCancellationRequest)}

              // Add modal to the JSX, after the existing modals
              <AdminCancellationModal
                open={showAdminCancelModal}
                onClose={() => {
                  setShowAdminCancelModal(false);
                  setSelectedCancellation(null);
                }}
                cancellationData={selectedCancellation}
                orgId={user?.orgId}
              />
              
              <DialogActions>
                <Button onClick={() => setExpenseModalOpen(false)}>
                  Cancel
                </Button>
                <Button 
                  onClick={() => handleExpenseAction('rejected')}
                  color="error"
                >
                  Reject
                </Button>
                <Button 
                  onClick={() => handleExpenseAction('processed')}
                  variant="contained"
                >
                  Approve
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    );
  };

export default Header;