import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Alert,
  Snackbar,
  Paper,
  Grid
} from '@mui/material';
import { usePlaces } from '../hooks/usePlaces';
import styles from '../styles/CreateCustomerModal.module.css';

const CreateCustomerModal = ({
  open,
  handleClose,
  handleCreateCustomer,
  customFields = [],
  isEditing = false,
  initialData = null
}) => {
  const defaultFormData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    serviceAddress: '',
    billingAddress: '',
    addresses: [],
    startTime: '09:00',
    endTime: '11:00',
    customFieldValues: {},
    notes: '',
    isPrivateNote: false,
    accountStatus: 'active'
  };

  // Form State
  const [formData, setFormData] = useState(defaultFormData);
  const [serviceAddressInput, setServiceAddressInput] = useState('');
  const [billingAddressInput, setBillingAddressInput] = useState('');

  // Places Autocomplete State
  const [serviceAutocomplete, setServiceAutocomplete] = useState(null);
  const [billingAutocomplete, setBillingAutocomplete] = useState(null);

  // Feedback State
  const [feedback, setFeedback] = useState({ open: false, message: '', severity: 'info' });

  // Places Hook
  const { loading: placesLoading, error: placesError, handlePlaceSelect: processPlace } = usePlaces();

  useEffect(() => {
    if (initialData && isEditing) {
      setFormData(initialData);
      setServiceAddressInput(initialData.serviceAddress || '');
      setBillingAddressInput(initialData.billingAddress || '');
    } else {
      setFormData(defaultFormData);
      setServiceAddressInput('');
      setBillingAddressInput('');
    }
  }, [initialData, isEditing, open]);

  const handleServiceAutocompleteLoad = useCallback((autocomplete) => {
    setServiceAutocomplete(autocomplete);
  }, []);

  const handleBillingAutocompleteLoad = useCallback((autocomplete) => {
    setBillingAutocomplete(autocomplete);
  }, []);

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handlePlaceSelect = async (type) => {
    const autocomplete = type === 'serviceAddress' ? serviceAutocomplete : billingAutocomplete;
    
    try {
      const place = autocomplete.getPlace();
      
      if (!place.address_components) {
        setFeedback({
          open: true,
          message: 'Please select an address from the dropdown',
          severity: 'warning'
        });
        return;
      }

      // Create address object using Places data
      const addressObject = {
        formattedAddress: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        lastUpdated: new Date()
      };

      // Update form data based on address type
      if (type === 'serviceAddress') {
        setFormData(prev => ({
          ...prev,
          serviceAddress: place.formatted_address,
          addresses: [addressObject],
        }));
        setServiceAddressInput(place.formatted_address);
      } else {
        setFormData(prev => ({
          ...prev,
          billingAddress: place.formatted_address,
        }));
        setBillingAddressInput(place.formatted_address);
      }

    } catch (error) {
      console.error('Error processing place selection:', error);
      setFeedback({
        open: true,
        message: 'Error processing selected address',
        severity: 'error'
      });
    }
  };

  const handleSubmit = () => {
    const fullName = `${formData.firstName} ${formData.lastName}`.trim();
    
    const customerData = {
      name: fullName,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      serviceAddress: formData.serviceAddress,
      billingAddress: formData.billingAddress || formData.serviceAddress,
      addresses: formData.addresses,
      customFieldValues: formData.customFieldValues,
      notes: formData.notes,
      isPrivateNote: formData.isPrivateNote,
      accountStatus: 'active',
      startTime: formData.startTime,
      endTime: formData.endTime,
      ...(isEditing ? { 
        updatedAt: new Date(),
      } : { 
        createdAt: new Date(),
      })
    };

    handleCreateCustomer(customerData);
    handleClose();
    setFormData(defaultFormData);
    setServiceAddressInput('');
    setBillingAddressInput('');
  };

  const renderAutocompleteField = (type) => (
    <Box className={`${styles.googlePlacesAutocomplete} relative w-full z-50`}>
      <Autocomplete
        onLoad={type === 'serviceAddress' ? handleServiceAutocompleteLoad : handleBillingAutocompleteLoad}
        onPlaceChanged={() => handlePlaceSelect(type)}
        options={{
          componentRestrictions: { country: 'us' },
          fields: [
            'address_components',
            'formatted_address',
            'geometry',
            'name',
            'place_id'
          ],
          types: ['address']
        }}
      >
        <TextField
          fullWidth
          className={styles.addressField}
          label={`${type === 'serviceAddress' ? 'Service' : 'Billing'} Address`}
          value={type === 'serviceAddress' ? serviceAddressInput : billingAddressInput}
          onChange={(e) => {
            if (type === 'serviceAddress') {
              setServiceAddressInput(e.target.value);
            } else {
              setBillingAddressInput(e.target.value);
            }
          }}
          sx={{
            backgroundColor: 'var(--light-foreground)',
            '&:hover': {
              backgroundColor: 'var(--light-foreground-accent)',
            },
            '[data-mui-color-scheme="dark"] &': {
              backgroundColor: 'var(--dark-text-area)',
              '&:hover': {
                backgroundColor: 'var(--dark-text-hover)',
              }
            }
          }}
        />
      </Autocomplete>
    </Box>
  );

  return (
    <>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: 'var(--light-background)',
            '[data-mui-color-scheme="dark"] &': {
              backgroundColor: 'var(--dark-background)'
            }
          }
        }}
      >
        <DialogTitle sx={{
          backgroundColor: 'var(--light-foreground)',
          '[data-mui-color-scheme="dark"] &': {
            backgroundColor: 'var(--dark-foreground)'
          }
        }}>
          {isEditing ? 'Edit Customer' : 'Create New Customer'}
        </DialogTitle>
        <DialogContent sx={{
          backgroundColor: 'var(--light-background)',
          '[data-mui-color-scheme="dark"] &': {
            backgroundColor: 'var(--dark-background)'
          }
        }}>
          <Paper elevation={3} sx={{ 
            p: 3, 
            mt: 2,
            backgroundColor: 'var(--light-foreground)',
            '[data-mui-color-scheme="dark"] &': {
              backgroundColor: 'var(--dark-foreground)'
            }
          }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Basic Information
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  fullWidth
                  value={formData.firstName}
                  onChange={(e) => handleChange('firstName', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  fullWidth
                  value={formData.lastName}
                  onChange={(e) => handleChange('lastName', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email Address"
                  type="email"
                  fullWidth
                  value={formData.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone Number"
                  fullWidth
                  value={formData.phone}
                  onChange={(e) => handleChange('phone', e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Address Information
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {renderAutocompleteField('serviceAddress')}
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!formData.billingAddress}
                      onChange={(e) => {
                        if (e.target.checked) {
                          handleChange('billingAddress', '');
                          setBillingAddressInput('');
                        }
                      }}
                    />
                  }
                  label="Billing Address same as Service Address"
                />
              </Grid>

              {formData.billingAddress && (
                <Grid item xs={12}>
                  {renderAutocompleteField('billingAddress')}
                </Grid>
              )}

              {customFields.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="h6" gutterBottom>
                      Custom Fields
                    </Typography>
                  </Grid>
                  {customFields.map((field) => (
                    <Grid item xs={12} key={field.id}>
                      <TextField
                        label={field.label}
                        fullWidth
                        value={formData.customFieldValues[field.id] || ''}
                        onChange={(e) => {
                          setFormData(prev => ({
                            ...prev,
                            customFieldValues: {
                              ...prev.customFieldValues,
                              [field.id]: e.target.value
                            }
                          }));
                        }}
                      />
                    </Grid>
                  ))}
                </>
              )}

              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  value={formData.notes}
                  onChange={(e) => handleChange('notes', e.target.value)}
                  placeholder="Enter customer notes here..."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isPrivateNote}
                      onChange={(e) => handleChange('isPrivateNote', e.target.checked)}
                    />
                  }
                  label="Make this note private"
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions sx={{
          backgroundColor: 'var(--light-foreground)',
          '[data-mui-color-scheme="dark"] &': {
            backgroundColor: 'var(--dark-foreground)'
          },
          p: 3
        }}>
          <Button onClick={handleClose} variant="outlined" sx={{
            color: 'var(--error)',
            borderColor: 'var(--error)',
            '&:hover': {
              borderColor: 'var(--error)',
              backgroundColor: 'rgba(235, 70, 70, 0.1)'
            }
          }}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={!formData.firstName || !formData.lastName || !formData.serviceAddress}
            sx={{
              backgroundColor: 'var(--light-primary)',
              '&:hover': {
                backgroundColor: 'var(--light-accent)',
              },
              '[data-mui-color-scheme="dark"] &': {
                backgroundColor: 'var(--dark-primary)',
                '&:hover': {
                  backgroundColor: 'var(--dark-accent)',
                }
              }
            }}
          >
            {isEditing ? 'Save Changes' : 'Create Customer'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={feedback.severity} onClose={() => setFeedback(prev => ({ ...prev, open: false }))}>
          {feedback.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateCustomerModal;