// /src/components/AddressLink.js

import React from 'react';
import { Typography } from '@mui/material';
import styles from '../styles/CleanerJobView.module.css';

const AddressLink = ({ address }) => {
  const handleNavigationClick = (e) => {
    e.preventDefault();
    // Try to use the geo: URI scheme first (works on Android)
    const geoUrl = `geo:0,0?q=${encodeURIComponent(address)}`;
    
    // Apple Maps URL format (works on iOS)
    const appleMapsUrl = `maps://?q=${encodeURIComponent(address)}`;
    
    // Google Maps as fallback (works on both platforms and web)
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`;

    // Check if the device supports the geo: scheme
    if (/android/i.test(navigator.userAgent)) {
      window.location.href = geoUrl;
    } 
    // Check if the device is iOS
    else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.location.href = appleMapsUrl;
    }
    // Fallback to Google Maps
    else {
      window.location.href = googleMapsUrl;
    }
  };

  return (
    <Typography variant="body2">
      <a 
        href="#"
        onClick={handleNavigationClick}
        className={styles.addressLink}
      >
        {address} 📍
      </a>
    </Typography>
  );
};

export default AddressLink;