// src/components/JobEmailHistory.js
import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { format } from 'date-fns';
import { CheckCircle, Clock } from 'lucide-react';

const JobEmailHistory = ({ jobId, organizationId }) => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!organizationId || !jobId) return;

    const emailsRef = collection(
      db, 
      'organizations', 
      organizationId, 
      'jobs', 
      jobId, 
      'mail'
    );

    const emailQuery = query(
      emailsRef,
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(emailQuery, (snapshot) => {
      const emailData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setEmails(emailData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [organizationId, jobId]);

  if (loading) {
    return <div className="p-2">Loading email history...</div>;
  }

  return (
    <div className="space-y-2">
      {emails.length === 0 ? (
        <p className="text-sm text-gray-500">No emails sent for this job</p>
      ) : (
        <div className="space-y-2">
          {emails.map((email) => (
            <div key={email.id} className="border rounded p-3 bg-white shadow-sm">
              <div className="flex items-start justify-between">
                <div className="flex items-center gap-2">
                  {email.status === 'sent' ? (
                    <CheckCircle className="w-4 h-4 text-green-500" />
                  ) : (
                    <Clock className="w-4 h-4 text-blue-500" />
                  )}
                  <div>
                    <h4 className="text-sm font-medium">
                      {email.subject}
                    </h4>
                    <p className="text-xs text-gray-600">
                      To: {email.to}
                    </p>
                  </div>
                </div>
                <div className="text-xs text-gray-500">
                  {email.createdAt?.toDate() ? 
                    format(email.createdAt.toDate(), 'MMM d, yyyy h:mm a') 
                    : 'Date not available'}
                </div>
              </div>
              
              {email.tracking && (
                <div className="mt-2 text-xs text-gray-600">
                  <div className="flex items-center gap-2">
                    <span>Opens: {email.tracking.opens || 0}</span>
                    {email.tracking.lastOpened && (
                      <span>
                        Last opened: {format(email.tracking.lastOpened.toDate(), 'MMM d, yyyy h:mm a')}
                      </span>
                    )}
                  </div>
                  {email.tracking.linkClicks?.length > 0 && (
                    <div className="mt-1">
                      <span>Link clicks: {email.tracking.linkClicks.length}</span>
                    </div>
                  )}
                </div>
              )}

              {email.status === 'scheduled' && (
                <div className="mt-1 text-xs text-blue-600">
                  Scheduled for: {format(new Date(email.scheduledDate), 'MMM d, yyyy h:mm a')}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default JobEmailHistory;