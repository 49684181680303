// src/components/WeeklyJobsView.js


import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Stack,
  Card,
  CardContent,
  Chip,
  Tooltip
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  Today as TodayIcon
} from '@mui/icons-material';
import { updateDoc, deleteField, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../context/AuthContext';
import AvailabilityModal from './AvailabilityModal';
import { useTimezone } from '../context/TimeZoneContext';
import moment from 'moment';

const WeeklyJobsView = ({ jobs, onJobClick, statusColors, getStatusDetails, orgId, onAvailableTimeBlocksChange, customJobStatuses }) => {
  const { timezone, formatInOrgTz, convertToOrgTz, convertFromOrgTz } = useTimezone();
  const [currentDate, setCurrentDate] = useState(moment());
  const [cleaners, setCleaners] = useState([]);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [availabilityStatuses, setAvailabilityStatuses] = useState({});
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [timeOffRequests, setTimeOffRequests] = useState({});
  const [scheduleSettings, setScheduleSettings] = useState({
    minimumGapNotification: 180,
    driveTimeGap: 30
  });

  const nextWeek = () => setCurrentDate(moment(currentDate).add(1, 'week'));
  const prevWeek = () => setCurrentDate(moment(currentDate).subtract(1, 'week'));
  const today = () => setCurrentDate(moment());

  const { user } = useAuth();

  // Fetch cleaners and their availability
  useEffect(() => {
    const fetchCleanersAndTimeOff = async () => {
      if (!orgId) return;

      try {
        const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
        const cleanersSnapshot = await getDocs(cleanersRef);

        const fetchedCleaners = {};
        const fetchedTimeOff = {};

        for (const doc of cleanersSnapshot.docs) {
          const cleanerData = doc.data();

          if (cleanerData.isActive) {
            fetchedCleaners[doc.id] = {
              ...cleanerData,
              id: doc.id
            };

            const timeOffRef = collection(db, 'organizations', orgId, 'cleaners', doc.id, 'timeOffRequests');
            const timeOffSnapshot = await getDocs(timeOffRef);

            fetchedTimeOff[doc.id] = {};
            timeOffSnapshot.forEach(timeOff => {
              const timeOffData = timeOff.data();
              fetchedTimeOff[doc.id][timeOff.id] = {
                id: timeOff.id,
                ...timeOffData,
                startTime: convertToOrgTz(timeOffData.startTime),
                endTime: convertToOrgTz(timeOffData.endTime)
              };
            });
          }
        }

        setCleaners(fetchedCleaners);
        setTimeOffRequests(fetchedTimeOff);

        // Fetch org schedule settings
        const orgDocRef = doc(db, 'organizations', orgId);
        const orgDocSnap = await getDoc(orgDocRef);
        if (orgDocSnap.exists() && orgDocSnap.data().scheduleSettings) {
          setScheduleSettings(orgDocSnap.data().scheduleSettings);
        }
      } catch (error) {
        console.error('Error fetching cleaners:', error);
      }
    };

    fetchCleanersAndTimeOff();
  }, [orgId, convertToOrgTz]);

  useEffect(() => {
    const fetchAvailabilityStatuses = async () => {
      if (!orgId) return;

      try {
        const cleanerRefs = Object.keys(cleaners).map(cleanerId =>
          doc(db, 'organizations', orgId, 'cleaners', cleanerId)
        );

        const cleanerDocs = await Promise.all(
          cleanerRefs.map(ref => getDoc(ref))
        );

        const statuses = {};
        cleanerDocs.forEach(doc => {
          if (doc.exists() && doc.data().availabilityStatuses) {
            statuses[doc.id] = doc.data().availabilityStatuses;
          }
        });

        setAvailabilityStatuses(statuses);
      } catch (error) {
        console.error('Error fetching availability statuses:', error);
      }
    };

    if (Object.keys(cleaners).length > 0) {
      fetchAvailabilityStatuses();
    }
  }, [cleaners, orgId]);

  const weekDays = [];
  const startOfWeek = moment(currentDate).startOf('week');
  for (let i = 0; i < 7; i++) {
    weekDays.push(moment(startOfWeek).add(i, 'days'));
  }

  const formatTimeRange = (job) => {
    const start = formatInOrgTz(job.appointmentDate, 'h:mm A');
    const end = job.scheduledEndTime
      ? formatInOrgTz(job.scheduledEndTime, 'h:mm A')
      : null;
    return end ? `${start} - ${end}` : start;
  };

  const getTimelineItemsForDay = (date) => {
    const dayJobs = jobs.filter(job => {
      if (!job.appointmentDate) return false;

      const jobDate = job.appointmentDate?.toDate?.() || job.appointmentDate;

      if (!moment.isDate(jobDate) && !moment.isMoment(jobDate)) {
        return false;
      }

      return convertToOrgTz(moment(jobDate)).format('YYYY-MM-DD') === date.format('YYYY-MM-DD');
    });

    // Separate jobs into canceled and active
    const canceledJobs = dayJobs.filter(job =>
      job.status?.toLowerCase() === 'cancelled' &&
      job.cancelledAt &&
      job.scheduledEndTime
    );

    const activeJobs = dayJobs.filter(job =>
      job.status?.toLowerCase() !== 'cancelled'
    );

    // Create availability slots ONLY for canceled jobs
    const canceledJobGaps = canceledJobs.map(job => {
      const jobStart = moment(job.appointmentDate.toDate());
      const jobEnd = moment(job.scheduledEndTime.toDate());

      // Ensure cleaner exists
      const cleaner = cleaners[job.cleanerId];
      if (!cleaner) {
        console.warn(`Cleaner not found for canceled job: ${job.id}`);
        return null;
      }

      return {
        type: 'gap',
        time: jobStart,
        data: {
          cleaner: cleaner,
          start: jobStart,
          end: jobEnd,
          duration: jobEnd.diff(jobStart, 'minutes'),
          status: null, // null status means available
          fromCanceledJob: true,
          originalJob: job,
          cancellationReason: job.cancellationReason,
          cancelledAt: job.cancelledAt,
          recurring: job.recurring,
          recurrenceRule: job.recurrenceRule
        }
      };
    }).filter(Boolean); // Remove any null gaps where cleaner wasn't found

    // Get regular availability gaps ONLY from active jobs
    const availabilityGaps = findAvailabilityGaps(date, activeJobs);

    // Create merged timeline items, including both canceled and active jobs
    const timelineItems = [
      ...dayJobs.map(job => ({
        type: 'job',
        time: moment(job.appointmentDate?.toDate?.() || job.appointmentDate),
        data: job,
        hasTimeOffRequest: false
      })),
      ...availabilityGaps.map(gap => ({
        type: 'gap',
        time: gap.start,
        data: gap
      })),
      ...canceledJobGaps
    ].sort((a, b) => a.time.diff(b.time));

    return timelineItems;
  };

  const handleAvailabilityStatusChange = async (availabilityData) => {
    try {
      setUpdating(true);

      const cleanerRef = doc(db, 'organizations', orgId, 'cleaners', availabilityData.cleaner.id);
      const startInOrgTz = convertToOrgTz(availabilityData.start);
      const endInOrgTz = convertToOrgTz(availabilityData.end);
      const availabilityKey = `${startInOrgTz.format('YYYY-MM-DD-HH-mm')}-${endInOrgTz.format('YYYY-MM-DD-HH-mm')}`;

      if (availabilityData.status) {
        await updateDoc(cleanerRef, {
          [`availabilityStatuses.${availabilityKey}`]: {
            status: availabilityData.status,
            startTime: convertFromOrgTz(availabilityData.start).toDate(),
            endTime: convertFromOrgTz(availabilityData.end).toDate(),
            duration: availabilityData.duration,
            updatedAt: new Date(),
            updatedBy: user.uid
          }
        });

        setAvailabilityStatuses(prev => ({
          ...prev,
          [availabilityData.cleaner.id]: {
            ...(prev[availabilityData.cleaner.id] || {}),
            [availabilityKey]: {
              status: availabilityData.status,
              startTime: convertFromOrgTz(availabilityData.start).toDate(),
              endTime: convertFromOrgTz(availabilityData.end).toDate(),
              duration: availabilityData.duration,
              updatedAt: new Date(),
              updatedBy: user.uid
            }
          }
        }));
      } else {
        await updateDoc(cleanerRef, {
          [`availabilityStatuses.${availabilityKey}`]: deleteField()
        });

        const newStatuses = { ...availabilityStatuses };
        if (newStatuses[availabilityData.cleaner.id]) {
          delete newStatuses[availabilityData.cleaner.id][availabilityKey];
        }
        setAvailabilityStatuses(newStatuses);
      }

      setIsAvailabilityModalOpen(false);
      setSelectedAvailability(null);

    } catch (error) {
      console.error('Error updating availability status:', error);
    } finally {
      setUpdating(false);
    }
  };


  const findAvailabilityGaps = (day, dayJobs) => {
    const getStatusForTimeBlock = (cleanerId, start, end, availabilityStatuses = {}) => {
      if (!cleanerId || !start || !end) return null;
      const startInOrgTz = convertToOrgTz(start);
      const endInOrgTz = convertToOrgTz(end);
      const availabilityKey = `${startInOrgTz.format('YYYY-MM-DD-HH-mm')}-${endInOrgTz.format('YYYY-MM-DD-HH-mm')}`;
      const cleanerStatuses = availabilityStatuses[cleanerId] || {};
      return cleanerStatuses[availabilityKey]?.status || null;
    };

    const gaps = [];
    const dayName = day.format('dddd').toLowerCase();

    Object.values(cleaners).forEach(cleaner => {
      // Check for time off requests first
      const cleanerTimeOff = timeOffRequests[cleaner.id] || {};
      const dayStart = moment(day).startOf('day');
      const dayEnd = moment(day).endOf('day');

      // Process time off requests
      Object.values(cleanerTimeOff).forEach(request => {
        const requestStart = convertToOrgTz(moment(request.startTime.toDate()));
        const requestEnd = convertToOrgTz(moment(request.endTime.toDate()));

        if ((request.status === 'pending' || request.status === 'approved') &&
          requestStart.isSame(dayStart, 'day')) {
          gaps.push({
            cleaner,
            start: requestStart,
            end: requestEnd,
            duration: requestEnd.diff(requestStart, 'minutes'),
            status: request.status === 'approved' ? 'time_off_approved' : 'time_off_requested',
            timeOffData: request
          });
        }
      });

      // Skip regular availability if there's approved time off
      if (Object.values(cleanerTimeOff).some(request =>
        request.status === 'approved' &&
        convertToOrgTz(moment(request.date.toDate())).isSame(day, 'day')
      )) {
        return;
      }

      // Get all jobs for this cleaner (including those where they're in the cleaners array)
      const cleanerJobs = dayJobs.filter(job => {
        const isAssignedAsPrimary = job.cleanerId === cleaner.id;
        const isAssignedInArray = Array.isArray(job.cleaners) &&
          job.cleaners.some(c => c.id === cleaner.id || c.cleanerId === cleaner.id);
        const isAssignedByName = job.cleanerName === cleaner.name ||
          job.cleanerName === `${cleaner.firstName} ${cleaner.lastName}`;

        return isAssignedAsPrimary || isAssignedInArray || isAssignedByName;
      });

      // Skip if cleaner has any non-cancelled jobs
      const hasActiveJobs = cleanerJobs.some(job =>
        job.status?.toLowerCase() !== 'cancelled' &&
        job.status?.toLowerCase() !== 'completed'
      );

      if (hasActiveJobs) {
        return; // Skip creating availability blocks for this cleaner
      }

      // Check if cleaner has working hours set for this day
      if (!cleaner.availability?.[dayName] ||
        !cleaner.workingHours?.[dayName]) {
        return;
      }

      const dayWorkingHours = cleaner.workingHours[dayName];

      // Skip if working hours aren't properly set
      if (!dayWorkingHours.start || !dayWorkingHours.end ||
        dayWorkingHours.start === "" || dayWorkingHours.end === "") {
        return;
      }

      // Set up work day start and end times
      const workStart = convertToOrgTz(moment(day))
        .set('hour', parseInt(dayWorkingHours.start.split(':')[0]))
        .set('minute', parseInt(dayWorkingHours.start.split(':')[1]))
        .set('second', 0);

      const workEnd = convertToOrgTz(moment(day))
        .set('hour', parseInt(dayWorkingHours.end.split(':')[0]))
        .set('minute', parseInt(dayWorkingHours.end.split(':')[1]))
        .set('second', 0);

      // Process availability based on time blocks or entire working period
      if (scheduleSettings.timeBlocks && scheduleSettings.timeBlocks.length > 0) {
        // Process each time block
        scheduleSettings.timeBlocks.forEach(block => {
          // Handle block format with start/end instead of startTime/endTime
          const startTime = block.startTime || block.start;
          const endTime = block.endTime || block.end;

          if (!startTime || !endTime) {
            console.log('Invalid time block format:', block);
            return;
          }

          const [startHour, startMinute] = startTime.split(':').map(Number);
          const [endHour, endMinute] = endTime.split(':').map(Number);

          if (isNaN(startHour) || isNaN(startMinute) || isNaN(endHour) || isNaN(endMinute)) {
            console.log('Invalid time format:', block);
            return;
          }

          const blockStart = convertToOrgTz(moment(day))
            .set('hour', startHour)
            .set('minute', startMinute)
            .set('second', 0);

          const blockEnd = convertToOrgTz(moment(day))
            .set('hour', endHour)
            .set('minute', endMinute)
            .set('second', 0);

          // Only process block if it's within working hours AND there are no active jobs
          if (blockStart.isBetween(workStart, workEnd, undefined, '[]') &&
            blockEnd.isBetween(workStart, workEnd, undefined, '[]')) {
            const duration = blockEnd.diff(blockStart, 'minutes');

            // Check if duration meets minimum gap requirement
            if (duration >= scheduleSettings.minimumGapNotification) {
              const status = getStatusForTimeBlock(cleaner.id, blockStart, blockEnd, availabilityStatuses);

              gaps.push({
                cleaner,
                start: blockStart.clone(),
                end: blockEnd.clone(),
                duration,
                status
              });
            }
          }
        });
      } else {
        // Use entire working period if no time blocks defined
        const duration = workEnd.diff(workStart, 'minutes');
        if (duration >= scheduleSettings.minimumGapNotification) {
          const status = getStatusForTimeBlock(cleaner.id, workStart, workEnd, availabilityStatuses);

          gaps.push({
            cleaner,
            start: workStart.clone(),
            end: workEnd.clone(),
            duration,
            status
          });
        }
      }

      // Process gaps between cancelled jobs
      const cancelledJobs = cleanerJobs.filter(job => job.status?.toLowerCase() === 'cancelled');
      let currentTime = workStart.clone();

      cancelledJobs.forEach((job, index) => {
        const jobStart = convertToOrgTz(moment(job.appointmentDate.toDate()));
        const jobEnd = convertToOrgTz(moment(job.scheduledEndTime.toDate()));

        // Check gap before cancelled job
        if (currentTime.isBefore(jobStart)) {
          const gapBeforeJob = jobStart.diff(currentTime, 'minutes');

          if (gapBeforeJob >= scheduleSettings.minimumGapNotification + scheduleSettings.driveTimeGap) {
            const gapEnd = moment(jobStart).subtract(scheduleSettings.driveTimeGap, 'minutes');
            const status = getStatusForTimeBlock(cleaner.id, currentTime, gapEnd, availabilityStatuses);

            gaps.push({
              cleaner,
              start: currentTime.clone(),
              end: gapEnd,
              duration: gapBeforeJob - scheduleSettings.driveTimeGap,
              status
            });
          }
        }

        currentTime = moment(jobEnd).add(scheduleSettings.driveTimeGap, 'minutes');

        // Check for gap after last cancelled job
        if (index === cancelledJobs.length - 1 && currentTime.isBefore(workEnd)) {
          const finalGap = workEnd.diff(currentTime, 'minutes');
          if (finalGap >= scheduleSettings.minimumGapNotification) {
            const status = getStatusForTimeBlock(cleaner.id, currentTime, workEnd, availabilityStatuses);

            gaps.push({
              cleaner,
              start: currentTime.clone(),
              end: workEnd.clone(),
              duration: finalGap,
              status
            });
          }
        }
      });
    });

    return gaps;
  };

  // Function to push available time blocks out to the client portal
  const getAvailableTimeBlocks = () => {
    const blocks = [];
    weekDays.forEach(day => {
      const timelineItems = getTimelineItemsForDay(day);
      timelineItems.forEach(item => {
        if (item.type === 'gap' && !item.data.status) {
          blocks.push({
            start: convertFromOrgTz(item.data.start).toDate(),
            end: convertFromOrgTz(item.data.end).toDate(),
            cleanerId: item.data.cleaner.id,
            cleanerName: `${item.data.cleaner.firstName} ${item.data.cleaner.lastName}`
          });
        }
      });
    });
    return blocks;
  };

  useEffect(() => {
    const blocks = getAvailableTimeBlocks();
    if (onAvailableTimeBlocksChange) {
      onAvailableTimeBlocksChange(blocks);
    }
  }, [jobs, cleaners, timeOffRequests, scheduleSettings]);

  const renderTimelineItem = (item, timelineItems) => {
    if (item.type === 'job') {
      const job = item.data;
      const statusDetails = getStatusDetails(job.status);

      const getCleanerName = (cleaner) => {
        // If cleaner has firstName/lastName, use those
        if (cleaner.firstName && cleaner.lastName) {
          return `${cleaner.firstName} ${cleaner.lastName}`;
        }
        // If cleaner has a displayName, use that
        if (cleaner.displayName && cleaner.displayName !== cleaner.email) {
          return cleaner.displayName;
        }
        // If the name is different from email, use it
        if (cleaner.name && cleaner.name !== cleaner.email) {
          return cleaner.name;
        }
        // Default to email if no better name is available
        return cleaner.email;
      };

      return (
        <Tooltip title={`${job.customerName} - ${statusDetails.label}`}>
          <Card
            key={job.id}
            sx={{
              mb: 1,
              cursor: 'pointer',
              '&:hover': { bgcolor: 'action.hover' },
              borderLeft: '4px solid',
              borderColor: statusDetails.chipColor || statusDetails.color,
            }}
            onClick={() => onJobClick(job)}
          >
            <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {formatInOrgTz(job.appointmentDate, 'h:mm A')} - {formatInOrgTz(job.scheduledEndTime, 'h:mm A')}
              </Typography>
              <Typography variant="body2" noWrap>
                {job.customerName}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                {(job.cleanerDetails && job.cleanerDetails.length > 0) ? (
                  // Show multiple cleaners from cleanerDetails array
                  job.cleanerDetails.map((cleaner, index) => (
                    <Typography
                      key={cleaner.id}
                      variant="caption"
                      color="text.secondary"
                      noWrap
                      sx={{ display: 'block' }}
                    >
                      {getCleanerName(cleaner)}
                      {index < job.cleanerDetails.length - 1 && ', '}
                    </Typography>
                  ))
                ) : job.cleanerName && job.cleanerName !== job.cleanerEmail ? (
                  // Show single cleaner from cleanerName if it's not the email
                  <Typography variant="caption" color="text.secondary" noWrap>
                    {job.cleanerName}
                  </Typography>
                ) : (
                  // No cleaners assigned
                  <Typography variant="caption" color="text.secondary" noWrap>
                    No cleaners assigned
                  </Typography>
                )}
              </Box>
              <Box sx={{ mt: 0.5, display: 'flex', gap: 0.5 }}>
                {job.recurring && (
                  <Chip
                    label="Recurring"
                    size="small"
                    sx={{
                      height: 20,
                      backgroundColor: '#008080',
                      color: 'white'
                    }}
                  />
                )}
                <Chip
                  label={statusDetails.label}
                  size="small"
                  sx={{
                    height: 20,
                    backgroundColor: statusDetails.chipColor || statusDetails.color,
                    color: 'white',
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Tooltip>
      );
    } else if (item.type === 'gap') {
      const gap = item.data;
      const availabilityStatus = gap.status;
      const isTimeOff = availabilityStatus?.includes('time_off');
      const isCanceledJobGap = gap.fromCanceledJob;

      if (!isCanceledJobGap && !isTimeOff && timelineItems) {
        const overlapsCanceledJob = timelineItems.some(otherItem =>
          otherItem.type === 'gap' &&
          otherItem.data.fromCanceledJob &&
          convertToOrgTz(otherItem.data.start).isSame(convertToOrgTz(gap.start)) &&
          convertToOrgTz(otherItem.data.end).isSame(convertToOrgTz(gap.end)) &&
          otherItem.data.cleaner.id === gap.cleaner.id
        );

        if (overlapsCanceledJob) {
          return null;
        }
      }

      const getAvailabilityStatusDetails = (status, isCanceled) => {
        if (isCanceled) {
          return {
            color: '#4CAF50',
            label: 'Available (Canceled)',
            bgColor: 'rgba(76, 175, 80, 0.15)',
            hoverColor: 'rgba(76, 175, 80, 0.25)'
          };
        }

        switch (status) {
          case 'time_off_approved':
            return {
              color: '#4A148C',
              label: 'Time Off',
              bgColor: 'rgba(74, 20, 140, 0.1)',
              hoverColor: 'rgba(74, 20, 140, 0.2)'
            };
          case 'time_off_requested':
            return {
              color: '#9C27B0',
              label: 'Time Off Requested',
              bgColor: 'rgba(156, 39, 176, 0.1)',
              hoverColor: 'rgba(156, 39, 176, 0.2)'
            };
          case 'offer_pending':
            return {
              color: '#FF9800',
              label: 'Offer Pending',
              bgColor: 'rgba(255, 152, 0, 0.1)',
              hoverColor: 'rgba(255, 152, 0, 0.2)'
            };
          case 'client_requested':
            return {
              color: '#2196F3',
              label: 'Client Requested',
              bgColor: 'rgba(33, 150, 243, 0.1)',
              hoverColor: 'rgba(33, 150, 243, 0.2)'
            };
          default:
            return {
              color: '#4CAF50',
              label: 'Available',
              bgColor: 'rgba(76, 175, 80, 0.1)',
              hoverColor: 'rgba(76, 175, 80, 0.2)'
            };
        }
      };

      const statusDetails = getAvailabilityStatusDetails(availabilityStatus, isCanceledJobGap);

      const tooltipText = isCanceledJobGap
        ? `${gap.cleaner.firstName} ${gap.cleaner.lastName} available (canceled ${formatInOrgTz(gap.cancelledAt, 'MMM D')}): ${formatInOrgTz(gap.start, 'h:mm A')} - ${formatInOrgTz(gap.end, 'h:mm A')}${gap.cancellationReason ? '\nReason: ' + gap.cancellationReason : ''}`
        : isTimeOff
          ? `${gap.cleaner.firstName} ${gap.cleaner.lastName}: ${statusDetails.label}`
          : `${gap.cleaner.firstName} ${gap.cleaner.lastName} available: ${formatInOrgTz(gap.start, 'h:mm A')} - ${formatInOrgTz(gap.end, 'h:mm A')}`;

      return (
        <Tooltip
          key={`${gap.cleaner.id}-${formatInOrgTz(gap.start, 'HH:mm')}`}
          title={tooltipText}
        >
          <Card
            sx={{
              mb: 1,
              backgroundColor: statusDetails.bgColor,
              border: '1px dashed',
              borderColor: statusDetails.color,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: statusDetails.hoverColor
              }
            }}
            onClick={() => {
              setSelectedAvailability(gap);
              setIsAvailabilityModalOpen(true);
            }}
          >
            <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {formatInOrgTz(gap.start, 'h:mm A')} - {formatInOrgTz(gap.end, 'h:mm A')}
              </Typography>
              <Typography variant="body2" noWrap>
                {gap.cleaner.firstName} {isTimeOff ? statusDetails.label : 'Available'}
              </Typography>
              {!isTimeOff && (
                <Typography variant="caption" color="text.secondary" noWrap>
                  {Math.round(gap.duration / 60 * 10) / 10}hr gap
                  {isCanceledJobGap && (
                    gap.recurring
                      ? ' (Job Canceled)'
                      : ' (canceled)'
                  )}
                </Typography>
              )}
              <Chip
                label={statusDetails.label}
                size="small"
                sx={{
                  height: 20,
                  backgroundColor: statusDetails.color,
                  color: 'white',
                  mt: 0.5
                }}
              />
            </CardContent>
          </Card>
        </Tooltip>
      );
    }
  };

  const renderDayColumn = (day) => {
    const timelineItems = getTimelineItemsForDay(day);

    return (
      <Paper
        elevation={2}
        key={day.format('YYYY-MM-DD')}
        sx={{
          height: '100%',
          bgcolor: moment().isSame(day, 'day') ? 'action.hover' : 'background.paper'
        }}
      >
        {/* Day header */}
        <Box sx={{ p: 1, bgcolor: 'primary.main', color: 'primary.contrastText' }}>
          <Typography variant="subtitle2" align="center">
            {day.format('ddd')}
          </Typography>
          <Typography variant="h6" align="center">
            {day.format('MMM D')}
          </Typography>
        </Box>

        {/* Timeline items list */}
        <Box sx={{ maxHeight: 700, overflow: 'auto', p: 1 }}>
          {timelineItems.map(item => renderTimelineItem(item, timelineItems))}
        </Box>
      </Paper>
    );
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h5">
            Week of {currentDate.format('MMM D, YYYY')}
          </Typography>
          <Box>
            <IconButton onClick={prevWeek}>
              <ChevronLeft />
            </IconButton>
            <IconButton onClick={today}>
              <TodayIcon />
            </IconButton>
            <IconButton onClick={nextWeek}>
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ overflow: 'auto' }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              minWidth: { xs: '800px', md: '100%' },
              '& > *': {
                width: `${100 / 7}%`,
                minWidth: '200px',
              }
            }}
          >
            {weekDays.map(day => renderDayColumn(day))}
          </Stack>
        </Box>
      </Paper>

      <AvailabilityModal
        open={isAvailabilityModalOpen}
        onClose={() => {
          setIsAvailabilityModalOpen(false);
          setSelectedAvailability(null);
        }}
        availabilityData={selectedAvailability}
        customJobStatuses={customJobStatuses}
        onStatusChange={handleAvailabilityStatusChange}
        loading={updating}
      />
    </Box>
  );
};

export default WeeklyJobsView;