import styles from '../../styles/JobOverview/Calendar.module.css';

import { useState, useEffect } from 'react';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import moment from 'moment';

import AvailableSlotCard from './CalendarCards/AvailableSlotCard';
import ScheduledCard from './CalendarCards/ScheduledCard';
import TimeOffCard from './CalendarCards/TimeOffCard';
import useJobOverview from "../../hooks/useJobOverview";

const Calendar = ({ jobs, timeOffRequests }) => {
  const {
    today,
    week,
    cleaners,
    currentDates,
    relativeWeek,
    setRelativeWeek,
    weekJobs,
    weekAvailabilityGaps
  } = useJobOverview();

  const [groupedJobs, setGroupedJobs] = useState({});

  const showingMonths = [
    ...new Set(
      currentDates.map((date) => date.toLocaleString('default', { month: 'long' }))
    )
  ];

  useEffect(() => {
    const generateGroupedJobs = () => {
      const grouped = weekJobs.reduce((acc, job) => {
        const selectedCleaners = Array.isArray(job.cleanerDetails)
          ? cleaners.filter(cleaner => cleaner.id.includes(job.cleanerDetails.map(data => data.id)))
          : cleaners.filter(cleaner => cleaner.id === job.cleanerId)

        const scheduleDuration = job.scheduledDuration ? job.scheduledDuration / 3600 : 0;
  
        selectedCleaners.forEach(cleaner => {
          const cleanerId = cleaner.id;

          if (!acc[cleanerId]) {
            acc[cleanerId] = {
              jobs: [],
              currentHours: 0,
              weekSchedule: Array.from({ length: 6 }, () => []),
              cleaner
            };
          }
  
          acc[cleanerId].jobs.push(job);
          acc[cleanerId].currentHours += scheduleDuration;
  
          const appointmentDate = job.appointmentDate;
          const dayOfWeek = appointmentDate.isoWeekday();
          const adjustedDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  
          acc[cleanerId].weekSchedule[adjustedDayOfWeek].push({
            ...job,
            type: "job",
          });
        });
  
        return acc;
      }, {});
  
      var completeGrouped = Object.keys(grouped).reduce((result, cleanerId) => {
        if (!grouped[cleanerId].weekSchedule) {
          grouped[cleanerId].weekSchedule = Array(7).fill([]).map(() => []);
        }
        result[cleanerId] = grouped[cleanerId];
        return result;
      }, {});
  
      const weekStart = currentDates[0];
      const weekEnd = currentDates[currentDates.length - 1];
  
      timeOffRequests.forEach(request => {
        const startTime = new Date(request.startTime.seconds * 1000);
        const endTime = new Date(request.endTime.seconds * 1000);
        const cleanerIds = Array.isArray(request.cleaner.userId) ? request.cleaner.userId : [request.cleaner.userId];
  
        if (
          (startTime >= weekStart && startTime <= weekEnd) ||
          (endTime >= weekStart && endTime <= weekEnd) ||
          (startTime <= weekStart && endTime >= weekEnd)
        ) {
          cleanerIds.forEach(cleanerId => {
            if (!completeGrouped[cleanerId]) {
              completeGrouped = {
                ...completeGrouped,
                [cleanerId]: {
                  cleaner: request.cleaner,
                  weekSchedule: Array(6).fill([]).map(() => [])
                }
              }
            }

            const appointmentDate = moment(request.startTime.seconds * 1000);
            const dayOfWeek = appointmentDate.isoWeekday();
            const adjustedDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

            completeGrouped[cleanerId].weekSchedule[adjustedDayOfWeek].push({
              ...request,
              type: "day-off",
            });
          });
        }
      });

      weekAvailabilityGaps.forEach(dayGaps => {
        dayGaps.forEach(gap => {
          const cleanerId = gap.cleaner.id

          if (!completeGrouped[cleanerId]) {
            completeGrouped = {
              ...completeGrouped,
              [cleanerId]: {
                cleaner: {
                  name: `${gap.cleaner.firstName} ${gap.cleaner.lastName}`,
                  ...gap.cleaner
                },
                weekSchedule: Array(6).fill([]).map(() => [])
              }
            }
          }

          const dayOfWeek = gap.start.isoWeekday();
          const adjustedDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

          completeGrouped[cleanerId].weekSchedule[adjustedDayOfWeek].push({
            ...gap,
            type: "availability-gap",
          });
        });
      })
  
      setGroupedJobs(completeGrouped);
    };
  
    generateGroupedJobs();
  }, [weekJobs, timeOffRequests, currentDates, weekAvailabilityGaps, cleaners]);  

  return (
    <div className={styles.container}>
      <div className={styles.header}>

        <div className={styles.headerActions}>
          <div
            className={styles.todayActionsButton}
            onClick={() => setRelativeWeek(0)}
          >
            Today
          </div>
          <div
            className={styles.headerActionsButton}
            onClick={() => setRelativeWeek(relativeWeek - 1)}
          >
            <FaAngleLeft />
          </div>
          <div
            className={styles.headerActionsButton}
            onClick={() => setRelativeWeek(relativeWeek + 1)}
          >
            <FaAngleRight />
          </div>
        </div>

        <div>
          {showingMonths.map((month, index) => (
            <span
              key={month}
              className={styles.headerMonth}
            >
              {index > 0 && showingMonths.length > 1 && <span> - </span>}
              {month.charAt(0).toUpperCase() + month.slice(1)}
            </span>
          ))}
        </div>
      </div>

      <div className={styles.calendar}>
        <div className={styles.row}>
          <div className={styles.rowItem}>
            <div className={styles.columnDate}>
              CLE
            </div>
            <div className={styles.columnDivider} />
          </div>

          {currentDates.length > 0 && week.map((column) => {
            const day = currentDates[column];
            const date = day.getDate();

            return (
              <div
                key={column}
                className={styles.rowItem}
              >
                <div className={styles.columnDate}>
                  <p className={`${(date === today) ? styles.columnWeekDayToday : ""}`}>
                    {day.toLocaleDateString('en-US', { weekday: 'short' })}
                  </p>
                  <p className={`${(date === today) ? styles.columnDateToday : ""}`}>
                    {date}
                  </p>
                </div>
                {(column !== 5) && <div className={styles.columnDivider} />}
              </div>
            )
          })}
        </div>

        <div className={styles.calendarCleaners}>
          {Object.entries(groupedJobs).map(([cleanerId, data, index]) => (
            <div
              key={`cleaner-${data.cleaner.id}`}
              className={styles.row}
            >
              <div key={index} className={styles.rowItem}>
                <div className={styles.rowHeading}>
                  <p>{(data.cleaner.name || `${data.cleaner.firstName} ${data.cleaner.lastName}`) || 'Unknown Cleaner'}</p>
                  {
                    data.currentHours && (
                      <p>
                        {(data.currentHours * 0.001).toFixed(2)} hrs.
                      </p>
                    )
                  }
                </div>

                <div className={styles.columnDivider} />
              </div>

              {data.weekSchedule.map((daySchedule, index) => (
                <div key={`day-${index}`} className={styles.rowItem}>
                  <div className={styles.dayJobs}>
                    {daySchedule.map((appointment) => (
                      <>
                        {appointment.type === "job" && <ScheduledCard key={`job-${appointment.id}`} job={appointment} />}
                        {appointment.type === "day-off" && <TimeOffCard key={`time-off-${appointment.id}`} timeOff={appointment} />}
                        {appointment.type === "availability-gap" && <AvailableSlotCard key={`availability-gap-${appointment.id}`} data={appointment} />}
                      </>
                    ))}
                  </div>

                  {(index !== 5) && <div className={styles.columnDivider} />}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Calendar