// src/context/ScheduleContext.js

import React, { createContext, useContext } from 'react';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';

const ScheduleContext = createContext();

export const ScheduleProvider = ({ children }) => {
  const fetchCleanerTimeOff = async (orgId, cleanerId) => {
    try {
      // Only fetch approved time off requests
      const timeOffRef = collection(db, 'organizations', orgId, 'cleaners', cleanerId, 'timeOffRequests');
      const q = query(timeOffRef, where('status', '==', 'approved'));
      const timeOffSnapshot = await getDocs(q);
      
      return timeOffSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
    } catch (error) {
      console.error('Error fetching time off requests:', error);
      return []; // Return empty array instead of throwing error
    }
  };

  const fetchAllCleaners = async (orgId) => {
    try {
      const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
      const cleanersSnapshot = await getDocs(cleanersRef);
      
      return cleanersSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(cleaner => cleaner.isActive); // Only return active cleaners
    } catch (error) {
      console.error('Error fetching cleaners:', error);
      return [];
    }
  };

  const isTimeBlockAvailable = (blockStart, blockEnd, jobs, timeOffRequests) => {
    // Check jobs conflicts
    const hasJobConflict = jobs.some(job => {
      const jobStart = moment(job.appointmentDate?.toDate());
      const jobEnd = moment(job.scheduledEndTime?.toDate());
      return blockStart.isBetween(jobStart, jobEnd, undefined, '[]') || 
             blockEnd.isBetween(jobStart, jobEnd, undefined, '[]') ||
             jobStart.isBetween(blockStart, blockEnd, undefined, '[]') ||
             jobEnd.isBetween(blockStart, blockEnd, undefined, '[]');
    });

    if (hasJobConflict) return false;

    // Check time off conflicts
    const hasTimeOffConflict = timeOffRequests.some(request => {
      const timeOffStart = moment(request.startTime?.toDate());
      const timeOffEnd = moment(request.endTime?.toDate());
      return blockStart.isBetween(timeOffStart, timeOffEnd, undefined, '[]') ||
             blockEnd.isBetween(timeOffStart, timeOffEnd, undefined, '[]') ||
             timeOffStart.isBetween(blockStart, blockEnd, undefined, '[]') ||
             timeOffEnd.isBetween(blockStart, blockEnd, undefined, '[]');
    });

    return !hasTimeOffConflict;
  };

  const calculateAvailableTimeBlocks = async (orgId, currentJob, allJobs, showAllCleaners = false) => {
    if (!orgId || !currentJob) return [];
  
    try {
      // Fetch organization settings
      const orgRef = doc(db, 'organizations', orgId);
      const orgDoc = await getDoc(orgRef);
      const scheduleSettings = orgDoc.exists() ? 
        orgDoc.data().scheduleSettings : 
        { minimumGapNotification: 180, driveTimeGap: 30, timeBlocks: [] };
  
      // Determine which cleaners to fetch
      let cleaners = [];
      if (showAllCleaners) {
        // Fetch all active cleaners
        const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
        const cleanersSnapshot = await getDocs(cleanersRef);
        cleaners = cleanersSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(cleaner => cleaner.isActive);
      } else {
        const currentCleanerRef = doc(db, 'organizations', orgId, 'cleaners', currentJob.cleanerId);
        const currentCleanerDoc = await getDoc(currentCleanerRef);
        if (currentCleanerDoc.exists()) {
          cleaners = [{
            id: currentJob.cleanerId,
            ...currentCleanerDoc.data()
          }];
        }
      }
  
      const blocks = [];
      // Calculate dates: today to 3 weeks ahead
      const startDate = moment().startOf('day');
      const totalDays = 21;
  
      // Process each cleaner
      for (const cleaner of cleaners) {
        let timeOffRequests = [];
        try {
          // Only fetch approved time off requests
          const timeOffRef = collection(db, 'organizations', orgId, 'cleaners', cleaner.id, 'timeOffRequests');
          const q = query(timeOffRef, where('status', '==', 'approved'));
          const timeOffSnapshot = await getDocs(q);
          timeOffRequests = timeOffSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
        } catch (error) {
          console.log(`Unable to fetch time off requests for cleaner ${cleaner.id}, continuing without them`);
        }
  
        // Get cleaner's jobs
        const cleanerJobs = allJobs.filter(job => 
          job.cleanerId === cleaner.id && 
          job.status !== 'cancelled' &&
          job.status !== 'completed'
        );
  
        // Look ahead for total days
        for (let i = 0; i < totalDays; i++) {
          const day = moment(startDate).add(i, 'days');
          const dayName = day.format('dddd').toLowerCase();
  
          // Skip if cleaner doesn't work this day
          if (!cleaner.workingHours?.[dayName]?.start || !cleaner.workingHours?.[dayName]?.end) {
            continue;
          }
  
          // Check for time off requests for this day
          const hasTimeOff = timeOffRequests.some(request => {
            const requestDate = moment(request.date.toDate()).startOf('day');
            return requestDate.isSame(day, 'day');
          });
  
          if (hasTimeOff) {
            continue; // Skip this day if cleaner has approved time off
          }
  
          // Use time blocks if defined, otherwise use working hours
          const timeBlocks = scheduleSettings.timeBlocks?.length > 0 ? 
            scheduleSettings.timeBlocks : 
            [{
              start: cleaner.workingHours[dayName].start,
              end: cleaner.workingHours[dayName].end
            }];
  
          timeBlocks.forEach(block => {
            const blockStart = moment(day)
              .set('hour', parseInt(block.start.split(':')[0]))
              .set('minute', parseInt(block.start.split(':')[1]));
            
            const blockEnd = moment(day)
              .set('hour', parseInt(block.end.split(':')[0]))
              .set('minute', parseInt(block.end.split(':')[1]));
  
            // Skip if block is in the past
            if (blockStart.isBefore(moment())) {
              return;
            }
  
            // Check for job conflicts
            const hasConflict = cleanerJobs.some(job => {
              const jobStart = moment(job.appointmentDate.toDate());
              const jobEnd = moment(job.scheduledEndTime.toDate());
  
              // Add drive time gap before and after job
              const jobStartWithGap = moment(jobStart).subtract(scheduleSettings.driveTimeGap, 'minutes');
              const jobEndWithGap = moment(jobEnd).add(scheduleSettings.driveTimeGap, 'minutes');
  
              // Check if block overlaps with job time (including drive time gaps)
              return blockStart.isBetween(jobStartWithGap, jobEndWithGap, undefined, '[]') || 
                     blockEnd.isBetween(jobStartWithGap, jobEndWithGap, undefined, '[]') ||
                     jobStartWithGap.isBetween(blockStart, blockEnd, undefined, '[]') ||
                     jobEndWithGap.isBetween(blockStart, blockEnd, undefined, '[]');
            });
  
            if (!hasConflict) {
              blocks.push({
                start: blockStart.toDate(),
                end: blockEnd.toDate(),
                cleanerId: cleaner.id,
                cleanerName: `${cleaner.firstName} ${cleaner.lastName}`
              });
            }
          });
        }
      }
  
      return blocks;
    } catch (error) {
      console.error('Error calculating available blocks:', error);
      return [];
    }
  };

  return (
    <ScheduleContext.Provider value={{ calculateAvailableTimeBlocks }}>
      {children}
    </ScheduleContext.Provider>
  );
};

export const useSchedule = () => useContext(ScheduleContext);