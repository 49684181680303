import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
  Alert,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { doc, writeBatch, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import styles from '../styles/ClientRequestModal.module.css';

const AdminCancellationModal = ({
  open,
  onClose,
  cancellationData,
  orgId
}) => {
  const [loading, setLoading] = useState(false);
  const [adminNotes, setAdminNotes] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [affectedJobs, setAffectedJobs] = useState([]);

  useEffect(() => {
    const fetchAffectedJobs = async () => {
      if (!cancellationData?.customerId || !orgId) return;
      
      try {
        const jobsRef = collection(db, 'organizations', orgId, 'jobs');
        const futureJobsQuery = query(
          jobsRef,
          where('customerId', '==', cancellationData.customerId),
          where('appointmentDate', '>=', new Date()),
          where('status', 'in', ['scheduled', 'confirmed'])
        );
        
        const snapshot = await getDocs(futureJobsQuery);
        const jobs = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setAffectedJobs(jobs.sort((a, b) => 
          moment(a.appointmentDate.toDate()).diff(moment(b.appointmentDate.toDate()))
        ));
      } catch (error) {
        console.error('Error fetching affected jobs:', error);
        setError('Failed to load affected appointments');
      }
    };

    if (open) {
      fetchAffectedJobs();
    }
  }, [cancellationData?.customerId, orgId, open]);

  const handleProcessCancellation = async () => {
    if (!cancellationData || !orgId) {
      setError('Missing required data');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    const batch = writeBatch(db);
    
    try {
      // 1. Update recurring service status
      if (cancellationData.recurringServiceId) {
        const recurringServiceRef = doc(
          db, 
          'organizations', 
          orgId, 
          'recurringServices', 
          cancellationData.recurringServiceId
        );
        batch.update(recurringServiceRef, {
          recurringStatus: 'Cancelled',
          cancelledAt: new Date(),
          cancelledBy: cancellationData.createdBy,
          cancellationReason: cancellationData.reason,
          cancellationFeedback: cancellationData.feedback,
          adminNotes: adminNotes,
          lastUpdated: new Date()
        });
      }

      // 2. Update all future jobs
      affectedJobs.forEach(job => {
        const jobRef = doc(db, 'organizations', orgId, 'jobs', job.id);
        batch.update(jobRef, {
          status: 'cancelled',
          cancelledAt: new Date(),
          cancelledBy: cancellationData.createdBy,
          cancellationReason: cancellationData.reason,
          cancellationFeedback: cancellationData.feedback,
          adminNotes: adminNotes,
          lastUpdated: new Date()
        });
      });

      // 3. Update cancellation request status
      const cancellationRef = doc(
        db, 
        'organizations', 
        orgId, 
        'cancellations', 
        cancellationData.id
      );
      batch.update(cancellationRef, {
        status: 'processed',
        processedAt: new Date(),
        processedBy: cancellationData.createdBy,
        adminNotes: adminNotes
      });

      // 4. Create customer notification
      const notificationsRef = collection(db, 'organizations', orgId, 'notifications');
      const notificationDoc = doc(notificationsRef);
      batch.set(notificationDoc, {
        userId: cancellationData.customerId,
        type: 'service_cancellation',
        title: 'Service Cancellation Processed',
        message: 'Your request to cancel services has been processed. All future appointments have been cancelled.',
        adminNotes: adminNotes,
        read: false,
        createdAt: new Date()
      });

      await batch.commit();
      
      setSuccessMessage('Service cancellation processed successfully');
      setTimeout(() => {
        setSuccessMessage('');
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error processing cancellation:', error);
      setError('Failed to process cancellation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!cancellationData) return null;

  return (
    <Dialog 
      open={open} 
      onClose={!loading ? onClose : undefined}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 1,
          bgcolor: 'background.paper'
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Typography variant="h6">
          Service Cancellation Request
        </Typography>
        <IconButton
          onClick={onClose}
          className={styles.closeButton}
          size="small"
          disabled={loading}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        {(error || successMessage) && (
          <Box sx={{ mb: 2 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
          </Box>
        )}

        <div className={styles.section}>
          <Typography className={styles.sectionTitle}>
            Client Details
          </Typography>
          <Typography>
            <span className={styles.infoLabel}>Client:</span>
            {cancellationData.customerName}
          </Typography>
          <Typography>
            <span className={styles.infoLabel}>Service Cost:</span>
            ${cancellationData.serviceCost?.toFixed(2)}
          </Typography>
          <Typography>
            <span className={styles.infoLabel}>Service Frequency:</span>
            {cancellationData.frequency}
          </Typography>
        </div>

        <div className={styles.section}>
          <Typography className={styles.sectionTitle}>
            Cancellation Reason
          </Typography>
          <Typography>
            <span className={styles.infoLabel}>Primary Reason:</span>
            {cancellationData.reason}
          </Typography>
          {cancellationData.cleanerIssues?.length > 0 && (
            <Typography>
              <span className={styles.infoLabel}>Cleaner Issues:</span>
              {cancellationData.cleanerIssues.join(', ')}
            </Typography>
          )}
          {cancellationData.feedback && (
            <Typography>
              <span className={styles.infoLabel}>Additional Feedback:</span>
              {cancellationData.feedback}
            </Typography>
          )}
        </div>

        <div className={styles.section}>
          <Typography className={styles.sectionTitle}>
            Affected Appointments ({affectedJobs.length})
          </Typography>
          {affectedJobs.map((job, index) => (
            <Typography key={job.id}>
              {moment(job.appointmentDate.toDate()).format('MMMM D, YYYY h:mm A')} - {job.cleanerName}
            </Typography>
          ))}
          {affectedJobs.length === 0 && (
            <Typography color="text.secondary">
              No future appointments found
            </Typography>
          )}
        </div>

        <div className={styles.section}>
          <Typography className={styles.sectionTitle}>
            Admin Notes
          </Typography>
          <TextField
            multiline
            rows={3}
            fullWidth
            value={adminNotes}
            onChange={(e) => setAdminNotes(e.target.value)}
            placeholder="Add any notes about this service cancellation..."
            disabled={loading}
            variant="outlined"
          />
        </div>
      </DialogContent>

      <DialogActions sx={{ 
        p: 2, 
        gap: 1,
        borderTop: 1,
        borderColor: 'divider'
      }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          color="inherit"
          disabled={loading}
        >
          CLOSE
        </Button>
        <Button
          variant="contained"
          onClick={handleProcessCancellation}
          disabled={loading}
        >
          {loading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={20} />
              <span>PROCESSING...</span>
            </Box>
          ) : (
            'PROCESS CANCELLATION'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminCancellationModal;