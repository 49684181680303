import { useContext } from "react";

import JobOverviewContext from "../context/JobOverviewContext";

export function useJobOverview() {
  const context = useContext(JobOverviewContext);
  if (!context) {
    throw new Error('useJobOverview must be used within a JobOverviewProvider');
  }
  return context;
}

export default useJobOverview;