import React, { useState, useEffect } from 'react';
import {
  Box, Card, CardContent, Tabs, Tab, Typography, TextField, Button, Dialog, DialogContent,
  DialogTitle, DialogContentText, DialogActions, Radio, RadioGroup, FormControlLabel,
  FormControl, FormLabel, Checkbox, Grid, Alert, Divider, styled, Table, TableBody,
  TableCell, TableRow, Chip, Tooltip
} from '@mui/material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useJobContext } from '../context/JobContext';
import moment from 'moment';
import CancellationModal from './CancellationModal';
import { formatRecurrenceRule } from '../utils/formatRecurrenceRule';

// TabPanel component for MUI tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`service-management-tabpanel-${index}`}
      aria-labelledby={`service-management-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4],
  }
}));

const ClientServiceManagement = ({ 
    customer, 
    recurringServices = [], 
    onUpdateCustomer, 
    orgId,
    clientPortalSettings
  }) => {
    // State Management
    const [activeTab, setActiveTab] = useState(0);
    const [cancelReason, setCancelReason] = useState('');
    const [cleanerMetrics, setCleanerMetrics] = useState({});
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cleaners, setCleaners] = useState([]);
    const [loadingCleaners, setLoadingCleaners] = useState(false);
    const { getCleanerMetrics } = useJobContext();
    const [formData, setFormData] = useState({
      companyName: customer.companyName || '',
      firstName: customer.firstName || '',
      lastName: customer.lastName || '',
      phone: customer.phone || '',
      secondaryContact: customer.secondaryContact || {
        name: '',
        phone: ''
      }
    });
  
    // Get the active recurring service
    const activeService = recurringServices.find(service => 
      service.recurringStatus?.toLowerCase() === 'active'
    );

    const fetchCleaners = async () => {
        if (!orgId) return;
        
        setLoadingCleaners(true);
        try {
          const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
          const cleanersQuery = query(
            cleanersRef,
            where('status', '==', 'active')
          );
          
          const cleanersSnapshot = await getDocs(cleanersQuery);
          const cleanersList = cleanersSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
    
          // Fetch metrics for each cleaner
          const metricsPromises = cleanersList.map(cleaner => 
            getCleanerMetrics(orgId, cleaner.id)
          );
          
          const metricsResults = await Promise.all(metricsPromises);
    
          const metricsMap = {};
          cleanersList.forEach((cleaner, index) => {
            metricsMap[cleaner.id] = metricsResults[index];
          });
    
          setCleaners(cleanersList);
          setCleanerMetrics(metricsMap);
        } catch (error) {
          console.error('Error fetching cleaners:', error);
        } finally {
          setLoadingCleaners(false);
        }
      };
    
      // Fetch cleaners when needed
      useEffect(() => {
        if (showCancelModal && cancelReason === 'cleaner') {
          fetchCleaners();
        }
      }, [showCancelModal, cancelReason]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSecondaryContactChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      secondaryContact: {
        ...prev.secondaryContact,
        [field]: value
      }
    }));
  };

  const handleCancelServices = () => {
    setShowCancelModal(true); // Just set the modal to show
  };

  const handleSaveContact = () => {
    onUpdateCustomer({
      ...customer,
      ...formData
    });
  };

  const handleCancellation = async (cancellationData) => {
    try {
      // Close the modal
      setShowCancelModal(false);
  
      // Refresh the customer data
      if (typeof onUpdateCustomer === 'function') {
        onUpdateCustomer(customer);
      }
    } catch (error) {
      console.error('Error processing cancellation:', error);
    }
  };

  const CancelServicesButton = () => {
    const tooltipMessage = !clientPortalSettings?.allowServiceCancellation
      ? (clientPortalSettings?.serviceCancellationLockedMessage || "Please contact our office to cancel your services")
      : "Cancel all recurring services";
  
    return (
      <Box sx={{ mt: 3 }}>
        <Tooltip title={tooltipMessage}>
          <span>
            <Button 
              variant="contained" 
              color="error" 
              fullWidth
              onClick={handleCancelServices}
              disabled={!clientPortalSettings?.allowServiceCancellation}
            >
              Cancel All Services
            </Button>
          </span>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto' }}>
      <StyledCard>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange} 
            aria-label="service management tabs"
          >
            <Tab label="Contact Information" />
            <Tab label="Service Information" />
            <Tab label="Billing Information" />
          </Tabs>
        </Box>

        {/* Contact Information Tab */}
        <TabPanel value={activeTab} index={0}>
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <TextField
                fullWidth
                label="Company Name"
                value={formData.companyName}
                onChange={(e) => handleInputChange('companyName', e.target.value)}
                placeholder="Company Name"
              />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    value={formData.firstName}
                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                    placeholder="First Name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    value={formData.lastName}
                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                    placeholder="Last Name"
                  />
                </Grid>
              </Grid>

              <TextField
                fullWidth
                label="Phone Number"
                value={formData.phone}
                onChange={(e) => handleInputChange('phone', e.target.value)}
                placeholder="Phone Number"
              />

              <TextField
                fullWidth
                label="Service Address"
                value={customer.serviceAddress}
                disabled
                sx={{ backgroundColor: 'action.hover' }}
              />

              <Divider sx={{ my: 2 }} />

              <Typography variant="h6">Secondary Contact</Typography>
              <TextField
                fullWidth
                label="Name"
                value={formData.secondaryContact.name}
                onChange={(e) => handleSecondaryContactChange('name', e.target.value)}
                placeholder="Secondary Contact Name"
              />
              <TextField
                fullWidth
                label="Phone Number"
                value={formData.secondaryContact.phone}
                onChange={(e) => handleSecondaryContactChange('phone', e.target.value)}
                placeholder="Secondary Contact Phone"
              />

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button variant="contained" onClick={handleSaveContact}>
                  Save Changes
                </Button>
              </Box>
            </Box>
          </CardContent>
        </TabPanel>

        {/* Service Information Tab */}
        <TabPanel value={activeTab} index={1}>
          <CardContent>
            {!activeService ? (
              <Alert severity="info" sx={{ mb: 3 }}>
                <Typography variant="subtitle1">
                  No Current or Past Recurring Services
                </Typography>
              </Alert>
            ) : (
              <Box>
                <Typography variant="h6" sx={{ mb: 3 }}>Recurring Service Details</Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: 'bold', width: '200px' }}>
                        Status
                      </TableCell>
                      <TableCell>
                        <Chip 
                          label={activeService.recurringStatus}
                          color={activeService.recurringStatus?.toLowerCase() === 'active' ? 'success' : 'default'}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: 'bold' }}>
                        Start Date
                      </TableCell>
                      <TableCell>
                        {moment(activeService.recurringStartDate?.toDate()).format('MMMM D, YYYY')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: 'bold' }}>
                        Service Cost
                      </TableCell>
                      <TableCell>
                        ${activeService.price?.toFixed(2) || '0.00'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: 'bold' }}>
                        Service Address
                      </TableCell>
                      <TableCell>
                        {activeService.address}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: 'bold' }}>
                        Assigned Cleaner
                      </TableCell>
                      <TableCell>
                        {activeService.cleanerName || 'Not Assigned'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: 'bold' }}>
                        Frequency
                      </TableCell>
                      <TableCell>
                        {formatRecurrenceRule(activeService.recurrenceRule)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: 'bold' }}>
                        Service Areas
                      </TableCell>
                      <TableCell>
                        <Box component="ul" sx={{ m: 0, pl: 2 }}>
                          {activeService.rooms?.map((room) => (
                            <Box component="li" key={room.id}>
                              {room.name} {room.quantity > 1 ? `(${room.quantity})` : ''}
                            </Box>
                          ))}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: 'bold' }}>
                        Service Notes
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ whiteSpace: 'pre-line' }}>
                          {activeService.notes}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            )}
          </CardContent>
        </TabPanel>

            {/* Billing Information Tab */}
            <TabPanel value={activeTab} index={2}>
            <CardContent>
            <Alert severity="info" sx={{ mb: 3 }}>
                <Typography variant="subtitle1">Coming Soon</Typography>
                <Typography>
                Credit card processing and billing preferences will be available here soon.
                </Typography>
            </Alert>

            <CancelServicesButton />
            </CardContent>
        </TabPanel>
      </StyledCard>

      {/* Cancellation Modal */}
      <CancellationModal
        open={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        customer={customer}
        orgId={orgId}
        cleaners={cleaners}
        onSaveCancellation={handleCancellation}
        clientPortalSettings={clientPortalSettings}
      />
    </Box>
  );
};

export default ClientServiceManagement;