// /src/components/ClientPortalWrapper.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { doc, collection, query, where, getDocs, getDoc, updateDoc } from 'firebase/firestore';
import ClientPortal from './ClientPortal';
import ClientHeader from './ClientHeader';

const ClientPortalWrapper = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [recurringServices, setRecurringServices] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [availableTimeBlocks, setAvailableTimeBlocks] = useState([]);
  const [clientPortalSettings, setClientPortalSettings] = useState(null);
  const [serviceAddOns, setServiceAddOns] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  // First, fetch organization and customer IDs
  useEffect(() => {
    const fetchIds = async () => {
      if (!user?.uid) return;
      
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        
        if (!userData?.orgId || !userData?.customerId) {
          throw new Error('Missing organization or customer ID');
        }

        setOrgId(userData.orgId);
        setCustomerId(userData.customerId);
      } catch (err) {
        console.error('Error fetching IDs:', err);
        setError(err.message);
      }
    };

    fetchIds();
  }, [user]);

  // Then fetch all related data once we have the IDs
  useEffect(() => {
    const fetchData = async () => {
      if (!orgId || !customerId) return;
      
      try {
        setLoading(true);

        // Get organization document
        const orgDoc = await getDoc(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          const orgData = orgDoc.data();
          
          // Get and set service add-ons
          if (orgData.clientPortalSettings) {
            setClientPortalSettings(orgData.clientPortalSettings);
            const addOns = orgData.clientPortalSettings?.serviceAddOns || [];
            const activeAddOns = addOns.filter(addOn => addOn.active);
            setServiceAddOns(activeAddOns);
          }

          // Get customer data
          const customerDoc = await getDoc(doc(db, 'organizations', orgId, 'customers', customerId));
          if (customerDoc.exists()) {
            setCustomerData({ id: customerDoc.id, ...customerDoc.data() });
          }

          // Get recurring services
          const recurringServicesQuery = query(
            collection(db, 'organizations', orgId, 'recurringServices'),
            where('customerId', '==', customerId),
            where('recurringStatus', '==', 'Active')
          );
          const recurringServicesSnapshot = await getDocs(recurringServicesQuery);
          const recurringServicesList = recurringServicesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setRecurringServices(recurringServicesList);

          // Get jobs
          const jobsQuery = query(
            collection(db, 'organizations', orgId, 'jobs'),
            where('customerId', '==', customerId)
          );
          const jobsSnapshot = await getDocs(jobsQuery);
          const jobsList = jobsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setJobs(jobsList);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [orgId, customerId, user.uid]);

  // Add a refresh function that can be passed to ClientPortal
  const refreshData = async () => {
    if (!orgId || !customerId) return;
    
    try {
      const servicesQuery = query(
        collection(db, 'organizations', orgId, 'recurringServices'),
        where('customerId', '==', customerId),
        where('recurringStatus', '==', 'active')
      );
      const servicesSnapshot = await getDocs(servicesQuery);
      setRecurringServices(servicesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));

      const jobsQuery = query(
        collection(db, 'organizations', orgId, 'jobs'),
        where('customerId', '==', customerId)
      );
      const jobsSnapshot = await getDocs(jobsQuery);
      setJobs(jobsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    } catch (err) {
      console.error('Error refreshing data:', err);
      setError(err.message);
    }
  };

  const handleCustomerUpdate = async (updatedCustomer) => {
    try {
      setCustomerData(prevData => ({
        ...prevData,
        ...updatedCustomer
      }));
    } catch (err) {
      console.error('Error updating customer data:', err);
      setError(err.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!customerData) {
    return <div>No customer data found</div>;
  }

  return (
    <>
      <ClientHeader 
        customer={customerData} 
        orgId={orgId} 
      />
      <ClientPortal
        customer={customerData}
        recurringServices={recurringServices}
        jobs={jobs}
        refreshData={refreshData}
        orgId={orgId}
        availableTimeBlocks={availableTimeBlocks}
        clientPortalSettings={clientPortalSettings}
        serviceAddOns={serviceAddOns}
        onUpdateCustomer={handleCustomerUpdate}
      />
    </>
  );
};

export default ClientPortalWrapper;