// src/context/JobContext.js

import React, { createContext, useContext, useState } from 'react';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  updateDoc, 
  doc, 
  serverTimestamp,
  deleteDoc,
  orderBy,
  writeBatch,
  getDoc,
} from 'firebase/firestore';
import { db } from '../firebase';
import { rrulestr } from 'rrule';

const JobContext = createContext();

export const useJobContext = () => useContext(JobContext);

// Job status constants to ensure consistency across the app
export const JOB_STATUSES = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled'
};

export const DEFAULT_STATUS_COLORS = {
  [JOB_STATUSES.PENDING]: '#f59e0b',      // amber
  [JOB_STATUSES.IN_PROGRESS]: '#3b82f6',  // blue
  [JOB_STATUSES.COMPLETED]: '#10b981',    // green
  [JOB_STATUSES.CANCELLED]: '#ef4444'      // red
};

  // Helper function to normalize status
  export const normalizeJobStatus = (status, customStatuses = []) => {
    if (!status) return JOB_STATUSES.PENDING;
  
    // First check if it's a custom status
    const customStatus = customStatuses.find(
      s => s.name.toLowerCase() === status.toLowerCase()
    );
    if (customStatus) {
      return customStatus.name;
    }
  
    // Then check default statuses
    const statusLower = status.toLowerCase();
    switch (statusLower) {
      case 'pending':
        return JOB_STATUSES.PENDING;
      case 'in progress':
      case 'in-progress':
      case 'inprogress':
        return JOB_STATUSES.IN_PROGRESS;
      case 'completed':
      case 'complete':
      case 'done':
        return JOB_STATUSES.COMPLETED;
      case 'cancelled':
      case 'canceled':
        return JOB_STATUSES.CANCELLED;
      default:
        return status; // Return original status if no match found
    }
  };


const calculateJobEfficiency = (scheduledMs, actualMs) => {
  return Math.round((scheduledMs / actualMs) * 10000) / 100;
};

const calculateWeekRange = () => {
  const today = new Date();
  const dayOfWeek = today.getUTCDay() || 7;
  const monday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
  
  monday.setUTCDate(today.getUTCDate() - (dayOfWeek - 2));
  
  if (dayOfWeek === 7) {
    monday.setUTCDate(monday.getUTCDate() + 1);
  }
  
  const startOfWeek = monday;
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 5); // 6 days from Monday
  endOfWeek.setUTCHours(23, 59, 59, 999);

  return { startOfWeek, endOfWeek };
};

export const JobProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);
  const [customJobStatuses, setCustomJobStatuses] = useState([]);

  const fetchCustomStatuses = async (orgId) => {
    try {
      const orgDoc = await getDoc(doc(db, 'organizations', orgId));
      if (orgDoc.exists()) {
        const scheduleSettings = orgDoc.data().scheduleSettings;
        if (scheduleSettings?.jobStatuses) {
          setCustomJobStatuses(scheduleSettings.jobStatuses);
        }
      }
    } catch (error) {
      console.error('Error fetching custom statuses:', error);
    }
  };

    // Helper function to ensure timestamp synchronization
    const synchronizeTimestamps = (data) => {
      const syncedData = { ...data };
      
      // Sync start times
      if (syncedData.startTime && !syncedData.actualStartTime) {
        syncedData.actualStartTime = syncedData.startTime;
      } else if (syncedData.actualStartTime && !syncedData.startTime) {
        syncedData.startTime = syncedData.actualStartTime;
      }
      
      // Sync end times
      if (syncedData.endTime && !syncedData.actualEndTime) {
        syncedData.actualEndTime = syncedData.endTime;
      } else if (syncedData.actualEndTime && !syncedData.endTime) {
        syncedData.endTime = syncedData.actualEndTime;
      }
      
      return syncedData;
    };

    const calculateDuration = (startTime, endTime) => {
      if (!startTime || !endTime) return null;
      
      const start = startTime.toDate ? startTime.toDate() : new Date(startTime);
      const end = endTime.toDate ? endTime.toDate() : new Date(endTime);
      
      return end.getTime() - start.getTime();
    };

    const fetchJobs = async (orgId, date) => {
      await fetchCustomStatuses(orgId);
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(
        jobsRef,
        where('appointmentDate', '>=', new Date(date)),
        where('appointmentDate', '<', new Date(new Date(date).setDate(new Date(date).getDate() + 1)))
      );
  
      const querySnapshot = await getDocs(q);
      const fetchedJobs = querySnapshot.docs.map(doc => {
        let data = doc.data();
        // Synchronize timestamps in fetched data
        data = synchronizeTimestamps(data);
        
        if (data.status === JOB_STATUSES.COMPLETED) {
          // Use either set of timestamps for duration calculation
          const startTimeToUse = data.actualStartTime || data.startTime;
          const endTimeToUse = data.actualEndTime || data.endTime;
          
          if (startTimeToUse && endTimeToUse) {
            const durationMs = calculateDuration(startTimeToUse, endTimeToUse);
            if (durationMs !== null) {
              data.duration = durationMs;
            }
          }
        }
        return {
          id: doc.id,
          ...data
        };
      });
  
      setJobs(fetchedJobs);
    };

    const startJob = async (orgId, jobId) => {
      try {
        const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
        const now = serverTimestamp();
        
        const updateData = {
          startTime: now,
          actualStartTime: now,
          status: JOB_STATUSES.IN_PROGRESS,
          updatedAt: serverTimestamp()
        };
    
        await updateDoc(jobRef, updateData);
    
        // Update local state
        setJobs(prevJobs => prevJobs.map(job => 
          job.id === jobId 
            ? { 
                ...job, 
                startTime: new Date(),
                actualStartTime: new Date(),
                status: JOB_STATUSES.IN_PROGRESS,
                updatedAt: new Date()
              } 
            : job
        ));
    
        return true;
      } catch (error) {
        console.error('Error starting job:', error);
        throw error;
      }
    };

    const completeJob = async (orgId, jobId) => {
      try {
        const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
        const jobSnap = await getDoc(jobRef);
        
        if (!jobSnap.exists()) {
          throw new Error('Job not found');
        }
    
        const jobData = synchronizeTimestamps(jobSnap.data());
        const now = serverTimestamp();
        
        // Use existing timestamps or create new ones
        const actualStartTime = jobData.actualStartTime || jobData.startTime || now;
        const actualEndTime = now;
        
        let scheduledDuration = null;
        let actualDuration = null;
        let efficiencyRating = null;
    
        if (jobData.appointmentDate && jobData.scheduledEndTime) {
          scheduledDuration = jobData.scheduledEndTime.toDate().getTime() - 
                             jobData.appointmentDate.toDate().getTime();
        }
    
        // Calculate durations using actualStartTime
        const startMs = actualStartTime.toDate ? actualStartTime.toDate().getTime() : new Date(actualStartTime).getTime();
        const endMs = actualEndTime.toDate ? actualEndTime.toDate().getTime() : new Date(actualEndTime).getTime();
    
        if (scheduledDuration && startMs && endMs) {
          actualDuration = endMs - startMs;
          if (actualDuration > 0) {
            efficiencyRating = calculateJobEfficiency(scheduledDuration, actualDuration);
          }
        }
    
        const updateData = {
          status: JOB_STATUSES.COMPLETED,
          startTime: actualStartTime,
          endTime: actualEndTime,
          actualStartTime: actualStartTime,
          actualEndTime: actualEndTime,
          actualDuration,
          scheduledDuration,
          efficiencyRating,
          updatedAt: serverTimestamp()
        };
    
        await updateDoc(jobRef, updateData);
    
        return true;
      } catch (error) {
        console.error('Error completing job:', error);
        throw error;
      }
    };

  const updateHistoricalDurations = async (orgId) => {
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(
        jobsRef,
        where('status', '==', JOB_STATUSES.COMPLETED)
      );

      const querySnapshot = await getDocs(q);
      const batch = writeBatch(db);
      let updateCount = 0;

      querySnapshot.docs.forEach(docSnapshot => {
        const data = docSnapshot.data();
        if (data.startTime && data.endTime) {
          const durationMs = calculateDuration(data.startTime, data.endTime);
          if (durationMs !== null) {
            const jobRef = doc(db, 'organizations', orgId, 'jobs', docSnapshot.id);
            batch.update(jobRef, { 
              duration: durationMs,
              updatedAt: serverTimestamp()
            });
            updateCount++;
          }
        }
      });

      if (updateCount > 0) {
        await batch.commit();
        await fetchJobs(orgId, new Date());
      }

      return updateCount;
    } catch (error) {
      console.error('Error updating historical durations:', error);
      throw error;
    }
  };
  
  const getCleanerMetrics = async (orgId, cleanerId) => {
    try {
      // Get cleaner document for absence information
      const cleanerRef = doc(db, 'organizations', orgId, 'cleaners', cleanerId);
      const cleanerDoc = await getDoc(cleanerRef);
      const cleanerData = cleanerDoc.exists() ? cleanerDoc.data() : {};
  
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      
      // Query for both old and new formats
      const oldFormatQuery = query(
        jobsRef,
        where('cleanerId', '==', cleanerId)
      );
      
      const newFormatQuery = query(
        jobsRef,
        where('cleaners', 'array-contains', cleanerId)
      );
      
      // Get jobs from both queries
      const [oldFormatSnapshot, newFormatSnapshot] = await Promise.all([
        getDocs(oldFormatQuery),
        getDocs(newFormatQuery)
      ]);
  
      // Combine jobs from both formats and remove duplicates
      const allJobs = [...oldFormatSnapshot.docs, ...newFormatSnapshot.docs]
        .reduce((unique, doc) => {
          if (!unique.find(item => item.id === doc.id)) {
            unique.push({
              id: doc.id,
              ...doc.data()
            });
          }
          return unique;
        }, []);
  
      // Get date ranges for filtering
      const now = new Date();
      const thisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);
      const twelveMonthsAgo = new Date();
      twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);
  
      // Calculate week range (Monday to Friday)
      const today = new Date();
      const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ...
      const mondayOffset = currentDay === 0 ? -6 : 1 - currentDay; // If Sunday, go back 6 days, otherwise get to Monday
      const fridayOffset = 5 - currentDay;
      
      const weekStart = new Date(today);
      weekStart.setDate(today.getDate() + mondayOffset);
      weekStart.setHours(0, 0, 0, 0);
      
      const weekEnd = new Date(today);
      weekEnd.setDate(today.getDate() + fridayOffset);
      weekEnd.setHours(23, 59, 59, 999);
  
      // Filter jobs by date ranges
      const thisMonthJobs = allJobs.filter(job => {
        const jobDate = job.appointmentDate.toDate();
        return jobDate >= thisMonth && jobDate <= now;
      });
  
      const lastMonthJobs = allJobs.filter(job => {
        const jobDate = job.appointmentDate.toDate();
        return jobDate >= lastMonth && jobDate <= lastMonthEnd;
      });
  
      const last12MonthsJobs = allJobs.filter(job => {
        const jobDate = job.appointmentDate.toDate();
        return jobDate >= twelveMonthsAgo && jobDate <= now;
      });
  
      // Get this week's jobs for projected hours
      const thisWeeksJobs = allJobs.filter(job => {
        if (job.status === JOB_STATUSES.CANCELLED) {
          return false;
        }
        const jobDate = job.appointmentDate.toDate();
        const isInRange = jobDate >= weekStart && jobDate <= weekEnd;
        return isInRange;
      });
  
  
      // Calculate client ratings
      const getClientRating = (jobs) => {
        const completedJobs = jobs.filter(job => 
          normalizeJobStatus(job.status) === JOB_STATUSES.COMPLETED
        );
        
        let totalRating = 0;
        let ratingCount = 0;
        
        completedJobs.forEach(job => {
          if (job.clientRating && job.clientRating > 0) {
            totalRating += job.clientRating;
            ratingCount++;
          }
        });
        return ratingCount > 0 ? Number((totalRating / ratingCount).toFixed(1)) : 0;
      };
  
      // Calculate ratings for different periods
      const allTimeRating = getClientRating(allJobs);
      const thisMonthRating = getClientRating(thisMonthJobs);
      const lastMonthRating = getClientRating(lastMonthJobs);

  
      // Calculate metrics for different time periods
      const calculateMetrics = (jobs) => {
        const completedJobs = jobs.filter(job => 
          normalizeJobStatus(job.status) === JOB_STATUSES.COMPLETED
        );
  
        // Unique clients
        const uniqueClients = new Set(jobs.map(job => job.customerName));
  
        // Calculate efficiency
        let totalEfficiency = 0;
        let validEfficiencyCount = 0;
        completedJobs.forEach(job => {
          if (job.startTime && job.endTime && job.appointmentDate && job.scheduledEndTime) {
            try {
              const scheduledDuration = job.scheduledEndTime.toDate().getTime() - 
                                      job.appointmentDate.toDate().getTime();
              const actualDuration = job.endTime.toDate().getTime() - 
                                   job.startTime.toDate().getTime();
              
              if (scheduledDuration > 0 && actualDuration > 0) {
                const efficiency = (scheduledDuration / actualDuration) * 100;
                if (efficiency > 0) {
                  totalEfficiency += efficiency;
                  validEfficiencyCount++;
                }
              }
            } catch (err) {
              console.warn('Error calculating efficiency for job:', job.id, err);
            }
          }
        });
  
        // Calculate on-time metrics and average duration
        let onTimeCount = 0;
        let lateCount = 0;
        let totalDuration = 0;
        let validDurationCount = 0;
  
        completedJobs.forEach(job => {
          try {
            // On-time calculation
            if (job.startTime && job.appointmentDate) {
              const startTime = job.startTime.toDate().getTime();
              const scheduledTime = job.appointmentDate.toDate().getTime();
              if (startTime <= scheduledTime) {
                onTimeCount++;
              } else {
                lateCount++;
              }
            }
  
            // Duration calculation
            if (job.startTime && job.endTime) {
              const duration = (job.endTime.toDate().getTime() - 
                              job.startTime.toDate().getTime()) / (1000 * 60 * 60);
              if (duration > 0) {
                totalDuration += duration;
                validDurationCount++;
              }
            }
          } catch (err) {
            console.warn('Error calculating job metrics:', job.id, err);
          }
        });
  
        // Count absences
        const absences = jobs.filter(job => job.status === 'Absent').length;
  
        return {
          totalCompletedJobs: completedJobs.length,
          totalClients: uniqueClients.size,
          avgEfficiency: validEfficiencyCount > 0 
            ? Number((totalEfficiency / validEfficiencyCount).toFixed(1)) 
            : 0,
          onTimeRate: completedJobs.length > 0 
            ? Number(((onTimeCount / completedJobs.length) * 100).toFixed(1)) 
            : 0,
          avgJobDuration: validDurationCount > 0 
            ? Number((totalDuration / validDurationCount).toFixed(1)) 
            : 0,
          absences,
          lateCount
        };
      };
  
      // Calculate projected hours for this week
      const projectedHours = thisWeeksJobs.reduce((total, job) => {
        try {
          if (job.scheduledDuration) {
            const hours = job.scheduledDuration / (1000 * 60 * 60);
            if (isFinite(hours) && hours > 0) {
              return total + hours;
            }
          }
          
          if (job.appointmentDate && job.scheduledEndTime) {
            const start = job.appointmentDate.toDate();
            const end = job.scheduledEndTime.toDate();
            const hours = (end.getTime() - start.getTime()) / (1000 * 60 * 60);
            if (isFinite(hours) && hours > 0) {
              return total + hours;
            }
          }
          
          return total;
        } catch (err) {
          console.warn('Error calculating hours for job:', job.id, err);
          return total;
        }
      }, 0);
  
      // Calculate metrics for different time periods
      const allTimeMetrics = calculateMetrics(allJobs);
      const thisMonthMetrics = calculateMetrics(thisMonthJobs);
      const lastMonthMetrics = calculateMetrics(lastMonthJobs);
      const last12MonthsMetrics = calculateMetrics(last12MonthsJobs);
  
      // Calculate trends
      const calculateTrend = (current, previous) => {
        if (previous === 0) return current;
        return Number((current - previous).toFixed(1));
      };
  
      return {
        totalCompletedJobs: {
          allTime: allTimeMetrics.totalCompletedJobs,
          trend: calculateTrend(thisMonthMetrics.totalCompletedJobs, lastMonthMetrics.totalCompletedJobs)
        },
        totalClients: {
          allTime: allTimeMetrics.totalClients,
          trend: calculateTrend(thisMonthMetrics.totalClients, lastMonthMetrics.totalClients)
        },
        avgEfficiency: {
          allTime: allTimeMetrics.avgEfficiency,
          trend: calculateTrend(thisMonthMetrics.avgEfficiency, lastMonthMetrics.avgEfficiency)
        },
        onTimeRate: {
          allTime: allTimeMetrics.onTimeRate,
          trend: calculateTrend(thisMonthMetrics.onTimeRate, lastMonthMetrics.onTimeRate)
        },
        absences: {
          allTime: last12MonthsMetrics.absences,
          trend: calculateTrend(thisMonthMetrics.absences, lastMonthMetrics.absences)
        },
        clientRating: {
          allTime: allTimeRating,
          trend: calculateTrend(thisMonthRating, lastMonthRating)
        },
        avgJobDuration: allTimeMetrics.avgJobDuration,
        lateCount: allTimeMetrics.lateCount,
        projectedHours: {
          allTime: Number(projectedHours.toFixed(1)),
          trend: null 
        }
      };
  
    } catch (error) {
      console.error('Error calculating cleaner metrics:', error);
      throw error;
    }
  };

  const handleDeleteJob = async (orgId, jobId) => {
    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
      await deleteDoc(jobRef);
      return true;
    } catch (error) {
      console.error('Error deleting job:', error);
      throw error;
    }
  };

  const handleDeleteRecurringJob = async (orgId, recurrenceGroupId) => {
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(jobsRef, where('recurrenceGroupId', '==', recurrenceGroupId));
      const querySnapshot = await getDocs(q);
      
      const batch = writeBatch(db);
      querySnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      
      await batch.commit();
      return true;
    } catch (error) {
      console.error('Error deleting recurring jobs:', error);
      throw error;
    }
  };

  const fetchRecurringInstances = async (orgId, recurrenceGroupId) => {
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(
        jobsRef, 
        where('recurrenceGroupId', '==', recurrenceGroupId),
        orderBy('appointmentDate', 'asc')
      );
      const querySnapshot = await getDocs(q);
      
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error fetching recurring instances:', error);
      throw error;
    }
  };

  const generateMoreInstances = async (orgId, job) => {
    try {
      if (!job.recurring || !job.recurrenceRule) {
        throw new Error('Job is not recurring or missing recurrence rule');
      }

      // Parse the appointment date
      const startDate = job.appointmentDate.toDate();
      const duration = job.scheduledDuration;
      
      // Create RRule instance
      const rruleString = `DTSTART:${startDate.toISOString().replace(/[-:]/g, '').split('.')[0]}Z\nRRULE:${job.recurrenceRule}`;
      const rule = rrulestr(rruleString);

      // Generate dates for the next 6 months
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 6);
      
      const dates = rule.between(startDate, endDate);
      const batch = writeBatch(db);
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');

      dates.forEach((date) => {
        if (date.getTime() === startDate.getTime()) return;

        const newJobRef = doc(jobsRef);
        const jobData = {
          ...job,
          id: newJobRef.id,
          appointmentDate: date,
          scheduledEndTime: new Date(date.getTime() + duration),
          status: 'pending',
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };

        delete jobData.startTime;
        delete jobData.endTime;
        delete jobData.duration;
        delete jobData.actualDuration;
        delete jobData.efficiencyRating;

        batch.set(newJobRef, jobData);
      });

      await batch.commit();
      return dates.length - 1;
    } catch (error) {
      console.error('Error generating instances:', error);
      throw error;
    }
  };

  return (
    <JobContext.Provider value={{ 
      jobs, 
      fetchJobs, 
      startJob, 
      completeJob,
      updateHistoricalDurations,
      getCleanerMetrics,
      handleDeleteJob,           // Add these new functions
      handleDeleteRecurringJob,  // to the provider value
      fetchRecurringInstances,
      generateMoreInstances,
      JOB_STATUSES,
      fetchCustomStatuses,
      normalizeJobStatus: (status) => normalizeJobStatus(status, customJobStatuses)
    }}>
      {children}
    </JobContext.Provider>
  );
};

export default JobContext;