// src/components/ClientPortal.js

import React, { useState, useEffect } from 'react';
import { 
  Box, Card, CardContent, Typography, Grid, Chip, Divider, TextField, Button, Stack,
  Tooltip, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Snackbar, Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { doc, updateDoc, collection, addDoc, getDoc, getDocs } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { getAuth } from 'firebase/auth';
import { styled } from '@mui/material/styles';
import ClientServiceManagement from './ClientServiceManagement';
import RescheduleModal from './RescheduleModal';
import ServiceAddOnsModal from './ServiceAddOnsModal';
import { DEFAULT_JOB_STATUSES } from '../constants/statuses';
import moment from 'moment';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground)' : 'var(--light-foreground)',
  color: theme.palette.mode === 'dark' ? 'var(--dark-text)' : 'var(--light-text)',
  boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-md-dark)' : 'var(--shadow-md-light)',
  borderRadius: 'var(--border-radius-base)',
  transition: 'all var(--transition-duration) var(--transition-ease)',
  marginBottom: theme.spacing(3),
  '&:hover': {
    boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-lg-dark)' : 'var(--shadow-lg-light)',
  }
}));

const StyledMetricBox = styled(Box)(({ theme }) => ({
  padding: '16px',
  borderRadius: 'var(--border-radius-base)',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'var(--dark-foreground-accent)' 
    : 'var(--light-foreground-accent)',
  boxShadow: theme.palette.mode === 'dark'
    ? 'var(--shadow-md-dark)'
    : 'var(--shadow-md-light)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '200px',
  flex: 1,
  transition: 'all 0.2s ease',
  '&:hover': {
    boxShadow: theme.palette.mode === 'dark'
      ? 'var(--shadow-lg-dark)'
      : 'var(--shadow-lg-light)'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`client-portal-tabpanel-${index}`}
      aria-labelledby={`client-portal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ClientPortal = ({ customer, jobs = [], orgId, clientPortalSettings, serviceAddOns, onCustomerUpdate, recurringServices }) => {
  const [error, setError] = useState(null);
  const [notesMap, setNotesMap] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalMode, setModalMode] = useState('reschedule');
  const [isAddOnsModalOpen, setIsAddOnsModalOpen] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState('future');
  const [activeTab, setActiveTab] = useState(0);
  const [cleanersMap, setCleanersMap] = useState({});

  const fetchCleaners = async () => {
    if (!orgId) return;
    
    try {
      const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
      const cleanersSnapshot = await getDocs(cleanersRef);
      const cleanersData = {};
      
      cleanersSnapshot.docs.forEach(doc => {
        const cleanerData = doc.data();
        cleanersData[doc.id] = {
          id: doc.id,
          firstName: cleanerData.firstName || '',
          lastName: cleanerData.lastName || '',
          email: cleanerData.email || '',
          displayName: cleanerData.displayName || '',
          name: cleanerData.firstName && cleanerData.lastName 
            ? `${cleanerData.firstName} ${cleanerData.lastName}`
            : cleanerData.displayName || cleanerData.name
        };
      });
      
      setCleanersMap(cleanersData);
    } catch (error) {
      console.error('Error fetching cleaners:', error);
    }
  };

  useEffect(() => {
    if (orgId) {
      fetchCleaners();
    }
  }, [orgId]);

  const getCleanerDisplayName = (job) => {
    if (!job) return 'TBD';
    
    // First try to get cleaner info from cleanerDetails
    if (Array.isArray(job.cleanerDetails)) {
      const cleanerNames = job.cleanerDetails
        .map(cleaner => cleanersMap[cleaner.id]?.name)
        .filter(Boolean);
      
      if (cleanerNames.length > 0) {
        return cleanerNames.join(', ');
      }
    }

    // Then try cleaners array
    if (Array.isArray(job.cleaners)) {
      const cleanerNames = job.cleaners
        .map(cleaner => cleanersMap[cleaner.id]?.name)
        .filter(Boolean);
      
      if (cleanerNames.length > 0) {
        return cleanerNames.join(', ');
      }
    }

    // Finally try single cleanerId
    if (job.cleanerId && cleanersMap[job.cleanerId]) {
      return cleanersMap[job.cleanerId].name;
    }

    return 'TBD';
  };

  // Filter and sort active jobs
  const activeJobs = jobs
    .filter(job => {
      const jobDate = job.appointmentDate?.toDate?.() || job.appointmentDate;
      return moment(jobDate).isAfter(new Date()) && 
             job.status?.toLowerCase() !== 'cancelled' &&
             job.status?.toLowerCase() !== 'completed';
    })
    .map(job => ({
      ...job,
      cleanerDisplayName: getCleanerDisplayName(job)
    }))
    .sort((a, b) => {
      const dateA = a.appointmentDate?.toDate?.() || a.appointmentDate;
      const dateB = b.appointmentDate?.toDate?.() || b.appointmentDate;
      return moment(dateA).valueOf() - moment(dateB).valueOf();
    });

  // Filter and sort past appointments
  const pastAppointments = jobs
    .filter(job => {
      const jobDate = job.appointmentDate?.toDate?.() || job.appointmentDate;
      return moment(jobDate).isBefore(new Date()) || 
             job.status?.toLowerCase() === 'completed';
    })
    .map(job => ({
      ...job,
      cleanerDisplayName: getCleanerDisplayName(job)
    }))
    .sort((a, b) => {
      const dateA = a.appointmentDate?.toDate?.() || a.appointmentDate;
      const dateB = b.appointmentDate?.toDate?.() || b.appointmentDate;
      return moment(dateB).valueOf() - moment(dateA).valueOf();
    });

  const nextAppointment = activeJobs[0];
  const futureAppointments = activeJobs.slice(1);

    // Calculate metrics
    const metrics = React.useMemo(() => {
      const completedJobs = jobs.filter(job => 
        job.status?.toLowerCase() === 'completed'
      );
  
      const canceledJobs = jobs.filter(job => 
        job.status?.toLowerCase() === 'cancelled'
      );
  
      const calculateDuration = (job) => {
        const start = job.appointmentDate?.toDate?.() || job.appointmentDate;
        const end = job.scheduledEndTime?.toDate?.() || job.scheduledEndTime;
        return end && start ? (end - start) / (1000 * 60 * 60) : 0; // Convert to hours
      };
  
      const totalDurationCompleted = completedJobs.reduce((total, job) => 
        total + calculateDuration(job), 0
      );
  
      const averageDuration = jobs.length > 0 
        ? jobs.reduce((total, job) => total + calculateDuration(job), 0) / jobs.length 
        : 0;
  
      return {
        completedCount: completedJobs.length,
        hoursSaved: totalDurationCompleted,
        averageDuration: averageDuration,
        cancelations: canceledJobs.length
      };
    }, [jobs]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCustomerUpdate = async (updatedCustomer) => {
    if (!orgId) return;
    
    setIsLoading(true);
    try {
      const customerRef = doc(db, 'organizations', orgId, 'customers', customer.id);
      await updateDoc(customerRef, updatedCustomer);
      
      setSuccessMessage('Customer information updated successfully!');
      if (onCustomerUpdate) {
        onCustomerUpdate(updatedCustomer);
      }
    } catch (err) {
      console.error('Error updating customer:', err);
      setError('Failed to update customer information. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  const handleRescheduleClick = () => {
    setModalMode('reschedule');
    setIsRescheduleModalOpen(true);
  };

  const handleCancelClick = () => {
    setModalMode('cancel');
    setIsRescheduleModalOpen(true);
  };

  const submitClientRequest = async (selectedSlot) => {
    if (!nextAppointment || !orgId) return;
    
    setIsLoading(true);
    try {
      const auth = getAuth();
      const userId = auth.currentUser?.uid;
      
      if (!userId) {
        throw new Error('User not authenticated');
      }
  
      const clientRequestsRef = collection(db, 'organizations', orgId, 'clientRequests');
      
      // Get proper cleaner name for current cleaner
      const currentCleanerName = getCleanerDisplayName(nextAppointment);
      
      // Base request data matching the exact structure
      const baseRequestData = {
        jobId: nextAppointment.id,
        customerId: userId,
        customerName: `${customer.firstName} ${customer.lastName}`.trim(),
        address: nextAppointment.address || '',
        currentAppointmentDate: nextAppointment.appointmentDate,
        currentEndTime: nextAppointment.scheduledEndTime,
        currentCleanerId: nextAppointment.cleanerDetails?.[0]?.id || nextAppointment.cleanerId,
        currentCleanerName: currentCleanerName,
        orgId,
        createdAt: new Date(),
        createdBy: userId,
        clientNotes: selectedSlot.notes || '',
        adminNotes: '',
        status: 'pending',
        read: false,
        type: selectedSlot.type
      };
  
      let requestData;
  
      if (selectedSlot.type === 'manual') {
        requestData = {
          ...baseRequestData,
          preferredDate: selectedSlot.preferredDate ? moment(selectedSlot.preferredDate).toDate() : null,
          preferredTimeRange: selectedSlot.preferredTimeRange || '',
          requestedDate: null,
          requestedEndTime: null,
          newCleanerId: null,
          newCleanerName: ''
        };
      } else {
        // For automatic scheduling, ensure we're using proper cleaner info
        const newCleanerName = selectedSlot.cleanerName?.includes('@') ? 'TBD' : selectedSlot.cleanerName;
        requestData = {
          ...baseRequestData,
          requestedDate: selectedSlot.start || selectedSlot.requestedDate,
          requestedEndTime: selectedSlot.end || selectedSlot.requestedEndTime,
          newCleanerId: selectedSlot.cleanerId || selectedSlot.newCleanerId,
          newCleanerName: newCleanerName
        };
      }
  
      // ... rest of the submitClientRequest function remains the same ...
    } catch (err) {
      console.error('Error submitting reschedule request:', err);
      setError(
        `Failed to submit reschedule request: ${err.message}. Please try again or contact support.`
      );
    } finally {
      setIsLoading(false);
    }
  };
  
  // Replace the existing handleRescheduleConfirm with this:
  const handleRescheduleConfirm = async (selectedSlot) => {
    await submitClientRequest(selectedSlot);
  };

  const saveImportantNotes = async (jobId) => {
    if (!orgId || !jobId) {
      setError('Missing required information');
      return;
    }
  
    setIsLoading(true);
    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
      await updateDoc(jobRef, {
        importantNotes: notesMap[jobId] || '',
        lastUpdated: new Date()
      });
  
      setSuccessMessage('Notes saved successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      console.error('Error details:', err);
      setError(`Failed to save notes: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotesChange = (jobId, value) => {
    setNotesMap((prev) => ({
      ...prev,
      [jobId]: value
    }));
  };

  const handleAddOnsSubmit = async (submitData) => {
    if (!nextAppointment?.id) return;
    
    setIsLoading(true);
    try {
      // Always update the job with selected services
      const jobRef = doc(db, 'organizations', orgId, 'jobs', nextAppointment.id);
      await updateDoc(jobRef, {
        addOns: submitData.selectedAddOns,
        serviceCost: submitData.totalCost,
        updatedAt: new Date(),
        updatedBy: auth.currentUser.uid,
        addOnNotes: submitData.notes || ''
      });
  
      // If there's a custom request, create a notification
      if (submitData.hasCustomRequest) {
        const clientRequestsRef = collection(db, 'organizations', orgId, 'clientRequests');
        await addDoc(clientRequestsRef, {
          type: 'custom_addon',
          status: 'pending',
          jobId: nextAppointment.id,
          customerId: auth.currentUser.uid,
          customerName: `${customer.firstName} ${customer.lastName}`.trim(),
          appointmentDate: nextAppointment.appointmentDate,
          customRequest: submitData.customRequest.description,
          notes: submitData.customRequest.notes,
          selectedAddOns: submitData.selectedAddOns, // Include for context
          currentTotal: submitData.totalCost,
          createdAt: new Date(),
          read: false
        });
  
        setSuccessMessage(
          'Services updated successfully. Our team will review your custom service request and contact you soon.'
        );
      } else {
        setSuccessMessage('Additional services added successfully!');
      }
  
      setIsAddOnsModalOpen(false);
    } catch (error) {
      console.error('Error updating services:', error);
      setError('Failed to update services');
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusInfo = (status) => {
    const statusConfig = DEFAULT_JOB_STATUSES.find(
      s => s.name.toLowerCase() === (status || '').toLowerCase()
    ) || {
      name: 'Scheduled',
      color: '#3b82f6',
      description: 'Job is scheduled'
    };
  
    return statusConfig;
  };

  const getStatusChipColor = (status) => {
    const statusInfo = getStatusInfo(status);
    return {
      backgroundColor: statusInfo.color,
      color: '#ffffff'
    };
  };

  const isModificationLocked = (appointmentDate, lockDays) => {
    try {
      if (!appointmentDate || typeof lockDays !== 'number') return false;
      
      const now = moment();
      const appointment = moment(appointmentDate?.toDate?.() || appointmentDate);
      
      if (!appointment.isValid()) return false;
      
      const daysDiff = appointment.diff(now, 'days', true);
      return daysDiff <= lockDays;
    } catch (error) {
      console.error('Error checking modification lock:', error);
      return false; // Fail safe - allow modification if there's an error
    }
  };
  
  const getModificationButtonTooltip = (action, isLocked, settings) => {
    // If settings aren't loaded yet, show a loading message
    if (!settings) return 'Loading settings...';
    
    // Check if the action is allowed
    const isAllowed = settings[`allow${action}`];
    if (!isAllowed) {
      return `${action} is not available through the portal. Please contact our office.`;
    }
    
    // If locked, show the custom message or default
    if (isLocked) {
      return settings.modificationLockedMessage || 
             `Please contact our office to ${action.toLowerCase()} your appointment`;
    }
    
    // Default tooltip for enabled buttons
    return `${action} this appointment`;
  };

  if (!nextAppointment) {
    return (
      <StyledCard>
        <CardContent>
          <Typography>No upcoming appointments scheduled.</Typography>
        </CardContent>
      </StyledCard>
    );
  }

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 3 }}>
      {/* Error Snackbar */}
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
      >
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      {/* Success Snackbar */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Main content */}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            aria-label="client portal tabs"
          >
            <Tab label="Your Services" />
            <Tab label="Service Management" />
          </Tabs>
        </Box>

        {/* Services Tab */}
        <TabPanel value={activeTab} index={0}>
          <Typography variant="h4" sx={{ mb: 4 }}>
            Your Services
          </Typography>

     {/* Metrics Section */}
     <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledMetricBox>
            <Typography color="textSecondary" variant="body2">Jobs Completed</Typography>
            <Typography variant="h4" sx={{ mt: 1, fontWeight: 'bold' }}>
              {metrics.completedCount}
            </Typography>
          </StyledMetricBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StyledMetricBox>
            <Typography color="textSecondary" variant="body2">Hours Saved</Typography>
            <Typography variant="h4" sx={{ mt: 1, fontWeight: 'bold' }}>
              {metrics.hoursSaved.toFixed(1)}
            </Typography>
          </StyledMetricBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StyledMetricBox>
            <Typography color="textSecondary" variant="body2">Avg. Duration</Typography>
            <Typography variant="h4" sx={{ mt: 1, fontWeight: 'bold' }}>
              {metrics.averageDuration.toFixed(1)}h
            </Typography>
          </StyledMetricBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StyledMetricBox>
            <Typography color="textSecondary" variant="body2">Cancelations</Typography>
            <Typography variant="h4" sx={{ mt: 1, fontWeight: 'bold' }}>
              {metrics.cancelations}
            </Typography>
          </StyledMetricBox>
        </Grid>
      </Grid>

      {/* Next Appointment Card */}
      <StyledCard>
        <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5">Next Appointment</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {/* Reschedule Button */}
            {(clientPortalSettings?.allowReschedule ?? true) && (
              <Tooltip title={getModificationButtonTooltip(
                'Reschedule',
                isModificationLocked(
                  nextAppointment.appointmentDate,
                  Number(clientPortalSettings?.rescheduleLockDays)
                ),
                clientPortalSettings
              )}>
                <span>
                  <Button 
                    variant="contained" 
                    onClick={handleRescheduleClick}
                    disabled={
                      isLoading || 
                      isModificationLocked(
                        nextAppointment.appointmentDate,
                        Number(clientPortalSettings?.rescheduleLockDays)
                      )
                    }
                  >
                    Reschedule
                  </Button>
                </span>
              </Tooltip>
            )}
            
            {/* Cancel Button */}
            {(clientPortalSettings?.allowCancel ?? true) && (
              <Tooltip title={getModificationButtonTooltip(
                'Cancel',
                isModificationLocked(
                  nextAppointment.appointmentDate,
                  Number(clientPortalSettings?.cancelLockDays)
                ),
                clientPortalSettings
              )}>
                <span>
                  <Button 
                    variant="contained" 
                    color="error"
                    onClick={handleCancelClick}
                    disabled={
                      isLoading || 
                      isModificationLocked(
                        nextAppointment.appointmentDate,
                        Number(clientPortalSettings?.cancelLockDays)
                      )
                    }
                  >
                    Cancel
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
        </Box>

          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={6} md={3}>
              <StyledMetricBox>
                <Typography color="textSecondary" variant="body2">Date</Typography>
                <Typography variant="h6" sx={{ mt: 1 }}>
                  {moment(nextAppointment.appointmentDate?.toDate()).format('MMM D, YYYY')}
                </Typography>
              </StyledMetricBox>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <StyledMetricBox>
                <Typography color="textSecondary" variant="body2">Time</Typography>
                <Typography variant="h6" sx={{ mt: 1 }}>
                  {moment(nextAppointment.appointmentDate?.toDate()).format('h:mm A')} -
                  {moment(nextAppointment.scheduledEndTime?.toDate()).format('h:mm A')}
                </Typography>
              </StyledMetricBox>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <StyledMetricBox>
                <Typography color="textSecondary" variant="body2">Cleaner</Typography>
                <Typography variant="h6" sx={{ mt: 1 }}>
                  {nextAppointment.cleanerDisplayName}
                </Typography>
              </StyledMetricBox>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <StyledMetricBox>
                <Typography color="textSecondary" variant="body2">Status</Typography>
                <Tooltip title={getStatusInfo(nextAppointment.status).description}>
                  <Chip
                    label={getStatusInfo(nextAppointment.status).name}
                    sx={{ 
                      mt: 1,
                      bgcolor: getStatusInfo(nextAppointment.status).color,
                      color: '#ffffff'
                    }}
                  />
                </Tooltip>
              </StyledMetricBox>
            </Grid>
          </Grid>

          {/* Appointment Details */}
          <Divider sx={{ mb: 3 }} />
          <Typography variant="h6" sx={{ mb: 2 }}>
            Appointment Details
          </Typography>

          {/* Date */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography color="textSecondary">Date</Typography>
            <Typography sx={{ fontWeight: 500 }}>
              {moment(nextAppointment.appointmentDate?.toDate?.() || nextAppointment.appointmentDate).format('MMM DD, YYYY')}
            </Typography>
          </Box>
          <Divider />

          {/* Scheduled Time */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 1 }}>
            <Typography color="textSecondary">Scheduled Time</Typography>
            <Typography sx={{ fontWeight: 500 }}>
              {moment(nextAppointment.appointmentDate?.toDate?.() || nextAppointment.appointmentDate).format('h:mm A')} - 
              {moment(nextAppointment.scheduledEndTime?.toDate?.() || nextAppointment.scheduledEndTime).format('h:mm A')}
            </Typography>
          </Box>
          <Divider />

          {/* Scheduled Time */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 1 }}>
            <Typography color="textSecondary">Service Cost</Typography>
            <Typography sx={{ fontWeight: 500 }}>
              ${nextAppointment?.serviceCost?.toFixed(2) || '0.00'}
            </Typography>
          </Box>
          <Divider />

          {/* Cleaner */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 1 }}>
            <Typography color="textSecondary">Cleaner</Typography>
            <Typography sx={{ fontWeight: 500 }}>
              {nextAppointment.cleanerDisplayName}
            </Typography>
          </Box>
          <Divider />

          {/* Rooms */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 1 }}>
            <Typography color="textSecondary">Rooms</Typography>
            <Typography sx={{ fontWeight: 500 }}>
              {Array.isArray(nextAppointment.rooms) && nextAppointment.rooms.length > 0
                ? nextAppointment.rooms.map((room) => room.name).join(', ')
                : 'N/A'}
            </Typography>
          </Box>
          <Divider />

          {/* Add-ons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 1 }}>
          <Typography color="textSecondary">Add-ons</Typography>
          {nextAppointment?.addOns?.length > 0 ? (
            <Typography sx={{ fontWeight: 500 }}>
              {nextAppointment.addOns.map((addOn) => addOn.name).join(', ')}
            </Typography>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontWeight: 500, color: 'text.secondary' }}>
                Need Additional Services?
              </Typography>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setIsAddOnsModalOpen(true)}
                disabled={isLoading}
                sx={{ minWidth: 'auto', py: 0.5 }}
              >
                Add Services
              </Button>
            </Box>
          )}
        </Box>
          <Divider />

          {/* Internal notes */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 1 }}>
            <Typography color="textSecondary">Company Notes</Typography>
            <Typography sx={{ fontWeight: 500 }}>
              {nextAppointment.notes || 'N/A'}
            </Typography>
          </Box>
          <Divider />

          <Box sx={{ mt: 3 }}>
            <Typography color="textSecondary" sx={{ mb: 1 }}>
              Your Notes / Special Instructions
            </Typography>
            <TextField
              multiline
              rows={3}
              fullWidth
              value={notesMap[nextAppointment.id] !== undefined ? 
                notesMap[nextAppointment.id] : 
                (nextAppointment.importantNotes || '')}
              onChange={(e) => handleNotesChange(nextAppointment.id, e.target.value)}
              disabled={isLoading}
              sx={{ mb: 2 }}
            />
            <Box sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                onClick={() => saveImportantNotes(nextAppointment.id)}
                disabled={isLoading}
              >
                Save Notes
              </Button>
            </Box>
          </Box>
        </CardContent>
      </StyledCard>

      {/* Appointments Accordions */}
      <Box sx={{ mt: 3 }}>
        {/* Future Appointments Accordion */}
        {futureAppointments.length > 0 && (
          <Accordion 
            expanded={expandedAccordion === 'future'} 
            onChange={handleAccordionChange('future')}
            sx={{ 
              mb: 2,
              backgroundColor: theme => theme.palette.mode === 'dark' ? 'var(--dark-foreground)' : 'var(--light-foreground)',
              boxShadow: theme => theme.palette.mode === 'dark' ? 'var(--shadow-md-dark)' : 'var(--shadow-md-light)',
              '&:before': { display: 'none' },
              borderRadius: 'var(--border-radius-base) !important',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                borderRadius: 'var(--border-radius-base)',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography variant="h6">Future Appointments</Typography>
                <Chip 
                  label={`${futureAppointments.length} Upcoming`} 
                  size="small"
                  sx={getStatusChipColor('Scheduled')}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2} divider={<Divider />}>
                {futureAppointments.map((job) => (
                  <Box 
                    key={job.id}
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      py: 1
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        {moment(job.appointmentDate?.toDate()).format('dddd, MMMM D, YYYY')}
                      </Typography>
                      <Typography color="textSecondary">
                        {moment(job.appointmentDate?.toDate()).format('h:mm A')} - 
                        {moment(job.scheduledEndTime?.toDate()).format('h:mm A')}
                        {' • '}
                        {job.cleanerDisplayName}
                      </Typography>
                    </Box>
                    <Chip 
                      label={job.status || 'Scheduled'} 
                      size="small"
                      sx={getStatusChipColor(job.status)}
                    />
                  </Box>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        )}

        {/* Past Appointments Accordion */}
        {pastAppointments.length > 0 && (
          <Accordion 
            expanded={expandedAccordion === 'past'} 
            onChange={handleAccordionChange('past')}
            sx={{ 
              backgroundColor: theme => theme.palette.mode === 'dark' ? 'var(--dark-foreground)' : 'var(--light-foreground)',
              boxShadow: theme => theme.palette.mode === 'dark' ? 'var(--shadow-md-dark)' : 'var(--shadow-md-light)',
              '&:before': { display: 'none' },
              borderRadius: 'var(--border-radius-base) !important',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                borderRadius: 'var(--border-radius-base)',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography variant="h6">Past Appointments</Typography>
                <Chip 
                  label={`${pastAppointments.length} Completed`} 
                  size="small"
                  sx={getStatusChipColor('Completed')}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2} divider={<Divider />}>
                {pastAppointments.map((job) => (
                  <Box 
                    key={job.id}
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      py: 1
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        {moment(job.appointmentDate?.toDate()).format('dddd, MMMM D, YYYY')}
                      </Typography>
                      <Typography color="textSecondary">
                        {moment(job.appointmentDate?.toDate()).format('h:mm A')} - 
                        {moment(job.scheduledEndTime?.toDate()).format('h:mm A')}
                        {' • '}
                        {job.cleanerDisplayName}
                        {' • '}
                        ${job.serviceCost?.toFixed(2) || '0.00'}
                      </Typography>
                      {job.addOns?.length > 0 && (
                        <Typography color="textSecondary" sx={{ fontSize: '0.875rem', mt: 0.5 }}>
                          Add-ons: {job.addOns.map(addOn => addOn.name).join(', ')}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Chip 
                        label={job.status || 'Completed'}
                        size="small"
                        sx={getStatusChipColor(job.status)}
                      />
                    </Box>
                  </Box>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </TabPanel>

        {/* Service Management Tab */}
        <TabPanel value={activeTab} index={1}>
          <ClientServiceManagement 
            customer={customer}
            orgId={orgId}
            recurringServices={recurringServices}
            onUpdateCustomer={handleCustomerUpdate}
          />
        </TabPanel>
      </Box>

      {/* Keep your existing modals */}
      <RescheduleModal
        open={isRescheduleModalOpen}
        onClose={() => setIsRescheduleModalOpen(false)}
        onReschedule={handleRescheduleConfirm}
        loading={isLoading}
        currentAppointment={nextAppointment}
        orgId={orgId}
        jobs={jobs}
        mode={modalMode}
        clientPortalSettings={clientPortalSettings}
      />

      <ServiceAddOnsModal
        open={isAddOnsModalOpen}
        onClose={() => setIsAddOnsModalOpen(false)}
        serviceAddOns={serviceAddOns}
        appointmentDate={nextAppointment ? moment(nextAppointment.appointmentDate?.toDate()).format('MMM D, YYYY') : ''}
        onSubmit={handleAddOnsSubmit}
        currentServiceCost={nextAppointment?.serviceCost || 0}
        loading={isLoading}
      />
    </Box>
  );
};

export default ClientPortal;