// src/components/EmailTemplateModal.js

import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import styles from '../styles/EmailTemplateModal.module.css';

const AVAILABLE_VARIABLES = [
    {
      group: 'Customer',
      subgroups: [
        {
          name: 'Basic Info',
          variables: [
            { key: '{{customer.name}}', description: 'Customer\'s full name' },
            { key: '{{customer.firstName}}', description: 'Customer\'s first name' },
            { key: '{{customer.lastName}}', description: 'Customer\'s last name' }
          ]
        },
        {
          name: 'Contact',
          variables: [
            { key: '{{customer.email}}', description: 'Customer\'s email' },
            { key: '{{customer.phone}}', description: 'Customer\'s phone number' }
          ]
        },
        {
          name: 'Address',
          variables: [
            { key: '{{customer.address}}', description: 'Customer\'s service address' },
            { key: '{{customer.billingAddress}}', description: 'Customer\'s billing address' }
          ]
        },
        {
          name: 'Other',
          variables: [
            { key: '{{customer.notes}}', description: 'Customer notes' }
          ]
        }
      ]
    },
    {
      group: 'Appointment',
      subgroups: [
        {
          name: 'Timing',
          variables: [
            { key: '{{appointment.appointmentDate}}', description: 'Scheduled appointment date' },
            { key: '{{appointment.scheduledStartTime}}', description: 'Scheduled start time' },
            { key: '{{appointment.scheduledEndTime}}', description: 'Scheduled end time' },
            { key: '{{appointment.duration}}', description: 'Scheduled duration' }
          ]
        },
        {
          name: 'Approximate Times',
          variables: [
            { key: '{{appointment.start_time_approx_one}}', description: '1hr range without minutes' },
            { key: '{{appointment.start_time_approx_two}}', description: '2hr range without minutes' },
            { key: '{{appointment.start_time_approx_three}}', description: '3hr range without minutes' },
            { key: '{{appointment.start_time_approx_one_minutes}}', description: '1hr range with minutes' },
            { key: '{{appointment.start_time_approx_two_minutes}}', description: '2hr range with minutes' },
            { key: '{{appointment.start_time_approx_three_minutes}}', description: '3hr range with minutes' }
          ]
        },
        {
          name: 'Service Details',
          variables: [
            { key: '{{appointment.serviceCost}}', description: 'Service cost' },
            { key: '{{appointment.address}}', description: 'Service address' },
            { key: '{{appointment.serviceType}}', description: 'Type of service' },
            { key: '{{appointment.status}}', description: 'Appointment status' },
            { key: '{{appointment.totalSize}}', description: 'Total size in square feet' }
          ]
        },
        {
          name: 'Notes',
          variables: [
            { key: '{{appointment.notes}}', description: 'Appointment notes' },
            { key: '{{appointment.importantNotes}}', description: 'Important appointment notes' }
          ]
        }
      ]
    },
    {
      group: 'Cleaner',
      subgroups: [
        {
          name: 'Cleaner Info',
          variables: [
            { key: '{{cleaners.names.comma}}', description: 'All cleaners\' names separated by commas' },
            { key: '{{cleaners.names.and}}', description: 'All cleaners\' names separated by "and"' },
            { key: '{{cleaners.htmlList}}', description: 'Formatted HTML list of all cleaners with names and emails' }
          ]
        }
      ]
    },
  // Organization Variables
  {
    group: 'Organization',
    subgroups: [
      {
        name: 'Company Info',
        variables: [
          { key: '{{organization.name}}', description: 'Company name' },
          { key: '{{organization.email}}', description: 'Company email' },
          { key: '{{organization.phone}}', description: 'Company phone number' },
          { key: '{{organization.logo}}', description: 'Company logo (sized for email)' }
        ]
      }
    ]
  },
  // Recurring Service Variables
  {
    group: 'Recurring Service',
    subgroups: [
      {
        name: 'Recurring Service',
        variables: [
          { key: '{{recurring.status}}', description: 'Recurring service status' },
          { key: '{{recurring.recurrenceRule}}', description: 'Recurrence pattern' }
        ]
      }
    ]
  }
];

const TRIGGER_TYPES = {
  STATUS_CHANGE: 'status_change',
  APPOINTMENT_REMINDER: 'appointment_reminder',
  MANUAL: 'manual'
};

const EmailTemplateModal = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  initialData,
  jobStatuses,
  loading 
}) => {
  const [template, setTemplate] = useState({
    name: '',
    subject: '',
    content: '',
    trigger: {
      type: TRIGGER_TYPES.MANUAL,
      status: '',
      daysBeforeAppointment: 1
    }
  });
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (initialData) {
      setTemplate(initialData);
    } else {
      setTemplate({
        name: '',
        subject: '',
        content: '',
        trigger: {
          type: TRIGGER_TYPES.MANUAL,
          status: '',
          daysBeforeAppointment: 1
        }
      });
    }
  }, [initialData]);

  const handleChange = (field, value) => {
    setTemplate(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleTriggerChange = (field, value) => {
    setTemplate(prev => ({
      ...prev,
      trigger: {
        ...prev.trigger,
        [field]: value
      }
    }));
  };

  const renderMenuItems = () => {
    const items = [];
    
    AVAILABLE_VARIABLES.forEach((group) => {
      // Add group header
      items.push(
        <MenuItem 
          key={group.group} 
          disabled 
          sx={{ 
            fontWeight: 'bold',
            backgroundColor: 'background.default',
            '&:hover': {
              backgroundColor: 'background.default'
            }
          }}
        >
          {group.group}
        </MenuItem>
      );
  
      // Add subgroups and their items
      group.subgroups.forEach((subgroup) => {
        items.push(
          <MenuItem 
            key={`${group.group}-${subgroup.name}`} 
            disabled 
            sx={{ 
              pl: 2,
              fontSize: '0.9rem',
              color: 'text.secondary',
              '&:hover': {
                backgroundColor: 'background.default'
              }
            }}
          >
            {subgroup.name}
          </MenuItem>
        );
  
        // Add variables in subgroup
        subgroup.variables.forEach((variable) => {
          items.push(
            <MenuItem
              key={`${group.group}-${subgroup.name}-${variable.key}`}
              value={variable.key}
              onClick={() => {
                handleVariableSelect(variable.key);
                setMenuOpen(false);
              }}
              sx={{
                pl: 4,
                fontSize: '0.9rem',
                whiteSpace: 'normal',
                minHeight: '48px'
              }}
            >
              <div>
                <div style={{ fontWeight: 500 }}>{variable.key}</div>
                <div style={{ fontSize: '0.8rem', color: 'text.secondary' }}>
                  {variable.description}
                </div>
              </div>
            </MenuItem>
          );
        });
      });
    });
  
    return items;
  };

  const handleVariableSelect = (variableKey) => {
    console.log('Selected variable:', variableKey);

    if (!variableKey) return;
    
    // For subject field
    if (document.activeElement.id === 'subject') {
      const input = document.getElementById('subject');
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const newValue = template.subject.substring(0, start) + 
                      variableKey + 
                      template.subject.substring(end);
      handleChange('subject', newValue);
      
      setTimeout(() => {
        input.setSelectionRange(start + variableKey.length, start + variableKey.length);
        input.focus();
      }, 0);
    }
    // For content (ReactQuill)
    else {
      const editor = document.querySelector('.ql-editor');
      if (editor) {
        const quill = editor.parentElement.__quill;
        if (quill) {
          const range = quill.getSelection();
          if (range) {
            quill.insertText(range.index, variableKey);
          } else {
            quill.insertText(quill.getLength(), variableKey);
          }
        }
      }
    }

    // Close the menu after selection
    setMenuOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(template);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.userModalOverlay}>
      <div className={styles.userModalContent}>
        <h2 className={styles.userModalTitle}>
          {initialData ? 'Edit Email Template' : 'Create Email Template'}
        </h2>

        <form onSubmit={handleSubmit} className={styles.userForm}>
          <div className={styles.userFormGroup}>
            <label htmlFor="name">Template Name</label>
            <input
              id="name"
              value={template.name}
              onChange={(e) => handleChange('name', e.target.value)}
              placeholder="Welcome Email"
            />
          </div>

          <div className={styles.userFormGroup}>
            <label htmlFor="subject">Email Subject</label>
            <div className={styles.inputWithVariable}>
              <input
                id="subject"
                value={template.subject}
                onChange={(e) => handleChange('subject', e.target.value)}
                placeholder="Welcome to our service"
              />
            </div>
          </div>

          <div className={styles.userFormGroup}>
            <label>Trigger Type</label>
            <select
              value={template.trigger.type}
              onChange={(e) => handleTriggerChange('type', e.target.value)}
            >
              <option value={TRIGGER_TYPES.MANUAL}>Manual Send</option>
              <option value={TRIGGER_TYPES.STATUS_CHANGE}>On Status Change</option>
              <option value={TRIGGER_TYPES.APPOINTMENT_REMINDER}>Appointment Reminder</option>
            </select>
          </div>

          {template.trigger.type === TRIGGER_TYPES.STATUS_CHANGE && (
            <div className={styles.userFormGroup}>
              <label>Status</label>
              <select
                value={template.trigger.status}
                onChange={(e) => handleTriggerChange('status', e.target.value)}
              >
                {jobStatuses.map(status => (
                  <option key={status.id} value={status.name}>{status.name}</option>
                ))}
              </select>
            </div>
          )}

          {template.trigger.type === TRIGGER_TYPES.APPOINTMENT_REMINDER && (
            <div className={styles.userFormGroup}>
              <label>Days Before Appointment</label>
              <input
                type="number"
                value={template.trigger.daysBeforeAppointment}
                onChange={(e) => handleTriggerChange('daysBeforeAppointment', parseInt(e.target.value))}
                min="1"
                max="30"
              />
            </div>
          )}

          <div className={styles.variablesSection}>
          <FormControl fullWidth size="small">
            <InputLabel>Insert Variable</InputLabel>
                <Select
                    value=""
                    open={menuOpen}
                    onOpen={() => setMenuOpen(true)}
                    onClose={() => setMenuOpen(false)}
                    label="Insert Variable"
                    MenuProps={{
                    PaperProps: {
                        style: {
                        maxHeight: 450
                        }
                    }
                    }}
                >
                    {renderMenuItems()}
                </Select>
            </FormControl>
          </div>

          <div className={styles.userFormGroup}>
            <label>Email Content</label>
            <ReactQuill
              value={template.content}
              onChange={(content) => handleChange('content', content)}
              className={styles.editor}
            />
          </div>

          <div className={styles.userButtonGroup}>
            <button
              type="button"
              onClick={onClose}
              className={`${styles.button} ${styles.userButtonSecondary}`}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`${styles.button} ${styles.userButtonPrimary}`}
              disabled={loading}
            >
              {loading 
                ? (initialData ? 'Saving...' : 'Creating...') 
                : (initialData ? 'Save Template' : 'Create Template')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailTemplateModal;