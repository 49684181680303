// src/components/CustomerProfileManagement.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography, Box, Card, CardContent, Chip, List, ListItem,
  ListItemText, Tabs, Tab, Button, Alert, Dialog, DialogTitle,
  DialogContent, Divider, Snackbar
} from '@mui/material';
import { ArrowLeft } from 'lucide-react';
import CreateCustomerModal from './CreateCustomerModal';
import AddIcon from '@mui/icons-material/Add';
import CreateRecurringService from './CreateRecurringService';
import { styled } from '@mui/material/styles';
import { 
    doc, getDoc, collection, query, where, 
    getDocs, writeBatch, updateDoc, serverTimestamp,
    deleteField
} from 'firebase/firestore';
import { db, auth } from '../firebase';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { organizationApi, notificationsApi } from '../utils/api';
import moment from 'moment';
import { rrulestr } from 'rrule';  
import JobDialog from './JobDialog';
import EditRecurringService from './EditRecurringService';
import ClientPreferences from './ClientPreferences';
import { formatRecurrenceRule } from '../utils/formatRecurrenceRule';
import { useTimezone } from '../context/TimeZoneContext';
import styles from '../styles/CustomerProfileManagement.module.css';
import { useJobContext } from '../context/JobContext';
import { DEFAULT_JOB_STATUSES } from '../constants/statuses';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground)' : 'var(--light-foreground)',
  color: theme.palette.mode === 'dark' ? 'var(--dark-text)' : 'var(--light-text)',
  boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-md-dark)' : 'var(--shadow-md-light)',
  borderRadius: 'var(--border-radius-base)',
  transition: 'all var(--transition-duration) var(--transition-ease)',
  '&:hover': {
    boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-lg-dark)' : 'var(--shadow-lg-light)',
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '16px',
  borderRadius: 'var(--border-radius-base)',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'var(--dark-foreground-accent)' 
    : 'var(--light-foreground-accent)',
  boxShadow: theme.palette.mode === 'dark'
    ? 'var(--shadow-sm-dark)'
    : 'var(--shadow-sm-light)',
}));

const ServiceMetricBox = styled(Box)(({ theme }) => ({
  padding: '16px',
  borderRadius: 'var(--border-radius-base)',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'var(--dark-foreground-accent)' 
    : 'var(--light-foreground-accent)',
  boxShadow: theme.palette.mode === 'dark'
    ? 'var(--shadow-sm-dark)'
    : 'var(--shadow-sm-light)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '180px',
  flex: 1,
}));

const StyledMetricBox = styled(Box)(({ theme }) => ({
  padding: '16px',
  borderRadius: 'var(--border-radius-base)',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'var(--dark-foreground-accent)' 
    : 'var(--light-foreground-accent)',
  boxShadow: theme.palette.mode === 'dark'
    ? 'var(--shadow-md-dark)'  // Changed from sm to md
    : 'var(--shadow-md-light)', // Changed from sm to md
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '200px',
  flex: 1,
  transition: 'all 0.2s ease', // Added transition
  '&:hover': {
    boxShadow: theme.palette.mode === 'dark'
      ? 'var(--shadow-lg-dark)'   // Added hover effect
      : 'var(--shadow-lg-light)'  // Added hover effect
  }
}));

const MetricValue = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: 500,
  marginTop: '8px',
});

const MetricLabel = styled(Typography)({
  color: 'var(--dark-text-light)',
  fontSize: '0.875rem',
  textAlign: 'center',
});

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'var(--dark-border)' : 'var(--light-border)'}`,
  marginBottom: '16px',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'var(--dark-text)' : 'var(--light-text)',
  '&.Mui-selected': {
    color: theme.palette.mode === 'dark' ? 'var(--dark-primary)' : 'var(--light-primary)',
  },
}));

const ServiceListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground-accent)' : 'var(--light-foreground-accent)',
  borderRadius: 'var(--border-radius-base)',
  marginBottom: '8px',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-dropdowns)' : 'var(--light-foreground-accent)',
  },
}));

const CustomerProfileManagement = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { convertFromOrgTz, convertToOrgTz, timezone } = useTimezone();
  const [orgId, setOrgId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [firstAppointment, setFirstAppointment] = useState(null);
  const [serviceMetricsMap, setServiceMetricsMap] = useState({});
  const [metrics, setMetrics] = useState({
    revenueToDate: 0,
    unpaidBalance: 0,
    completedJobsCount: 0,
    avgDuration: 0,
    avgPayrollExpense: 0,
  });

  const [clientTotalMetrics, setClientTotalMetrics] = useState({
    totalRevenue: 0,
    totalPayrollCost: 0,
    totalProfit: 0
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const [activeTab, setActiveTab] = useState('profile');
  const [servicesTab, setServicesTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedJob, setEditedJob] = useState(null);
  const [success, setSuccess] = useState('');
  const [emailTemplates, setEmailTemplates] = useState([]);

  // Custom Job Statuses
  const [customJobStatuses, setCustomJobStatuses] = useState([]);
  
  // Add these states to handle recurring logic and applying changes
  const [showingRecurringSeries, setShowingRecurringSeries] = useState(false);
  const [recurringInstances, setRecurringInstances] = useState([]);
  const [applyToRecurring, setApplyToRecurring] = useState(false);
  const [isReactivating, setIsReactivating] = useState(false);
  const [isReactivatingService, setIsReactivatingService] = useState(false);
  const [serviceToReactivate, setServiceToReactivate] = useState(null);
  const [fetchedRecurringServices, setFetchedRecurringServices] = useState([]);
  const [isAddingNewService, setIsAddingNewService] = useState(false);
  const [recurringServices, setRecurringServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [cleaners, setCleaners] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false);

  const [customerData, setCustomerData] = useState({
    phone: customer?.phone || '',
    email: customer?.email || '',
    serviceAddress: customer?.serviceAddress || '',
    billingAddress: customer?.billingAddress || customer?.serviceAddress || '',
  });

  const [serviceInfo, setServiceInfo] = useState({
    type: 'One-Time',
    recurrenceDetails: null,
  });

  const { 
    startJob, 
    completeJob, 
    handleDeleteJob,
    handleDeleteRecurringJob,
    fetchRecurringInstances,
    generateMoreInstances,
    JOB_STATUSES,
    fetchCustomStatuses
  } = useJobContext();

  const SERVICE_STATUSES = {
    ACTIVE: 'active',
    CANCELLED: 'cancelled',
    COMPLETED: 'completed'
  };
  
    useEffect(() => {
      if (orgId) {
        fetchJobs();
        fetchCustomStatuses(orgId); // Fetch custom statuses when component mounts
      }
    }, [orgId]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setOrgId(userDoc.data().orgId);
          } else {
            setError('Organization ID not found');
          }
        } catch (err) {
          console.error('Error fetching user data:', err);
          setError('Failed to retrieve organization ID');
        }
      } else {
        setError('No authenticated user');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const calculateMetricsForServices = async () => {
      if (!orgId) {
        return;
      }
  
      const metricsMap = {};
  
      for (const service of recurringServices) {
        const serviceJobs = jobs.filter(job => job.recurrenceGroupId === service.id);
        try {
          const metrics = await calculateServiceMetrics(serviceJobs, orgId);
          if (metrics) {
            metricsMap[service.id] = metrics;
          }
        } catch (error) {
          console.error('Error calculating metrics for service:', service.id, error);
        }
      }
  
      setServiceMetricsMap(metricsMap);
    };
  
    calculateMetricsForServices();
  }, [recurringServices, jobs, orgId]);
  

  useEffect(() => {
    if (customer) {
      setCustomerData({
        phone: customer.phone || '',
        email: customer.email || '',
        address: customer.address || '',
        serviceAddress: customer.serviceAddress || '',
        billingAddress: customer.billingAddress || customer.serviceAddress || '',
      });
    }
  }, [customer]);

  // Capitalize the first letter for the service type
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const fetchCustomer = async () => {
    if (!orgId || !customerId) return;
  
    try {
      const customerRef = doc(db, 'organizations', orgId, 'customers', customerId);
      const customerSnap = await getDoc(customerRef);
  
      if (!customerSnap.exists()) {
        setError(`Customer ${customerId} not found`);
        setCustomer(null);
        return;
      }
  
      const customerData = customerSnap.data();
      setCustomer({
        id: customerSnap.id,
        name: customerData.name || 'Unknown Customer',
        email: customerData.email || 'N/A',
        phone: customerData.phone || 'N/A',
        serviceAddress: customerData.serviceAddress || 'N/A',
        billingAddress: customerData.billingAddress || 'N/A',  // Add this line
        serviceType: customerData.serviceType || 'N/A',
        serviceCost: customerData.serviceCost || customerData.price || 0,
      });
    } catch (err) {
      console.error('Error fetching customer:', err);
      setError('Failed to fetch customer details');
      setCustomer(null);
    }
  };

  // Create Customer Portal Account
  const createCustomerAccount = async () => {
    if (!customer?.email) {
      setError('Customer must have an email address to create an account');
      return;
    }
  
    try {
      setLoading(true);
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken(true);
  
      const response = await organizationApi.createCustomerPortalAccount(token, {
        customerId: customerId,
        orgId: orgId,
        email: customer.email,
        name: customer.name
      });
  
      if (response.data.success) {
        setSuccess('Customer portal account created successfully. Password reset email sent.');
        
        // Update local customer state
        setCustomer(prev => ({
          ...prev,
          userAccountId: response.data.userId,
          accountStatus: 'active'
        }));
      }
    } catch (err) {
      console.error('Error creating customer account:', err);
      setError(err.response?.data?.error || 'Failed to create customer portal account');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCustomer = async (updatedCustomerData) => {
    if (!orgId || !customerId) return;

    try {
      // Create a clean update object with only the fields we want to update
      const updateData = {
        name: `${updatedCustomerData.firstName} ${updatedCustomerData.lastName}`.trim(),
        email: updatedCustomerData.email,
        phone: updatedCustomerData.phone,
        serviceAddress: typeof updatedCustomerData.serviceAddress === 'object' 
          ? updatedCustomerData.serviceAddress.complete 
          : updatedCustomerData.serviceAddress,
        billingAddress: typeof updatedCustomerData.billingAddress === 'object'
          ? updatedCustomerData.billingAddress.complete
          : updatedCustomerData.billingAddress,
        updatedAt: serverTimestamp(),
        // Add any custom fields if they exist
        ...(updatedCustomerData.customFieldValues && { customFieldValues: updatedCustomerData.customFieldValues }),
        ...(updatedCustomerData.notes && { notes: updatedCustomerData.notes }),
        ...(typeof updatedCustomerData.isPrivateNote !== 'undefined' && { 
          isPrivateNote: updatedCustomerData.isPrivateNote 
        })
      };

      const customerRef = doc(db, 'organizations', orgId, 'customers', customerId);
      await updateDoc(customerRef, updateData);

      // Update local state
      setCustomer(prev => ({
        ...prev,
        ...updateData,
        // Don't include serverTimestamp in local state
        updatedAt: new Date()
      }));

      setSuccess('Customer updated successfully');
    } catch (err) {
      console.error('Error updating customer:', err);
      setError('Failed to update customer details');
    }
  };

  const handleEditCustomerClick = () => {
    const formattedCustomerData = {
      firstName: customer.name.split(' ')[0] || '',
      lastName: customer.name.split(' ').slice(1).join(' ') || '',
      email: customer.email,
      phone: customer.phone,
      serviceAddress: typeof customer.serviceAddress === 'object'
        ? customer.serviceAddress.complete
        : customer.serviceAddress,
      useSameAddress: true,
      billingAddress: typeof customer.billingAddress === 'object'
        ? customer.billingAddress.complete
        : customer.billingAddress || customer.serviceAddress,
      customFieldValues: customer.customFieldValues || {},
      notes: customer.notes || '',
      isPrivateNote: customer.isPrivateNote || false
    };

    setEditCustomerData(formattedCustomerData);
    setIsEditModalOpen(true);
  };

  const fetchRecurringServices = async () => {
    if (!orgId || !customerId) return;
    
    try {
      const recurringServicesRef = collection(db, 'organizations', orgId, 'recurringServices');
      const servicesQuery = query(
        recurringServicesRef,
        where('customerId', '==', customerId),
        where('recurringStatus', '==', 'Active')
      );
      
      // First, fetch all cleaners
      const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
      const cleanersSnap = await getDocs(cleanersRef);
      const cleanersMap = {};
      cleanersSnap.docs.forEach(doc => {
        const cleanerData = doc.data();
        cleanersMap[doc.id] = {
          name: cleanerData.firstName && cleanerData.lastName 
            ? `${cleanerData.firstName} ${cleanerData.lastName}`
            : cleanerData.name || cleanerData.email,
          email: cleanerData.email
        };
      });
  
      const servicesSnap = await getDocs(servicesQuery);
      
      const rawServices = servicesSnap.docs.map(doc => {
        const serviceData = {
          id: doc.id,
          ...doc.data()
        };
  
        if (Array.isArray(serviceData.cleanerDetails) && serviceData.cleanerDetails.length > 0) {
          serviceData.cleanerNames = serviceData.cleanerDetails
            .map(c => cleanersMap[c.id]?.name || c.name || c.email)
            .filter(Boolean)
            .join(', ');
        }
        else if (serviceData.cleanerId && cleanersMap[serviceData.cleanerId]) {
          serviceData.cleanerNames = cleanersMap[serviceData.cleanerId].name;
        }
        else if (serviceData.cleanerName) {
          serviceData.cleanerNames = serviceData.cleanerName;
        }
        else {
          serviceData.cleanerNames = 'Unassigned';
        }
  
        return serviceData;
      });
  
      setFetchedRecurringServices(rawServices);
    } catch (err) {
      console.error('Error fetching recurring services:', err);
      setError('Failed to fetch recurring services');
    }
  };

  useEffect(() => {
    fetchRecurringServices();
  }, [orgId, customerId]);

  useEffect(() => {
    fetchJobs();
  }, [orgId, customerId]);

  const fetchJobs = async () => {
    if (!orgId || !customerId) return;
  
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const jobsQuery = query(
        jobsRef, 
        where('customerId', '==', customerId)
      );
  
      const jobsSnap = await getDocs(jobsQuery);
  
      // First, fetch all cleaners to have their info available
      const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
      const cleanersSnap = await getDocs(cleanersRef);
      const cleanersMap = {};
      cleanersSnap.docs.forEach(doc => {
        const cleanerData = doc.data();
        cleanersMap[doc.id] = {
          id: doc.id,
          firstName: cleanerData.firstName || '',
          lastName: cleanerData.lastName || '',
          email: cleanerData.email || '',
          displayName: cleanerData.displayName || '',
          name: cleanerData.firstName && cleanerData.lastName 
            ? `${cleanerData.firstName} ${cleanerData.lastName}`
            : cleanerData.displayName || cleanerData.email
        };
      });
  
      const fetchedJobs = jobsSnap.docs.map(doc => {
        const jobData = {
          id: doc.id,
          ...doc.data()
        };
  
        // Process cleaners array with priority order
        if (Array.isArray(jobData.cleanerDetails) && jobData.cleanerDetails.length > 0) {
          jobData.cleanerDetails = jobData.cleanerDetails.map(cleaner => {
            const cleanerInfo = cleanersMap[cleaner.id];
            return {
              ...cleaner,
              name: cleanerInfo?.name || cleaner.name || cleaner.email || 'Unassigned'
            };
          });
          jobData.cleanerNames = jobData.cleanerDetails
            .map(c => c.name)
            .filter(Boolean)
            .join(', ');
        }
        // Check legacy cleaner ID
        else if (jobData.cleanerId && cleanersMap[jobData.cleanerId]) {
          jobData.cleanerNames = cleanersMap[jobData.cleanerId].name;
        }
        // Check legacy cleaner name
        else if (jobData.cleanerName) {
          jobData.cleanerNames = jobData.cleanerName;
        }
        else {
          jobData.cleanerNames = 'Unassigned';
        }
  
        // Ensure recurring jobs have a recurrenceGroupId
        jobData.recurrenceGroupId = jobData.recurring ? (jobData.recurrenceGroupId || doc.id) : null;
  
        return jobData;
      });
  
      setJobs(fetchedJobs);
    } catch (err) {
      console.error('Error fetching jobs:', err);
      setError('Failed to fetch jobs');
    }
  };

  const fetchFirstAppointment = async () => {
    if (!orgId || !customerId) return;
    
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(
        jobsRef,
        where('customerId', '==', customerId)
      );
      
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const dates = querySnapshot.docs
          .map(doc => doc.data().appointmentDate)
          .filter(Boolean);
        
        if (dates.length > 0) {
          const earliestDate = dates.reduce((earliest, current) => {
            if (!earliest) return current;
            return current.toDate() < earliest.toDate() ? current : earliest;
          }, null);
          
          setFirstAppointment(earliestDate);
        }
      }
    } catch (err) {
      console.error('Error fetching first appointment:', err);
    }
  };

  useEffect(() => {
    if (orgId && customerId) {
      fetchFirstAppointment();
    }
  }, [orgId, customerId]);

  const fetchOrganizationCleaners = async (orgId) => {
    if (!orgId) return;
    
    try {
      const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
      const cleanersSnapshot = await getDocs(cleanersRef);
      
      const cleanersData = cleanersSnapshot.docs
        .map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.firstName && data.lastName 
              ? `${data.firstName} ${data.lastName}`
              : data.name || 'Unassigned',
            email: data.email,
            ...data
          };
        })
        .filter(cleaner => cleaner.isActive !== false);
      
      setCleaners(cleanersData);
    } catch (error) {
      console.error('Error fetching cleaners:', error);
    }
  };
  
  // Add useEffect to fetch cleaners
  useEffect(() => {
    if (orgId) {
      fetchOrganizationCleaners(orgId);
    }
  }, [orgId]);
  
  const calculateServiceMetrics = async (serviceJobs, orgId) => {
    try {
      
      if (!orgId) {
        throw new Error('Organization ID is required');
      }
  
      const cleaners = await fetchOrganizationCleaners(orgId);
  
      if (!Array.isArray(serviceJobs) || serviceJobs.length === 0) {
        return {
          firstAppointment: null,
          serviceCost: 0,
          completedJobsCount: 0,
          avgDuration: 0,
          avgRevenuePerService: 0,
          avgPayrollCost: 0,
          avgProfit: 0
        };
      }
  
      const completedJobs = serviceJobs.filter(job => job?.status?.toLowerCase() === 'completed');
      const completedJobsCount = completedJobs.length;
  
      let totalServiceCost = 0;
      let totalDuration = 0;
      let totalPayrollCost = 0;
  
      completedJobs.forEach(job => {
        totalServiceCost += job.serviceCost || 0;
  
        const durationInHours = job.actualDuration / (1000 * 60 * 60);
        totalDuration += durationInHours;
  
        const cleaner = cleaners.find(c => c.userId === job.cleanerId);
  
        if (cleaner?.payRate) {
          const payRate = parseFloat(cleaner.payRate);
          if (!isNaN(payRate)) {
            const jobPayrollCost = payRate * durationInHours;
            totalPayrollCost += jobPayrollCost;
          }
        }
      });
  
      const avgDuration = completedJobsCount > 0 
        ? Number((totalDuration / completedJobsCount).toFixed(2)) 
        : 0;
  
      const avgRevenuePerService = completedJobsCount > 0 
        ? Number((totalServiceCost / completedJobsCount).toFixed(2)) 
        : 0;
  
      const avgPayrollCost = completedJobsCount > 0 
        ? Number((totalPayrollCost / completedJobsCount).toFixed(2)) 
        : 0;
  
      const avgProfit = Number((avgRevenuePerService - avgPayrollCost).toFixed(2));
  
      return {
        firstAppointment: null,
        serviceCost: totalServiceCost,
        completedJobsCount,
        avgDuration,
        avgRevenuePerService,
        avgPayrollCost,
        avgProfit,
        totalRevenue: totalServiceCost, 
        totalPayrollCost, 
        totalProfit: totalServiceCost - totalPayrollCost 
      };
    } catch (error) {
      console.error('Error in calculateServiceMetrics:', error);
      return {
        firstAppointment: null,
        serviceCost: 0,
        completedJobsCount: 0,
        avgDuration: 0,
        avgRevenuePerService: 0,
        avgPayrollCost: 0,
        avgProfit: 0
      };
    }
  };

  // Calculate total metrics
  const calculateClientTotalMetrics = async () => {
    const completedJobs = jobs.filter(job => 
      job?.status?.toLowerCase() === 'completed'
    );
  
    if (completedJobs.length === 0) {
      return {
        totalRevenue: 0,
        totalPayrollCost: 0,
        totalProfit: 0
      };
    }
  
    let totalRevenue = 0;
    let totalPayrollCost = 0;
  
    for (const job of completedJobs) {
      const serviceCost = parseFloat(job.serviceCost || job.price || 0);
      totalRevenue += serviceCost;
      
      if ((job.startTime || job.actualStartTime) && (job.endTime || job.actualEndTime) && job.cleanerId) {
        const startTime = job.startTime?.toDate?.() || job.actualStartTime?.toDate?.() || job.startTime || job.actualStartTime;
        const endTime = job.endTime?.toDate?.() || job.actualEndTime?.toDate?.() || job.endTime || job.actualEndTime;
        
        if (startTime instanceof Date && endTime instanceof Date) {
          const durationInHours = (endTime - startTime) / (1000 * 60 * 60);
          
          try {
            const cleanerRef = doc(db, 'users', job.cleanerId);
            const cleanerDoc = await getDoc(cleanerRef);
            
            if (cleanerDoc.exists()) {
              const cleanerData = cleanerDoc.data();
              const payRate = parseFloat(cleanerData.payRate);
              
              if (!isNaN(payRate)) {
                const jobPayrollCost = payRate * durationInHours;
                totalPayrollCost += jobPayrollCost;
              }
            }
          } catch (error) {
            console.error('Error fetching cleaner data:', error);
          }
        }
      }
    }
  
    return {
      totalRevenue,
      totalPayrollCost,
      totalProfit: totalRevenue - totalPayrollCost
    };
  };

  useEffect(() => {
    const fetchTotalMetrics = async () => {
      if (jobs.length > 0) {
        try {
          const totals = await calculateClientTotalMetrics();
          setClientTotalMetrics(totals);
        } catch (error) {
          console.error('Error calculating client metrics:', error);
        }
      }
    };
  
    fetchTotalMetrics();
  }, [jobs, orgId]);

  const handleTabChange = (event, newValue) => {
    setServicesTab(newValue);
  };

  // Add the handleApplyToRecurringChange function here
  const handleApplyToRecurringChange = (checked) => {
    setApplyToRecurring(checked);
    if (editedJob) {
      setEditedJob(prev => ({
        ...prev,
        applyToRecurring: checked
      }));
    }
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    setIsDialogOpen(true);
  };
  
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedJob(null);
    setIsEditing(false);
    setEditedJob(null);
    setRecurringInstances([]);
    setShowingRecurringSeries(false);
    setSuccess('');
    setError(null);
    setApplyToRecurring(false);  // Add this line
  };

    // Helper function to synchronize timestamps
    const synchronizeTimestamps = (jobData) => {
      const syncedData = { ...jobData };
  
      // Sync start times
      if (syncedData.startTime && !syncedData.actualStartTime) {
        syncedData.actualStartTime = syncedData.startTime;
      } else if (syncedData.actualStartTime && !syncedData.startTime) {
        syncedData.startTime = syncedData.actualStartTime;
      }
  
      // Sync end times
      if (syncedData.endTime && !syncedData.actualEndTime) {
        syncedData.actualEndTime = syncedData.endTime;
      } else if (syncedData.actualEndTime && !syncedData.endTime) {
        syncedData.endTime = syncedData.actualEndTime;
      }
  
      return syncedData;
    };

  const getCleanerDisplayName = (cleaner) => {
    if (!cleaner) return '';
    // Try different possible name field combinations
    if (cleaner.name) return cleaner.name;
    if (cleaner.firstName && cleaner.lastName) return `${cleaner.firstName} ${cleaner.lastName}`;
    if (cleaner.displayName) return cleaner.displayName;
    if (cleaner.email) return cleaner.email;
    return `Cleaner ${cleaner.id}`;
  };
  
  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);

    try {
      console.log('Selected job for editing:', selectedJob);

      // Handle appointment date
      let appointmentDateTime;
      if (selectedJob.appointmentDate?.toDate) {
        appointmentDateTime = convertToOrgTz(selectedJob.appointmentDate.toDate());
      } else if (selectedJob.appointmentDate instanceof Date) {
        appointmentDateTime = convertToOrgTz(selectedJob.appointmentDate);
      } else {
        appointmentDateTime = convertToOrgTz(moment(selectedJob.appointmentDate));
      }

      // Format for datetime-local input
      const formattedAppointmentDate = appointmentDateTime.format('YYYY-MM-DDTHH:mm');

      // Handle end time conversion
      let endDateTime;
      if (selectedJob.scheduledEndTime) {
        if (selectedJob.scheduledEndTime?.toDate) {
          endDateTime = convertToOrgTz(selectedJob.scheduledEndTime.toDate());
        } else if (selectedJob.scheduledEndTime instanceof Date) {
          endDateTime = convertToOrgTz(selectedJob.scheduledEndTime);
        } else {
          endDateTime = convertToOrgTz(moment(selectedJob.scheduledEndTime));
        }
      } else {
        endDateTime = appointmentDateTime.clone().add(2, 'hours');
      }

      const formattedEndTime = endDateTime.format('YYYY-MM-DDTHH:mm');

      // Handle cleaners data - properly merge legacy and new formats
      const cleaners = [];

      // First, add the legacy cleaner if it exists
      if (selectedJob.cleanerId && selectedJob.cleanerName) {
        cleaners.push({
          id: selectedJob.cleanerId,
          name: selectedJob.cleanerName,
        });
      }
      
      // Then, add any cleaners from the cleaners array
      if (Array.isArray(selectedJob.cleaners)) {
        selectedJob.cleaners.forEach(cleaner => {
          // Only add if this cleaner isn't already in the array
          if (!cleaners.some(existingCleaner => existingCleaner.id === cleaner.id)) {
            cleaners.push({
              id: cleaner.id,
              name: getCleanerDisplayName(cleaner),
            });
          }
        });
      }

      setEditedJob({
        ...selectedJob,
        customerName: selectedJob.customerName,
        customerId: selectedJob.customerId,
        cleaners: cleaners,
        address: selectedJob.address,
        totalSize: selectedJob.totalSize || '',
        notes: selectedJob.notes || '',
        importantNotes: selectedJob.importantNotes || '',
        appointmentDate: formattedAppointmentDate,
        scheduledEndTime: formattedEndTime,
        recurring: selectedJob.recurring,
        recurrenceRule: selectedJob.recurrenceRule,
        rooms: selectedJob.rooms || [],
        serviceCost: selectedJob.serviceCost || selectedJob.price || 0,
        price: selectedJob.price || selectedJob.serviceCost || 0
      });
    } catch (error) {
      console.error("Error during handleEditClick:", error);
    }
  };
  
  const handleSaveEdit = async () => {
    try {
      // Add date validation and conversion
      let appointmentDate, scheduledEndTime;
      
      try {
        // Convert appointment date from string to moment object with timezone
        appointmentDate = moment(editedJob.appointmentDate).isValid() 
          ? convertFromOrgTz(moment(editedJob.appointmentDate))
          : null;
  
        // Convert end time from string to moment object with timezone
        scheduledEndTime = moment(editedJob.scheduledEndTime).isValid()
          ? convertFromOrgTz(moment(editedJob.scheduledEndTime))
          : null;
  
        if (!appointmentDate || !scheduledEndTime) {
          throw new Error('Invalid appointment date or end time');
        }
  
        // Convert to JavaScript Date objects
        appointmentDate = appointmentDate.toDate();
        scheduledEndTime = scheduledEndTime.toDate();
        
        // Validate the dates are valid
        if (isNaN(appointmentDate.getTime()) || isNaN(scheduledEndTime.getTime())) {
          throw new Error('Invalid date conversion');
        }
      } catch (error) {
        console.error('Date conversion error:', error);
        throw new Error('Invalid date format. Please check appointment times.');
      }
  
      const scheduledDuration = scheduledEndTime.getTime() - appointmentDate.getTime();
  
      // Get the primary cleaner (first in array) for legacy fields
      const primaryCleaner = Array.isArray(editedJob.cleaners) && editedJob.cleaners.length > 0
        ? editedJob.cleaners[0]
        : null;
  
      // Only include cleaner fields if they exist
      const cleanerFields = primaryCleaner ? {
        cleanerName: getCleanerDisplayName(primaryCleaner),
        cleanerId: primaryCleaner.id,
        ...(primaryCleaner.email && { cleanerEmail: primaryCleaner.email }),
      } : {};
  
      // Prepare base update data
      const baseUpdateData = synchronizeTimestamps({
        customerName: editedJob.customerName,
        ...cleanerFields,
        address: editedJob.address,
        notes: editedJob.notes || '',
        importantNotes: editedJob.importantNotes || '',
        totalSize: parseFloat(editedJob.totalSize) || 0,
        serviceCost: parseFloat(editedJob.serviceCost),
        price: parseFloat(editedJob.serviceCost),
        rooms: editedJob.rooms || [],
        serviceType: editedJob.serviceType || 'one-time',
        recurring: editedJob.serviceType === 'recurring',
        recurrenceRule: editedJob.serviceType === 'recurring' ? editedJob.recurrenceRule : null,
        updatedAt: new Date(),
        organizationId: orgId,
        customerId: selectedJob.customerId,
        cleaners: Array.isArray(editedJob.cleaners) ? editedJob.cleaners.map(cleaner => ({
          id: cleaner.id,
          name: getCleanerDisplayName(cleaner),
          ...(cleaner.email && { email: cleaner.email })
        })) : [],
        cleanerDetails: Array.isArray(editedJob.cleaners) ? editedJob.cleaners.map(cleaner => ({
          id: cleaner.id,
          name: getCleanerDisplayName(cleaner),
          ...(cleaner.email && { email: cleaner.email })
        })) : [],
        appointmentDate,
        scheduledEndTime,
        scheduledDuration
      });
  
      // Handle times if they exist
      if (editedJob.startTime) {
        try {
          baseUpdateData.startTime = moment(editedJob.startTime).isValid() 
            ? moment(editedJob.startTime).toDate() 
            : null;
        } catch (e) {
          console.warn('Invalid start time, skipping:', e);
        }
      }
  
      if (editedJob.endTime) {
        try {
          baseUpdateData.endTime = moment(editedJob.endTime).isValid() 
            ? moment(editedJob.endTime).toDate() 
            : null;
        } catch (e) {
          console.warn('Invalid end time, skipping:', e);
        }
      }
  
      if (editedJob.actualStartTime) {
        try {
          baseUpdateData.actualStartTime = moment(editedJob.actualStartTime).isValid() 
            ? moment(editedJob.actualStartTime).toDate() 
            : null;
        } catch (e) {
          console.warn('Invalid actual start time, skipping:', e);
        }
      }
  
      if (editedJob.actualEndTime) {
        try {
          baseUpdateData.actualEndTime = moment(editedJob.actualEndTime).isValid() 
            ? moment(editedJob.actualEndTime).toDate() 
            : null;
        } catch (e) {
          console.warn('Invalid actual end time, skipping:', e);
        }
      }
  
      const batch = writeBatch(db);
  
      if (editedJob.recurring && selectedJob.recurrenceGroupId) {
        let customerId = selectedJob.customerId;
  
        if (!customerId) {
          throw new Error('Unable to find customer ID');
        }
  
        // Update recurring service
        const recurringServiceRef = doc(db, 'organizations', orgId, 'recurringServices', selectedJob.recurrenceGroupId);
        const recurringDoc = await getDoc(recurringServiceRef);
  
        if (!recurringDoc.exists()) {
          const recurringServiceData = {
            ...baseUpdateData,
            recurringStatus: "Active",
            recurringStartDate: appointmentDate,
            lastUpdated: new Date(),
            recurrenceGroupId: selectedJob.recurrenceGroupId,
            customerId,
            customerName: editedJob.customerName
          };
          batch.set(recurringServiceRef, recurringServiceData);
        } else {
          const recurringServiceData = {
            ...baseUpdateData,
            recurringStatus: "Active",
            lastUpdated: new Date(),
            customerId
          };
          batch.update(recurringServiceRef, recurringServiceData);
        }
  
        if (editedJob.applyToRecurring) {
          const jobsRef = collection(db, 'organizations', orgId, 'jobs');
          const recurringJobsQuery = query(
            jobsRef,
            where('recurrenceGroupId', '==', selectedJob.recurrenceGroupId),
            where('appointmentDate', '>=', appointmentDate)
          );
  
          const recurringJobsSnapshot = await getDocs(recurringJobsQuery);
  
          recurringJobsSnapshot.docs.forEach(doc => {
            const instanceData = doc.data();
  
            if (!instanceData.status || instanceData.status.toLowerCase() === 'pending') {
              try {
                const instanceDate = convertToOrgTz(instanceData.appointmentDate.toDate());
                const newInstanceStart = instanceDate
                  .hour(appointmentDate.getHours())
                  .minute(appointmentDate.getMinutes());
  
                const newInstanceEnd = moment(newInstanceStart).add(scheduledDuration, 'milliseconds');
  
                batch.update(doc.ref, {
                  ...baseUpdateData,
                  appointmentDate: convertFromOrgTz(newInstanceStart).toDate(),
                  scheduledEndTime: convertFromOrgTz(newInstanceEnd).toDate(),
                  updatedAt: new Date(),
                  organizationId: orgId,
                  customerId
                });
              } catch (error) {
                console.error('Error updating recurring instance:', error);
              }
            }
          });
        } else {
          const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
          batch.update(jobRef, {
            ...baseUpdateData,
            customerId
          });
        }
      } else {
        const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
        batch.update(jobRef, baseUpdateData);
      }

      await batch.commit();
      
      // Refresh all necessary data
      await Promise.all([
        fetchJobs(),
        fetchRecurringServices(),
        fetchCustomer()
      ]);

      setIsEditing(false);
      setEditedJob(null);
      handleCloseDialog();

      setSnackbar({
        open: true,
        message: `Job${editedJob.applyToRecurring ? 's' : ''} updated successfully`,
        severity: 'success'
      });

    } catch (error) {
      console.error('Failed to save job:', error);
      setSnackbar({
        open: true,
        message: `Failed to update job: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };


  const handleRevertJobStatus = async () => {
    try {
      if (!selectedJob || !orgId) {
        return;
      }
  
      const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
      
      await updateDoc(jobRef, {
        status: JOB_STATUSES.PENDING,
        actualStartTime: null,
        actualEndTime: null,
        actualDuration: null,
        updatedAt: new Date()
      });
      
      await fetchJobs();
      setSuccess('Job reopened successfully');
    } catch (error) {
      console.error('Error reopening job:', error);
      setError('Failed to reopen job');
    }
  };
  
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedJob(null);
  };
  
  const handleEditInputChange = (field, value) => {
    setEditedJob(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const fetchServiceData = async () => {
    try {
      if (!orgId) return;
  
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const cleanersList = usersSnapshot.docs
        .map(doc => ({ 
          id: doc.id, 
          ...doc.data() 
        }))
        .filter(user => 
          user.orgId === orgId && 
          user.role === 'cleaner' &&
          user.isActive !== false
        );
      setCleaners(cleanersList);
  
      const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomTypesList = roomTypesSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.name.localeCompare(b.name));
      setAvailableRooms(roomTypesList);
  
    } catch (err) {
      console.error('Error fetching service data:', err);
      setError('Failed to fetch service data');
    }
  };

  const handleAddRooms = async (rooms) => {
    try {
      if (!editedJob) return;
      setEditedJob(prev => ({
        ...prev,
        rooms: [...(prev.rooms || []), ...rooms]
      }));
    } catch (error) {
      console.error('Error adding rooms:', error);
    }
  };
  
  const handleDeleteRoom = (index) => {
    if (!editedJob) return;
    setEditedJob(prev => ({
      ...prev,
      rooms: prev.rooms.filter((_, i) => i !== index)
    }));
  };

  const handleMarkCompleted = async () => {
    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
      
      // Check if actual times exist, if not, use current time
      const actualStartTime = selectedJob.actualStartTime || new Date();
      const actualEndTime = selectedJob.actualEndTime || new Date();
      
      await updateDoc(jobRef, {
        status: JOB_STATUSES.COMPLETED,
        actualStartTime,
        actualEndTime,
        updatedAt: new Date()
      });
      
      await fetchJobs();
      handleCloseDialog();
      setSuccess('Job marked as completed successfully');
    } catch (error) {
      console.error('Error completing job:', error);
      setError('Failed to complete job');
    }
  };

// Correctly handle recurring service status
const getRecurringStatusDetails = (status, recurringEndDate, reactivatedDate) => {
  switch (status?.toLowerCase()) {
    case 'active':
      return { 
        label: `Active${reactivatedDate ? ` since ${moment(reactivatedDate).format('MM/DD/YY')}` : ''}`,
        chipColor: '#4caf50'
      };
    case 'cancelled':
      return { 
        label: `Cancelled${recurringEndDate ? ` on ${moment(recurringEndDate).format('MM/DD/YY')}` : ''}`,
        chipColor: '#f44336'
      };
    default:
      return { 
        label: 'Unknown',
        chipColor: '#757575'
      };
  }
};

  const handleDeleteService = async (serviceId) => {
    try {
      const confirmDelete = window.confirm(
        'Are you sure you want to cancel this recurring service? This will:\n' +
        '• Delete all pending future appointments\n' +
        '• Keep completed appointments for records\n' +
        '• Mark the recurring service as cancelled'
      );
  
      if (!confirmDelete) return;
  
      const batch = writeBatch(db);
      
      // 1. Update the recurring service status
      const recurringServiceRef = doc(db, 'organizations', orgId, 'recurringServices', serviceId);
      batch.update(recurringServiceRef, {
        recurringStatus: 'cancelled',
        recurringEndDate: new Date(),
        lastUpdated: new Date()
      });
  
      // 2. Get all future pending jobs for this service
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const futureJobsQuery = query(
        jobsRef,
        where('recurrenceGroupId', '==', serviceId),
        where('appointmentDate', '>', new Date())
      );
  
      const futureJobsSnapshot = await getDocs(futureJobsQuery);
  
      // 3. Delete all future jobs that aren't completed
      futureJobsSnapshot.docs.forEach(doc => {
        const jobData = doc.data();
        if (jobData.status?.toLowerCase() !== 'completed') {
          batch.delete(doc.ref);
        }
      });
  
      // 4. Commit all changes
      await batch.commit();
  
    // 5. Refresh all relevant data
    await Promise.all([
      fetchJobs(),
      fetchRecurringServices()
    ]);

    setSuccess('Recurring service cancelled successfully');
  } catch (error) {
    console.error('Error cancelling recurring service:', error);
    setError('Failed to cancel recurring service: ' + error.message);
  }
};

const handleCompleteServiceDeletion = async (serviceId) => {
  try {
    const confirmDelete = window.confirm(
      'WARNING: This will permanently delete this recurring service and ALL associated jobs including completed ones.\n\n' +
      'This action cannot be undone. Are you sure you want to proceed?\n\n' +
      '• All jobs (past and future) will be permanently deleted\n' +
      '• All service history will be lost\n' +
      '• This cannot be reversed'
    );

    if (!confirmDelete) return;

    const batch = writeBatch(db);
    
    // 1. Delete the recurring service document
    const recurringServiceRef = doc(db, 'organizations', orgId, 'recurringServices', serviceId);
    batch.delete(recurringServiceRef);

    // 2. Get and delete ALL jobs associated with this service
    const jobsRef = collection(db, 'organizations', orgId, 'jobs');
    const allJobsQuery = query(
      jobsRef,
      where('recurrenceGroupId', '==', serviceId)
    );

    const allJobsSnapshot = await getDocs(allJobsQuery);

    // 3. Delete every single job
    allJobsSnapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
    });

    // 4. Commit all deletions
    await batch.commit();

    // 5. Refresh all relevant data
    await Promise.all([
      fetchJobs(),
      fetchRecurringServices()
    ]);

    setSuccess('Recurring service completely deleted');
  } catch (error) {
    console.error('Error deleting recurring service:', error);
    setError('Failed to delete recurring service: ' + error.message);
  }
};

const handleReactivateService = async (serviceId) => {
  const confirmReactivate = window.confirm(
    'Would you like to reactivate this recurring service? ' +
    'You will be able to update the service details before new appointments are created.'
  );

  if (!confirmReactivate) return;

  try {
    // 1. Fetch service data
    const recurringServiceRef = doc(db, 'organizations', orgId, 'recurringServices', serviceId);
    const recurringServiceDoc = await getDoc(recurringServiceRef);

    if (!recurringServiceDoc.exists()) {
      setError('Service not found');
      return;
    }

    // 2. (Optional) Reactivate the service in Firestore right away
    //    Or you can do this after user finishes editing the reactivated service if needed.
    const batch = writeBatch(db);
    batch.update(recurringServiceRef, {
      recurringStatus: 'active',
      recurringEndDate: deleteField(), // Removes the field
      lastUpdated: new Date()
    });
    await batch.commit();

    // 3. Prepare and open up the edit dialog with default values
    const serviceData = recurringServiceDoc.data();

    // Create a properly structured job object for the edit form
    const jobData = {
      ...serviceData,
      id: serviceId,
      // Use whatever default date/time you want here
      appointmentDate: convertFromOrgTz(new Date().setHours(9, 0, 0, 0)).toDate(),
      scheduledEndTime: convertFromOrgTz(new Date().setHours(11, 0, 0, 0)).toDate(),
      timezone,
      serviceType: 'recurring',
      recurring: true,
      status: 'pending',  // Ensure status is set
      cleanerId: serviceData.cleanerId || null,
      cleanerName: serviceData.cleanerName || '',
      customerName: customer.name || '',
      address: customer.serviceAddress || '',
      rooms: serviceData.rooms || [],
      notes: serviceData.notes || '',
      importantNotes: serviceData.importantNotes || '',
      totalSize: serviceData.totalSize || 0,
      serviceCost: serviceData.serviceCost || 0,
      recurrenceRule: serviceData.recurrenceRule || ''
    };

    // Set the states
    setSelectedJob(jobData);
    setEditedJob(jobData);
    setIsEditing(true);
    setIsReactivating(true);
    setIsDialogOpen(true);
  } catch (error) {
    console.error('Error preparing service reactivation:', error);
    setError('Failed to prepare service for reactivation');
  }
};
  
  const getStatusDetails = (status) => {
    if (!status) {
      return { label: 'Pending', chipColor: '#ff9800', color: 'warning' };
    }
  
    const statusLower = status.toString().toLowerCase();
  
    switch (statusLower) {
      case 'completed':
        return { label: 'Completed', chipColor: '#4caf50', color: 'success' };
      case 'in progress':
      case 'inprogress':
        return { label: 'In Progress', chipColor: '#2196f3', color: 'info' };
      case 'pending':
        return { label: 'Pending', chipColor: '#ff9800', color: 'warning' };
      case 'cancelled':
      case 'canceled':
        return { label: 'Cancelled', chipColor: '#f44336', color: 'error' };
      default:
        return { 
          label: status.toString(), 
          chipColor: '#757575', 
          color: 'default' 
        };
    }
  };

  const groupRecurringServices = (jobsList) => {
    if (!Array.isArray(jobsList)) {
      console.warn('Expected an array of jobs, received:', typeof jobsList);
      return [];
    }
  
    const servicesMap = jobsList.reduce((acc, job) => {
      if (!job) return acc;
  
      if (job.recurring && job.recurrenceGroupId) {
        if (!acc[job.recurrenceGroupId]) {
          // Initialize new service entry with complete structure
          acc[job.recurrenceGroupId] = {
            id: job.recurrenceGroupId,
            serviceType: job.serviceType || 'Standard Service',
            recurrenceRule: job.recurrenceRule || null,
            serviceCost: job.serviceCost || job.price || 0,
            serviceStatus: job.serviceStatus || SERVICE_STATUSES.ACTIVE,
            serviceActivatedDate: job.serviceActivatedDate || null,
            serviceCancelledDate: job.serviceCancelledDate || null,
            status: job.status || 'pending',
            metrics: {
              revenueToDate: 0,
              completedJobsCount: 0,
              avgDuration: 0,
              totalDuration: 0,
              nextAppointment: null,
              jobs: []
            }
          };
        }
  
        // Update service status if this is a status marker job
        if (job.isStatusMarker) {
          acc[job.recurrenceGroupId].serviceStatus = job.serviceStatus;
          acc[job.recurrenceGroupId].serviceActivatedDate = job.serviceActivatedDate || null;
          acc[job.recurrenceGroupId].serviceCancelledDate = job.serviceCancelledDate || null;
        }
  
        const metrics = acc[job.recurrenceGroupId].metrics;
        metrics.jobs.push(job);
  
        const status = (job.status || '').toLowerCase();
        if (status === 'completed') {
          metrics.completedJobsCount++;
          metrics.revenueToDate += parseFloat(job.price || 0);
          
          if (job.startTime && job.endTime) {
            try {
              const startTime = job.startTime?.toDate?.() || job.startTime;
              const endTime = job.endTime?.toDate?.() || job.endTime;
              
              if (startTime instanceof Date && endTime instanceof Date) {
                const duration = moment(endTime).diff(moment(startTime), 'minutes');
                if (!isNaN(duration) && duration > 0) {
                  metrics.totalDuration += duration;
                }
              }
            } catch (err) {
              console.error('Error calculating duration:', err);
            }
          }
        }
  
        // Find next appointment (only for pending jobs)
        if (job.appointmentDate) {
          try {
            const appointmentDate = job.appointmentDate?.toDate?.() || job.appointmentDate;
            const currentDate = new Date();
            
            if (appointmentDate instanceof Date && appointmentDate > currentDate) {
              if (!metrics.nextAppointment || appointmentDate < metrics.nextAppointment) {
                metrics.nextAppointment = appointmentDate;
              }
            }
          } catch (err) {
            console.error('Error processing appointment date:', err);
          }
        }
  
        // Update the accumulated service details
        acc[job.recurrenceGroupId] = {
          ...acc[job.recurrenceGroupId],
          serviceType: job.serviceType || acc[job.recurrenceGroupId].serviceType,
          recurrenceRule: job.recurrenceRule || acc[job.recurrenceGroupId].recurrenceRule,
          serviceCost: job.serviceCost || job.price || acc[job.recurrenceGroupId].serviceCost,
        };
      }
      return acc;
    }, {});
  
    // Process metrics and sort jobs for each service
    Object.values(servicesMap).forEach(service => {
      const metrics = service.metrics;
  
      // Calculate average duration
      metrics.avgDuration = metrics.completedJobsCount > 0 
        ? metrics.totalDuration / metrics.completedJobsCount 
        : 0;
  
      // Sort jobs by date
      if (Array.isArray(metrics.jobs)) {
        metrics.jobs.sort((a, b) => {
          try {
            const dateA = a.appointmentDate?.toDate?.() || a.appointmentDate;
            const dateB = b.appointmentDate?.toDate?.() || b.appointmentDate;
            
            if (dateA instanceof Date && dateB instanceof Date) {
              return moment(dateA).diff(moment(dateB));
            }
            return 0;
          } catch (err) {
            console.error('Error sorting jobs:', err);
            return 0;
          }
        });
      }
  
      // Ensure all required properties exist
      service.metrics = {
        revenueToDate: metrics.revenueToDate || 0,
        completedJobsCount: metrics.completedJobsCount || 0,
        avgDuration: metrics.avgDuration || 0,
        totalDuration: metrics.totalDuration || 0,
        nextAppointment: metrics.nextAppointment || null,
        jobs: metrics.jobs || []
      };
  
      // Ensure service status properties exist
      service.serviceStatus = service.serviceStatus || SERVICE_STATUSES.ACTIVE;
      service.serviceActivatedDate = service.serviceActivatedDate || null;
      service.serviceCancelledDate = service.serviceCancelledDate || null;
    });
    return Object.values(servicesMap);
  };
  
  const handleServiceSelect = (serviceId) => {
    // If we're already showing this service's form, clicking again should close it
    if (selectedServiceId === serviceId) {
      setSelectedServiceId(null);
      return;
    }
    
    setSelectedServiceId(serviceId);
  };

  useEffect(() => {
    if (orgId && customerId) {
      fetchCustomer();
      fetchJobs();
      fetchFirstAppointment();
      fetchServiceData();
    }
  }, [orgId, customerId]);

  useEffect(() => {
    // Only run if we have both fetchedRecurringServices and jobs loaded
    if (fetchedRecurringServices.length > 0 && jobs.length > 0) {
      const groupedServices = groupRecurringServices(jobs); // This returns services with metrics
      
      // Convert grouped services into a map for easy lookup by ID
      const metricsMap = groupedServices.reduce((acc, svc) => {
        acc[svc.id] = svc.metrics;
        return acc;
      }, {});
  
      // Merge fetchedRecurringServices with metrics from jobs
      const unifiedServices = fetchedRecurringServices.map(service => {
        const metrics = metricsMap[service.id] || {
          completedJobsCount: 0,
          avgDuration: 0,
          nextAppointment: null,
          revenueToDate: 0
        };
        return {
          ...service,
          metrics
        };
      });
  
      setRecurringServices(unifiedServices);
    }
  }, [fetchedRecurringServices, jobs]);// Only depends on jobs

  const fetchEmailTemplates = async () => {
    if (!orgId) {
      console.log('No orgId available, skipping email template fetch');
      return;
    }
    
    try {
      const templatesRef = collection(db, 'organizations', orgId, 'emailTemplates');
      const templatesSnap = await getDocs(templatesRef);
      const templates = templatesSnap.docs.map(doc => ({
        documentId: doc.id,
        ...doc.data()
      }));
      setEmailTemplates(templates);
    } catch (error) {
      console.error('Error fetching email templates:', error);
      setError('Failed to load email templates');
    }
  };
  
  // Use the function in useEffect
  useEffect(() => {
    fetchEmailTemplates();
  }, [orgId]); 

  const getAuthToken = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken(true);
      return token;
    } catch (err) {
      console.error('Error getting auth token:', err);
      throw err;
    }
  };

  // Function to send email
  const handleSendEmail = async (emailData) => {
    try {
      if (!selectedJob) {
        throw new Error('No job selected');
      }
  
      const token = await getAuthToken();
  
      // Fetch customer data from the customers collection
      const customerRef = doc(db, 'organizations', selectedJob.organizationId, 'customers', selectedJob.customerId);
      const customerSnap = await getDoc(customerRef);
  
      if (!customerSnap.exists()) {
        throw new Error('Customer not found');
      }
  
      const customerData = customerSnap.data();
  
      if (!customerData.email) {
        throw new Error('Customer email not found');
      }
  
      // Get cleaner details from the job
      const cleanerDetails = selectedJob.cleanerDetails || [];
      console.log('Cleaner details:', cleanerDetails);
  
      console.log('Email data being sent:', {
        orgId: selectedJob.organizationId,
        documentId: emailData.documentId,
        subject: emailData.subject,
        content: emailData.content,
        recipientEmail: customerData.email,
        cleanerDetails
      });
  
      await notificationsApi.sendEmail(token, {
        orgId: selectedJob.organizationId,
        documentId: emailData.documentId,
        recipientEmail: customerData.email,
        recipientName: customerData.name || customerData.firstName,
        subject: emailData.subject,
        content: emailData.content,
        jobData: {
          appointmentDate: selectedJob.appointmentDate?.toDate?.() || selectedJob.appointmentDate,
          scheduledStartTime: selectedJob.scheduledStartTime?.toDate?.() || selectedJob.scheduledStartTime,
          scheduledEndTime: selectedJob.scheduledEndTime?.toDate?.() || selectedJob.scheduledEndTime,
          customerName: customerData.name || `${customerData.firstName} ${customerData.lastName}`,
          customerEmail: customerData.email,
          customerPhone: customerData.phone || customerData.phoneNumber,
          address: selectedJob.address,
          // Replace single cleaner fields with cleanerDetails array
          cleanerDetails: selectedJob.cleanerDetails || [],
          serviceCost: selectedJob.serviceCost,
          serviceType: selectedJob.serviceType,
          status: selectedJob.status,
          totalSize: selectedJob.totalSize,
          notes: selectedJob.notes,
          importantNotes: selectedJob.importantNotes,
        },
      });
  
      setSuccess('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      setError(`Failed to send email: ${error.message}`);
    }
  };

  // Use useEffect to fetch templates when component mounts
  useEffect(() => {
    fetchEmailTemplates();
  }, [orgId]);

  useEffect(() => {
    const fetchCustomStatuses = async () => {
      if (!orgId) return;

      try {
        const orgDoc = await getDoc(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          const scheduleSettings = orgDoc.data().scheduleSettings;
          if (scheduleSettings?.jobStatuses) {
            setCustomJobStatuses(scheduleSettings.jobStatuses);
          }
        }
      } catch (error) {
        console.error('Error fetching custom statuses:', error);
      }
    };

    fetchCustomStatuses();
  }, [orgId]);

  // Custom Job Statuses
      useEffect(() => {
        const fetchCustomStatuses = async () => {
          if (!orgId) return;
    
          try {
            const orgDoc = await getDoc(doc(db, 'organizations', orgId));
            if (orgDoc.exists()) {
              const scheduleSettings = orgDoc.data().scheduleSettings;
              if (scheduleSettings?.jobStatuses) {
                setCustomJobStatuses(scheduleSettings.jobStatuses);
              }
            }
          } catch (error) {
            console.error('Error fetching custom statuses:', error);
          }
        };
    
        fetchCustomStatuses();
      }, [orgId]);
    
      const handleUpdateStatus = async (jobId, newStatus) => {
        try {
          const token = await getAuthToken();
    
          await notificationsApi.updatedJobStatus(token, {
            orgId,
            jobId,
            status: newStatus,
          });
    
          await fetchJobs();
          setSuccess('Status updated successfully!');
        } catch (error) {
          console.error('Error updating status:', error);
          setError(`Failed to update status: ${error.message}`);
        }
      };
    
      const handleUpdate = async () => {
        await fetchJobs();
      };
  

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;
  if (!customer) {
    return <Typography>No customer details available.</Typography>;
  }


  return (
    <div className={styles.container}>
      <div className={styles.profileHeader}>
        <button 
          onClick={() => navigate('/jobs/clients')}
          className={styles.backButton}
        >
          <ArrowLeft className={styles.backIcon} />
          Back to Clients
        </button>
        <h1 className={styles.customerName}>{customer?.name || 'Customer Profile'}</h1>
      </div>
      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${activeTab === 'profile' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('profile')}
        >
          Customer Profile
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'recurring' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('recurring')}
        >
          Recurring Services
        </button>
      </div>
  
      <div className={styles.tabContent}>
        {activeTab === 'profile' ? (
          <div className={styles.profileContainer}>
            <div className={styles.topSection}>
            <StyledCard className={styles.topLeftCard}>
              <CardContent sx={{ pb: 2 }}> 
                <StyledBox>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    mb: 1.5  
                  }}>
                    <Typography variant="h6">Client Details</Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {!customer.userAccountId && (
                        <Button
                          onClick={createCustomerAccount}
                          variant="outlined"
                          size="small"
                          disabled={loading}
                          sx={{ 
                            borderColor: 'primary.main',
                            '&:hover': {
                              borderColor: 'primary.dark',
                              backgroundColor: 'rgba(25, 118, 210, 0.04)'
                            }
                          }}
                        >
                          Create Portal Account
                        </Button>
                      )}
                      <Button 
                        onClick={handleEditCustomerClick}
                        variant="text"
                        size="small"  
                        disabled={loading}
                      >
                        Edit
                      </Button>
                    </Box>
                  </Box>
                  {/* Customer Portal Status */}
                  {customer.userAccountId && (
                  <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="body2">
                      <strong>Portal Account:</strong>
                    </Typography>
                    <Chip
                      label={customer.accountStatus === 'active' ? 'Active' : 'Inactive'}
                      color={customer.accountStatus === 'active' ? 'success' : 'default'}
                      size="small"
                    />
                  </Box>
                )}                  

                  <Box sx={{ 
                    display: 'grid',
                    gap: 1.5  
                  }}>
                    <Typography sx={{ lineHeight: 1.4 }}> 
                      <strong>Name:</strong> {customer.name}
                    </Typography>

                    <Typography sx={{ lineHeight: 1.4 }}>
                      <strong>Customer Since:</strong> {firstAppointment 
                        ? moment(firstAppointment.toDate()).format('MM/DD/YYYY')
                        : 'N/A'}
                    </Typography>

                    <Typography sx={{ lineHeight: 1.4 }}>
                      <strong>Phone:</strong> {customer.phone}
                    </Typography>

                    <Typography sx={{ lineHeight: 1.4 }}>
                      <strong>Email:</strong> {customer.email}
                    </Typography>

                    <Divider sx={{ my: 1 }} /> 

                    <Typography sx={{ lineHeight: 1.4 }}>
                      <strong>Service Address:</strong> {
                        typeof customer.serviceAddress === 'object' 
                          ? customer.serviceAddress.complete || `${customer.serviceAddress.line1}, ${customer.serviceAddress.city}, ${customer.serviceAddress.state}`
                          : customer.serviceAddress || 'N/A'
                      }
                    </Typography>

                    <Typography sx={{ lineHeight: 1.4 }}>
                      <strong>Billing Address:</strong> {
                        typeof customer.billingAddress === 'object'
                          ? customer.billingAddress.complete || `${customer.billingAddress.line1}, ${customer.billingAddress.city}, ${customer.billingAddress.state}`
                          : customer.billingAddress || customer.serviceAddress || 'N/A'
                      }
                    </Typography>
                  </Box>
                </StyledBox>
              </CardContent>
            </StyledCard>
  
            <StyledCard className={styles.topRightCard}>
              <CardContent>
                <StyledBox>
                  <Typography variant="h6" gutterBottom>Financial Summary</Typography>
                  <Box sx={{ mt: 2 }}>
                    <div className={styles.metricsRow}>
                      <StyledMetricBox>
                        <MetricLabel>Total Revenue</MetricLabel>
                        <MetricValue>${clientTotalMetrics.totalRevenue.toFixed(2)}</MetricValue>
                      </StyledMetricBox>
                      
                      <StyledMetricBox>
                        <MetricLabel>Total Profit</MetricLabel>
                        <MetricValue>${clientTotalMetrics.totalProfit.toFixed(2)}</MetricValue>
                      </StyledMetricBox>
                    </div>

                    <Divider sx={{ my: 1 }} />

                    <div className={styles.metricsRow} sx={{ mt: 3 }}>
                      <StyledMetricBox>
                        <MetricLabel>Average Profit Margin</MetricLabel>
                        <MetricValue>
                          {clientTotalMetrics.totalRevenue > 0 
                            ? ((clientTotalMetrics.totalProfit / clientTotalMetrics.totalRevenue) * 100).toFixed(1)
                            : '0'}%
                        </MetricValue>
                      </StyledMetricBox>

                      <StyledMetricBox>
                        <MetricLabel>Unpaid Invoices</MetricLabel>
                        <MetricValue>0</MetricValue>
                      </StyledMetricBox>
                    </div>
                  </Box>
                </StyledBox>
              </CardContent>
            </StyledCard>
            </div>
  
            {recurringServices.map(service => {
              const serviceJobs = jobs.filter(job => job.recurrenceGroupId === service.id);
              const serviceMetrics = serviceMetricsMap[service.id] || {
                completedJobsCount: 0,
                avgDuration: 0,
                avgRevenuePerService: 0,
                avgPayrollCost: 0,
                avgProfit: 0
              };
              
              const latestJob = serviceJobs.reduce((latest, job) => {
                if (!latest) return job;
                
                const getDate = (dateField) => {
                  if (!dateField) return null;
                  if (dateField instanceof Date) return dateField;
                  if (dateField.toDate && typeof dateField.toDate === 'function') return dateField.toDate();
                  return new Date(dateField); // fallback for string dates
                };
                
                const currentDate = getDate(job.updatedAt);
                const latestDate = getDate(latest.updatedAt);
                
                if (!currentDate || !latestDate) return latest;
                return currentDate > latestDate ? job : latest;
              }, null);

              const currentServiceCost = parseFloat(latestJob?.serviceCost) 
                || parseFloat(latestJob?.price) 
                || parseFloat(service.serviceCost) 
                || 0;
              
              return (
                <div key={service.id} className={styles.serviceMetricsSection}>
                  <Typography 
                    variant="h6" 
                    gutterBottom 
                    sx={{ 
                      px: 2,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      mb: 2
                    }}
                  >
                    {service.recurrenceRule ? 'Recurring' : 'One-Time'} Services Information
                  </Typography>

                  <StyledCard className={styles.jobInfoCard}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom sx={{ px: 2 }}>Service Information</Typography>
                      <div className={styles.metricsRow}>
                        <ServiceMetricBox>
                          <MetricLabel>Service Started</MetricLabel>
                          <MetricValue>
                            {(service.activatedDate || service.recurringStartDate)
                              ? moment((service.activatedDate || service.recurringStartDate).toDate()).format('MM/DD/YYYY')
                              : 'N/A'}
                          </MetricValue>
                        </ServiceMetricBox>

                        <ServiceMetricBox>
                          <MetricLabel>Service Type</MetricLabel>
                          <MetricValue>
                            {service.recurrenceRule ? 'Recurring' : 'One-Time'}
                          </MetricValue>
                          {service.recurrenceRule && (
                            <>
                              <Typography 
                                variant="caption" 
                                display="block" 
                                sx={{ 
                                  color: 'var(--dark-text-light)',
                                  fontSize: '0.75rem',
                                  marginTop: '4px'
                                }}
                              >
                                {formatRecurrenceRule(service.recurrenceRule)}
                              </Typography>
                              <Chip
                                label={service.recurringStatus === 'Active' ? 'Active' : 'Cancelled'}
                                size="small"
                                sx={{ 
                                  mt: 1,
                                  backgroundColor: service.recurringStatus === 'Active' ? '#4caf50' : '#f44336',
                                  color: 'white'
                                }}
                              />
                            </>
                          )}
                        </ServiceMetricBox>

                        <ServiceMetricBox>
                          <MetricLabel>Completed Services</MetricLabel>
                          <MetricValue>
                            {serviceMetrics?.completedJobsCount || 0}
                          </MetricValue>
                        </ServiceMetricBox>

                        <ServiceMetricBox>
                          <MetricLabel>Average Duration</MetricLabel>
                          <MetricValue>
                            {Number(serviceMetrics?.avgDuration || 0).toFixed(2)} hrs
                          </MetricValue>
                        </ServiceMetricBox>
                      </div>
                    </CardContent>
                  </StyledCard>

                  <StyledCard className={styles.jobInfoCard}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom sx={{ px: 2 }}>Financial Metrics</Typography>
                      <div className={styles.metricsRow}>
                        <StyledMetricBox>
                          <MetricLabel>Service Cost</MetricLabel>
                          <MetricValue>
                            ${Number(service.serviceCost || 0).toFixed(2)}
                          </MetricValue>
                        </StyledMetricBox>

                        <StyledMetricBox>
                          <MetricLabel>Average Revenue Per Service</MetricLabel>
                          <MetricValue>
                            ${Number(serviceMetrics?.avgRevenuePerService || 0).toFixed(2)}
                          </MetricValue>
                        </StyledMetricBox>

                        <StyledMetricBox>
                          <MetricLabel>Average Payroll Cost</MetricLabel>
                          <MetricValue>
                            ${Number(serviceMetrics?.avgPayrollCost || 0).toFixed(2)}
                          </MetricValue>
                        </StyledMetricBox>

                        <StyledMetricBox>
                          <MetricLabel>Average Profit Per Service</MetricLabel>
                          <MetricValue>
                            ${Number(serviceMetrics?.avgProfit || 0).toFixed(2)}
                          </MetricValue>
                        </StyledMetricBox>
                      </div>
                    </CardContent>
                  </StyledCard>

                  
                  
                  {/* Add the ClientPreferences component here */}
                  <StyledCard className={styles.preferencesCard}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>Service Preferences</Typography>
                      <ClientPreferences 
                        customer={customer}
                        orgId={orgId}
                        recurrenceGroupId={service.id}
                      />
                    </CardContent>
                  </StyledCard>
                  
                  <Divider sx={{ my: 4 }} />
                    </div>
                  );
                })}
  
            <StyledCard className={styles.servicesCard}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ px: 2 }}>Services</Typography>
                <StyledTabs 
                  value={servicesTab} 
                  onChange={handleTabChange}
                >
                  <StyledTab label="Upcoming Services" />
                  <StyledTab label="Past Services" />
                </StyledTabs>
                
                <List>
                {jobs
                  .filter((job) => {
                    const jobDate = job.appointmentDate;
                    if (!jobDate) return false;
                    
                    try {
                      const now = moment().tz(timezone);
                      const appointmentDate = jobDate.toDate?.() 
                        ? moment(jobDate.toDate()).tz(timezone)
                        : moment(jobDate).tz(timezone);
                      
                      return servicesTab === 0 
                        ? appointmentDate.isSameOrAfter(now, 'day')
                        : appointmentDate.isBefore(now, 'day');
                    } catch (err) {
                      console.error('Error processing date:', err);
                      return false;
                    }
                  })
                  .sort((a, b) => {
                    try {
                      const dateA = a.appointmentDate.toDate?.() 
                        ? moment(a.appointmentDate.toDate()).tz(timezone)
                        : moment(a.appointmentDate).tz(timezone);
                      const dateB = b.appointmentDate.toDate?.() 
                        ? moment(b.appointmentDate.toDate()).tz(timezone)
                        : moment(b.appointmentDate).tz(timezone);
                      
                      return servicesTab === 0 
                        ? dateA.valueOf() - dateB.valueOf()
                        : dateB.valueOf() - dateA.valueOf();
                    } catch (err) {
                      console.error('Error sorting dates:', err);
                      return 0;
                    }
                  })
                  .map((job) => (
                    <ServiceListItem 
                      key={job.id}
                      onClick={() => handleJobClick(job)}
                    >
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="subtitle1" component="span">
                          {job.appointmentDate.toDate?.() 
                            ? moment(job.appointmentDate.toDate()).tz(timezone).format('MM/DD/YYYY hh:mm A')
                            : moment(job.appointmentDate).tz(timezone).format('MM/DD/YYYY hh:mm A')}
                          </Typography>
                          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            {job.recurring && (
                              <Chip
                                label="Recurring"
                                size="small"
                                sx={{ backgroundColor: '#2196f3', color: 'white' }}
                              />
                            )}
                            <Chip
                              label={getStatusDetails(job.status).label}
                              size="small"
                              sx={{ 
                                backgroundColor: getStatusDetails(job.status).chipColor,
                                color: 'white'
                              }}
                            />
                          </Box>
                        </Box>
                      }
                      secondary={
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="body2" component="span">
                            Cleaner: {job.cleanerNames || 'Unassigned'}
                          </Typography>
                        <Typography variant="body2" component="span" sx={{ ml: 2 }}>
                            Address: {
                              typeof job.address === 'object' 
                                ? job.address.complete || `${job.address.line1}, ${job.address.city}, ${job.address.state}`
                                : job.address || 'N/A'
                            }
                          </Typography>
                          {job.recurring && (
                            <Typography variant="body2" component="span" sx={{ ml: 2 }}>
                              Service Type: {capitalizeFirstLetter(job.serviceType) || 'Standard Service'}
                            </Typography>
                          )}
                        </Box>
                        }
                        primaryTypographyProps={{ component: 'div' }}
                        secondaryTypographyProps={{ component: 'div' }}
                      />
                    </ServiceListItem>
                  ))}
                </List>
              </CardContent>
            </StyledCard>
          </div>
        ) : (
          <div className={styles.recurringServicesContainer}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError('')}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess('')}>
                {success}
              </Alert>
            )}
  
            {recurringServices.length > 0 ? (
              <>
              {recurringServices.length > 0 && (
                <StyledCard sx={{ mb: 3 }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h6">Recurring Services</Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsAddingNewService(true)}
                        startIcon={<AddIcon />}
                        size="small"
                      >
                        Add New Service
                      </Button>
                    </Box>
                    <div className={styles.serviceCards}>
                    {recurringServices.map((service) => {
                      const recurringStatus = service.recurringStatus || 'Active';
                      const statusDetails = getRecurringStatusDetails(
                        recurringStatus
                      );

                      return (
                        <StyledCard 
                          key={service.id}
                          className={`${styles.serviceCard} ${selectedServiceId === service.id ? styles.selectedCard : ''}`}
                          onClick={() => handleServiceSelect(service.id)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Typography variant="subtitle1">Recurring Service</Typography>
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                {recurringStatus === 'Active' ? (
                                  <>
                                    <Button
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteService(service.id);
                                      }}
                                    >
                                      Cancel Service
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleCompleteServiceDeletion(service.id);
                                      }}
                                    >
                                      Delete All
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      variant="contained"
                                      color="success"
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleReactivateService(service.id);
                                      }}
                                    >
                                      Reactivate
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleCompleteServiceDeletion(service.id);
                                      }}
                                    >
                                      Delete All
                                    </Button>
                                  </>
                                )}
                              </Box>
                            </Box>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                              {formatRecurrenceRule(service.recurrenceRule)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Next Service: {service.metrics?.nextAppointment 
                                ? moment(service.metrics.nextAppointment).format('MM/DD/YYYY')
                                : 'None scheduled'}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                              <Chip
                                label={statusDetails.label}
                                sx={{ 
                                  backgroundColor: statusDetails.chipColor,
                                  color: 'white'
                                }}
                                size="small"
                              />
                              <Typography variant="body2">
                                ${service.serviceCost}/service
                              </Typography>
                            </Box>
                          </CardContent>
                        </StyledCard>
                        );
                      })}
                    </div>
                  </CardContent>
                </StyledCard>
                )}

                <Dialog
                  open={isAddingNewService}
                  onClose={() => setIsAddingNewService(false)}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle>Create New Recurring Service</DialogTitle>
                  <DialogContent>
                    <CreateRecurringService
                      customer={customer}
                      orgId={orgId}
                      cleaners={cleaners}
                      availableRooms={availableRooms}
                      onServiceCreated={async () => {
                        await fetchJobs();
                        setIsAddingNewService(false);
                        setSuccess('New recurring service created successfully');
                      }}
                      onCancel={() => setIsAddingNewService(false)}
                      open={isAddingNewService}  // Add this prop
                    />
                  </DialogContent>
                </Dialog>

              {selectedServiceId && !isReactivating && (
                      <StyledCard>
                        <CardContent>
                          <EditRecurringService
                            customer={customer}
                            jobs={jobs.filter(job => job.recurrenceGroupId === selectedServiceId)}
                            orgId={orgId}
                            fetchJobs={fetchJobs}
                            recurrenceGroupId={selectedServiceId}
                            service={recurringServices.find(s => s.id === selectedServiceId)}
                            onSave={async () => {
                              await fetchJobs();
                              await fetchRecurringServices();
                              setSuccess('Service updated successfully');
                            }}
                            onCancel={() => {
                              setSelectedServiceId(null);
                            }}
                          />
                        </CardContent>
                      </StyledCard>
                    )}
  
                  {isEditing && isReactivating && editedJob && (
                  <EditRecurringService
                    customer={customer}
                    // Filter jobs by the editedJob's recurrenceGroupId
                    jobs={Array.isArray(jobs) ? jobs.filter(job => job.recurrenceGroupId === editedJob.id) : []}
                    orgId={orgId}
                    fetchJobs={fetchJobs}
                    recurrenceGroupId={editedJob.id}
                    service={recurringServices.find(s => s.id === editedJob.id) || {}}
                    isReactivating={true}
                    onSave={async () => {
                      await fetchJobs();
                      await fetchRecurringServices();
                      setIsEditing(false);
                      setIsReactivating(false);
                      setIsReactivatingService(false);
                      setServiceToReactivate(null);
                      setEditedJob(null);
                      setSuccess('Service reactivated successfully');
                    }}
                    onCancel={() => {
                      setIsEditing(false);
                      setIsReactivating(false);
                      setIsReactivatingService(false);
                      setServiceToReactivate(null);
                      setEditedJob(null);
                    }}
                  />
                )}
              </>
            ) : (
              <div className={styles.noServices}>
                <Typography variant="h6" gutterBottom>No Recurring Services</Typography>
                <Typography color="textSecondary" sx={{ mb: 3 }}>
                  This customer doesn't have any recurring services set up yet.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddingNewService(true)}
                  startIcon={<AddIcon />}
                >
                  Create Recurring Service
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
  
      <JobDialog
        open={isDialogOpen}
        onClose={() => {
          handleCloseDialog();
          setIsReactivating(false); // Add this to reset reactivation state
        }}
        job={selectedJob}
        isEditing={isEditing}
        handleEditClick={handleEditClick}
        handleDeleteJob={handleDeleteJob}
        handleMarkCompleted={handleMarkCompleted}
        handleDeleteRecurringJob={handleDeleteRecurringJob}
        fetchRecurringInstances={fetchRecurringInstances}
        showingRecurringSeries={showingRecurringSeries}
        recurringInstances={recurringInstances}
        generateMoreInstances={generateMoreInstances}
        handleSaveEdit={handleSaveEdit}
        handleCancelEdit={handleCancelEdit}
        editedJob={editedJob}
        handleEditInputChange={handleEditInputChange}
        handleAddRooms={handleAddRooms}
        handleDeleteRoom={handleDeleteRoom}
        openAddRoomsModal={openAddRoomsModal}
        setOpenAddRoomsModal={setOpenAddRoomsModal}
        availableRooms={availableRooms}
        formatRecurrenceRule={formatRecurrenceRule}
        getStatusDetails={getStatusDetails}
        handleApplyToRecurringChange={handleApplyToRecurringChange}
        applyToRecurring={applyToRecurring}
        handleRevertJobStatus={handleRevertJobStatus}
        cleaners={cleaners}
        customJobStatuses={customJobStatuses}
        defaultJobStatuses={DEFAULT_JOB_STATUSES}
        handleUpdateStatus={handleUpdateStatus}
        onUpdate={handleUpdate}
        emailTemplates={emailTemplates}
        onSendEmail={handleSendEmail}
      />
  
      <CreateCustomerModal
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        handleCreateCustomer={handleUpdateCustomer}
        isEditing={true}
        initialData={editCustomerData}
      />
  
      <Dialog
        open={isAddingNewService}
        onClose={() => setIsAddingNewService(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create New Recurring Service</DialogTitle>
        <DialogContent>
          <CreateRecurringService
            customer={customer}
            orgId={orgId}
            cleaners={cleaners}
            totalSize={totalSize}
            setTotalSize={setTotalSize}
            availableRooms={availableRooms}
            onServiceCreated={async () => {
              await fetchJobs();
              setIsAddingNewService(false);
              setSuccess('New recurring service created successfully');
            }}
            onCancel={() => setIsAddingNewService(false)}
          />
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomerProfileManagement;
