// src/context/ModalContext.js
import React, { createContext, useContext, useState } from 'react';
import { doc, updateDoc, collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import AvailabilityModal from '../components/AvailabilityModal';
import ClientRequestModal from '../components/ClientRequestModal';
import moment from 'moment'; 
import { useAuth } from '../context/AuthContext';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalData, setModalData] = useState({
    isOpen: false,
    type: null,
    data: null,
    loading: false
  });
  const { user } = useAuth();

  const openAvailabilityModal = (data) => {
    setModalData({
      isOpen: true,
      type: 'availability',
      data,
      loading: false
    });
  };

  const openClientRequestModal = (data) => {
    setModalData({
      isOpen: true,
      type: 'clientRequest',
      data,
      loading: false
    });
  };

  const closeModal = () => {
    setModalData({
      isOpen: false,
      type: null,
      data: null,
      loading: false
    });
  };

  const handleClientRequestUpdate = async (updatedData) => {
    if (!updatedData || !updatedData.orgId || !updatedData.id) {
      console.error('Missing required data for update');
      return;
    }
  
    setModalData(prev => ({ ...prev, loading: true }));
    
    try {
      // First update the client request with only the allowed fields
      const requestRef = doc(
        db, 
        'organizations', 
        updatedData.orgId, 
        'clientRequests', 
        updatedData.id
      );
  
      // Only update the fields allowed by security rules
      const requestUpdate = {
        status: updatedData.status,
        adminNotes: updatedData.adminNotes || '',
        updatedAt: new Date(),
        updatedBy: user?.uid || ''
      };
  
      await updateDoc(requestRef, requestUpdate);
  
      // If approved, then update the job
      if (updatedData.status === 'approved' && updatedData.jobId) {
        const jobRef = doc(
          db, 
          'organizations', 
          updatedData.orgId, 
          'jobs', 
          updatedData.jobId
        );
  
        const jobUpdate = {
          lastUpdated: new Date()
        };
  
        // Add appropriate updates based on request type
        if (updatedData.type === 'cancel') {
          jobUpdate.status = 'cancelled';
          jobUpdate.cancellationReason = updatedData.clientNotes || '';
          jobUpdate.cancelledAt = new Date();
        } else {
          if (updatedData.requestedDate) jobUpdate.appointmentDate = updatedData.requestedDate;
          if (updatedData.requestedEndTime) jobUpdate.scheduledEndTime = updatedData.requestedEndTime;
          if (updatedData.newCleanerId) jobUpdate.cleanerId = updatedData.newCleanerId;
          if (updatedData.newCleanerName) jobUpdate.cleanerName = updatedData.newCleanerName;
        }
  
        await updateDoc(jobRef, jobUpdate);
      }
  
      // Create notification
      const notificationsRef = collection(
        db,
        'organizations',
        updatedData.orgId,
        'notifications'
      );
  
      const notificationData = {
        userId: updatedData.customerId,
        type: updatedData.type === 'cancel' ? 'cancellation_request' : 'reschedule_request',
        status: updatedData.status,
        title: `${updatedData.type === 'cancel' ? 'Cancellation' : 'Reschedule'} Request ${updatedData.status === 'approved' ? 'Approved' : 'Denied'}`,
        message: updatedData.type === 'cancel' ?
          `Your request to cancel your appointment on ${moment(updatedData.currentAppointmentDate).format('MMMM D, YYYY')} has been ${updatedData.status}.` :
          `Your request to reschedule your appointment ${updatedData.requestedDate ? `to ${moment(updatedData.requestedDate).format('MMMM D, YYYY')} at ${moment(updatedData.requestedDate).format('h:mm A')}` : ''} has been ${updatedData.status}.`,
        read: false,
        createdAt: new Date(),
        createdBy: user?.uid || '',
        jobId: updatedData.jobId,
        meta: {
          oldDate: updatedData.currentAppointmentDate,
          newDate: updatedData.requestedDate,
          adminNotes: updatedData.adminNotes
        }
      };
  
      await addDoc(notificationsRef, notificationData);
  
      closeModal();
    } catch (error) {
      console.error('Error updating client request:', error);
      setModalData(prev => ({ ...prev, loading: false }));
    }
  };

  const handleStatusChange = async (updatedData) => {
    setModalData(prev => ({ ...prev, loading: true }));
    
    try {
      const { orgId, cleanerId } = updatedData;

      // Update the time off request status
      const requestRef = doc(
        db, 
        'organizations', 
        orgId, 
        'cleaners', 
        cleanerId, 
        'timeOffRequests', 
        updatedData.requestId
      );

      await updateDoc(requestRef, {
        status: updatedData.status,
        updatedAt: new Date(),
        updatedBy: user.uid
      });

      if (updatedData.status === 'approved') {
        const cleanerRef = doc(db, 'organizations', orgId, 'cleaners', cleanerId);
        const timeKey = `${updatedData.date.format('YYYY-MM-DD')}-${updatedData.start.format('HH-mm')}-${updatedData.end.format('HH-mm')}`;
        
        await updateDoc(cleanerRef, {
          [`availabilityStatuses.${timeKey}`]: {
            status: 'time_off_approved',
            startTime: updatedData.start.toDate(),
            endTime: updatedData.end.toDate(),
            duration: updatedData.duration,
            updatedAt: new Date(),
            updatedBy: user.uid,
            type: 'time_off',
            requestId: updatedData.requestId
          }
        });
      }

      closeModal();
    } catch (error) {
      console.error('Error updating request:', error);
      setModalData(prev => ({ ...prev, loading: false }));
    }
  };

  return (
    <ModalContext.Provider value={{ 
      openAvailabilityModal, 
      openClientRequestModal, 
      closeModal 
    }}>
      {children}
      {modalData.type === 'availability' && (
        <AvailabilityModal
          open={modalData.isOpen}
          onClose={closeModal}
          availabilityData={modalData.data}
          onStatusChange={handleStatusChange}
          loading={modalData.loading}
        />
      )}
      {modalData.type === 'clientRequest' && (
        <ClientRequestModal
          open={modalData.isOpen}
          onClose={closeModal}
          requestData={modalData.data}
          onStatusChange={handleClientRequestUpdate}
          loading={modalData.loading}
          orgId={modalData.data?.orgId} 
        />
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};