import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  Divider,
  IconButton,
  Paper,
  Grid,
  Card,
  CardContent
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';

const AdminServiceAddOnsModal = ({ 
  open, 
  onClose, 
  serviceAddOns = [], 
  currentServiceCost,
  onSubmit,
  loading 
}) => {
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [notes, setNotes] = useState('');

  // Reset state when modal opens
  useEffect(() => {
    if (open) {
      setSelectedAddOns([]);
      setNotes('');
    }
  }, [open]);

  const handleQuantityChange = (addOn, delta) => {
    setSelectedAddOns(current => {
      const existing = current.find(item => item.name === addOn.name);
      
      if (!existing) {
        if (delta < 0) return current;
        return [...current, { ...addOn, quantity: 1 }];
      }

      const newQuantity = (existing.quantity || 1) + delta;
      
      if (newQuantity <= 0) {
        return current.filter(item => item.name !== addOn.name);
      }

      return current.map(item => 
        item.name === addOn.name 
          ? { ...item, quantity: newQuantity }
          : item
      );
    });
  };

  const calculateTotal = () => {
    const baseCost = Number(currentServiceCost) || 0;
    const addOnsTotal = selectedAddOns.reduce((sum, addOn) => {
      const price = Number(addOn.price) || 0;
      const quantity = Number(addOn.quantity) || 1;
      return sum + (price * quantity);
    }, 0);
    return baseCost + addOnsTotal;
  };

  const getItemQuantity = (addOnName) => {
    const item = selectedAddOns.find(item => item.name === addOnName);
    return item?.quantity || 0;
  };

  const handleSubmit = () => {
    onSubmit({
      selectedAddOns,
      notes,
      totalCost: calculateTotal()
    });
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6">Add Services to Job</Typography>
      </DialogTitle>
      
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <Grid container spacing={3}>
            {/* Available Services */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Available Services
              </Typography>
              
              {serviceAddOns.map((addOn) => (
                <Card 
                  key={addOn.name}
                  sx={{ 
                    mb: 2,
                    bgcolor: getItemQuantity(addOn.name) > 0 ? 'action.selected' : 'background.paper'
                  }}
                >
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                          {addOn.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          ${addOn.price.toFixed(2)} per {addOn.unit}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          {addOn.description}
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          justifyContent: 'flex-end',
                          gap: 1 
                        }}>
                          <IconButton 
                            onClick={() => handleQuantityChange(addOn, -1)}
                            disabled={getItemQuantity(addOn.name) === 0}
                            size="small"
                          >
                            <RemoveIcon />
                          </IconButton>
                          
                          <Typography sx={{ minWidth: '40px', textAlign: 'center' }}>
                            {getItemQuantity(addOn.name)}
                          </Typography>
                          
                          <IconButton 
                            onClick={() => handleQuantityChange(addOn, 1)}
                            size="small"
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                        
                        {getItemQuantity(addOn.name) > 0 && (
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              textAlign: 'right',
                              mt: 1,
                              fontWeight: 500
                            }}
                          >
                            Subtotal: ${(addOn.price * getItemQuantity(addOn.name)).toFixed(2)}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>

            {/* Additional Notes */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Additional Notes
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder="Any special instructions or notes..."
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Grid>

            {/* Cost Summary */}
            <Grid item xs={12}>
              <Paper 
                elevation={2} 
                sx={{ 
                  p: 2,
                  bgcolor: 'primary.light',
                  color: 'primary.contrastText'
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Current Service Cost: ${Number(currentServiceCost).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Add-ons Total: ${(calculateTotal() - Number(currentServiceCost)).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      New Total: ${calculateTotal().toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button 
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={loading || selectedAddOns.length === 0}
        >
          {loading ? 'Adding Services...' : 'Add Services'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminServiceAddOnsModal;