// /src/components/StatusChip.jsx

import React from 'react';
import { JOB_STATUSES, DEFAULT_STATUS_COLORS } from '../context/JobContext';
import styles from '../styles/StatusChip.module.css';

const StatusChip = ({ status, customStatuses = [] }) => {
  // Combine default and custom statuses
  const getStatusColor = (status) => {
    // First check custom statuses
    const customStatus = customStatuses.find(s => s.name === status);
    if (customStatus) {
      return customStatus.color;
    }

    // Then check default colors
    return DEFAULT_STATUS_COLORS[status] || '#808080'; // fallback gray
  };

  return (
    <div 
      className={styles.statusChip}
      style={{ 
        backgroundColor: getStatusColor(status),
        color: '#ffffff'
      }}
    >
      {status}
    </div>
  );
};

export default StatusChip;