// src/components/ClientHeader.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  IconButton, 
  Badge, 
  Menu, 
  MenuItem, 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getAuth, signOut } from 'firebase/auth';
import { collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Bell, LogOut } from 'lucide-react';
import moment from 'moment';
import { useAuth } from '../context/AuthContext';

const StyledHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground)' : 'var(--light-foreground)',
  padding: theme.spacing(2, 3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-md-dark)' : 'var(--shadow-md-light)',
  marginBottom: theme.spacing(3)
}));

const NotificationDialog = ({ open, onClose, notification }) => {
  if (!notification) return null;

  const formatDate = (date) => {
    return moment(date?.toDate()).format('MMM D, YYYY h:mm A');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Request Details</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Status: <span style={{ 
              color: notification.status === 'approved' ? 'green' : 
                     notification.status === 'declined' ? 'red' : 'orange' 
            }}>
              {notification.status.charAt(0).toUpperCase() + notification.status.slice(1)}
            </span>
          </Typography>
          
          <Divider sx={{ my: 2 }} />
          
          <Typography variant="subtitle2" color="textSecondary">Original Appointment</Typography>
          <Typography sx={{ mb: 2 }}>
            {formatDate(notification.currentAppointmentDate)}
          </Typography>
          
          <Typography variant="subtitle2" color="textSecondary">Requested Time</Typography>
          <Typography sx={{ mb: 2 }}>
            {formatDate(notification.requestedDate)}
          </Typography>
          
          {notification.adminNotes && (
            <>
              <Typography variant="subtitle2" color="textSecondary">Admin Notes</Typography>
              <Typography sx={{ mb: 2 }}>
                {notification.adminNotes}
              </Typography>
            </>
          )}
          
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            Request submitted: {formatDate(notification.createdAt)}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const ClientHeader = ({ customer, orgId }) => {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.uid || !orgId) return;

    // Subscribe to client requests
    const requestsQuery = query(
      collection(db, 'organizations', orgId, 'clientRequests'),
      where('customerId', '==', user.uid)
    );

    const unsubscribe = onSnapshot(requestsQuery, (snapshot) => {
      const notificationsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        read: doc.data().read || false
      }));

      // Sort by creation date, newest first
      notificationsList.sort((a, b) => b.createdAt?.toDate() - a.createdAt?.toDate());
      
      setNotifications(notificationsList);
      setUnreadCount(notificationsList.filter(n => !n.read).length);
    });

    return () => unsubscribe();
  }, [user, orgId]);

  const handleSignOut = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      navigate('/customer-login'); // Redirect to customer login
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationSelect = async (notification) => {
    setSelectedNotification(notification);
    setDialogOpen(true);
    handleMenuClose();

    // Mark as read if it's unread
    if (!notification.read) {
      const notificationRef = doc(
        db, 
        'organizations', 
        orgId, 
        'clientRequests', 
        notification.id
      );
      
      await updateDoc(notificationRef, {
        read: true
      });
    }
  };

  return (
    <>
      <StyledHeader>
        <Typography variant="h5">
          Hi, {customer?.firstName || 'Client'}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton onClick={handleNotificationClick}>
            <Badge badgeContent={unreadCount} color="primary">
              <Bell />
            </Badge>
          </IconButton>

          <Button 
            variant="outlined" 
            startIcon={<LogOut />} 
            onClick={handleSignOut}
          >
            Sign Out
          </Button>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: { width: 320, maxHeight: 400 }
          }}
        >
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <MenuItem 
                key={notification.id}
                onClick={() => handleNotificationSelect(notification)}
                sx={{
                  backgroundColor: notification.read ? 'inherit' : 'action.hover',
                  display: 'block'
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: notification.read ? 400 : 600 }}>
                  Reschedule Request - {notification.status.toUpperCase()}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {moment(notification.createdAt?.toDate()).fromNow()}
                </Typography>
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              <Typography variant="body2" color="textSecondary">
                No notifications
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </StyledHeader>

      <NotificationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        notification={selectedNotification}
      />
    </>
  );
};

export default ClientHeader;