// src/components/ScheduleSettings.jsx
import React, { useState } from 'react';
import { 
  Typography, 
  Slider,
  Box,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Tabs,
  Tab
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import StatusSettings from './StatusSettings';
import EmailTemplates from './EmailTemplates';
import { DEFAULT_JOB_STATUSES, DEFAULT_RECURRING_STATUSES } from '../constants/statuses';
import moment from 'moment-timezone';
import styles from '../styles/OrgSettings.module.css';

const ScheduleSettings = ({ 
  scheduleSettings, 
  onScheduleSettingsChange,
  onSave,
  loading 
}) => {
  const [newBlockStart, setNewBlockStart] = useState('');
  const [newBlockEnd, setNewBlockEnd] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSettingChange = (key, value) => {
    onScheduleSettingsChange({
      ...scheduleSettings,
      [key]: value
    });
  };

  const handleSliderChange = (key) => (event, newValue) => {
    handleSettingChange(key, newValue);
  };

  const handleAddTimeBlock = () => {
    if (!newBlockStart || !newBlockEnd) return;
    
    const timeBlocks = [...(scheduleSettings.timeBlocks || [])];
    timeBlocks.push({
      start: newBlockStart,
      end: newBlockEnd
    });
    
    // Sort blocks by start time
    timeBlocks.sort((a, b) => moment(a.start, 'HH:mm').diff(moment(b.start, 'HH:mm')));
    
    handleSettingChange('timeBlocks', timeBlocks);
    setNewBlockStart('');
    setNewBlockEnd('');
  };

  const handleRemoveTimeBlock = (index) => {
    const timeBlocks = [...(scheduleSettings.timeBlocks || [])];
    timeBlocks.splice(index, 1);
    handleSettingChange('timeBlocks', timeBlocks);
  };

  // Email Templates
  const handleTemplateCreate = (newTemplate) => {
    const currentTemplates = scheduleSettings.emailTemplates || [];
    handleSettingChange('emailTemplates', [...currentTemplates, newTemplate]);
  };

  const handleTemplateUpdate = (updatedTemplate) => {
    const currentTemplates = scheduleSettings.emailTemplates || [];
    handleSettingChange('emailTemplates', 
      currentTemplates.map(template => 
        template.id === updatedTemplate.id ? updatedTemplate : template
      )
    );
  };

  const handleTemplateDelete = (templateId) => {
    const currentTemplates = scheduleSettings.emailTemplates || [];
    handleSettingChange('emailTemplates', 
      currentTemplates.filter(template => template.id !== templateId)
    );
  };
  
  return (
    <div className={styles.orgContent}>
      <div className={styles.orgSection}>
        <Typography variant="h6" className={styles.sectionTitle}>
          Schedule Settings
        </Typography>

        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Time Settings" />
            <Tab label="Status Settings" />
            <Tab label="Email Templates" />
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <>
            {/* Minimum Gap Notification */}
            <div className="space-y-4 mb-8">
              <Typography>
                Minimum Gap for Availability Notification 
                <Typography variant="body2" color="textSecondary" className="mt-1">
                  Show available time slots when there's a gap larger than this duration
                </Typography>
              </Typography>
              <Slider
                value={scheduleSettings.minimumGapNotification}
                onChange={(e, value) => handleSettingChange('minimumGapNotification', value)}
                min={30}
                max={480}
                step={30}
                marks={[
                  { value: 30, label: '30m' },
                  { value: 60, label: '1h' },
                  { value: 90, label: '1.5h' },
                  { value: 120, label: '2h' },
                  { value: 150, label: '2.5h' },
                  { value: 180, label: '3h' },
                  { value: 210, label: '3.5h' },
                  { value: 240, label: '4h' },
                  { value: 270, label: '4.5h' },
                  { value: 300, label: '5h' },
                  { value: 330, label: '5.5h' },
                  { value: 360, label: '6h' },
                  { value: 390, label: '6.5h' },
                  { value: 420, label: '7h' },
                  { value: 450, label: '7.5h' },
                  { value: 480, label: '8h' }
                ]}
                valueLabelDisplay="auto"
                valueLabelFormat={value => `${value} minutes`}
                className="mt-2"
              />
            </div>

            {/* Drive Time Gap */}
            <div className="space-y-4">
              <Typography>
                Drive Time Gap Between Jobs
                <Typography variant="body2" color="textSecondary" className="mt-1">
                  Required buffer time between jobs for travel
                </Typography>
              </Typography>
              <Slider
                value={scheduleSettings.driveTimeGap}
                onChange={(e, value) => handleSettingChange('driveTimeGap', value)}
                min={15}
                max={120}
                step={15}
                marks={[
                  { value: 15, label: '15m' },
                  { value: 30, label: '30m' },
                  { value: 45, label: '45m' },
                  { value: 60, label: '1h' },
                  { value: 75, label: '1.25h' },
                  { value: 90, label: '1.5h' },
                  { value: 105, label: '1.75h' },
                  { value: 120, label: '2h' }
                ]}
                valueLabelDisplay="auto"
                valueLabelFormat={value => `${value} minutes`}
                className="mt-2"
              />
            </div>

            <Divider sx={{ my: 4 }} />

            <Box sx={{ mb: 4 }}>
              <Typography variant="subtitle1" gutterBottom>
                Time Block Settings
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                Configure how available time is divided into blocks
              </Typography>

              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
                <TextField
                  label="Start Time"
                  type="time"
                  value={newBlockStart}
                  onChange={(e) => setNewBlockStart(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="End Time"
                  type="time"
                  value={newBlockEnd}
                  onChange={(e) => setNewBlockEnd(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <IconButton 
                  onClick={handleAddTimeBlock}
                  color="primary"
                  disabled={!newBlockStart || !newBlockEnd}
                >
                  <AddIcon />
                </IconButton>
              </Box>

              <List>
                {(scheduleSettings.timeBlocks || []).map((block, index) => (
                  <ListItem 
                    key={index} 
                    sx={{ 
                      bgcolor: 'background.paper', 
                      mb: 1, 
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: 'divider'
                    }}
                  >
                    <ListItemText 
                      primary={`${block.start} - ${block.end}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton 
                        edge="end" 
                        aria-label="delete"
                        onClick={() => handleRemoveTimeBlock(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
        )}

        {activeTab === 1 && (
          <StatusSettings
            customJobStatuses={scheduleSettings.jobStatuses || []}
            customRecurringStatuses={scheduleSettings.recurringStatuses || []}
            onStatusUpdate={handleSettingChange}
            onSave={onSave}
            loading={loading}
          />
        )}

        {activeTab === 2 && (
          <EmailTemplates
            templates={scheduleSettings.emailTemplates || []}
            jobStatuses={[
              ...DEFAULT_JOB_STATUSES,
              ...(scheduleSettings.jobStatuses || [])
            ]}
            recurringStatuses={[
              ...DEFAULT_RECURRING_STATUSES,
              ...(scheduleSettings.recurringStatuses || [])
            ]}
            onTemplateCreate={handleTemplateCreate}
            onTemplateUpdate={handleTemplateUpdate}
            onTemplateDelete={handleTemplateDelete}
            onSave={onSave}
            loading={loading}
          />
        )}

        {/* Save Button */}
        <Box sx={{ mt: 4 }}>
          <button
            onClick={onSave}
            disabled={loading}
            className={`${styles.orgButton} ${styles.orgButtonPrimary} ${loading ? styles.orgButtonDisabled : ''}`}
          >
            {loading ? 'Saving...' : 'Save Settings'}
          </button>
        </Box>
      </div>
    </div>
  );
};

export default ScheduleSettings;