// src/components/ExpenseSubmission.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useTimezone } from '../context/TimeZoneContext';
import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  CircularProgress,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Tabs,
  Tab
} from '@mui/material';
import { Receipt, Upload } from 'lucide-react';
import MobileHeader from './MobileHeader';
import ExpenseHistory from './ExpenseHistory';
import { collection, query, where, getDocs, orderBy, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import moment from 'moment-timezone';
import styles from '../styles/ExpenseSubmission.module.css';

const ExpenseSubmission = () => {
  const navigate = useNavigate();
  const { cleanerId } = useParams();
  const { user } = useAuth();
  const { timezone } = useTimezone();
  const isMobile = useMediaQuery('(max-width:768px)');
  const [activeTab, setActiveTab] = useState('submit');
  const [selectedDate, setSelectedDate] = useState('');
  const [expenseType, setExpenseType] = useState('');
  const [clientName, setClientName] = useState('');
  const [miles, setMiles] = useState('');
  const [products, setProducts] = useState('');
  const [cost, setCost] = useState('');
  const [photoFile, setPhotoFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expenses, setExpenses] = useState([]);

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhotoFile(file);
    }
  };

  useEffect(() => {
    const fetchExpenses = async () => {
      if (!user?.orgId || !cleanerId) return;
      
      setLoading(true);
      try {
        const expensesRef = collection(db, 'organizations', user.orgId, 'cleaners', cleanerId, 'expenses');
        const expensesQuery = query(expensesRef, orderBy('date', 'desc'));
        const querySnapshot = await getDocs(expensesQuery);
  
        const expensesList = [];
        querySnapshot.forEach((doc) => {
          expensesList.push({
            id: doc.id,
            ...doc.data(),
            date: doc.data().date.toDate(),
            createdAt: doc.data().createdAt.toDate(),
            updatedAt: doc.data().updatedAt.toDate()
          });
        });
  
        console.log('Fetched expenses:', expensesList); // Debug log
        setExpenses(expensesList);
      } catch (err) {
        console.error('Error fetching expenses:', err);
        setError('Failed to load expenses');
      } finally {
        setLoading(false);
      }
    };
  
    fetchExpenses();
  }, [user?.orgId, cleanerId]);

    // Validation function
    const isFormValid = () => {
        if (!selectedDate || !expenseType || !photoFile) return false;
    
        if (expenseType === 'Gas') {
          return Boolean(clientName && miles);
        } else if (expenseType === 'Supplies') {
          return Boolean(products && cost);
        }
    
        return false;
      };

  const handleSubmit = async () => {
    if (loading) return;
    
    setLoading(true);
    setError(null);
    
    try {
      let photoUrl = null;
      if (photoFile) {
        const storageRef = ref(storage, `organizations/${user.orgId}/cleaners/${cleanerId}/expenses/${Date.now()}_${photoFile.name}`);
        const uploadResult = await uploadBytes(storageRef, photoFile);
        photoUrl = await getDownloadURL(uploadResult.ref);
      }
  
      const expensesRef = collection(db, 'organizations', user.orgId, 'cleaners', cleanerId, 'expenses');
      
      const expenseData = {
        date: moment(selectedDate).tz(timezone).toDate(),
        type: expenseType,
        status: 'pending',
        photoUrl,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: user.uid,
        updatedBy: user.uid,
        ...(expenseType === 'Gas' ? {
          clientName,
          miles: Number(miles)
        } : {
          products,
          cost: Number(cost)
        })
      };
  
      await addDoc(expensesRef, expenseData);
      navigate(`/cleaner-expenses/${cleanerId}`);
    } catch (err) {
      console.error('Error submitting expense:', err);
      setError('Failed to submit expense');
    } finally {
      setLoading(false);
    }
  };

  if (!isMobile) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.container}>
      <MobileHeader title="Submit Expense" />

      <Card className={styles.tabCard}>
        <Tabs 
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant="fullWidth"
          className={styles.tabs}
        >
          <Tab 
            value="submit" 
            label="Submit" 
            className={styles.tab}
          />
          <Tab 
            value="history" 
            label="History" 
            className={styles.tab}
          />
        </Tabs>
      </Card>
      
      {activeTab === 'submit' ? (
        <div className={styles.content}>
          <Card className={styles.card}>
            <CardContent className={styles.cardContent}>
              {/* Your existing expense submission form content */}
              <div className={styles.header}>
                <Receipt className={styles.headerIcon} />
                <Typography variant="h6">Expense Details</Typography>
              </div>
            
            <div className={styles.form}>
              <div className={styles.formGroup}>
              <Typography variant="subtitle2">
                Date <span className={styles.required}>*</span>
              </Typography>
                <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                className={styles.dateInput}
                max={moment().format('YYYY-MM-DD')}
                required
                />
              </div>
              
              <div className={styles.formGroup}>
                <Typography variant="subtitle2">
                    Expense Type <span className={styles.required}>*</span>
                </Typography>
                <FormControl fullWidth required>
                    <Select
                    value={expenseType}
                    onChange={(e) => setExpenseType(e.target.value)}
                    className={styles.select}
                    required
                    >
                    <MenuItem value="Gas">Gas</MenuItem>
                    <MenuItem value="Supplies">Supplies</MenuItem>
                    </Select>
                </FormControl>
                </div>

                {expenseType === 'Gas' && (
                <>
                    <div className={styles.formGroup}>
                    <Typography variant="subtitle2">
                        Client Name <span className={styles.required}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        className={styles.input}
                        required
                    />
                    </div>
                    
                    <div className={styles.formGroup}>
                    <Typography variant="subtitle2">
                        Miles Driven <span className={styles.required}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        type="number"
                        value={miles}
                        onChange={(e) => setMiles(e.target.value)}
                        className={styles.input}
                        required
                    />
                    </div>
                </>
                )}

                {expenseType === 'Supplies' && (
                <>
                    <div className={styles.formGroup}>
                    <Typography variant="subtitle2">
                        Products Purchased <span className={styles.required}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        value={products}
                        onChange={(e) => setProducts(e.target.value)}
                        className={styles.textArea}
                        required
                    />
                    </div>
                    
                    <div className={styles.formGroup}>
                    <Typography variant="subtitle2">
                        Total Cost <span className={styles.required}>*</span>
                    </Typography>
                    <TextField
                        fullWidth
                        type="number"
                        value={cost}
                        onChange={(e) => setCost(e.target.value)}
                        className={styles.input}
                        required
                    />
                    </div>
                </>
                )}

                <div className={styles.formGroup}>
                <Typography variant="subtitle2">
                    Upload {expenseType === 'Gas' ? 'Map Screenshot' : 'Receipt'} <span className={styles.required}>*</span>
                </Typography>
                <div className={styles.fileUpload}>
                    <Upload className={styles.uploadIcon} />
                    <input
                    type="file"
                    accept="image/*"
                    onChange={handlePhotoUpload}
                    className={styles.fileInput}
                    required
                    />
                </div>
                {photoFile && (
                    <Typography variant="body2" className={styles.fileSelected}>
                    ✓ File selected: {photoFile.name}
                    </Typography>
                )}
                </div>
              </div>
            </CardContent>
        </Card>

        {error && (
          <div className={styles.error}>
            <Typography>{error}</Typography>
          </div>
        )}

          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            disabled={loading || !selectedDate || !expenseType || !photoFile}
            className={styles.submitButton}
          >
            {loading ? (
              <div className={styles.loadingState}>
                <CircularProgress size={20} />
                <span>Submitting...</span>
              </div>
            ) : (
              'Submit Expense'
            )}
          </Button>
        </div>
      ) : (
        <ExpenseHistory />
      )}
    </div>
  );
};

export default ExpenseSubmission;