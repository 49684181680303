import React, { useState, useEffect } from 'react';
import styles from '../styles/TimeOffRequestModal.module.css';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import moment from 'moment-timezone';
import { useTimezone } from '../context/TimeZoneContext';

const TimeOffRequestModal = ({ 
  open, 
  onClose, 
  cleanerId, 
  orgId, 
  cleaner,
  onSuccess 
}) => {
  const { timezone } = useTimezone();
  
  const [formData, setFormData] = useState({
    startDate: moment().tz(timezone).format('YYYY-MM-DD'),
    endDate: moment().tz(timezone).format('YYYY-MM-DD'),
    startTime: '09:00',
    endTime: '17:00',
    notes: ''
  });

  const [loading, setLoading] = useState(false);
  const [timeError, setTimeError] = useState('');
  const [scheduledJobs, setScheduledJobs] = useState([]);

  // Fetch jobs when date changes
  useEffect(() => {
    const fetchJobsForDate = async () => {
      if (!orgId || !cleanerId) return;
      
      setLoading(true);
      setTimeError(null);
      try {
        const jobsRef = collection(db, 'organizations', orgId, 'jobs');
        const selectedDateTime = moment(formData.startDate).tz(timezone);
        
        // Query all jobs for this cleaner
        const jobQuery = query(
          jobsRef,
          where('cleanerId', '==', cleanerId)
        );

        const querySnapshot = await getDocs(jobQuery);
        const jobs = [];
        
        querySnapshot.forEach((doc) => {
          const jobData = { id: doc.id, ...doc.data() };
          const jobDate = moment(jobData.appointmentDate.toDate()).tz(timezone);
          
          // Only include jobs for the selected date
          if (jobDate.isSame(selectedDateTime, 'day')) {
            jobs.push(jobData);
          }
        });

        jobs.sort((a, b) => 
          moment(a.appointmentDate.toDate()).valueOf() - 
          moment(b.appointmentDate.toDate()).valueOf()
        );

        setScheduledJobs(jobs);
      } catch (err) {
        console.error('Error fetching jobs:', err);
        setTimeError('Failed to fetch scheduled jobs');
      } finally {
        setLoading(false);
      }
    };

    fetchJobsForDate();
  }, [formData.startDate, cleanerId, orgId, timezone]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    
    setLoading(true);
    setTimeError(null);
    
    try {
      const timeOffRef = collection(db, 'organizations', orgId, 'cleaners', cleanerId, 'timeOffRequests');
      
      // Create date object in local timezone to avoid conversion issues
      const selectedMoment = moment(formData.startDate).tz(timezone);
      const [startHours, startMinutes] = formData.startTime.split(':').map(Number);
      const [endHours, endMinutes] = formData.endTime.split(':').map(Number);

      // Set the hours and minutes on the same date object
      const timeOffStart = selectedMoment.clone()
        .set({ hour: startHours, minute: startMinutes, second: 0 });

      const timeOffEnd = selectedMoment.clone()
        .set({ hour: endHours, minute: endMinutes, second: 0 });

      await addDoc(timeOffRef, {
        date: timeOffStart.toDate(),
        startTime: timeOffStart.toDate(),
        endTime: timeOffEnd.toDate(),
        status: 'pending',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: auth.currentUser.uid,
        updatedBy: auth.currentUser.uid,
        notes: formData.notes || '',
        affectedJobs: scheduledJobs.map(job => ({
          jobId: job.id,
          customerName: job.customerName,
          startTime: job.appointmentDate,
          endTime: job.scheduledEndTime,
          address: job.address
        }))
      });

      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('Error submitting time off request:', error);
      setTimeError('Error submitting request. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const formatAddress = (address) => {
    if (typeof address === 'string') return address;
    if (!address) return '';
    
    const {line1, line2, city, state, zip} = address;
    const addressParts = [
      line1,
      line2,
      [city, state, zip].filter(Boolean).join(', ')
    ].filter(Boolean);
    
    return addressParts.join(', ');
  };


  if (!open) return null;

  return (
    <div className={styles.userModalOverlay}>
      <div className={styles.userModalContent}>
        <h2 className={styles.userModalTitle}>
          Request Time Off for {cleaner?.firstName} {cleaner?.lastName}
        </h2>

        <form onSubmit={handleSubmit} className={styles.userForm}>
          {timeError && (
            <div className={styles.errorMessage}>{timeError}</div>
          )}
          
          <div className={styles.formSection}>
            <div className={styles.formColumn}>
              <label>Start Date</label>
              <input
                type="date"
                value={formData.startDate}
                onChange={(e) => setFormData(prev => ({ 
                  ...prev, 
                  startDate: e.target.value,
                  endDate: e.target.value // Set end date to match start date
                }))}
                min={moment().format('YYYY-MM-DD')}
                className={styles.input}
              />
            </div>
          </div>

          <div className={styles.formSection}>
            <div className={styles.formColumn}>
              <label>Start Time ({timezone})</label>
              <input
                type="time"
                value={formData.startTime}
                onChange={(e) => setFormData(prev => ({ ...prev, startTime: e.target.value }))}
                className={styles.input}
              />
            </div>

            <div className={styles.formColumn}>
              <label>End Time ({timezone})</label>
              <input
                type="time"
                value={formData.endTime}
                onChange={(e) => setFormData(prev => ({ ...prev, endTime: e.target.value }))}
                className={styles.input}
              />
            </div>
          </div>

          {scheduledJobs.length > 0 && (
            <div className={styles.jobsSection}>
            <h3>Scheduled Jobs for This Date:</h3>
            {scheduledJobs.map(job => (
                <div key={job.id} className={styles.jobItem}>
                <div className={styles.jobTitle}>{job.customerName}</div>
                <div className={styles.jobTime}>
                    {moment(job.appointmentDate.toDate()).format('h:mm A')} - {' '}
                    {moment(job.scheduledEndTime.toDate()).format('h:mm A')}
                </div>
                <div className={styles.jobAddress}>{formatAddress(job.address)}</div>
                </div>
            ))}
            </div>
          )}

          <div className={styles.userFormGroup}>
            <label>Notes</label>
            <textarea
              value={formData.notes}
              onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
              placeholder="Add any notes about this time off request"
              className={styles.textArea}
              rows={4}
            />
          </div>

          <div className={styles.userButtonGroup}>
            <button
              type="button"
              onClick={onClose}
              className={`${styles.button} ${styles.userButtonSecondary}`}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`${styles.button} ${styles.userButtonPrimary}`}
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit Request'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TimeOffRequestModal;