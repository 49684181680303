// /src/components/CustomerPortalLogin.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase';
import AuthHeader from './AuthHeader';

const CustomerPortalLogin = ({ isDarkMode, toggleDarkMode }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // First authenticate with Firebase
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // First check if user exists and is a customer
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (!userDoc.exists()) {
        await auth.signOut();
        setError('No account found for this email.');
        return;
      }

      const userData = userDoc.data();
      if (userData.role !== 'customer') {
        await auth.signOut();
        setError('This login is for customers only. Please use the regular login page.');
        return;
      }

      // Get the organization document
      const orgId = userData.orgId;
      if (!orgId) {
        await auth.signOut();
        setError('Account not properly configured. Please contact support.');
        return;
      }

      // Verify user exists in organization's users collection
      const orgUserDoc = await getDoc(doc(db, 'organizations', orgId, 'users', user.uid));
      if (!orgUserDoc.exists()) {
        await auth.signOut();
        setError('Customer account not found in organization.');
        return;
      }

      // Verify customer exists in organization's customers collection
      const customerId = userData.customerId;
      if (!customerId) {
        await auth.signOut();
        setError('Customer ID not found. Please contact support.');
        return;
      }

      const customerDoc = await getDoc(doc(db, 'organizations', orgId, 'customers', customerId));
      if (!customerDoc.exists()) {
        await auth.signOut();
        setError('Customer details not found. Please contact support.');
        return;
      }

      // Successfully authenticated as customer
      navigate('/customer-portal');
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid email or password. Please try again.');
    }
  };

  return (
    <div className={`login-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="auth-card">
        <AuthHeader isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        <form onSubmit={handleLogin}>
          <h2>Customer Portal Login</h2>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email"
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              id="password"
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="btn btn-primary">Login</button>
          <div className="links">
            <Link to="/forgot-password">Forgot Password?</Link>
            <p className="text-sm text-gray-600">
              Need an account? Please contact your service provider.
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerPortalLogin;