import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Checkbox,
  Divider,
  Alert
} from '@mui/material';
import { doc, updateDoc, setDoc, getDoc, collection, query, getDocs, where } from 'firebase/firestore';
import { db, auth } from '../firebase';
import CleanerCard from './CleanerCard';
import { useJobContext } from '../context/JobContext';

const CancellationModal = ({ 
  open, 
  onClose = () => {}, 
  customer, 
  orgId,
  onSaveCancellation = () => {} 
}) => {
  // Main state
  const [step, setStep] = useState(1);
  const [subStep, setSubStep] = useState(1);
  const [cancelReason, setCancelReason] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  
  // Cleaner-specific state
  const [cleaners, setCleaners] = useState([]);
  const [cleanerIssues, setCleanerIssues] = useState([]);
  const [otherIssueText, setOtherIssueText] = useState('');
  const [selectedCleaner, setSelectedCleaner] = useState(null);
  const [loadingCleaners, setLoadingCleaners] = useState(false);
  const [declinedCleanerChange, setDeclinedCleanerChange] = useState(false);
  const [cleanerMetrics, setCleanerMetrics] = useState({});
  const { getCleanerMetrics } = useJobContext();
  
  // Pricing and offers state
  const [pricingOption, setPricingOption] = useState('');
  const [acceptOffer, setAcceptOffer] = useState(false);
  const [hasUsedDiscount, setHasUsedDiscount] = useState(false);

  // Reset state when modal opens/closes
  useEffect(() => {
    if (!open) {
      setStep(1);
      setSubStep(1);
      setCancelReason('');
      setFeedbackText('');
      setCleanerIssues([]);
      setOtherIssueText('');
      setSelectedCleaner(null);
      setPricingOption('');
      setAcceptOffer(false);
    }
  }, [open]);

  // Check if customer has previously used the discount
  useEffect(() => {
    const checkPreviousDiscount = async () => {
      if (!customer?.id || !orgId) return;
      
      try {
        const customerRef = doc(db, 'organizations', orgId, 'customers', customer.id);
        const customerDoc = await getDoc(customerRef);
        
        if (customerDoc.exists()) {
          setHasUsedDiscount(customerDoc.data()?.hasUsedCancellationDiscount || false);
        }
      } catch (error) {
        console.error('Error checking previous discount:', error);
      }
    };
    
    if (open) {
      checkPreviousDiscount();
    }
  }, [customer, orgId, open]);

    // Add fetchCleaners function
    const fetchCleaners = async () => {
      if (!orgId) return;
      
      setLoadingCleaners(true);
      try {
        const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
        const cleanersQuery = query(
          cleanersRef,
          where('status', '==', 'active')
        );
        
        const cleanersSnapshot = await getDocs(cleanersQuery);
        const cleanersList = cleanersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
  
        // Fetch metrics for each cleaner
        const metricsPromises = cleanersList.map(cleaner => 
          getCleanerMetrics(orgId, cleaner.id)
        );
        
        const metricsResults = await Promise.all(metricsPromises);
  
        const metricsMap = {};
        cleanersList.forEach((cleaner, index) => {
          metricsMap[cleaner.id] = metricsResults[index];
        });
  
        setCleaners(cleanersList);
        setCleanerMetrics(metricsMap);
      } catch (error) {
        console.error('Error fetching cleaners:', error);
      } finally {
        setLoadingCleaners(false);
      }
    };
  
    // Update useEffect to call fetchCleaners when needed
    useEffect(() => {
      if (open && step === 2) {
        fetchCleaners();
      }
    }, [open, step, orgId]);

  // Add useEffect to fetch cleaner metrics when cleaners change
  useEffect(() => {
    const fetchCleanerMetrics = async () => {

      if (!cleaners.length || !orgId) return;
      
      setLoadingCleaners(true);
      try {
        const metricsPromises = cleaners.map(cleaner => 
          getCleanerMetrics(orgId, cleaner.id)
        );
        
        const metricsResults = await Promise.all(metricsPromises);

        const metricsMap = {};
        cleaners.forEach((cleaner, index) => {
          metricsMap[cleaner.id] = metricsResults[index];
        });

        setCleanerMetrics(metricsMap);
      } catch (error) {
        console.error('Error fetching cleaner metrics:', error);
      } finally {
        setLoadingCleaners(false);
      }
    };

    fetchCleanerMetrics();
  }, [cleaners, orgId, getCleanerMetrics]);

  const handleReasonChange = (event) => {
    if (!event?.target?.value) return;
    setCancelReason(event.target.value);
    setStep(2);
    setSubStep(1);
  };

  const handleCleanerIssueChange = (issue) => {
    if (!issue) return;
    setCleanerIssues(prev => {
      if (prev.includes(issue)) {
        return prev.filter(i => i !== issue);
      }
      return [...prev, issue];
    });
  };

  const handleSaveCancellation = async () => {
    if (!customer?.id || !orgId) return;
  
    try {
      // Get the current user's data to verify role
      const userRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userRef);
  
      const cancellationData = {
        timestamp: new Date(),
        customerId: customer.id,
        orgId: orgId,
        reason: cancelReason,
        feedback: feedbackText || '',
        status: 'pending',
        cleanerIssues: cleanerIssues || [],
        otherIssueText: otherIssueText || '',
        selectedNewCleaner: selectedCleaner || null,
        acceptedOffer: acceptOffer || false,
        pricingOption: pricingOption || null,
        createdAt: new Date(),
        createdBy: auth.currentUser.uid,
        updatedAt: new Date(),
        updatedBy: auth.currentUser.uid
      };
  
      // Save cancellation request
      const cancellationRef = doc(
        db, 
        'organizations', 
        orgId, 
        'cancellations', 
        `${customer.id}_${Date.now()}`
      );
      
      await setDoc(cancellationRef, cancellationData);

      // If they accepted the offer, update customer record
      if (acceptOffer) {
        const customerRef = doc(db, 'organizations', orgId, 'customers', customer.id);
        await updateDoc(customerRef, {
          hasUsedCancellationDiscount: true,
          discountAppliedDate: new Date(),
          updatedAt: new Date(),
          updatedBy: customer.id
        });
      }

      // Call parent handler
      onSaveCancellation(cancellationData);
      onClose();
    } catch (error) {
      console.error('Error saving cancellation:', error, error.code);
    }
  };

  const handleCleanerSelection = () => {
    // If they selected a new cleaner, skip case 3 and go straight to final step
    setStep(3);
    setAcceptOffer(true); // They're accepting the offer by choosing a new cleaner
  };

  const handleDeclineCleanerChange = () => {
    setDeclinedCleanerChange(true);
    setSubStep(3); // Go to the special offer step
  };

  const renderCleanerSteps = () => {
    switch (subStep) {
      case 1:
        return (
          <Box>
            <Typography variant="body1" sx={{ mb: 3 }}>
              We are really sorry to hear that. Can you tell us a little more about what your cleaner did that lead you to this decision?
            </Typography>
            
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              {[
                'Missed the finer details of cleaning',
                'Always seemed like the cleaner was in a rush',
                'Lack of Professionalism',
                'Punctuality',
                'Inappropriate Behavior',
                'Communication',
                'Compatibility',
                'Something Else'
              ].map((issue) => (
                <FormControlLabel
                  key={issue}
                  control={
                    <Checkbox
                      checked={cleanerIssues.includes(issue)}
                      onChange={() => handleCleanerIssueChange(issue)}
                    />
                  }
                  label={issue}
                />
              ))}
            </FormControl>

            {cleanerIssues.includes('Something Else') && (
              <TextField
                fullWidth
                multiline
                rows={3}
                value={otherIssueText}
                onChange={(e) => setOtherIssueText(e.target.value || '')}
                placeholder="Please tell us more..."
                sx={{ mt: 2 }}
              />
            )}

            {cleanerIssues.length > 0 && (
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  onClick={() => setSubStep(2)}
                >
                  Continue
                </Button>
              </Box>
            )}
          </Box>
        );

        case 2:
          return (
            <Box sx={{ mt: 3 }}>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Would you have any interest in one of our other cleaners?
              </Typography>
              
              {loadingCleaners ? (
                <>
                  <CleanerCard loading />
                  <CleanerCard loading />
                  <CleanerCard loading />
                </>
              ) : cleaners.length > 0 ? (
                <>
                  {cleaners.map(cleaner => (
                    <CleanerCard
                      key={cleaner.id}
                      cleaner={cleaner}
                      metrics={cleanerMetrics[cleaner.id]}
                      selected={selectedCleaner === cleaner.id}
                      onSelect={setSelectedCleaner}
                    />
                  ))}
                  <Divider sx={{ my: 3 }} />
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                    {selectedCleaner ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCleanerSelection}
                        sx={{ textTransform: 'none' }}
                      >
                        Continue with selected cleaner
                      </Button>
                    ) : (
                      <Button
                        color="error"
                        onClick={handleDeclineCleanerChange}
                        sx={{ textTransform: 'none' }}
                      >
                        No thanks, I would still like to cancel my services
                      </Button>
                    )}
                  </Box>
                </>
              ) : (
                <Typography color="text.secondary">
                  No other cleaners available at the moment.
                </Typography>
              )}
            </Box>
          );

          case 3:
            // Only show this case if they declined cleaner change
            if (declinedCleanerChange) {
              return (
                <Box>
                  {!hasUsedDiscount ? (
                    <>
                      <Typography variant="body1" sx={{ mb: 3 }}>
                        What if we gave you 50% off your next 2 cleaning appointments and sent a different cleaner to see if that would work out better?
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={acceptOffer}
                            onChange={(e) => setAcceptOffer(e.target.checked)}
                          />
                        }
                        label="Yes, I'd like to accept this offer"
                      />
                    </>
                  ) : (
                    <Alert severity="info" sx={{ mb: 3 }}>
                      This discount offer has already been used previously.
                    </Alert>
                  )}
                  <Button
                    onClick={() => setStep(4)} // Move to final step
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    {acceptOffer ? 'Continue with new cleaner' : 'Proceed with cancellation'}
                  </Button>
                </Box>
              );
            }

      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <Box>
            <Typography variant="body1" sx={{ mb: 3 }}>
              We're sorry that you feel you need to leave us. We hope we did not leave a bad impression because we do take a lot of pride in our work, but always are looking to improve. If you can help us with your thoughts on why you need to leave, it will help us in the future, but we might also be able to try and save our relationship together.
            </Typography>

            <FormControl component="fieldset">
              <RadioGroup value={cancelReason} onChange={handleReasonChange}>
                <FormControlLabel value="cleaner" control={<Radio />} label="Was it the Cleaner, or the work they provided?" />
                <FormControlLabel value="communication" control={<Radio />} label="Was it the communication with the office?" />
                <FormControlLabel value="pricing" control={<Radio />} label="Was it pricing?" />
                <FormControlLabel value="other-service" control={<Radio />} label="Did you find Someone else?" />
                <FormControlLabel value="other" control={<Radio />} label="Something Else?" />
              </RadioGroup>
            </FormControl>

            {cancelReason === 'other' && (
              <TextField
                fullWidth
                multiline
                rows={4}
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value || '')}
                placeholder="Please tell us more..."
                sx={{ mt: 2 }}
              />
            )}
          </Box>
        );

      case 2:
        if (cancelReason === 'cleaner') {
          return renderCleanerSteps();
        }
        
        if (cancelReason === 'communication') {
          return (
            <Box>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Can you let us know where we might have gone wrong?
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value || '')}
                placeholder="Please provide details..."
              />
              <Button
                variant="contained"
                onClick={() => setStep(3)}
                sx={{ mt: 2 }}
              >
                Continue
              </Button>
            </Box>
          );
        }
        
        if (cancelReason === 'pricing') {
          return (
            <Box>
              <Typography variant="body1" sx={{ mb: 3 }}>
                We understand pricing can sometimes be an issue. Here are a couple of things that we could work with you on:
              </Typography>
              
              <FormControl component="fieldset">
                <RadioGroup value={pricingOption} onChange={(e) => setPricingOption(e.target.value || '')}>
                  <FormControlLabel value="focus" control={<Radio />} label="Focus on Kitchens and Bathrooms Only" />
                  <FormControlLabel value="frequency" control={<Radio />} label="Come Less Frequently to save money" />
                  <FormControlLabel value="restructure" control={<Radio />} label="Work with you on restructuring your plan with us" />
                </RadioGroup>
              </FormControl>

              <TextField
                fullWidth
                multiline
                rows={3}
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value || '')}
                placeholder="Please let us know your thoughts..."
                sx={{ mt: 2 }}
              />

              <Button
                variant="contained"
                onClick={() => setStep(3)}
                sx={{ mt: 2 }}
              >
                Continue
              </Button>
            </Box>
          );
        }

        if (cancelReason === 'other-service') {
          return (
            <Box>
              <Typography variant="body1" sx={{ mb: 2 }}>
                If we did something that made you start looking, we're sorry about that. We'd like to back track and see what we can do to keep you. If there was anything we could have done differently what would that have been?
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value || '')}
                placeholder="Please let us know (minimum 40 characters)..."
                error={feedbackText.length > 0 && feedbackText.length < 40}
                helperText={feedbackText.length > 0 && feedbackText.length < 40 ? 'Please provide more detail (minimum 40 characters)' : ''}
              />
              {feedbackText.length >= 40 && (
                <Button
                  variant="contained"
                  onClick={() => setStep(3)}
                  sx={{ mt: 2 }}
                >
                  Continue
                </Button>
              )}
            </Box>
          );
        }

        return null;

      case 3:
        return (
          <Box>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Is there anything else you'd like us to know?
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value || '')}
              placeholder="Any final feedback..."
            />
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {step === 1 && "We're Sorry to See You Go"}
        {step === 2 && "Help Us Understand"}
        {step === 3 && "Final Feedback"}
      </DialogTitle>
      
      <DialogContent>
        {renderContent()}
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {step === 3 && (
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleSaveCancellation}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CancellationModal;