// src/components/TimeOffRequest.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useTimezone } from '../context/TimeZoneContext';
import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  CircularProgress,
  useMediaQuery 
} from '@mui/material';
import { Calendar, AlertCircle } from 'lucide-react';
import MobileHeader from './MobileHeader';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment-timezone';

const TimeOffRequest = () => {
  const navigate = useNavigate();
  const { cleanerId } = useParams();
  const { user } = useAuth();
  const { timezone } = useTimezone();
  const isMobile = useMediaQuery('(max-width:768px)');
  const [selectedDate, setSelectedDate] = useState('');
  const [startTime, setStartTime] = useState('08:00');
  const [endTime, setEndTime] = useState('17:00');
  const [scheduledJobs, setScheduledJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const fetchJobsForDate = async (date) => {
    if (!user?.orgId || !cleanerId) return;
    
    setLoading(true);
    setError(null);
    try {
      const jobsRef = collection(db, 'organizations', user.orgId, 'jobs');
      const selectedDateTime = moment(date).tz(timezone);
      
      // Query all jobs for this cleaner
      const jobQuery = query(
        jobsRef,
        where('cleanerId', '==', cleanerId)
      );

      const querySnapshot = await getDocs(jobQuery);
      const jobs = [];
      
      querySnapshot.forEach((doc) => {
        const jobData = { id: doc.id, ...doc.data() };
        const jobDate = moment(jobData.appointmentDate.toDate()).tz(timezone);
        
        // Only include jobs for the selected date
        if (jobDate.isSame(selectedDateTime, 'day')) {
          jobs.push(jobData);
        }
      });

      // Sort jobs by appointment time
      jobs.sort((a, b) => 
        moment(a.appointmentDate.toDate()).valueOf() - 
        moment(b.appointmentDate.toDate()).valueOf()
      );

      setScheduledJobs(jobs);
    } catch (err) {
      console.error('Error fetching jobs:', err);
      setError('Failed to fetch scheduled jobs');
    } finally {
      setLoading(false);
    }
  };

  const handleDateSelect = async (date) => {
    setSelectedDate(date);
    await fetchJobsForDate(date);
  };

  const handleSubmit = async () => {
    if (submitting) return;
    
    setSubmitting(true);
    setError(null);
    
    try {
      const timeOffRef = collection(db, 'organizations', user.orgId, 'cleaners', cleanerId, 'timeOffRequests');
      
      // Create date object in local timezone to avoid conversion issues
      const selectedMoment = moment(selectedDate).tz(timezone);
      const [startHours, startMinutes] = startTime.split(':').map(Number);
      const [endHours, endMinutes] = endTime.split(':').map(Number);

      // Set the hours and minutes on the same date object
      const timeOffStart = selectedMoment.clone()
        .set({ hour: startHours, minute: startMinutes, second: 0 });

      const timeOffEnd = selectedMoment.clone()
        .set({ hour: endHours, minute: endMinutes, second: 0 });

      await addDoc(timeOffRef, {
        date: timeOffStart.toDate(), // Use the same base date as start time
        startTime: timeOffStart.toDate(),
        endTime: timeOffEnd.toDate(),
        status: 'pending',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: user.uid,
        updatedBy: user.uid,
        affectedJobs: scheduledJobs.map(job => ({
          jobId: job.id,
          customerName: job.customerName,
          startTime: job.appointmentDate,
          endTime: job.scheduledEndTime,
          address: job.address
        }))
      });
      
      navigate(`/cleaner-timeoff/${cleanerId}`);
    } catch (err) {
      console.error('Error submitting time off request:', err);
      setError('Failed to submit request');
    } finally {
      setSubmitting(false);
    }
  };

  if (!isMobile) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex flex-col h-full bg-gray-50">
      <MobileHeader title="Request Time Off" />
      
      <div className="p-4 space-y-4">
        <Card className="shadow-sm">
          <CardContent className="space-y-4">
            <div className="flex items-center gap-2">
              <Calendar className="w-5 h-5 text-blue-500" />
              <Typography variant="h6">Select Date</Typography>
            </div>
            
            <div className="space-y-4">
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => handleDateSelect(e.target.value)}
                className="w-full p-2 border rounded-md"
                min={moment().format('YYYY-MM-DD')}
              />
              
              <div className="flex gap-4">
                <div className="flex-1">
                  <Typography variant="body2" className="mb-1">Start Time</Typography>
                  <input
                    type="time"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    className="w-full p-2 border rounded-md"
                  />
                </div>
                <div className="flex-1">
                  <Typography variant="body2" className="mb-1">End Time</Typography>
                  <input
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    className="w-full p-2 border rounded-md"
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {loading && (
          <div className="flex justify-center items-center p-8">
            <CircularProgress />
          </div>
        )}

        {error && (
          <Card className="bg-red-50">
            <CardContent className="flex items-center gap-2">
              <AlertCircle className="w-5 h-5 text-red-500" />
              <Typography color="error">{error}</Typography>
            </CardContent>
          </Card>
        )}

        {selectedDate && scheduledJobs.length > 0 && (
          <Card className="shadow-sm">
            <CardContent>
              <Typography variant="h6" className="mb-4">Scheduled Jobs</Typography>
              <div className="space-y-3">
                {scheduledJobs.map(job => (
                  <div key={job.id} className="p-3 bg-gray-50 rounded-md">
                    <Typography variant="subtitle1">
                      {job.customerName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {moment(job.appointmentDate.toDate()).format('h:mm A')} - {' '}
                      {moment(job.scheduledEndTime.toDate()).format('h:mm A')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {job.address}
                    </Typography>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        )}

        {selectedDate && scheduledJobs.length === 0 && !loading && (
          <Card className="shadow-sm">
            <CardContent>
              <Typography className="text-center">
                No jobs scheduled for this date
              </Typography>
            </CardContent>
          </Card>
        )}

        {selectedDate && (
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            disabled={submitting}
            className="mt-4 bg-blue-500 hover:bg-blue-600"
          >
            {submitting ? 'Submitting...' : 'Submit Request'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TimeOffRequest;