// src/components/CleanerProfileSelfManage.js
import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent,
  TextField,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../context/AuthContext';

const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const CleanerProfileSelfManage = ({ open, onClose }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    availability: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
      sunday: false,
    },
    workingHours: {
      monday: { start: '07:30', end: '17:00' },
      tuesday: { start: '07:30', end: '17:00' },
      wednesday: { start: '07:30', end: '17:00' },
      thursday: { start: '07:30', end: '17:00' },
      friday: { start: '07:30', end: '17:00' },
      saturday: { start: '', end: '' },
      sunday: { start: '', end: '' },
    }
  });

  useEffect(() => {
    const fetchCleanerData = async () => {
      if (!user?.uid) return;

      try {
        const cleanerRef = doc(db, 'organizations', user.orgId, 'cleaners', user.uid);
        const cleanerDoc = await getDoc(cleanerRef);
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);

        if (cleanerDoc.exists() && userDoc.exists()) {
          const cleanerData = cleanerDoc.data();
          const userData = userDoc.data();
          
          setProfileData({
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            email: userData.email || '',
            phoneNumber: userData.phoneNumber || '',
            address: userData.address || '',
            availability: cleanerData.availability || {
              monday: true,
              tuesday: true,
              wednesday: true,
              thursday: true,
              friday: true,
              saturday: false,
              sunday: false,
            },
            workingHours: cleanerData.workingHours || {
              monday: { start: '07:30', end: '17:00' },
              tuesday: { start: '07:30', end: '17:00' },
              wednesday: { start: '07:30', end: '17:00' },
              thursday: { start: '07:30', end: '17:00' },
              friday: { start: '07:30', end: '17:00' },
              saturday: { start: '', end: '' },
              sunday: { start: '', end: '' },
            }
          });
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cleaner data:', error);
        setLoading(false);
      }
    };

    fetchCleanerData();
  }, [user]);

  const handleInputChange = (field) => (event) => {
    setProfileData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleAvailabilityChange = (day) => {
    setProfileData(prev => ({
      ...prev,
      availability: {
        ...prev.availability,
        [day]: !prev.availability[day]
      },
      workingHours: {
        ...prev.workingHours,
        [day]: !prev.availability[day] 
          ? { start: '07:30', end: '17:00' }
          : { start: '', end: '' }
      }
    }));
  };

  const handleWorkingHoursChange = (day, type) => (event) => {
    setProfileData(prev => ({
      ...prev,
      workingHours: {
        ...prev.workingHours,
        [day]: {
          ...prev.workingHours[day],
          [type]: event.target.value
        }
      }
    }));
  };

  const copyToAllDays = (sourceDay) => {
    const { start, end } = profileData.workingHours[sourceDay];
    setProfileData(prev => ({
      ...prev,
      workingHours: Object.keys(prev.workingHours).reduce((acc, day) => {
        if (prev.availability[day]) {
          acc[day] = { start, end };
        } else {
          acc[day] = prev.workingHours[day];
        }
        return acc;
      }, {})
    }));
  };

  const handleSubmit = async () => {
    if (!user?.uid) return;

    try {
      // Update user profile
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        phoneNumber: profileData.phoneNumber,
        address: profileData.address,
        updatedAt: new Date(),
        updatedBy: user.uid
      });

      // Update cleaner availability and working hours
      const cleanerRef = doc(db, 'organizations', user.orgId, 'cleaners', user.uid);
      await updateDoc(cleanerRef, {
        availability: profileData.availability,
        workingHours: profileData.workingHours,
        updatedAt: new Date(),
        updatedBy: user.uid
      });

      onClose();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>Update Profile</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="First Name"
              value={profileData.firstName}
              onChange={handleInputChange('firstName')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Last Name"
              value={profileData.lastName}
              onChange={handleInputChange('lastName')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              value={profileData.email}
              disabled
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Phone Number"
              value={profileData.phoneNumber}
              onChange={handleInputChange('phoneNumber')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              value={profileData.address}
              onChange={handleInputChange('address')}
              margin="normal"
              multiline
              rows={2}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Availability & Working Hours
            </Typography>
            <FormControl component="fieldset" fullWidth>
              <FormGroup>
                {DAYS_OF_WEEK.map((day) => (
                  <Grid container spacing={2} key={day} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={profileData.availability[day]}
                            onChange={() => handleAvailabilityChange(day)}
                          />
                        }
                        label={day.charAt(0).toUpperCase() + day.slice(1)}
                      />
                    </Grid>
                    {profileData.availability[day] && (
                      <>
                        <Grid item xs={6} sm={3}>
                          <TextField
                            label="Start Time"
                            type="time"
                            value={profileData.workingHours[day].start}
                            onChange={handleWorkingHoursChange(day, 'start')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <TextField
                            label="End Time"
                            type="time"
                            value={profileData.workingHours[day].end}
                            onChange={handleWorkingHoursChange(day, 'end')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            fullWidth
                          />
                        </Grid>
                        {day === 'monday' && (
                          <Grid item xs={12} sm={3}>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => copyToAllDays('monday')}
                              fullWidth
                            >
                              Copy to All Days
                            </Button>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                ))}
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CleanerProfileSelfManage;