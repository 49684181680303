// src/components/CreateRecurringService.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Alert,
  Divider,
  InputAdornment,
  ListItemText,
  ListItem,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { collection, addDoc, serverTimestamp, writeBatch, doc, setDoc, Timestamp } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import RecurrenceSelect from './RecurrenceSelect';
import { rrulestr } from 'rrule';
import { useTimezone } from '../context/TimeZoneContext';
import AddRoomsModal from './AddRoomsModal';
import SelectCleanersModal from './SelectCleanersModal';
import styles from '../styles/CreateRecurringService.module.css';
import { onAuthStateChanged } from 'firebase/auth';
import { useJobContext } from '../context/JobContext';
import { fetchGeocodeData } from '../utils/api';

const libraries = ['places'];

const CreateRecurringService = ({ 
  customer, 
  orgId, 
  onServiceCreated, 
  onCancel,
  cleaners = [],
  availableRooms = [],
  open
}) => {

  const { timezone, convertFromOrgTz, moment } = useTimezone();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerName, setCustomerName] = useState(customer?.name || '');
  const [customerEmail, setCustomerEmail] = useState(customer?.email || '');
  const [customerPhone, setCustomerPhone] = useState(customer?.phone || '');
  const [address, setAddress] = useState(customer?.serviceAddress || '');
  const [jobType, setJobType] = useState('residential');
  const [serviceType, setServiceType] = useState('one-time');
  const [selectedCleaners, setSelectedCleaners] = useState([]);
  const [price, setPrice] = useState(customer?.serviceCost?.toString() || '');
  const [recurrenceRule, setRecurrenceRule] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('10:00');
  const [notes, setNotes] = useState('');
  const [importantNotes, setImportantNotes] = useState('');
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [error, setError] = useState('');
  const [totalSize, setTotalSize] = useState(0);
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false);
  const [openSelectCleanersModal, setOpenSelectCleanersModal] = useState(false);
  const [addressAutocomplete, setAddressAutocomplete] = useState(null);
  const { generateMoreInstances } = useJobContext();
  const [success, setSuccess] = useState('');

  // Add auth listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setError('No authenticated user');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Add logging to handlers
  const handleJobTypeChange = (event) => {
    setJobType(event.target.value);
  };

  const handleServiceTypeChange = (event) => {
    const newType = event.target.value;
    setServiceType(newType);
    if (newType === 'one-time') {
      setRecurrenceRule('');
    }
  };

  const handleAddRooms = (roomsToAdd) => {
    setSelectedRooms(prevRooms => {
      const updatedRooms = [...prevRooms];
      roomsToAdd.forEach(newRoom => {
        const formattedTasks = (newRoom.tasks || []).map(task => ({
          description: typeof task === 'string' ? task : task.description,
          completed: false
        }));

        const roomToAdd = {
          ...newRoom,
          tasks: formattedTasks,
          quantity: newRoom.quantity || 1
        };
        
        const existingIndex = updatedRooms.findIndex(room => room.name === newRoom.name);
        if (existingIndex !== -1) {
          updatedRooms[existingIndex] = {
            ...updatedRooms[existingIndex],
            ...roomToAdd,
            tasks: roomToAdd.tasks.length > 0 ? roomToAdd.tasks : updatedRooms[existingIndex].tasks,
            quantity: roomToAdd.quantity || updatedRooms[existingIndex].quantity || 1
          };
        } else {
          updatedRooms.push(roomToAdd);
        }
      });
      
      return updatedRooms;
    });
  };

  const geocodeAddress = async (address) => {
    try {
      const response = await fetchGeocodeData(address);
      return {
        latitude: response.lat,
        longitude: response.lng,
        formattedAddress: response.formatted_address || address
      };
    } catch (error) {
      console.error('Geocoding error:', error);
      // Return null to handle the error gracefully in handleSubmit
      return null;
    }
  };

  const calculateDuration = (startTimeStr, endTimeStr) => {
    const startDate = new Date(`2000-01-01T${startTimeStr}`);
    const endDate = new Date(`2000-01-01T${endTimeStr}`);
    return endDate.getTime() - startDate.getTime();
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      // Basic validation
      if (!currentUser) throw new Error("You must be logged in to create a service");
      if (!orgId) throw new Error("Organization ID is required");
      if (!startDate) throw new Error("Please select a start date");
      if (serviceType === 'recurring' && !recurrenceRule) {
        throw new Error("Please select a recurrence pattern for recurring jobs");
      }
      if (!price) throw new Error("Please enter a service price");
      if (selectedCleaners.length === 0) throw new Error("Please select at least one cleaner");
      if (!customerName) throw new Error("Please enter customer name");
      if (!address) throw new Error("Please enter service address");
      if (!totalSize || totalSize <= 0) throw new Error("Please enter a valid total size in square feet");
      if (new Date(endTime) <= new Date(startTime)) {
        throw new Error("End time must be after start time");
      }
  
      // Format dates with timezone
      const startDateTime = `${startDate}T${startTime}`;
      const endDateTime = `${startDate}T${endTime}`;
      const duration = calculateDuration(startTime, endTime);
  
      // Try to geocode the address
      let locationData = null;
      try {
        locationData = await geocodeAddress(address);
      } catch (geocodeError) {
        console.error('Geocoding error:', geocodeError);
        // Continue without geocoding if it fails
      }
  
      // Base job data
      const baseJobData = {
        customerId: customer?.id,
        customerName,
        customerEmail,
        customerPhone,
        address,
        totalSize: parseFloat(totalSize),
        rooms: selectedRooms.map(room => ({
          ...room,
          tasks: room.tasks.map(task => ({
            description: typeof task === 'string' ? task : task.description,
            completed: false
          }))
        })),
        notes,
        importantNotes,
        status: 'Pending',
        createdAt: new Date(),
        updatedAt: new Date(),
        organizationId: orgId,
        jobType,
        cleaners: selectedCleaners.map(cleaner => cleaner.id),
        cleanerDetails: selectedCleaners.map(cleaner => ({
          id: cleaner.id,
          name: cleaner.firstName && cleaner.lastName 
            ? `${cleaner.firstName} ${cleaner.lastName}`
            : cleaner.email,
          email: cleaner.email
        })),
        serviceType,
        recurring: serviceType === 'recurring',
        appointmentDate: convertFromOrgTz(startDateTime).toDate(),
        scheduledEndTime: convertFromOrgTz(endDateTime).toDate(),
        timezone,
        scheduledDuration: duration,
        serviceCost: parseFloat(price),
        createdBy: currentUser.uid,
        updatedBy: currentUser.uid,
        latitude: locationData?.latitude,
        longitude: locationData?.longitude,
        formattedAddress: locationData?.formattedAddress || address
      };
  
      if (serviceType === 'recurring') {
        const recurrenceGroupId = `rec_${new Date().getTime()}`;
        const jobsRef = collection(db, 'organizations', orgId, 'jobs');
        const initialJobRef = doc(jobsRef);
        
        // Initial job data
        const initialJobData = {
          ...baseJobData,
          recurrenceGroupId,
          recurrenceRule,
          recurring: true,
          status: 'Pending',
          organizationId: orgId
        };
  
        // Recurring service data
        const recurringServicesRef = collection(db, 'organizations', orgId, 'recurringServices');
        const recurringServiceData = {
          ...baseJobData,
          id: recurrenceGroupId,
          customerId: customer?.id,
          cleanerId: selectedCleaners[0]?.id,
          cleanerEmail: selectedCleaners[0]?.email,
          cleanerName: selectedCleaners[0]?.firstName && selectedCleaners[0]?.lastName ? 
            `${selectedCleaners[0].firstName} ${selectedCleaners[0].lastName}` : 
            selectedCleaners[0]?.email,
          recurringStatus: "Active",
          recurringStartDate: convertFromOrgTz(startDateTime).toDate(),
          recurrenceRule,
          organizationId: orgId,
          recurrenceGroupId,
          lastUpdated: new Date(),
          totalJobsCreated: 1,
          nextScheduledDate: convertFromOrgTz(startDateTime).toDate(),
          status: 'Pending',
          price: parseFloat(price)
        };
  
        try {
          // Create initial documents
          const batch = writeBatch(db);
          batch.set(initialJobRef, initialJobData);
          batch.set(doc(recurringServicesRef, recurrenceGroupId), recurringServiceData);
          await batch.commit();
  
          // Generate recurring instances
          const dtstart = convertFromOrgTz(startDateTime).toDate();
          const dtstartStr = dtstart.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
          const rruleString = `DTSTART:${dtstartStr}\nRRULE:${recurrenceRule}`;
          
          const rule = rrulestr(rruleString);
          const dates = rule.between(
            dtstart,
            new Date(dtstart.getTime() + (90 * 24 * 60 * 60 * 1000)), // 90 days
            true
          );
  
          // Create recurring instances
          for (const date of dates.slice(1)) { // Skip first date as it's already created
            const jobRef = doc(jobsRef);
            
            await setDoc(jobRef, {
              ...initialJobData,
              id: jobRef.id,
              appointmentDate: convertFromOrgTz(date.toISOString()).toDate(),
              scheduledEndTime: convertFromOrgTz(
                new Date(date.getTime() + duration).toISOString()
              ).toDate(),
              status: "Pending",
              createdAt: new Date()
            });
          }
          
          onServiceCreated();
        } catch (error) {
          console.error('Error in batch operation:', error);
          throw error;
        }
      } else {
        // Create single job
        const jobsRef = collection(db, 'organizations', orgId, 'jobs');
        await addDoc(jobsRef, baseJobData);
        onServiceCreated();
      }
  
      setSuccess('Service created successfully!');
    } catch (err) {
      console.error('Error creating service:', err);
      setError(err.message);
    }
  };

  // Google Places handlers
  const handleAddressAutocompleteLoad = (autocomplete) => {
    setAddressAutocomplete(autocomplete);
  };
  
  const handlePlaceSelect = () => {
    try {
      if (!addressAutocomplete) {
        return;
      }
      
      const place = addressAutocomplete.getPlace();
      
      if (place.formatted_address) {
        setAddress(place.formatted_address);
      }
    } catch (error) {
      console.error('Error in place selection:', error);
    }
  };

  // Show loading state while checking auth
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Dialog 
      open={open} 
      onClose={onCancel}
      maxWidth="md"
      fullWidth
      PaperProps={{
        className: styles.dialogPaper
      }}
    >
      <DialogTitle className={styles.dialogTitle}>
        Create Service
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Box className={styles.container}>
          <form onSubmit={handleSubmit}>
              {error && (
              <Alert severity="error" onClose={() => setError('')} sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
              {success && (
                <Alert severity="success" onClose={() => setSuccess('')} sx={{ mb: 2 }}>
                {success}
              </Alert>
            )}

            {/* Customer Information Section */}
            <Box className={styles.section}>
              <Typography variant="h6">Customer Information</Typography>
              <TextField
                label="Customer Name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                label="Email"
                type="email"
                value={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                fullWidth
                margin="normal"
              />

              <TextField
                label="Phone"
                type="tel"
                value={customerPhone}
                onChange={(e) => setCustomerPhone(e.target.value)}
                fullWidth
                margin="normal"
              />

              <GoogleAutocomplete
                onLoad={handleAddressAutocompleteLoad}
                onPlaceChanged={handlePlaceSelect}
                options={{
                  componentRestrictions: { country: 'us' },
                  fields: ['address_components', 'formatted_address', 'geometry', 'name'],
                  types: ['address']
                }}
              >
                <TextField
                  label="Service Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  fullWidth
                  margin="normal"
                  required
                />
              </GoogleAutocomplete>
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* Service Details Section */}
            <Box className={styles.section}>
              <Typography variant="h6">Service Details</Typography>
              
              <FormControl fullWidth margin="normal">
                <InputLabel id="job-type-label">Job Type</InputLabel>
                <Select
                  labelId="job-type-label"
                  value={jobType}
                  onChange={handleJobTypeChange}
                  label="Job Type"
                  required
                  size="small"
                  MenuProps={{
                    disablePortal: true,
                    style: { zIndex: 2000 },
                  }}
                >
                  <MenuItem value="residential">Residential</MenuItem>
                  <MenuItem value="commercial">Commercial</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel id="service-type-label">Service Type</InputLabel>
                <Select
                  labelId="service-type-label"
                  value={serviceType}
                  onChange={handleServiceTypeChange}
                  label="Service Type"
                  required
                  size="small"
                  MenuProps={{
                    disablePortal: true,
                    style: { zIndex: 2000 },
                  }}
                >
                  <MenuItem value="one-time">One Time Service</MenuItem>
                  <MenuItem value="recurring">Recurring Service</MenuItem>
                </Select>
              </FormControl>

              {serviceType === 'recurring' && (
                <div className={styles.recurrenceContainer}>
                  <RecurrenceSelect
                    value={recurrenceRule}
                    onChange={setRecurrenceRule}
                    MenuProps={{
                      container: document.body,
                      style: { zIndex: 1400 }
                    }}
                  />
                </div>
              )}

              {/* Cleaners Section */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>Assigned Cleaners</Typography>
                <Box sx={{ mb: 2 }}>
                  {selectedCleaners.map(cleaner => (
                    <Box
                      key={cleaner.id}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                        mb: 1,
                        borderRadius: 1,
                        backgroundColor: 'var(--light-foreground)',
                        '[data-mui-color-scheme="dark"] &': {
                          backgroundColor: 'var(--dark-text-area)'
                        }
                      }}
                    >
                      <Typography>
                        {cleaner.firstName && cleaner.lastName
                          ? `${cleaner.firstName} ${cleaner.lastName}`
                          : cleaner.email}
                      </Typography>
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => setSelectedCleaners(prev => 
                          prev.filter(c => c.id !== cleaner.id)
                        )}
                      >
                        Remove
                      </Button>
                    </Box>
                  ))}
                </Box>
                <Button
                  variant="outlined"
                  onClick={() => setOpenSelectCleanersModal(true)}
                  fullWidth
                >
                  {selectedCleaners.length === 0 ? 'Select Cleaners' : 'Modify Cleaners'}
                </Button>
              </Grid>

              <Divider sx={{ my: 2 }} />

              {/* Date and Time Section */}
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'var(--light-foreground)',
                      '[data-mui-color-scheme="dark"] &': {
                        backgroundColor: 'var(--dark-text-area)'
                      }
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Start Time"
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'var(--light-foreground)',
                      '[data-mui-color-scheme="dark"] &': {
                        backgroundColor: 'var(--dark-text-area)'
                      }
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="End Time"
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'var(--light-foreground)',
                      '[data-mui-color-scheme="dark"] &': {
                        backgroundColor: 'var(--dark-text-area)'
                      }
                    }
                  }}
                />
              </Grid>

              {/* Price and Size Section */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Service Price"
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'var(--light-foreground)',
                      '[data-mui-color-scheme="dark"] &': {
                        backgroundColor: 'var(--dark-text-area)'
                      }
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Total Size (SqFt)"
                  type="number"
                  value={totalSize}
                  onChange={(e) => setTotalSize(Math.max(0, parseFloat(e.target.value) || 0))}
                  fullWidth
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'var(--light-foreground)',
                      '[data-mui-color-scheme="dark"] &': {
                        backgroundColor: 'var(--dark-text-area)'
                      }
                    }
                  }}
                />
              </Grid>

              {/* Rooms Section */}
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" sx={{ mb: 2 }}>Rooms</Typography>
                <Button
                  variant="outlined"
                  onClick={() => setOpenAddRoomsModal(true)}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  Add Rooms
                </Button>
                
                {selectedRooms.length > 0 ? (
                  <List>
                    {selectedRooms.map((room, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          backgroundColor: 'var(--light-foreground)',
                          '[data-mui-color-scheme="dark"] &': {
                            backgroundColor: 'var(--dark-text-area)'
                          },
                          mb: 1,
                          borderRadius: 1
                        }}
                      >
                        <ListItemText
                          primary={room.name}
                          secondary={`${room.tasks.length} tasks`}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography color="text.secondary">No rooms added yet</Typography>
                )}
              </Grid>

              {/* Notes Section */}
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" sx={{ mb: 2 }}>Notes</Typography>
                <TextField
                  multiline
                  rows={3}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  fullWidth
                  placeholder="Add notes here"
                  sx={{
                    mb: 2,
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'var(--light-foreground)',
                      '[data-mui-color-scheme="dark"] &': {
                        backgroundColor: 'var(--dark-text-area)'
                      }
                    }
                  }}
                />
                
                <Typography variant="h6" sx={{ mb: 2 }}>Important Notes</Typography>
                <TextField
                  multiline
                  rows={3}
                  value={importantNotes}
                  onChange={(e) => setImportantNotes(e.target.value)}
                  fullWidth
                  placeholder="Add important notes here"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'var(--light-foreground)',
                      '[data-mui-color-scheme="dark"] &': {
                        backgroundColor: 'var(--dark-text-area)'
                      }
                    }
                  }}
                />
              </Grid>
            </Box>

            {/* Action Buttons */}
            <Box className={styles.formActions}>
              <Button onClick={onCancel} variant="outlined" color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Create Service
              </Button>
            </Box>
          </form>
        </Box>

        {/* Modals */}
        <SelectCleanersModal
          open={openSelectCleanersModal}
          handleClose={() => setOpenSelectCleanersModal(false)}
          cleaners={cleaners}
          selectedCleaners={selectedCleaners}
          setSelectedCleaners={setSelectedCleaners}
        />

        <AddRoomsModal
          open={openAddRoomsModal}
          handleClose={() => setOpenAddRoomsModal(false)}
          availableRooms={availableRooms}
          handleAddRooms={handleAddRooms}
          existingRooms={selectedRooms}
          orgId={orgId}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateRecurringService;