import React from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Avatar,
  Stack,
  Radio,
  Skeleton
} from '@mui/material';
import { Star } from '@mui/icons-material';

const CleanerCard = ({ 
  cleaner, 
  metrics, 
  selected, 
  onSelect,
  loading 
}) => {
  if (loading) {
    return (
      <Card 
        sx={{ 
          mb: 2, 
          cursor: 'pointer',
          '&:hover': { boxShadow: 6 }
        }}
      >
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Skeleton variant="circular" width={56} height={56} />
          <Stack spacing={1} flex={1}>
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={150} />
            <Skeleton variant="text" width={180} />
          </Stack>
          <Skeleton variant="circular" width={20} height={20} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card 
      sx={{ 
        mb: 2, 
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        backgroundColor: selected ? 'action.selected' : 'background.paper',
        '&:hover': { 
          boxShadow: 6,
          backgroundColor: selected ? 'action.selected' : 'action.hover'
        }
      }}
      onClick={() => onSelect(cleaner.id)}
    >
      <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar 
          sx={{ width: 56, height: 56 }}
          src={cleaner.photoURL}
        >
          {cleaner.name?.charAt(0)}
        </Avatar>

        <Stack spacing={0.5} flex={1}>
          <Typography variant="h6" component="div">
          {`${cleaner.firstName} ${cleaner.lastName}`}
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Star sx={{ color: 'warning.main', fontSize: 20 }} />
            <Typography variant="body2" color="text.secondary">
              {metrics?.clientRating?.allTime || 0}/5 Rating
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Typography variant="body2" color="text.secondary">
              {metrics?.totalCompletedJobs?.allTime || 0} Jobs Completed
            </Typography>
            <Typography variant="body2" color="text.secondary">
              •
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {metrics?.totalClients?.allTime || 0} Current Clients
            </Typography>
          </Box>
        </Stack>

        <Radio 
          checked={selected}
          onChange={() => onSelect(cleaner.id)}
          onClick={(e) => e.stopPropagation()}
        />
      </CardContent>
    </Card>
  );
};

export default CleanerCard;