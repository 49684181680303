// src/components/CreateJobModal.js

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  writeBatch,
  deleteDoc,
} from 'firebase/firestore';
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { Plus, Edit2, Trash2 } from 'lucide-react';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import CreateRoomModal from './CreateRoomModal';
import EditRoomModal from './EditRoomModal';
import AddRoomsModal from './AddRoomsModal';
import CreateCustomerModal from './CreateCustomerModal';
import AddCleanerModal from './AddCleanerModal';
import RecurrenceSelect from './RecurrenceSelect';
import styles from '../styles/CreateJobForm.module.css';
import { useTimezone } from '../context/TimeZoneContext';

const CreateJobModal = ({
  open,
  handleClose,
  // If you have defaultAppointmentDate or defaultAppointmentEndDate,
  // define them here. Otherwise, remove these lines.
  defaultAppointmentDate = '',
  defaultAppointmentEndDate = '',
}) => {
  //
  // 1) Always call Hooks, even if `open` is false
  //
  const location = useLocation();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [address, setAddress] = useState('');
  const [notes, setNotes] = useState('');
  const [importantNotes, setImportantNotes] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  const [user, setUser] = useState(null);
  const { convertFromOrgTz, timezone } = useTimezone();
  const [orgId, setOrgId] = useState(null);

  const [availableRooms, setAvailableRooms] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [openCreateRoomModal, setOpenCreateRoomModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingRoom, setEditingRoom] = useState(null);
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false);
  const [roomAccordionOpen, setRoomAccordionOpen] = useState({});

  // Cleaner management state
  const [cleaners, setCleaners] = useState([]);
  const [selectedCleaner, setSelectedCleaner] = useState(null);
  const [openAddCleanerModal, setOpenAddCleanerModal] = useState(false);
  
  // Customer management state
  const [openCreateCustomerModal, setOpenCreateCustomerModal] = useState(false);
  
  // Appointment fields
  const [appointmentDate, setAppointmentDate] = useState(defaultAppointmentDate);
  const [appointmentEndDate, setAppointmentEndDate] = useState(defaultAppointmentEndDate);

  const [accordionOpen, setAccordionOpen] = useState({});
  const [recurring, setRecurring] = useState(false);
  const [recurrenceRule, setRecurrenceRule] = useState('');
  const [serviceType, setServiceType] = useState('one-time'); // 'one-time' or 'recurring'
  const [serviceCost, setServiceCost] = useState('');
  const [totalSize, setTotalSize] = useState('');

  // Autocomplete state
  const [customerInput, setCustomerInput] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
  const [cleanerInput, setCleanerInput] = useState('');
  const [filteredCleaners, setFilteredCleaners] = useState([]);
  const [showCleanerDropdown, setShowCleanerDropdown] = useState(false);

  // Google Maps state
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);

  //
  // 2) Authentication effect
  //
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchUserOrgId(currentUser.uid);
      } else {
        setLoading(false);
        setError('User not authenticated');
      }
    });
    return () => unsubscribe();
  }, []);

  const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return end.getTime() - start.getTime();
  };

  // Fetch user orgId
  const fetchUserOrgId = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }
      const userOrgId = userDoc.data().orgId;
      setOrgId(userOrgId);
      fetchData(userOrgId);
    } catch (err) {
      console.error("Error fetching user's organization ID:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  // Fetch data
  const fetchData = async (organizationId) => {
    setLoading(true);
    setError(null);
    try {
      // Fetch customers
      const customersRef = collection(db, 'organizations', organizationId, 'customers');
      const customersSnapshot = await getDocs(customersRef);
      const customersList = customersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCustomers(customersList);

      // Fetch room types
      const roomTypesRef = collection(db, 'organizations', organizationId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomTypesList = roomTypesSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.name.localeCompare(b.name));
      setAvailableRooms(roomTypesList);

      // Fetch cleaners (from users collection)
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const cleanersList = usersSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter(
          (user) =>
            user.orgId === organizationId &&
            user.role === 'cleaner' &&
            user.isActive !== false
        );
      setCleaners(cleanersList);
    } catch (err) {
      console.error('Error in fetchData:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  //
  // 3) Event handlers, tasks, room management, etc. (unchanged)
  //
  const handleCustomerInputChange = (e) => {
    const value = e.target.value;
    setCustomerInput(value);
    if (value.length > 1) {
      const filtered = customers.filter((customer) =>
        customer.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCustomers(filtered);
      setShowCustomerDropdown(true);
    } else {
      setShowCustomerDropdown(false);
    }
  };

  const handleCleanerInputChange = (e) => {
    const value = e.target.value;
    setCleanerInput(value);
    if (value.length > 1) {
      const filtered = cleaners.filter((cleaner) => {
        const name =
          cleaner.firstName && cleaner.lastName
            ? `${cleaner.firstName} ${cleaner.lastName}`
            : cleaner.email;
        return name.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredCleaners(filtered);
      setShowCleanerDropdown(true);
    } else {
      setShowCleanerDropdown(false);
    }
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setCustomerInput(customer.name);
    setAddress(customer.address || '');
    setShowCustomerDropdown(false);
  };

  const toggleAccordion = (index) => {
    setAccordionOpen((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleRoomAccordion = (roomId) => {
    setRoomAccordionOpen((prev) => ({
      ...prev,
      [roomId]: !prev[roomId],
    }));
  };

  // Task management
  const handleTaskChange = (roomIndex, taskIndex, completed) => {
    setSelectedRooms((prevRooms) => {
      const updatedRooms = [...prevRooms];
      if (updatedRooms[roomIndex] && updatedRooms[roomIndex].tasks) {
        updatedRooms[roomIndex].tasks[taskIndex].completed = completed;
      }
      return updatedRooms;
    });
  };

  // Room management
  const fetchAvailableRooms = async () => {
    try {
      const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomTypesList = roomTypesSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.name.localeCompare(b.name));
      setAvailableRooms(roomTypesList);
    } catch (err) {
      console.error('Error fetching room types:', err);
    }
  };

  const handleCreateRoom = async (newRoom) => {
    try {
      if (!orgId) throw new Error('Organization ID not found');
      const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
      await addDoc(roomTypesRef, newRoom);
      await fetchData(orgId);
      setSuccess('Room created successfully!');
      setOpenCreateRoomModal(false);
    } catch (error) {
      setError('Failed to create new room type');
    }
  };

  const handleEditRoomClick = (room) => {
    setEditingRoom(room);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setEditingRoom(null);
  };

  const handleEditRoom = async (updatedRoom) => {
    try {
      if (!orgId) throw new Error('Organization ID not found');
      const roomDocRef = doc(db, 'organizations', orgId, 'roomTypes', updatedRoom.id);
      await updateDoc(roomDocRef, {
        name: updatedRoom.name,
        tasks: updatedRoom.tasks,
        updatedAt: new Date(),
      });
      await fetchData(orgId);
      setSuccess('Room updated successfully!');
      handleCloseEditModal();
    } catch (error) {
      setError('Failed to edit room type');
    }
  };

  const handleDeleteRoom = async (roomId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this room?');
    if (!confirmDelete) return;
    try {
      if (!orgId) throw new Error('Organization ID not found');
      const roomDocRef = doc(db, 'organizations', orgId, 'roomTypes', roomId);
      await deleteDoc(roomDocRef);
      await fetchData(orgId);
      setSuccess('Room deleted successfully!');
    } catch (error) {
      setError('Failed to delete room type');
    }
  };

  // Handle adding rooms from AddRoomsModal
  const handleAddRooms = (roomsToAdd) => {
    setSelectedRooms((prevRooms) => {
      const updatedRooms = [...prevRooms];
      roomsToAdd.forEach((newRoom) => {
        const formattedTasks = (newRoom.tasks || []).map((task) => {
          if (typeof task === 'string') {
            return { description: task, completed: false };
          }
          return {
            description: task.description || '',
            completed: task.completed || false,
            ...task,
          };
        });
        const roomToAdd = {
          ...newRoom,
          tasks: formattedTasks,
          quantity: newRoom.quantity || 1,
        };
        const existingIndex = updatedRooms.findIndex((room) => room.name === newRoom.name);
        if (existingIndex !== -1) {
          const existingRoom = updatedRooms[existingIndex];
          updatedRooms[existingIndex] = {
            ...existingRoom,
            ...roomToAdd,
            tasks: roomToAdd.tasks.length > 0 ? roomToAdd.tasks : existingRoom.tasks,
            quantity: roomToAdd.quantity || existingRoom.quantity || 1,
          };
        } else {
          updatedRooms.push(roomToAdd);
        }
      });
      return updatedRooms;
    });
  };

  // Customer management
  const handleCreateCustomer = async (newCustomer) => {
    try {
      if (!orgId) throw new Error('Organization ID not found');
      const customersRef = collection(db, 'organizations', orgId, 'customers');
      await addDoc(customersRef, newCustomer);
      await fetchData(orgId);
      setSuccess('Customer created successfully!');
      setOpenCreateCustomerModal(false);
    } catch (error) {
      setError('Failed to create new customer');
    }
  };

  // Cleaner management
  const handleAddCleaner = async (newCleaner) => {
    try {
      if (!orgId) throw new Error('Organization ID not found');
      const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
      await addDoc(cleanersRef, newCleaner);
      await fetchData(orgId);
      setSuccess('Cleaner added successfully!');
      setOpenAddCleanerModal(false);
    } catch (error) {
      setError('Failed to add new cleaner');
    }
  };

  // CSV handling
  const handleCSVUpload = (roomsFromCSV) => {
    setSelectedRooms((prevRooms) => [...prevRooms, ...roomsFromCSV]);
    setSuccess('Rooms imported from CSV successfully!');
  };

  //
  // 4) handleSubmit for the Create Job form
  //
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    try {
      if (!selectedCustomer) throw new Error('Please select a customer');
      if (!selectedCleaner) throw new Error('Please assign a cleaner');
      if (!appointmentDate) throw new Error('Please set an appointment start date');
      if (!appointmentEndDate) throw new Error('Please set an appointment end date');
      if (!serviceCost) throw new Error('Please enter the service cost');
      if (new Date(appointmentEndDate) <= new Date(appointmentDate)) {
        throw new Error('End time must be after start time');
      }
      if (!orgId) throw new Error('Organization ID not found');

      // Validate recurrence
      if (serviceType === 'recurring') {
        if (!recurrenceRule || recurrenceRule === '') {
          throw new Error('Please select a recurrence pattern for recurring jobs');
        }
        if (!recurrenceRule.startsWith('FREQ=')) {
          throw new Error('Invalid recurrence pattern format');
        }
      }

      // Generate recurring job ID if needed
      const recurrenceGroupId = serviceType === 'recurring'
        ? `rec_${new Date().getTime()}`
        : null;

      // Calculate duration
      const duration = calculateDuration(appointmentDate, appointmentEndDate);

      // Build job data
      const baseJobData = {
        customerId: selectedCustomer.id,
        customerName: selectedCustomer.name,
        address,
        rooms: selectedRooms.map((room) => ({
          ...room,
          tasks: room.tasks.map((task) => ({
            description: typeof task === 'string' ? task : task.description,
            completed: false,
          })),
        })),
        notes,
        importantNotes,
        status: 'Pending',
        createdAt: new Date(),
        updatedAt: new Date(),
        organizationId: orgId,
        cleanerId: selectedCleaner.id,
        cleanerName:
          selectedCleaner.firstName && selectedCleaner.lastName
            ? `${selectedCleaner.firstName} ${selectedCleaner.lastName}`
            : selectedCleaner.email,
        cleanerEmail: selectedCleaner.email,
        serviceType,
        recurring: serviceType === 'recurring',
        recurrenceRule: serviceType === 'recurring' ? recurrenceRule : null,
        recurrenceGroupId,
        appointmentDate: convertFromOrgTz(appointmentDate).toDate(),
        scheduledEndTime: convertFromOrgTz(appointmentEndDate).toDate(),
        timezone: timezone,
        scheduledDuration: duration,
        serviceCost: parseFloat(serviceCost),
        totalSize: parseFloat(totalSize),
      };

      // Firestore references
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const recurringServicesRef = collection(db, 'organizations', orgId, 'recurringServices');

      // Validate totalSize
      if (!totalSize || totalSize <= 0) {
        throw new Error('Please enter a valid total size in square feet.');
      }

      // If recurring, create multiple job instances + recurring service record
      if (serviceType === 'recurring') {
        const dtstart = new Date(appointmentDate);
        const dtstartStr = dtstart.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
        const rruleString = `DTSTART:${dtstartStr}\nRRULE:${recurrenceRule}`;

        try {
          const rule = rrulestr(rruleString);
          // Generate the next 6 occurrences within 180 days
          const dates = rule
            .between(dtstart, new Date(dtstart.getTime() + 180 * 24 * 60 * 60 * 1000), true)
            .slice(0, 6);

          const batch = writeBatch(db);

          dates.forEach((date) => {
            const jobRef = doc(jobsRef);
            const jobData = {
              ...baseJobData,
              appointmentDate: date,
              scheduledEndTime: new Date(date.getTime() + duration),
            };
            batch.set(jobRef, jobData);
          });

          // Create the recurring service document
          const recurringServiceData = {
            ...baseJobData,
            recurringStatus: 'Active',
            recurringStartDate: new Date(appointmentDate),
            lastUpdated: new Date(),
          };
          batch.set(doc(recurringServicesRef, recurrenceGroupId), recurringServiceData);

          await batch.commit();
          console.log(`Created ${dates.length} recurring instances + service record`);
        } catch (error) {
          console.error('Error parsing RRULE:', error);
          throw new Error(`Invalid recurrence pattern: ${error.message}`);
        }
      } else {
        // One-time service
        await addDoc(jobsRef, baseJobData);
      }

      // Reset form
      setSelectedCustomer(null);
      setCustomerInput('');
      setAddress('');
      setSelectedRooms([]);
      setNotes('');
      setImportantNotes('');
      setSelectedCleaner(null);
      setCleanerInput('');
      setAppointmentDate('');
      setAppointmentEndDate('');
      setServiceType('one-time');
      setRecurring(false);
      setRecurrenceRule('');
      setServiceCost('');
      setSuccess('Job created successfully!');
    } catch (error) {
      console.error('Error creating job:', error);
      setError(error.message);
    }
  };

  //
  // 5) Handle loading/error states (always called)
  //
  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>{error}</div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className={styles.errorContainer}>
        Please sign in to create a job.
      </div>
    );
  }

  //
  // 6) Conditionally render the modal's DOM if `open` is true
  //
  return open ? (
    <div className={styles.modalOverlay}>
      <button className={styles.closeButton} onClick={handleClose}>
        Close
      </button>

      <div className={styles.modalContent}>
        <div className={styles.formContainer}>
          <div className={styles.formContent}>
            <div className={styles.formHeader}>
              <h2 className={styles.formTitle}>Create New Job</h2>
              <button
                type="button"
                onClick={() => setOpenCreateCustomerModal(true)}
                className={styles.button}
              >
                Create Customer
              </button>
            </div>

            <form onSubmit={handleSubmit} className={styles.form}>
              {/* -- Your entire form as is -- */}

              {/* Customer Selection */}
              <div className={styles.formSection}>
                <div className={styles.inputGroup}>
                  <div className={styles.autocompleteContainer}>
                    <label className={styles.label}>Customer</label>
                    <input
                      type="text"
                      placeholder="Search For A Customer"
                      value={customerInput}
                      onChange={handleCustomerInputChange}
                      className={styles.input}
                      required
                    />
                    {showCustomerDropdown && (
                      <div className={styles.dropdown}>
                        {filteredCustomers.map((customer) => (
                          <div
                            key={customer.id}
                            className={styles.dropdownItem}
                            onClick={() => handleCustomerSelect(customer)}
                          >
                            {customer.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Address */}
              <div className={styles.formSection}>
                <label className={styles.label}>Address</label>
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className={styles.input}
                  required
                />
              </div>

              {/* Cleaner Selection */}
              <div className={styles.formSection}>
                <label className={styles.label}>Assign Cleaner</label>
                <select
                  value={selectedCleaner?.id || ''}
                  onChange={(e) => {
                    const cleaner = cleaners.find((c) => c.id === e.target.value);
                    setSelectedCleaner(cleaner);
                  }}
                  className={styles.select}
                  required
                >
                  <option value="">Select a cleaner</option>
                  {cleaners.map((cleaner) => (
                    <option key={cleaner.id} value={cleaner.id}>
                      {cleaner.firstName && cleaner.lastName
                        ? `${cleaner.firstName} ${cleaner.lastName}`
                        : cleaner.email}
                    </option>
                  ))}
                </select>
              </div>

              {/* Date and Time Selection */}
              <div className={styles.formSection}>
                <div className={styles.dateTimeContainer}>
                  <div className={styles.dateSelectGroup}>
                    <label className={styles.label}>Appointment Date</label>
                    <input
                      type="date"
                      value={appointmentDate.split('T')[0] || ''}
                      onChange={(e) => {
                        const date = e.target.value;
                        const time = appointmentDate.split('T')[1] || '00:00';
                        setAppointmentDate(`${date}T${time}`);
                        const endTime = appointmentEndDate.split('T')[1] || '00:00';
                        setAppointmentEndDate(`${date}T${endTime}`);
                      }}
                      className={styles.input}
                      required
                    />
                  </div>

                  <div className={styles.timeSelectGroup}>
                    <label className={styles.label}>Start Time</label>
                    <select
                      value={appointmentDate.split('T')[1]?.slice(0, -3) || '09:00'}
                      onChange={(e) => {
                        const date =
                          appointmentDate.split('T')[0] ||
                          new Date().toISOString().split('T')[0];
                        setAppointmentDate(`${date}T${e.target.value}:00`);
                      }}
                      className={styles.select}
                      required
                    >
                      {Array.from({ length: 97 }, (_, i) => {
                        const hour24 = Math.floor(i / 4);
                        const minute = (i % 4) * 15;
                        const hour12 = hour24 % 12 || 12;
                        const ampm = hour24 < 12 ? 'AM' : 'PM';
                        const time24 = `${hour24.toString().padStart(2, '0')}:${minute
                          .toString()
                          .padStart(2, '0')}`;
                        const displayTime = `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
                        return (
                          <option key={time24} value={time24}>
                            {displayTime}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className={styles.timeSelectGroup}>
                    <label className={styles.label}>End Time</label>
                    <select
                      value={appointmentEndDate.split('T')[1]?.slice(0, -3) || '10:00'}
                      onChange={(e) => {
                        const date =
                          appointmentDate.split('T')[0] ||
                          new Date().toISOString().split('T')[0];
                        setAppointmentEndDate(`${date}T${e.target.value}:00`);
                      }}
                      className={styles.select}
                      required
                    >
                      {Array.from({ length: 97 }, (_, i) => {
                        const hour24 = Math.floor(i / 4);
                        const minute = (i % 4) * 15;
                        const hour12 = hour24 % 12 || 12;
                        const ampm = hour24 < 12 ? 'AM' : 'PM';
                        const time24 = `${hour24.toString().padStart(2, '0')}:${minute
                          .toString()
                          .padStart(2, '0')}`;
                        const displayTime = `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
                        if (time24 <= (appointmentDate.split('T')[1]?.slice(0, -3) || '00:00')) {
                          return null;
                        }
                        return (
                          <option key={time24} value={time24}>
                            {displayTime}
                          </option>
                        );
                      }).filter(Boolean)}
                    </select>
                  </div>
                </div>
              </div>

              {/* Service Type and Cost */}
              <div className={styles.formSection}>
                <div className={styles.inputGroup}>
                  <label className={styles.label}>Service Type</label>
                  <select
                    value={serviceType}
                    onChange={(e) => {
                      setServiceType(e.target.value);
                      setRecurring(e.target.value === 'recurring');
                      if (e.target.value === 'one-time') {
                        setRecurrenceRule('');
                      }
                    }}
                    className={styles.select}
                    required
                  >
                    <option value="one-time">One Time Service</option>
                    <option value="recurring">Recurring Service</option>
                  </select>
                </div>

                <div className={styles.inputGroup}>
                  <label className={styles.label}>Service Cost ($)</label>
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={serviceCost}
                    onChange={(e) => setServiceCost(e.target.value)}
                    className={styles.input}
                    placeholder="Enter service cost"
                    required
                  />
                </div>

                {serviceType === 'recurring' && (
                  <div className={styles.recurrenceContainer}>
                    <RecurrenceSelect value={recurrenceRule} onChange={setRecurrenceRule} />
                  </div>
                )}
              </div>

              {/* Total Size (SqFt) Field */}
              <div className={styles.formSection}>
                <label className={styles.label}>Total Size (SqFt)</label>
                <input
                  type="number"
                  min="0"
                  step="1"
                  value={totalSize}
                  onChange={(e) => setTotalSize(e.target.value)}
                  className={styles.input}
                  placeholder="Enter the total size in square feet"
                  required
                />
              </div>

              {/* Rooms Section */}
              <div className={styles.formSection}>
                <div className={styles.sectionHeader}>
                  <h3 className={styles.sectionTitle}>Rooms</h3>
                  <button
                    type="button"
                    onClick={() => setOpenAddRoomsModal(true)}
                    className={styles.button}
                  >
                    <Plus size={16} /> Add Rooms
                  </button>
                </div>
                <div className={styles.roomsList}>
                  {selectedRooms.map((room, index) => (
                    <div key={index} className={styles.roomCard}>
                      <div
                        className={styles.roomHeader}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h4 className={styles.roomName}>{room.name}</h4>
                        <span>{accordionOpen[index] ? '-' : '+'}</span>
                      </div>
                      {accordionOpen[index] && (
                        <div className={styles.tasksList}>
                          {room.tasks?.map((task, taskIndex) => (
                            <div key={taskIndex} className={styles.taskItem}>
                              <label className={styles.checkboxLabel}>
                                <input
                                  type="checkbox"
                                  checked={task.completed || false}
                                  onChange={(e) => handleTaskChange(index, taskIndex, e.target.checked)}
                                  className={styles.checkbox}
                                />
                                <span>{task.description}</span>
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* Notes Fields */}
              <div className={styles.formSection}>
                <label className={styles.label}>Notes</label>
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className={styles.textarea}
                  rows={3}
                />
              </div>

              <div className={styles.formSection}>
                <label className={styles.label}>Important Notes</label>
                <textarea
                  value={importantNotes}
                  onChange={(e) => setImportantNotes(e.target.value)}
                  className={styles.textarea}
                  rows={3}
                />
              </div>

              {success && <div className={styles.successMessage}>{success}</div>}

              <button type="submit" className={styles.submitButton}>
                Create Job
              </button>
            </form>
          </div>

          {/* Child modals */}
          {openCreateRoomModal && (
            <CreateRoomModal
              open={openCreateRoomModal}
              handleClose={() => setOpenCreateRoomModal(false)}
              handleCreateRoom={handleCreateRoom}
            />
          )}

          {isEditModalOpen && (
            <EditRoomModal
              open={isEditModalOpen}
              handleClose={handleCloseEditModal}
              roomData={editingRoom}
              handleEditRoom={handleEditRoom}
            />
          )}

          {openAddRoomsModal && (
            <AddRoomsModal
              open={openAddRoomsModal}
              handleClose={() => setOpenAddRoomsModal(false)}
              availableRooms={availableRooms}
              handleAddRooms={handleAddRooms}
              existingRooms={selectedRooms}
              orgId={orgId}
              fetchAvailableRooms={fetchAvailableRooms}
            />
          )}

          {openCreateCustomerModal && (
            <CreateCustomerModal
              open={openCreateCustomerModal}
              handleClose={() => setOpenCreateCustomerModal(false)}
              handleCreateCustomer={handleCreateCustomer}
            />
          )}

          {openAddCleanerModal && (
            <AddCleanerModal
              open={openAddCleanerModal}
              handleClose={() => setOpenAddCleanerModal(false)}
              handleAddCleaner={handleAddCleaner}
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default CreateJobModal;
