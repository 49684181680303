// src/components/ExpenseRequests.js
import React, { useState, useEffect } from 'react';
import { 
  CircularProgress, Typography, Tabs, Tab, List, ListItem, ListItemText, Paper,
  Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, Stack, Chip,
  Avatar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { collection, query, where, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import { Clock, DollarSign, MapPin, MapPinned, Package, Camera, User, SprayCan } from 'lucide-react';
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import moment from 'moment-timezone';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  margin: theme.spacing(2, 0),
  overflow: 'hidden'
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:last-child': {
    borderBottom: 'none'
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer'
  }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2)
}));

const DetailItem = ({ icon: Icon, label, value }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
    <Icon size={20} color="primary" />
    <Box>
      <Typography variant="caption" color="textSecondary" display="block">
        {label}
      </Typography>
      <Typography variant="body1">
        {value}
      </Typography>
    </Box>
  </Box>
);

const ExpenseRequests = () => {
  const [user, setUser] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [cleaners, setCleaners] = useState({});
  const [tab, setTab] = useState('current');
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  // Authentication effect
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchUserOrgId(currentUser.uid);
      } else {
        setLoading(false);
        setError('User not authenticated');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (orgId) {
      fetchExpenses();
    }
  }, [orgId, tab]);

  const fetchUserOrgId = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }
      const userOrgId = userDoc.data().orgId;
      setOrgId(userOrgId);
    } catch (err) {
      console.error("Error fetching user's organization ID:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchCleanerDetails = async (cleanerId) => {
    try {
      const cleanerRef = doc(db, 'users', cleanerId);
      const cleanerDoc = await getDoc(cleanerRef);
      if (cleanerDoc.exists()) {
        return cleanerDoc.data();
      }
      return null;
    } catch (err) {
      console.error('Error fetching cleaner details:', err);
      return null;
    }
  };

  const fetchExpenses = async () => {
    if (!orgId) return;
    
    setLoading(true);
    try {
      const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
      const cleanersSnapshot = await getDocs(cleanersRef);
      
      // Fetch all cleaners first
      const cleanersData = {};
      await Promise.all(cleanersSnapshot.docs.map(async (cleanerDoc) => {
        const cleanerDetails = await fetchCleanerDetails(cleanerDoc.id);
        if (cleanerDetails) {
          cleanersData[cleanerDoc.id] = cleanerDetails;
        }
      }));
      setCleaners(cleanersData);

      // Then fetch expenses
      const allExpenses = await Promise.all(
        cleanersSnapshot.docs.map(async (cleanerDoc) => {
          const expensesRef = collection(
            db, 
            'organizations', 
            orgId, 
            'cleaners', 
            cleanerDoc.id, 
            'expenses'
          );
          
          const expensesQuery = query(
            expensesRef,
            where('status', 'in', 
              tab === 'current' 
                ? ['pending'] 
                : ['processed', 'rejected']
            )
          );
          
          const expensesSnapshot = await getDocs(expensesQuery);
          
          return expensesSnapshot.docs.map(doc => ({
            id: doc.id,
            cleanerId: cleanerDoc.id,
            ...doc.data(),
            path: doc.ref.path
          }));
        })
      );
      
      const flattenedExpenses = allExpenses
        .flat()
        .sort((a, b) => 
          (b.createdAt?.toDate?.() || b.createdAt) - 
          (a.createdAt?.toDate?.() || a.createdAt)
        );
      
      setExpenses(flattenedExpenses);
    } catch (err) {
      console.error('Error fetching expenses:', err);
      setError('Failed to load expenses');
    } finally {
      setLoading(false);
    }
  };

  const getCleanerName = (cleanerId) => {
    const cleaner = cleaners[cleanerId];
    if (!cleaner) return 'Unknown Cleaner';
    return `${cleaner.firstName || ''} ${cleaner.lastName || ''}`.trim() || cleaner.email;
  };

  const getCleanerInitials = (cleanerId) => {
    const cleaner = cleaners[cleanerId];
    if (!cleaner) return 'U';
    if (cleaner.firstName && cleaner.lastName) {
      return `${cleaner.firstName[0]}${cleaner.lastName[0]}`;
    }
    return cleaner.email?.[0]?.toUpperCase() || 'U';
  };

  const handleExpenseClick = (expense) => {
    setSelectedExpense(expense);
    setDialogOpen(true);
  };

  const handleApprove = async () => {
    if (!selectedExpense?.path) return;
    
    try {
      const expenseRef = doc(db, selectedExpense.path);
      await updateDoc(expenseRef, {
        status: 'processed',
        updatedAt: new Date(),
        updatedBy: user.uid
      });
      
      setDialogOpen(false);
      fetchExpenses();
    } catch (err) {
      console.error('Error approving expense:', err);
      setError('Failed to approve expense');
    }
  };

  const handleReject = async () => {
    if (!selectedExpense?.path) return;
    
    try {
      const expenseRef = doc(db, selectedExpense.path);
      await updateDoc(expenseRef, {
        status: 'rejected',
        updatedAt: new Date(),
        updatedBy: user.uid
      });
      
      setDialogOpen(false);
      fetchExpenses();
    } catch (err) {
      console.error('Error rejecting expense:', err);
      setError('Failed to reject expense');
    }
  };

  if (error) {
    return <div className="p-4 text-red-500">{error}</div>;
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Expense Requests
        </Typography>
        
        <Tabs 
          value={tab} 
          onChange={(e, newValue) => setTab(newValue)}
          sx={{
            '& .MuiTab-root': {
              minWidth: 120,
              fontWeight: 500
            }
          }}
        >
          <Tab 
            value="current" 
            label="Current Expenses" 
            sx={{ textTransform: 'none' }}
          />
          <Tab 
            value="past" 
            label="Past Expenses" 
            sx={{ textTransform: 'none' }}
          />
        </Tabs>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      ) : expenses.length === 0 ? (
        <StyledPaper>
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <Typography color="textSecondary">
              No {tab === 'current' ? 'pending' : 'processed'} expenses found
            </Typography>
          </Box>
        </StyledPaper>
      ) : (
        <List>
          {expenses.map((expense) => (
            <ListItem
              key={expense.id}
              onClick={() => handleExpenseClick(expense)}
              sx={{
                borderBottom: '1px solid',
                borderColor: 'divider',
                '&:hover': { bgcolor: 'action.hover' },
                cursor: 'pointer'
              }}
            >
              <Stack direction="row" alignItems="center" spacing={2} width="100%">
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                  {getCleanerInitials(expense.cleanerId)}
                </Avatar>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {expense.type === 'Gas' ? (
                        <MapPin size={20} />
                      ) : (
                        <Package size={20} />
                      )}
                      <Typography variant="subtitle1">
                        {expense.type} Expense
                      </Typography>
                      <Chip 
                        size="small"
                        label={expense.status === 'pending' ? 'Pending' : 
                              expense.status === 'rejected' ? 'Rejected' : 'Processed'}
                        color={expense.status === 'pending' ? 'warning' : 
                              expense.status === 'rejected' ? 'error' : 'success'}
                        sx={{ ml: 1 }}
                      />
                    </Box>
                  }
                  secondary={
                    <Stack direction="row" spacing={2} alignItems="center" mt={0.5}>
                      <Typography variant="body2" color="textSecondary">
                        {getCleanerName(expense.cleanerId)}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Clock size={16} />
                        {moment(expense.createdAt?.toDate()).format('MMM D, YYYY')}
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {expense.type === 'Gas' ? (
                          <>
                            <MapPinned size={16} />
                            {`${expense.miles} miles`}
                          </>
                        ) : (
                          <>
                            <SprayCan size={16} />
                            {`$${expense.cost.toFixed(2)}`}
                          </>
                        )}
                    </Box>
                    </Stack>
                  }
                />
              </Stack>
            </ListItem>
          ))}
        </List>
      )}

      <Dialog 
        open={dialogOpen} 
        onClose={() => setDialogOpen(false)} 
        maxWidth="sm" 
        fullWidth
      >
        {selectedExpense && (
          <>
            <DialogTitle>
              <Stack spacing={2}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {selectedExpense.type === 'Gas' ? (
                    <MapPin size={24} />
                  ) : (
                    <Package size={24} />
                  )}
                  <Typography variant="h6">
                    {selectedExpense.type} Expense Details
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    {getCleanerInitials(selectedExpense.cleanerId)}
                  </Avatar>
                  <Typography>
                    {getCleanerName(selectedExpense.cleanerId)}
                  </Typography>
                </Stack>
              </Stack>
            </DialogTitle>
            
            <DialogContent>
              <Box sx={{ pt: 2 }}>
                <Stack spacing={2}>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Clock size={20} />
                    <Box>
                      <Typography variant="caption" color="textSecondary">
                        Date Submitted
                      </Typography>
                      <Typography>
                        {moment(selectedExpense.date.toDate()).format('MMMM D, YYYY')}
                      </Typography>
                    </Box>
                  </Box>

                  {selectedExpense.type === 'Gas' ? (
                    <>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <User size={20} />
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Client
                          </Typography>
                          <Typography>
                            {selectedExpense.clientName}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <MapPinned size={20} />
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Miles
                          </Typography>
                          <Typography>
                            {selectedExpense.miles} miles
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Package size={20} />
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Products
                          </Typography>
                          <Typography>
                            {selectedExpense.products}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <DollarSign size={20} />
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Cost
                          </Typography>
                          <Typography>
                            ${selectedExpense.cost.toFixed(2)}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}

                  {selectedExpense.photoUrl && (
                    <Box>
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
                        <Camera size={20} />
                        <Typography variant="caption" color="textSecondary">
                          {selectedExpense.type === 'Gas' ? 'Map Screenshot' : 'Receipt'}
                        </Typography>
                      </Box>
                      <Box 
                        component="img"
                        src={selectedExpense.photoUrl} 
                        alt="Expense documentation"
                        sx={{
                          width: '100%',
                          height: 'auto',
                          borderRadius: 1,
                          border: '1px solid',
                          borderColor: 'divider'
                        }}
                      />
                    </Box>
                  )}
                </Stack>
              </Box>
            </DialogContent>
            
            <DialogActions>
              <Stack direction="row" spacing={2}>
                <Button 
                  onClick={() => setDialogOpen(false)}
                  variant="outlined"
                  sx={{
                    bgcolor: 'background.paper',
                    borderColor: 'divider',
                    color: 'text.primary',
                    '&:hover': {
                      bgcolor: 'action.hover',
                      borderColor: 'divider'
                    }
                  }}
                >
                  Cancel
                </Button>
                {tab === 'current' && (
                  <>
                    <Button 
                      onClick={handleReject}
                      sx={(theme) => ({
                        bgcolor: theme.palette.error.main,
                        color: theme.palette.error.contrastText,
                        '&:hover': {
                          bgcolor: theme.palette.error.dark
                        }
                      })}
                    >
                      Reject
                    </Button>
                    <Button 
                      onClick={handleApprove}
                      variant="contained"
                    >
                      Approve
                    </Button>
                  </>
                )}
              </Stack>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default ExpenseRequests;